import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Image from 'src/components/Image'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

function ViewTable({ data, onSelect }) {
  return (
    <Root>
      {data.map((elm, index) => {
        let src = ''
        if (elm?.type === 'web') {
          if (elm?.ext === 'canv') {
            src = `https://www.canva.com/design/${elm?.url}/screen`
          } else {
            src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
          }
        } else {
          src = elm?.path_thumbnail
        }
        return (
          <Item key={uuidv4()} onClick={() => onSelect?.(elm)}>
            <WrapImg>
              <Image alt={elm?.fileName ?? elm?.title} src={src} RenderImg={Img}></Image>
              {elm.select && (
                <Select>
                  <FontAwesomeIcon icon={faCheck} />
                </Select>
              )}
              {elm.status === 0 && (
                <Block>
                  <FontAwesomeIcon icon={faLock} />
                </Block>
              )}
            </WrapImg>
            <WrapContent>
              <WrapName>{elm?.name}</WrapName>
              <WrapType>{elm?.type}</WrapType>
              <WrapType>{elm?.size} KB</WrapType>
              <WrapTime>{elm?.updated_at}</WrapTime>
            </WrapContent>
          </Item>
        )
      })}
    </Root>
  )
}

export default ViewTable

const Root = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Item = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-bottom: 12px;
  cursor: pointer;
`

const WrapImg = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(220, 220, 220);
  border-radius: 15px;
  position: relative;
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 15px;
`

const Block = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
  }
`

const Select = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgba(56, 175, 202, 0.7);
  border-radius: 15px;

  svg {
    font-size: 40px;
    color: #fff;
  }
`

const WrapContent = styled.div`
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  align-items: center;
  p {
    margin-left: 0px;
    :first-child {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      margin-left: 12px;
    }
    :last-child {
      padding-right: 0;
    }
  }
`

const WrapName = styled.p`
  width: 30%;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(134, 134, 134);
`

const WrapType = styled(WrapName)`
  width: 20%;
`

const WrapTime = styled(WrapName)``
