import createAction from '../action'

export const AuthActionTypes = Object.freeze({
  SET_PROFILE: 'user/SET_PROFILE',
  SET_IS_AUTH: 'user/SET_IS_AUTH',
  ACTIVE_SUCCESS: 'user/ACTIVE_SUCCESS',
})

const onSetUserProfile = (profile) => createAction(AuthActionTypes.SET_PROFILE, profile)

const onSetIsAuth = (value) => createAction(AuthActionTypes.SET_IS_AUTH, value)
const activeAccountSuccess = (value) => createAction(AuthActionTypes.ACTIVE_SUCCESS, value)

const AuthActions = {
  onSetUserProfile,
  onSetIsAuth,
  activeAccountSuccess,
}

export default AuthActions
