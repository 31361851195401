import React, { useCallback, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'rc-time-picker/assets/index.css'
import styled from 'styled-components'
import { Provider } from 'react-redux'
import authApi from './api/auth'
import MainRoute from './page'
import * as storage from './helpers/storage'
import store from './redux/store'
import AuthActions from './redux/auth/action'
import Image from './components/Image'
import handleError from './api/handle-error'
import NavigationGuard from './components/NavigationGuard'
import './App.css'

const Root = styled.div`
  * {
    font-family: 'Roboto', sans-serif;
    /* font-size: 14px;
    font-weight: 400; */
  }
  height: 100vh;
  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  /* 
  & .react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.6) !important;
  } */
`

const LoadingRoot = styled(Root)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast--success {
    color: var(--primary-color);
    background-color: #343434;
  }
  .Toastify__toast--error {
    color: #fff;
    background-color: #343434;
  }
  .Toastify__toast--info {
    color: #fff;
    background-color: #343434;
  }
  .Toastify__toast-body {
    margin: 0;
  }
  .Toastify__toast {
    min-height: 0;
  }
`

export default function () {
  useEffect(() => {
    const initDevice = storage.getDevice()

    if (!initDevice) {
      authApi
        .initDevice({ device_type: 0 })
        .then((res) => {
          if (!res?.success) {
            throw res
          }
          const { data } = res
          storage.setDevice(JSON.stringify(data))
        })
        .catch((err) => handleError('initDevice', err))
    }
  }, [])

  // useEffect(() => {
  //   window.eventsChange = {}
  //   window.addChange = function (groupName, code, name) {
  //     const id = `${groupName}|${code}`
  //     window.eventsChange[id] = name;

  //     window.addEventListener("beforeunload", function (e) {
  //       let eventName = window.hasChange()

  //       if (eventName !== "") {

  //         var confirmationMessage = "\o/";

  //         (e || window.event).returnValue = confirmationMessage;     //Gecko + IE
  //         return confirmationMessage;
  //       }                                                            //Webkit, Safari, Chrome etc.
  //     });
  //   }

  //   window.removeChange = function (groupName, code) {
  //     let id = `${groupName}|`

  //     if (groupName === "") {
  //       window.eventsChange = {}
  //       return
  //     }

  //     if (typeof code !== 'string') {
  //       for (var i in window.eventsChange) {
  //         if (window.eventsChange[i] === null) {
  //           continue
  //         }
  //         if (window.eventsChange[i].indexOf(id) < 0) {
  //           continue;
  //         }
  //         window.eventsChange[i] = null;

  //       }
  //       return
  //     }
  //     id = `${id}${code}`

  //     window.eventsChange[id] = null;
  //   }

  //   window.hasChange = function () {

  //     let eventName = ""
  //     for (var i in window.eventsChange) {
  //       if (window.eventsChange[i] === null) {
  //         continue
  //       }
  //       eventName = window.eventsChange[i]
  //       break
  //     }
  //     console.log('hasChange', eventName)
  //     return eventName;
  //   }

  // }, [])

  return (
    <Provider store={store}>
      <Root>
        <MainRoute />
      </Root>

      <StyledToastContainer position="bottom-left" hideProgressBar toastClassName="toast" limit={3} autoClose={2000} />
    </Provider>
  )
}
