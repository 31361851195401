import React, { useEffect } from 'react'
import { useModal } from 'react-hooks-use-modal'
import styled from 'styled-components'

function CustomModalConfirm({ title, onYes, onClose, isShow, type }) {
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })

  useEffect(() => {
    if (isShow) {
      open()
    } else {
      close()
    }
  }, [isShow])

  useEffect(() => {
    if (!isOpen) {
      onClose()
    }
  }, [isOpen])

  return (
    <Modal>
      <Root>
        <Title>{title || 'Are you sure?'}</Title>
        <WrapButton>
          <BtnYes onClick={onYes}>{type === 'delete' ? 'Yes, delete' : 'Yes'}</BtnYes>
          <BtnNo onClick={onClose}>{type === 'delete' ? 'No, keep' : 'No'}</BtnNo>
        </WrapButton>
      </Root>
    </Modal>
  )
}

export default CustomModalConfirm

const Root = styled.div`
  width: 320px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding: 30px;
  background-color: #fff;
`

const Title = styled.p`
  font-size: 14px;
  font-weight: 500;
`
const WrapButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`

const BtnYes = styled.button`
  width: calc(50% - 8px);
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  background: #ff6600;
  color: #fff;
  border-radius: 6px;
  border: none;
  cursor: pointer;
`

const BtnNo = styled.button`
  width: calc(50% - 8px);
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: #5f5f64;
  background: none;
  border-radius: 6px;
  border: 1px solid #c3c8cd;
  cursor: pointer;
`
