import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import Image from 'src/components/Image'
import { getHHMMSSfromDurationNumber } from 'src/utils/render-time'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

function ViewList({ dataSource, onSelect, fromPage }) {
  return (
    <Root>
      <WrapHeader>
        <div className="header-name">
          <p>Name</p>
        </div>
        <div className="header-center">
          <p>{fromPage == 'web' ? 'Duration' : 'Size'}</p>
        </div>
        <div className="header-center">
          <p>type</p>
        </div>
        <div className="header-time">
          <p>date</p>
        </div>
      </WrapHeader>
      {dataSource.map((elm, index) => {
        let src = ''
        if (elm?.type === 'web') {
          if (elm?.ext === 'canv') {
            src = `https://www.canva.com/design/${elm?.url}/screen`
          } else {
            src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
          }
        } else {
          src = elm?.path_thumbnail || elm?.thumbnail
        }
        return (
          <Item key={uuidv4()} onClick={() => onSelect?.(elm)} selected={elm?.select}>
            <WrapContent>
              <WrapName>
                <WrapImgName>
                  <Image alt={elm?.fileName ?? elm?.title} src={src} RenderImg={Img}></Image>
                  {elm.status === 0 && (
                    <Block>
                      <FontAwesomeIcon icon={faLock} />
                    </Block>
                  )}
                </WrapImgName>
                <span>{elm?.name}</span>
              </WrapName>
              <WrapType>
                {elm?.type === 'image' && `${elm?.size} KB`}
                {elm?.type === 'video' &&
                  `${getHHMMSSfromDurationNumber(elm?.duration)} / ${(elm?.size / 1024).toFixed(2)} MB`}
                {(elm?.type === 'canva' || elm?.type === 'youtube') && `${getHHMMSSfromDurationNumber(elm?.duration)}`}
              </WrapType>
              <WrapType>{elm?.type}</WrapType>
              <WrapTime>{moment().format('DD-MM-YYYY HH:mm:ss')}</WrapTime>
            </WrapContent>
          </Item>
        )
      })}
    </Root>
  )
}

export default ViewList

const Root = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const WrapHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 15px 15px 0 0;

  p {
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
  }

  .header-name {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-top-left-radius: 15px;
    padding: 0 10px;
  }

  .header-center {
    width: 25%;
    min-width: 55px;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    p {
      text-align: center;
    }
  }

  .header-time {
    width: 20%;
    min-width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-top-right-radius: 15px;

    p {
      text-align: center;
    }
  }
`

const Item = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  border: 1px solid #f4f4f4;
  cursor: pointer;
  background: ${(props) => (props.selected ? 'rgba(56, 175, 202, .7)' : '#fff')};

  :hover {
    background: ${(props) => (props.selected ? 'rgba(56, 175, 202, .7)' : 'rgb(220 244 249 / 70%)')};
  }
`

const WrapImgName = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(220, 220, 220);
  border-radius: 15px;
  position: relative;
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 15px;
`
const Block = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
  }
`
const Select = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgba(56, 175, 202, 0.7);
  border-radius: 15px;

  svg {
    font-size: 40px;
    color: #fff;
  }
`

// rgb(220 244 249 / 70%) !important

const WrapContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const WrapName = styled.div`
  width: 30%;
  max-width: 50%;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(134, 134, 134);
  overflow: hidden;
  border-right: 1px solid #fff;

  span {
    width: calc(100% - 80px);
    padding-left: 10px;
    word-wrap: break-word;
    :last-child {
      padding-right: 0;
    }
  }
`

const WrapType = styled.div`
  width: 25%;
  height: 50%;
  min-width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  /* color: rgb(134, 134, 134); */
  border-right: 1px solid #fff;
`

const WrapTime = styled.div`
  width: 20%;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  /* color: rgb(134, 134, 134); */
`
