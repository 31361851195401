import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import ScrollToTop from 'src/components/ScrollToTop'
import styled, { css, keyframes } from 'styled-components'
import { playlistApi } from '../../../api/playlistApi'
import Loading from '../../../components/Loading'
import { handleError } from '../../../utils/handle-error'
import { Root } from '../../Home/components/ContentHomePage'
import MenuLeft from './components/MenuLeft'
import PlayListsContent from './components/PlayListsContent'

const limit = 50
var timeout

function Playlists({ isMobile }) {
  const Param = useParams()
  const playlistId = Param?.playlistId

  // console.log('paht', pathName)

  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [playlist, setPlayList] = useState([])
  const refScroll = useRef(null)
  const refLoadMore = useRef(null)
  const [isLastPage, setIsLastPage] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [page, setPage] = useState(1)

  useEffect(() => {
    // const resetPage = 1
    // setPage(resetPage)
    console.log('mounted --->')
    setIsLastPage(false)
    setPage(1)
    setLoadingText('')
    handleGetPlaylist(1, limit)
  }, [playlistId])

  useEffect(() => {
    if (isLastPage) {
      setLoadingText('- END -')
    }
    // console.log('after scroll -->', refLoadMore, isLastPage)
    if (refLoadMore && refLoadMore.current && !isLastPage) {
      // console.log('addEventListener ---> ')
      refLoadMore?.current.addEventListener('scroll', handleScroll, false)
    }
    return () => {
      if (refLoadMore.current) {
        // console.log('removeEventListener --->')
        refLoadMore?.current.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [playlist, playlistId])

  const handleGetPlaylist = (page, limit) => {
    setIsLoading(true)
    playlistApi
      .getPlaylist(page, limit)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const { playlists } = res.data
        if (playlists.length < limit) {
          setIsLastPage(true)
        } else {
          setIsLastPage(false)
        }
        setPlayList(playlists)
      })
      .catch((err) => {
        handleError('getPlaylist', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleScroll = (e) => {
    console.log('scroll --->', e.target.scrollTop, e.target.clientHeight, e.target.scrollHeight)
    if (isLastPage) {
      setLoadingText('- END -')
      return
    }
    clearTimeout(timeout)
    setLoadingText('Loading ...')
    timeout = setTimeout(() => {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 500) {
        handeLoadMore()
      }
    }, 100)
  }

  const handeLoadMore = () => {
    const pageNum = page + 1
    setPage(pageNum)

    playlistApi
      .getPlaylist(pageNum, limit)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const { playlists } = res.data
        if (playlists.length < limit) {
          setIsLastPage(true)
        } else {
          setIsLastPage(false)
        }
        setPlayList([...playlist, ...playlists])
      })
      .catch((err) => {
        if (err) {
          handleError('getPlaylist', err)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const renderScrollToTOp = useCallback(() => {
    let flag = true
    if (isMobile && showMenuDetail) {
      flag = false
    }

    return flag ? <ScrollToTop refScroll={refLoadMore} /> : ''
  }, [showMenuDetail, isMobile])

  return (
    <>
      {!playlistId ? (
        <WrapContent>
          <WrapRoot>
            <Root ref={refScroll}>
              <Content ref={refLoadMore}>
                <Title>
                  <p>Playlists</p>

                  {isMobile && (
                    <WrapRigth onClick={() => setShowMenuDetail(true)}>
                      <p> New Playlist</p>
                      <WrapIcon>
                        <FontAwesomeIcon icon={faPlus} />
                      </WrapIcon>
                    </WrapRigth>
                  )}
                </Title>
                <PlayListsContent playlist={playlist} />
                {loadingText !== '' && (
                  <WrapLoadingBottom>
                    <WrapLoading>
                      {loadingText !== '- END -' && (
                        <Loader>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderText></LoaderText>
                        </Loader>
                      )}
                      {loadingText === '- END -' && <WrapEnd>- END -</WrapEnd>}
                    </WrapLoading>
                  </WrapLoadingBottom>
                )}
              </Content>

              {renderScrollToTOp()}
              {/* <ScrollToTop refScroll={refLoadMore} /> */}
            </Root>
          </WrapRoot>
          <MenuLeft isMobile={isMobile} showMenuDetail={showMenuDetail} onClose={() => setShowMenuDetail(false)} />
        </WrapContent>
      ) : (
        <Outlet />
      )}
      {isLoading && <Loading />}
    </>
  )
}

export default Playlists

const WrapContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`

const WrapRoot = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 30px;
`

const WrapRigth = styled.div`
  width: 150px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 1px solid #ccc;
  p {
    font-size: 14px;
    color: #000;
    margin-right: 5px;
    text-transform: none;
  }
`

const WrapIcon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 50%;
  border: 1px solid rgb(255, 102, 0); */
  color: rgb(255, 102, 0);
  cursor: pointer;
`
const WrapLoadingBottom = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
`
const WrapLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = styled.div`
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const LoadingAniText = keyframes`
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
`

const LoaderAniDot = keyframes`
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
`

const LoaderDot = styled.div`
  animation-name: ${LoaderAniDot};
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;

  :first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }
  :nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  :nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  :nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  :nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  :nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }
`
const LoaderText = styled.div`
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
  :after {
    content: 'Loading';
    font-weight: bold;
    animation-name: ${LoadingAniText};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`
const WrapEnd = styled.div`
  margin-top: 40px;
  font-weight: 500;
`
