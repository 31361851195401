import React, { useEffect, useState, useRef } from 'react'
import { Keyboard, Navigation, Pagination, Mousewheel, FreeMode } from 'swiper'
import Image from 'src/components/Image'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/bundle'
import { faCirclePlay, faSearch, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import ImgaeV2 from 'src/components/ImageV2'
import ImgaePlaylistMain from './ImagePlaylistMain'
import ImagePlaylistMain from './ImagePlaylistMain'

export default function SwiperPlaylistItem({ listDataSource, onClickSlideItem }) {
  // console.log('🚀 ~ file: SwiperPlaylistItem.js ~ line 19 ~ SwiperPlaylistItem ~ listDataSource', listDataSource)
  const [canvaWidths, setCanvaWidths] = useState({})

  const swiper = useRef(null)

  const setSwiper = (newSwiper) => {
    swiper.current = newSwiper
  }

  const handleUpdateSwiper = () => {
    swiper.current.update()
  }

  return (
    <Root>
      <Swiper
        key={uuidv4()}
        direction={'horizontal'}
        slidesPerView={'auto'}
        spaceBetween={10}
        onSwiper={setSwiper}
        navigation={true}
        keyboard={{
          enabled: false,
        }}
        modules={[Keyboard, Pagination, Navigation, Mousewheel, FreeMode]}
        preloadImages={false}
        lazy={true}
        freeMode={true}
        mousewheel={{
          releaseOnEdges: true,
          forceToAxis: true,
        }}
      >
        {listDataSource?.map((elm) => {
          let id = elm.idv4 ?? elm.id

          return (
            <SwiperSlide key={uuidv4()} onClick={onClickSlideItem}>
              {elm?.type !== 'web' && (
                <ImagePlaylistMain
                  item={elm}
                  RenderImg={ImgMainPlaylist}
                  onLoaded={handleUpdateSwiper}
                ></ImagePlaylistMain>
              )}

              {elm?.type === 'web' && elm?.ext === 'yout' && (
                <ImgaeV2
                  fromPage="from_playlist_main_item"
                  item={elm}
                  isMultiImg={true}
                  RenderImg={YoutubeImg}
                  showName={false}
                ></ImgaeV2>
              )}

              {elm?.type === 'web' && elm?.ext === 'canv' && (
                <ImgaeV2
                  fromPage="from_playlist_main_item"
                  item={elm}
                  isMultiImg={true}
                  style={{ width: canvaWidths[id] }}
                  RenderImg={CanvaImg}
                  showName={false}
                  onLoading={(e) => {
                    const value = e.target.width
                    if (typeof canvaWidths[id] !== 'number') {
                      setCanvaWidths({ ...canvaWidths, [id]: value })
                    }
                  }}
                ></ImgaeV2>
              )}

              {elm.status === 0 && (
                <Block>
                  <FontAwesomeIcon icon={faLock} />
                </Block>
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  height: 100%;

  & .swiper {
    position: relative;
    display: flex;
  }
`

const YoutubeImg = styled.img`
  width: 238px;
  max-width: 238px;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  pointer-events: none;
`
const CanvaImg = styled.img`
  width: auto;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  pointer-events: none;
`

const ImgMainPlaylist = styled.img`
  width: auto;
  max-width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
  pointer-events: none;
`

const Block = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
    width: 24px;
    height: 24px;
  }
`
