import { faVideoCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useState } from 'react'
import favouriteApi from 'src/api/favourite'
import HttpRequest from 'src/api/HttpRequest'
import useCheckIsMobile from 'src/hooks/useCheckIsMobile'
import { getHHMMSSfromDurationNumber } from 'src/utils/render-time'
import styled from 'styled-components'
import CanvaLogo from '../../../../../../assets/image/canva.svg'
import defaultImage from '../../../../../../assets/image/defaultImage.png'
import YoutubeLogo from '../../../../../../assets/image/youtube.svg'

export default function ImagePlaylistMain({ item, RenderImg, onLoaded }) {
  const [src, setSrc] = useState(item.path_thumbnail)

  const isMobile = useCheckIsMobile()

  const handleError = (e) => {
    setSrc(defaultImage)
    // onLoaded()
  }

  const handleOnload = () => {
    onLoaded()
  }

  const propsTypeImg = {
    alt: item?.name || item?.title,
    src,
    onError: handleError,
    onLoad: onLoaded,
  }

  return (
    <Root>
      <WrapImage isMobile={isMobile}>
        {item.type === 'video' && (
          <>
            <TimeOfVideo>{getHHMMSSfromDurationNumber(item?.duration)}</TimeOfVideo>
            <IconVideo>
              <FontAwesomeIcon icon={faVideoCamera} />
            </IconVideo>
          </>
        )}
        {item.ext === 'canv' && (
          <>
            <TimeOfVideo>{getHHMMSSfromDurationNumber(item?.duration)}</TimeOfVideo>
            <Icon>
              <img src={CanvaLogo} />
            </Icon>
          </>
        )}
        {item.ext === 'yout' && (
          <>
            <TimeOfVideo>{getHHMMSSfromDurationNumber(item?.duration)}</TimeOfVideo>
            <Icon>
              <img src={YoutubeLogo} />
            </Icon>
          </>
        )}
        <RenderImg {...propsTypeImg} />
      </WrapImage>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: ${(props) => (props.fromPage == 'from_playlist_main_item' ? '' : ' 1px solid #ddd')}; */
  border-radius: 15px;
`

const WrapImage = styled.div`
  width: 100%;
  height: ${(props) => (props.isMobile ? '132px' : '178px')};
  background-color: rgb(220, 220, 220);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Img = styled.img`
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
`

const WrapName = styled.p`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    height: 100%;
    color: rgb(134, 134, 134);
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px;
  }
`
const IconVideo = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 28px;
  display: flex;
  border-radius: 50%;
  height: 28px;
  background-color: #ccc;
  z-index: 10;
  pointer-events: none;
  svg {
    margin: auto;
    color: #fff;
  }
`

const Icon = styled.div`
  position: absolute;
  bottom: 6px;
  left: 15px;
  width: auto;
  background: #ccc;
  display: flex;
  height: 16px;
  z-index: 10;
  pointer-events: none;
  border-radius: 4px;
  svg {
    margin: auto;
    color: #fff;
  }
`

const TimeOfVideo = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  width: 60px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #626262;
  border-radius: 4px;
  z-index: 10;
  font-size: 14px;
  color: #fff;
  pointer-events: none;
`
