import axios from 'axios'
import md5 from 'crypto-js/md5'
import * as storage from '../helpers/storage'
import authApi from './auth'

const timeout = 15000

class HttpRequest {
  constructor() {
    this._requestedObj = {}
    this.isGetDevice = false
  }

  request({ url, method, data, params, headers }, key) {
    if (!key) {
      key = md5(
        JSON.stringify({
          url,
          method,
        })
      ).toString()
    }

    const preReq = this._requestedObj[key]
    if (preReq) {
      preReq.cancel()
      delete this._requestedObj[key]
    }

    const req = this._call({
      url,
      method,
      data,
      params,
      headers,
      cancelToken: new axios.CancelToken((cancel) => {
        this._requestedObj[key] = { cancel }
      }),
    })
    req.finally(() => {
      delete this._requestedObj[key]
    })
    return req
  }

  _call({ headers, ...rest }) {
    let token = storage.getToken()

    const initDevice = storage.getDevice()
    if (!initDevice && !this.isGetDevice) {
      this.isGetDevice = true
      Promise.all(authApi.initDevice({ device_type: 0 }))
        .then((res) => {
          if (!res?.success) {
            throw res
          }
          const { data } = res
          storage.setDevice(JSON.stringify(data))
        })
        .catch((err) => console.log(err))
    }

    if (token.toLowerCase().indexOf('bearer') === -1) {
      token = 'Bearer ' + token
    }

    const device = storage.getDevice()

    // const device = storage.getDevice();
    // const bodyFormData = new URLSearchParams();
    // if (rest.data) {
    //   for (let key in rest.data) {
    //     bodyFormData.append(key, rest.data[key]);
    //   }
    // }

    return axios
      .request({
        ...rest,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'DEVICE-CODE': device,
          Authorization: token,
          ...headers,
        },
        timeout: timeout,
      })
      .then((res) => res.data)
      .then((res) => {
        if (res.code !== 200) {
          throw res
        }
        return res
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          throw err
        }
      })
  }
}

export default new HttpRequest()
