import { faLock, faVideoCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import Image from 'src/components/Image'
import ImgaeV2 from 'src/components/ImageV2'
import { getHHMMSSfromDurationNumber } from 'src/utils/render-time'
import styled from 'styled-components'

const Checkmark = ({ selected }) => (
  <div style={selected ? { left: '4px', top: '4px', position: 'absolute', zIndex: '1' } : { display: 'none' }}>
    <svg style={{ fill: 'white', position: 'absolute' }} width="24px" height="24px">
      <circle cx="12.5" cy="12.2" r="8.292" />
    </svg>
    <svg style={{ fill: '#06befa', position: 'absolute' }} width="24px" height="24px">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
)

const defaultImgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
  objectFit: 'contain',
  width: '100%',
  height: 'auto',
  minWidth: '25%',
}
const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
}
const cont = {
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
}

const SelectedImage = ({
  variant = 'normal',
  index,
  photo,
  margin,
  direction,
  top,
  left,
  onSelectedItem,
  selected,
  truncate,
  imgStyle = defaultImgStyle,
  fromPage = '',
}) => {
  //calculate x,y scale
  const [count, setCount] = useState(photo?.countInPlayList)

  const sx = (100 - (30 / (photo?.width ?? 1)) * 100) / 100
  const sy = (100 - (30 / (photo?.height ?? 1)) * 100) / 100
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`

  if (direction === 'column') {
    cont.position = 'absolute'
    cont.left = left
    cont.top = top
  }

  const handleOnClick = (e) => {
    onSelectedItem?.(photo)
    setCount((pre) => pre + 1)
  }

  useEffect(() => {
    setCount(photo?.countInPlayList)
  }, [photo])

  const imageName = photo?.name ?? photo?.title

  const Root = useMemo(() => {
    switch (variant) {
      case 'normal':
        return Normal
      case 'secondary':
        return Secondary

      default:
        break
    }
  }, [variant])

  return (
    <Root onClick={handleOnClick}>
      <div style={{ margin, ...cont }} className={'not-selected'}>
        <ShowCount>{count}</ShowCount>

        {photo?.status === 0 && (
          <Block onClick={handleOnClick}>
            <FontAwesomeIcon icon={faLock} />
          </Block>
        )}

        <ImgaeV2 item={photo} showCount={true} />
      </div>
      <style>{`.style-warp-img,.not-selected{position: relative};.not-selected:hover{outline:2px solid #06befa}`}</style>
    </Root>
  )
}

export default SelectedImage

const Normal = styled.div``

const Secondary = styled.div`
  padding: 2px;
  display: flex;
  width: 140px;
  height: 140px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > div {
    padding: 8px;
    height: 100%;
    width: 100%;
    :hover {
      background: #e6f7ff !important;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
`

const ShowCount = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #82dbff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 11;
`

const WrapName = styled.p`
  position: absolute;
  bottom: 6%;
  left: 0;
  right: 0;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 9;
`

const IconVideo = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 40px;
  display: flex;
  border-radius: 50%;
  height: 40px;
  background-color: #ccc;
  z-index: 10;
  pointer-events: none;
  svg {
    margin: auto;
    color: #fff;
  }
`

const Img = styled.img`
  border-radius: 8px;
  border: 1px solid #ccc;
`

const ImgNoneStyle = styled.img``

const Block = styled.div`
  position: absolute;
  bottom: 40px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
  }
`

const TimeOfVideo = styled.div`
  position: absolute;
  top: 25px;
  left: 20px;
  width: 50px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #626262;
  border-radius: 5px;
  z-index: 10;
  font-size: 14px;
  color: #fff;
  pointer-events: none;
`
const PhotoSize = styled.div`
  position: absolute;
  bottom: calc(6% + 28px);
  right: 0;
  left: 0;
  width: 65px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #626262;
  border-radius: 5px;
  z-index: 10;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
`
const TotalRate = styled.div`
  position: absolute;
  top: 25px;
  right: 20px;
  width: 65px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #626262;
  border-radius: 5px;
  z-index: 10;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
`
