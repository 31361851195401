import createAction from '../action'

export const UrlActionType = Object.freeze({
  POST_HAVE_CHANGE: 'url/POST_HAVE_CHANGE',
  RESET_HAVE_CHANGE: 'url/RESET_HAVE_CHANGE',
  CURRENT_PATH: 'url/CURRENT_PATH',
  NEXT_PATH: 'url/NEXT_PATH',
})

let eventChange = []
const postHaveChange = (group, id = '', name = '') => {
  const index = eventChange.indexOf(group)
  if (index === -1) {
    eventChange.push(group)
  }

  return createAction(UrlActionType.POST_HAVE_CHANGE)
}
const resetHaveChange = (group = '') => {
  // console.log('events', eventChange)
  if (group !== '') {
    const index = eventChange.indexOf(group)
    if (index > -1) {
      eventChange.splice(index, 1)
    }
    if (eventChange.length > 0) {
      return createAction(UrlActionType.POST_HAVE_CHANGE)
    }
  }
  return createAction(UrlActionType.RESET_HAVE_CHANGE)
}

const setCurrentPath = (path) => createAction(UrlActionType.CURRENT_PATH, path)

const setNextPath = (path) => createAction(UrlActionType.NEXT_PATH, path)

const UrlAction = {
  postHaveChange,
  resetHaveChange,
  setCurrentPath,
  setNextPath,
}

export default UrlAction
