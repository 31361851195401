import Tooltip from 'rc-tooltip'
// import { isMobile } from 'react-device-detect'
import React from 'react'
import useCheckIsMobile from 'src/hooks/useCheckIsMobile'

export default function Tooltipv2({ overlay, placement, children }) {
  const isMobile = useCheckIsMobile()
  return (
    <>
      {!isMobile ? (
        <Tooltip overlay={overlay} placement={placement}>
          {children}
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
