import React, { Component } from 'react';
import { convertStyle } from './utils';


import Tooltip from 'rc-tooltip';

/*
var decode = function(str) {
    const i = parseInt(str, 16);
    if (isNaN(i)) {
        return 0;
    }
    return i;
}
// const Icon = require('../FontPhotoIcon');
// const TouchableOpacity = isNative === true ? require('react-native').TouchableOpacity : undefined;
const createIconSet = require('react-native-vector-icons').createIconSet;

const glyphMap = {
  "fi-3-dot-hoz": decode("61"),
  "fi-ads": decode("62"),
  "fi-camera-plus": decode("67"),
  "fi-camera": decode("68"),
  "fi-square-uncheck": decode("6c"),
  "fi-checked-circle": decode("6d"),
  "fi-calendar": decode("63"),
  "fi-category-cms": decode("66"),
  "fi-checked": decode("69"),
  "fi-square-checked": decode("6a"),
  "fi-comment": decode("6e"),
  "fi-comment-question": decode("6f"),
  "fi-company": decode("70"),
  "fi-computer": decode("71"),
  "fi-course": decode("72"),
  "fi-customers": decode("73"),
  "fi-delete": decode("74"),
  "fi-deliver": decode("75"),
  "fi-department": decode("76"),
  "fi-3-dots-ver": decode("77"),
  "fi-down": decode("78"),
  "fi-down-circle": decode("79"),
  "fi-download": decode("7a"),
  "fi-edit": decode("41"),
  "fi-email": decode("42"),
  "fi-favourite": decode("43"),
  "fi-fb": decode("44"),
  "fi-gift": decode("45"),
  "fi-order-delivery": decode("46"),
  "fi-filter": decode("47"),
  "fi-flag": decode("48"),
  "fi-flash": decode("49"),
  "fi-right-circle": decode("4b"),
  "fi-fprint": decode("4c"),
  "fi-fullscr": decode("4d"),
  "fi-eye-hide": decode("4e"),
  "fi-home-btn": decode("4f"),
  "fi-bin-full": decode("50"),
  "fi-bin-empty": decode("51"),
  "fi-info": decode("54"),
  "fi-insta": decode("55"),
  "fi-lessions": decode("56"),
  "fi-levels": decode("57"),
  "fi-like": decode("58"),
  "fi-menu": decode("30"),
  "fi-money-bg": decode("32"),
  "fi-msg": decode("33"),
  "fi-noti-circle": decode("34"),
  "fi-noti": decode("35"),
  "fi-ok": decode("36"),
  "fi-order": decode("37"),
  "fi-pause": decode("38"),
  "fi-phone": decode("39"),
  "fi-phone-circle": decode("21"),
  "fi-picture": decode("22"),
  "fi-play-circle": decode("23"),
  "fi-plus-circle": decode("25"),
  "fi-plus": decode("26"),
  "fi-present": decode("27"),
  "fi-pricesie": decode("28"),
  "fi-products": decode("29"),
  "fi-products-cms": decode("2a"),
  "fi-profile": decode("2b"),
  "fi-promotion": decode("2c"),
  "fi-qr": decode("2d"),
  "fi-questions": decode("2e"),
  "fi-star": decode("2f"),
  "fi-ratio": decode("3a"),
  "fi-ratio-non": decode("3b"),
  "fi-report-circle": decode("3e"),
  "fi-sales-off": decode("3f"),
  "fi-search-btn": decode("40"),
  "fi-eye-show": decode("5b"),
  "fi-send-btn": decode("5d"),
  "fi-send-msg": decode("5e"),
  "fi-settings-black": decode("5f"),
  "fi-settings-white": decode("60"),
  "fi-settings-circle": decode("7b"),
  "fi-share": decode("7c"),
  "fi-shield": decode("7d"),
  "fi-students": decode("5c"),
  "fi-survey": decode("e000"),
  "fi-time": decode("e001"),
  "fi-twitter": decode("e002"),
  "fi-typing": decode("e003"),
  "fi-unfavourite": decode("e004"),
  "fi-unlike": decode("e005"),
  "fi-up": decode("e006"),
  "fi-up-circle": decode("e007"),
  "fi-user-role": decode("e008"),
  "fi-user-settings": decode("e009"),
  "fi-users-group": decode("e00a"),
  "fi-users": decode("e00b"),
  "fi-wifi": decode("e00c"),
  "fi-zalo": decode("e00e"),
  "fi-cart": decode("e00f"),
  "fi-back-btn": decode("e010"),
  "fi-filter-setting": decode("e011"),
  "fi-shoot": decode("e013"),
  "fi-play": decode("24"),
  "fi-menu-course": decode("e014"),
  "fi-menu-user": decode("e017"),
  "fi-menu-agency": decode("e018"),
  "fi-menu-finance": decode("e019"),
  "fi-menu-order": decode("e01d"),
  "fi-report": decode("3c"),
  "fi-menu-category": decode("6b"),
  "fi-menu-product": decode("e012"),
  "fi-menu-gift": decode("e015"),
  "fi-menu-setting": decode("e016"),
  "fi-menu-noti": decode("e01b"),
  "fi-reply": decode("e01c"),
  "fi-left": decode("64"),
  "fi-forward-right": decode("4a"),
  "fi-svg-computer": decode("e01a"),
  "fi-menu-left": decode("31"),
  "fi-mute": decode("e01e"),
  "fi-fullscreen": decode("e01f"),
  "fi-minimize": decode("e020"),
  "fi-unmute": decode("e021"),
  "fi-shopping": decode("e022"),
  "fi-folder": decode("e023"),
  "fi-next10-btn": decode("e024"),
  "fi-back10-btn": decode("e025"),
  "fi-next-track": decode("e026"),
  "fi-prev-track": decode("e027"),
  "fi-ibenefit-logo": decode("e028"),
  "fi-micoff-btn": decode("e029"),
  "fi-outcall": decode("e02a"),
  "fi-incall": decode("e02b"),
  "fi-camera-rotate": decode("3d"),
  "fi-idcard-front": decode("53"),
  "fi-idcard-back": decode("52"),
  "fi-waiting": decode("e02d"),
  "fi-error": decode("e02c"),
  "fi-error2": decode("e02e"),
  "fi-x-btn": decode("e00d"),
  "fi-back-btn": decode("65"),
  "fi-copy": decode("e02f"),
  "fi-next": decode("e030"),
  "fi-skip": decode("e031"),
  "fi-ok": decode("e032"),
  "fi-back": decode("e033"),
  "fi-slides": decode("7e"),
  "fi-x": decode("e034"),
  "fi-waiting2": decode("e035"),
  "fi-return": decode("e036"),
  "fi-products": decode("e037"),
  "fi-checked": decode("e038"),
  "fi-money": decode("e039"),
  "fi-new": decode("e03a"),
  "fi-money-collection": decode("e03b"),
  "fi-sort-az": decode("e03c"),
  "fi-grid": decode("e03d"),
  "fi-location": decode("59"),
  "fi-list": decode("e03e"),
  "fi-menu-btn": decode("5a"),
  "fi-locked": decode("e03f"),
  "fi-unlock": decode("e040"),
  "fi-sort-za": decode("e041"),
  "fi-zoom-in": decode("e042"),
  "fi-table": decode("e043"),
}
// const Icon = typeof createIconSet === 'function' ? createIconSet(glyphMap, 'photo-font-all', 'photo-font-all.ttf') : undefined;
const Icon = createIconSet(glyphMap, 'ibenefit', 'ibenefit.ttf');
*/

export default class GlobalIcon extends Component {

  render() {
    /*
      const style = this.props.style || {};  
      return (
          <Icon
            style={style}
            name={this.props.name}
            size={this.props.size || 14}
            color={this.props.color}
          />
      );
    */
    

    const dataWebProps = convertStyle(this.props.style);
    let name = this.props.name || '';
    if (typeof name === 'object' && name.length > 1) {
      name = name[0];
      for (let index = 1; index < this.props.name.length; index++) {
        name += ' ' + this.props.name[index];
      }
    }

    dataWebProps.className += ' ' + name;
    // if (typeof dataWebProps.className === 'string') {
    //   dataWebProps.className = name;
    // } else {
    //   dataWebProps.className += ' ' + name;
    // }

    if (typeof this.props.tooltip === 'undefined') {
      return (
        <i {...dataWebProps} />
      )
    }
    return (<Tooltip
      placement={this.props.tooltipPlacement || 'top'}
      // mouseEnterDelay={0}
      // mouseLeaveDelay={0.1}
      // destroyTooltipOnHide={this.state.destroyTooltipOnHide}
      // trigger={Object.keys(this.state.trigger)}
      // onVisibleChange={this.onVisibleChange}
      overlay={this.props.tooltip}
      // align={{
        // offset: [this.state.offsetX, this.state.offsetY],
      // }}
      // transitionName={this.state.transitionName}
    >
      <i {...dataWebProps} />
    </Tooltip>);
    
  }
}
