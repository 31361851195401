import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Image from '../../../../../components/Image'
import { getHHMMSSfromDurationNumber } from '../../../../../utils/render-time'

const Checkmark = ({ selected }) => (
  <div style={selected ? { left: '4px', top: '4px', position: 'absolute', zIndex: '1' } : { display: 'none' }}>
    <svg style={{ fill: 'white', position: 'absolute' }} width="24px" height="24px">
      <circle cx="12.5" cy="12.2" r="8.292" />
    </svg>
    <svg style={{ fill: '#06befa', position: 'absolute' }} width="24px" height="24px">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
)

const imgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
  objectFit: 'contain',
  width: '100%',
  height: 'auto',
  minWidth: '25%',
}
const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
}
const cont = {
  backgroundColor: '#eee',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
}

const SelectedImage = ({ index, photo, margin, direction, top, left, selected, onSelectedItem }) => {
  const [isSelected, setIsSelected] = useState(selected)
  //calculate x,y scale
  const sx = (100 - (30 / (photo?.width ?? 1)) * 100) / 100
  const sy = (100 - (30 / (photo?.height ?? 1)) * 100) / 100
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`

  if (direction === 'column') {
    cont.position = 'absolute'
    cont.left = left
    cont.top = top
  }

  const handleOnClick = (e) => {
    setIsSelected(!isSelected)
    onSelectedItem?.(photo)
  }

  useEffect(() => {
    setIsSelected(photo?.select)
  }, [photo])

  const imageName = photo?.name ?? photo?.title

  return (
    <div
      style={{ margin, ...cont }}
      className={!isSelected ? 'not-selected' : 'style-warp-img'}
      onClick={handleOnClick}
    >
      <Checkmark selected={isSelected ? true : false} />
      {photo.type === 'video' && <TimeOfVideo>{getHHMMSSfromDurationNumber(photo.duration)}</TimeOfVideo>}
      {photo.type === 'video' && <TotalRate>{`${photo.width}x${photo.height}`}</TotalRate>}
      <PhotoSize>{`${photo.width}x${photo.height}`}</PhotoSize>
      {photo?.status === 0 && (
        <Block>
          <FontAwesomeIcon icon={faLock} />
        </Block>
      )}
      <WrapName>{imageName}</WrapName>
      <Image
        alt={imageName}
        style={isSelected ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }}
        src={photo?.src}
        select=""
      />
      <style>{`.style-warp-img,.not-selected{position: relative};.not-selected:hover{outline:2px solid #06befa}`}</style>
    </div>
  )
}

export default SelectedImage

const WrapName = styled.p`
  position: absolute;
  bottom: 6%;
  left: 0;
  right: 0;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px 6px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 9;
`

const Block = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
  }
`

const TimeOfVideo = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  width: 70px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #626262;
  border-radius: 5px;
  z-index: 10;
  font-size: 16px;
  color: #fff;
`
const PhotoSize = styled.div`
  position: absolute;
  bottom: calc(6% + 28px);
  right: 0;
  left: 0;
  width: 70px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #626262;
  border-radius: 5px;
  z-index: 10;
  font-size: 16px;
  color: #fff;
`
const TotalRate = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 70px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #626262;
  border-radius: 5px;
  z-index: 10;
  font-size: 16px;
  color: #fff;
`
