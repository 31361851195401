import React from 'react'

import ViewDefault from './ViewDefault'
import ViewDetail from './ViewDetail'
import styled from 'styled-components'
import { Root } from 'src/page/Home/components/ContentHomePage'

export default function MenuRight({
  isMobile,
  showMenuDetail,
  onCloseMenuRight,
  typeMenuRight,
  selectedItem,
  listArticle,
  onChangeSlide,
  listCategory,
  onFilter,
  onCheckedNew,
}) {
  return (
    <>
      <MenuDetail mobile={isMobile} show={showMenuDetail}>
        <Root>
          <Content>
            {typeMenuRight === 'search' && (
              <ViewDefault listCategory={listCategory} onFilter={onFilter} onCheckedNew={onCheckedNew} />
            )}
            {typeMenuRight === 'detail' && (
              <ViewDetail
                // onEditSuccess={onEditSuccess}
                selectedItem={selectedItem}
                listArticle={listArticle}
                onChangeSlide={onChangeSlide}
                // listCategory={listCategory}
              />
            )}
          </Content>
        </Root>
      </MenuDetail>
      {isMobile && showMenuDetail && <BackDrop onClick={onCloseMenuRight} />}
    </>
  )
}

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 92;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
