import React, { useEffect, useState, useRef } from 'react'
import { Keyboard, Navigation, Pagination, Mousewheel, FreeMode } from 'swiper'
import Image from 'src/components/Image'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/free-mode'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/bundle'
import { faCirclePlay, faSearch, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import ImgaeV2 from '../ImageV2'
import { v4 as uuidv4 } from 'uuid'
import { number } from 'yup'

export default function SwiperSlideList({
  fromPage,
  listDataSource,
  selectedItem = [],
  onChangeSlide = () => {
    return
  },
  isShowIconPreview = false,
  slidesPerView,
  isShowIconBlock = false,
  onClickSlideItem,
  activeKeyboard = true,
  isBorderSlide = false,
  isPreview = false,
  indexSelectedSlide = 0,
  index = 0,
}) {
  // const [initialSlide, setInitialSlide] = useState(0)
  const [currentIdx, setCurrentIdx] = useState('')
  const [canvaWidths, setCanvaWidths] = useState({})

  const swiper = useRef(null)

  const setSwiper = (newSwiper) => {
    swiper.current = newSwiper
  }
  useEffect(() => {
    if (swiper.current && listDataSource?.length > 0 && selectedItem?.length > 0) {
      const idx = listDataSource?.findIndex((elm) => {
        if (fromPage === 'from_playlist_detail_item' || fromPage === 'from_playlist_detail_item_preview') {
          return elm.idv4 === selectedItem[0].idv4
        }
        return elm.id === selectedItem[0].id
      })
      setCurrentIdx(idx)
    }
  }, [selectedItem])

  // console.log('item select ---> ', selectedItem, fromPage)

  return (
    <Root>
      <Swiper
        key={fromPage == 'from_playlist_main_item' ? uuidv4() : indexSelectedSlide}
        direction={'horizontal'}
        slidesPerView={slidesPerView ?? 'auto'}
        spaceBetween={10}
        initialSlide={fromPage == 'from_playlist_main_item' ? 0 : indexSelectedSlide}
        onSwiper={setSwiper}
        navigation={true}
        keyboard={{
          enabled: activeKeyboard,
        }}
        modules={[Keyboard, Pagination, Navigation, Mousewheel, FreeMode]}
        onSlideChange={(swiperCore) => {
          onChangeSlide(swiperCore.realIndex)
        }}
        preloadImages={false}
        lazy={true}
        freeMode={fromPage === 'from_playlist_main_item'}
        mousewheel={{
          releaseOnEdges: true,
          forceToAxis: true,
        }}
      >
        {listDataSource?.map((elm, index) => {
          let id = elm.idv4 ?? elm.id
          let idSelect = selectedItem[0]?.idv4 || selectedItem[0]?.id

          return (
            <SwiperSlide key={uuidv4()} onClick={onClickSlideItem}>
              {slidesPerView === 1 &&
                elm.type !== 'web' &&
                elm.type !== 'canva' &&
                elm.type !== 'youtube' &&
                !isPreview &&
                !isBorderSlide && (
                  <Image
                    alt={elm?.name ?? elm?.title}
                    src={elm?.path_thumbnail || elm?.thumbnail}
                    RenderImg={Img}
                  ></Image>
                )}

              {elm.type === 'web' && slidesPerView === 1 && elm?.ext === 'yout' && !isPreview && !isBorderSlide && (
                <Image
                  alt={elm?.name ?? elm?.title}
                  src={`https://img.youtube.com/vi/${elm?.url}/0.jpg`}
                  RenderImg={Img}
                ></Image>
              )}

              {elm.type === 'web' && slidesPerView === 1 && elm?.ext === 'canv' && !isPreview && !isBorderSlide && (
                <Image
                  alt={elm?.name ?? elm?.title}
                  src={`https://www.canva.com/design/${elm?.url}/screen`}
                  RenderImg={Img}
                ></Image>
              )}

              {/* {elm.type === 'video' && elm.ext === 'mp4' && slidesPerView === 1 && isPreview && id === idSelect && (
                <Video controls="true" autoPlay>
                  <source src={elm.path} type="video/mp4" />
                </Video>
              )}

              {slidesPerView === 1 && elm.type === 'image' && isPreview && (
                <Image alt={elm?.name ?? elm?.title} src={elm?.path_thumbnail} RenderImg={Img}></Image>
              )} */}

              {slidesPerView === 1 && (elm.type === 'canva' || elm.type === 'youtube') && !isPreview && (
                <Image alt={elm?.name ?? elm?.title} src={elm?.thumbnail} RenderImg={Img}></Image>
              )}

              {/* {slidesPerView === 1 && fromPage === 'template_item_preview' && isPreview && (
                <Image alt={elm?.name ?? elm?.title} src={elm?.thumbnail} RenderImg={Img}></Image>
              )} */}

              {slidesPerView !== 1 && fromPage !== 'from_playlist_main_item' && (
                <ImgaeV2 item={elm} isMultiImg={true} />
              )}

              {/* {elm.type === 'web' && slidesPerView === 1 && elm?.ext === 'yout' && isPreview && id === idSelect && (
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${elm?.url}?autoplay=1&controls=1&loop=1&enablejsapi=1&html5=1&rel=1`}
                  frameBorder="0"
                  allow=" autoplay; encrypted-media;"
                  title="Embedded youtube"
                />
              )}

              {elm.type === 'web' && slidesPerView === 1 && elm?.ext === 'canv' && isPreview && id === idSelect && (
                <iframe
                  width="100%"
                  height="100%"
                  loading="lazy"
                  src={`https://www.canva.com/design/${elm?.url}/watch?embed&autoplay=1&mute=0`}
                  allow="autoplay;"
                />
              )} */}

              {fromPage === 'from_playlist_main_item' && elm?.type !== 'web' && isBorderSlide && (
                <ImgaeV2
                  fromPage="from_playlist_main_item"
                  item={elm}
                  isMultiImg={true}
                  showName={false}
                  RenderImg={ImgMainPlaylist}
                ></ImgaeV2>
              )}

              {fromPage === 'from_playlist_main_item' &&
                elm?.type === 'web' &&
                elm?.ext === 'yout' &&
                isBorderSlide && (
                  <ImgaeV2
                    fromPage="from_playlist_main_item"
                    item={elm}
                    isMultiImg={true}
                    RenderImg={YoutubeImg}
                    showName={false}
                  ></ImgaeV2>
                )}

              {fromPage === 'from_playlist_main_item' && elm?.type === 'web' && elm?.ext === 'canv' && isBorderSlide && (
                <ImgaeV2
                  fromPage="from_playlist_main_item"
                  item={elm}
                  isMultiImg={true}
                  style={{ width: canvaWidths[id] }}
                  RenderImg={CanvaImg}
                  showName={false}
                  onLoading={(e) => {
                    const value = e.target.width
                    if (typeof canvaWidths[id] !== 'number') {
                      setCanvaWidths({ ...canvaWidths, [id]: value })
                    }
                  }}
                ></ImgaeV2>
              )}

              {/* {slidesPerView === 1 && elm?.type === 'canva' && isPreview && id === idSelect && (
                <iframe
                  width="100%"
                  height="100%"
                  loading="lazy"
                  src={`https://www.canva.com/design/${elm?.url_link}/watch?embed&autoplay=1&mute=0`}
                  allow="autoplay;"
                />
              )}

              {slidesPerView === 1 && elm?.type === 'youtube' && isPreview && id === idSelect && (
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${elm?.url_link}?autoplay=1&controls=1&loop=1&enablejsapi=1&html5=1&rel=1`}
                  frameBorder="0"
                  allow=" autoplay; encrypted-media;"
                  title="Embedded youtube"
                />
              )} */}

              {elm.status === 0 && isShowIconBlock && (
                <Block>
                  <FontAwesomeIcon icon={faLock} />
                </Block>
              )}
              {isShowIconPreview && elm?.type === 'image' && (
                <WrapIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </WrapIcon>
              )}

              {isShowIconPreview && fromPage === 'template_item' && (
                <WrapIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </WrapIcon>
              )}

              {isShowIconPreview &&
                (elm?.type === 'video' || elm?.type === 'web' || elm?.type === 'canva' || elm?.type === 'youtube') &&
                !isPreview && (
                  <WrapIcon>
                    <FontAwesomeIcon icon={faCirclePlay} />
                  </WrapIcon>
                )}
            </SwiperSlide>
          )
        })}
        {/* <SwiperScrollbar className="swiper-scrollbar"></SwiperScrollbar> */}
      </Swiper>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  height: 100%;

  & .swiper {
    position: relative;
    display: flex;
  }
`

const SwiperScrollbar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;

  .swiper-scrollbar-drag {
    height: 100%;
    transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
    background: rgba(0, 0, 0, 0.4);
  }
`

const WrapIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(1, 1, 1, 0.6);
  border-radius: 50%;
  bottom: 4px;
  left: 4px;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
`
const WrapYoutube = styled.div`
  width: auto;
  max-width: 500px;
  height: 100%;
`
const YoutubeImg = styled.img`
  width: 238px;
  max-width: 238px;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  pointer-events: none;
`
const CanvaImg = styled.img`
  width: auto;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  pointer-events: none;
`

const ImgMainPlaylist = styled.img`
  width: auto;
  max-width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
  pointer-events: none;
`

const Video = styled.video`
  width: auto;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* pointer-events: none; */
`
const Block = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
    width: 24px;
    height: 24px;
  }
`
