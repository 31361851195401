import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import favouriteApi from '../../api/favourite'
import ModalPreviewImage from '../Articles/ListArticle/ListArticleTheme/ModalPreviewImage'
import ContentArticle from '../Articles/ListArticle/ListArticleTheme/ContentArticle'
import handleError from 'src/api/handle-error'

export default function Favourite() {
  const [listFavourite, setListFavourite] = useState([])
  const [itemsSelected, setItemsSelected] = useState([])

  useEffect(() => {
    favouriteApi
      .getListFavourite()
      .then((res) => {
        setListFavourite(res.data)
      })
      .catch((err) => {
        handleError('getListFavourite', err)
      })
  }, [])

  const handleItemsSelected = (item) => {
    setItemsSelected(item)
  }

  return (
    <Root>
      <WrapContent>
        <Title>LIST FAVOURITE</Title>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <div style={{ overflow: 'hidden', flexGrow: '1' }}>
              <div className="ag-theme-alpine">
                <ContentArticle
                  listArticle={listFavourite}
                  selectedItemChange={(item) => handleItemsSelected(item)}
                  statusView={0}
                />
              </div>
            </div>
          </div>
        </div>
        {itemsSelected && <ModalPreviewImage itemsSelected={itemsSelected} onClose={() => setItemsSelected('')} />}
      </WrapContent>
    </Root>
  )
}

const Root = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`

const Title = styled.div`
  width: 100%;
  font-size: 22px;
  color: #999999;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding: 10px;
`

const WrapContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  & .ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: transparent;
  }
`
