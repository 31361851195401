import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SelectedImage from '../../../../../../components/SelectedImage/SelectdImage'
import StackGridList from '../../../../../../components/StackGridList'

function ViewGrid({ listArticle, onSelectedItem }) {
  const [items, setItems] = useState(listArticle)

  useEffect(() => {
    const newData = listArticle.map((elm) => {
      return {
        ...elm,
        src: elm.thumbnail,
        width: 428,
        height: 420,
      }
    })
    setItems(newData)
  }, [listArticle])

  return (
    <Root>
      <StackGridList dataSource={items}>
        {(items ?? []).map((val, idx) => {
          return (
            <SelectedImage
              key={`${idx}${val?.idx}`}
              margin={'2px'}
              index={idx}
              photo={val}
              onSelectedItem={onSelectedItem}
            />
          )
        })}
      </StackGridList>
    </Root>
  )
}

export default ViewGrid
const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const WrapButton = styled.div`
  width: 120px;
  height: 46px;
  margin-bottom: 12px;

  .styled_button {
    background: ${({ drag }) => (drag ? '#FF6600' : ' none')};
    color: ${({ drag }) => (drag ? '#fff' : ' #000')};
  }
`
