import appConstants from '../constants'
import HttpRequest from './HttpRequest'

export const devicesApi = Object.freeze({
  getListDevices: (page = 1, limit) =>
    HttpRequest.request({
      method: 'GET',
      url: `${appConstants.apiUrl}/player`,
      params: {
        limit,
        page,
      },
    }),
  getDeviceDetail: (id) =>
    HttpRequest.request({
      method: 'GET',
      url: `${appConstants.apiUrl}/player/${id}`,
    }),

  updateDeviceDetail: (id, data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/player/${id}`,
      data,
    }),
  createDevice: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/link-player`,
      data,
    }),
  deleteDevice: (id) =>
    HttpRequest.request({
      method: 'DELETE',
      url: `${appConstants.apiUrl}/link-player/${id}`,
    }),
})
