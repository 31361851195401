import * as React from 'react'
import { UNSAFE_NavigationContext } from 'react-router-dom'
// import { History, Blocker, Transition } from 'history';
// import { useLocation, useNavigate } from 'react-router-dom';

export function useBlocker(blocker, when = true) {
  // const navigate = useNavigate();
  const navigator = React.useContext(UNSAFE_NavigationContext).navigator

  React.useEffect(() => {
    if (!when) {
      // navigate(nextLocation?.location?.pathname || '/')
      // console.log("🚀 ~ file: useBlocker.js ~ line 14 ~ React.useEffect ~ nextLocation", nextLocation)
      return
    }

    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])
}
