export const changeUuid = (uuid) => {
  if (!uuid) return;
  const len = uuid.length;
  let result = [];
  for (let i = 0; i < len - 1; i += 4) {
    let cutString = uuid.slice(i, i + 4);

    result.push(cutString);
  }
  return result.join("-");
};
