import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Tooltipv2 from 'src/components/ToolTipv2'
import styled from 'styled-components'
import userGroupApi from '../../../api/userGroup'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../utils/handle-error'

export default function UserGroupEditTab({ selectedItem, onEditSuccess, onDeleteUserGroup }) {
  const [inputsInfo, setInputsInfo] = useState({
    id: '',
    name: '',
    durationForEachVid: '',
    sizeForEach: '',
    durationForTotal: '',
    sizeForTotal: '',
    numberForTotalMedia: '',
    numberForTotalDevice: '',
    numberForTotalDeviceRep: '',
    daysLock: '',
  })

  useEffect(() => {
    const {
      id,
      name,
      allow_duration_each_video,
      allow_duration_total_video,
      allow_size_each_media,
      allow_size_total_media,
      allow_total_device,
      allow_total_device_replication,
      allow_total_media,
      day_lock_without_net,
    } = selectedItem

    setInputsInfo({
      id,
      name,
      durationForEachVid: allow_duration_each_video || '',
      sizeForEach: allow_size_each_media || '',
      durationForTotal: allow_duration_total_video || '',
      sizeForTotal: allow_size_total_media || '',
      numberForTotalMedia: allow_total_media || '',
      numberForTotalDevice: allow_total_device || '',
      numberForTotalDeviceRep: allow_total_device_replication || '',
      daysLock: day_lock_without_net || '',
    })
  }, [selectedItem])

  const handleChangeInfo = (e) => {
    const name = e.target.name
    const value = e.target.value

    setInputsInfo({
      ...inputsInfo,
      [name]: value,
    })
  }

  const handleUpdate = () => {
    if (!inputsInfo.name) {
      return handleError('Name is required!')
    }
    const {
      id,
      name,
      durationForEachVid,
      sizeForEach,
      durationForTotal,
      sizeForTotal,
      numberForTotalMedia,
      numberForTotalDevice,
      numberForTotalDeviceRep,
      daysLock,
    } = inputsInfo

    const data = {
      id,
      name,
      allow_duration_each_video: parseInt(durationForEachVid),
      allow_size_each_media: parseInt(sizeForEach),
      allow_duration_total_video: parseInt(durationForTotal),
      allow_size_total_media: parseInt(sizeForTotal),
      allow_total_media: parseInt(numberForTotalMedia),
      allow_total_device: parseInt(numberForTotalDevice),
      allow_total_device_replication: parseInt(numberForTotalDeviceRep),
      day_lock_without_net: parseInt(daysLock),
    }

    userGroupApi
      .updateUserGroup(id, data)
      .then((res) => {
        handleSuccess('Update user group success!')
        onEditSuccess?.()
      })
      .catch((err) => {
        handleError('updateUserGroup', err)
      })
  }

  return (
    <>
      <TitleDetail>
        <span>User Group Info</span>
        <WrapIconTop>
          <Tooltipv2 overlay="Delete user group" placement="top">
            <FontAwesomeIcon onClick={onDeleteUserGroup} icon={faTrashCan} />
          </Tooltipv2>
        </WrapIconTop>
      </TitleDetail>
      <LabelInput>Group ID:</LabelInput>
      <WrapInput>
        <ButtonID>
          <span>ID: {inputsInfo.id}</span>
        </ButtonID>
      </WrapInput>
      <LabelInput>Group name:</LabelInput>
      <WrapInput>
        <Input type="text" name="name" placeholder="Name" value={inputsInfo.name} onChange={handleChangeInfo} />
      </WrapInput>
      <LabelInput>Duration for Each Video (seconds):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.durationForEachVid}
          name="durationForEachVid"
          placeholder="Duration for Each Video (seconds)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Size for Each Media (MB):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.sizeForEach}
          name="sizeForEach"
          placeholder="Size for Each Media (MB)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Duration for Total Media (seconds):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.durationForTotal}
          name="durationForTotal"
          placeholder="Duration for Total Media (seconds)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Size for Total Media (MB):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.sizeForTotal}
          name="sizeForTotal"
          placeholder="Size for Total Media (MB)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Number for Total Media (max files):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.numberForTotalMedia}
          name="numberForTotalMedia"
          placeholder="Number for Total Media (maximum files)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Number for Total Device:</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.numberForTotalDevice}
          name="numberForTotalDevice"
          placeholder="Number for Total Device"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Number for Total Device Replication:</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.numberForTotalDeviceRep}
          name="numberForTotalDeviceRep"
          placeholder="Number for Total Device Replication"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Days lock without net:</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.daysLock}
          name="daysLock"
          placeholder="days lock without net"
          onChange={handleChangeInfo}
        />
      </WrapInput>

      <WrapInput>
        <ButtonOutLine onClick={handleUpdate} title={'Save'} />
      </WrapInput>
    </>
  )
}

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  :last-child {
    margin-top: 12px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const LabelInput = styled.div`
  padding: 0 10px;
  font-size: 16px;
  color: #ccc;
  margin-top: 12px;
`

const WrapIconTop = styled.div`
  min-width: 30px;
  height: 30px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  color: rgb(255, 102, 0);
  padding: 0 2px;
  border-radius: 4px;
  background-color: ${({ select }) => (select ? 'rgba(255, 102, 0, 0.3)' : 'none')};
  /* margin: 0 10px; */
  margin-left: 5px;
  cursor: pointer;
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 12px;
  margin-left: 12px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin-left: 5px;
`

const ButtonID = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d9af93;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 10px;
  }
  :hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    span {
      color: #fff;
    }
  }
`
