import React, { useEffect, useState } from 'react'
import { editorStateFromRaw, editorStateToJSON, MegadraftEditor } from 'megadraft'

import 'megadraft/dist/css/megadraft.css'
import Select from 'react-select'
import styled from 'styled-components'
import articleApi from '../../../../../api/article'
import { handleError, handleSuccess } from '../../../../../utils/handle-error'
import { Root } from '../../../../Home/components/ContentHomePage'
import pluginImage from '../../../CreateArticle/ImagePlugin/plugin'
import { convertToRaw, convertFromRaw } from 'draft-js'
import AddArticle from './AddArticle'
import UpdateArticle from './UpdateArticle'
import SearchThemeArticle from '../../ListArticleTheme/MenuRight/SearchThemeArticle'

const MenuRight = ({
  itemSelected,
  listCategory,
  onSuccess,
  typeMenuRight,
  showMenuRight,
  isMobile,
  onCloseMenuRight,
  onRemove,
}) => {
  return (
    <>
      <MenuDetail mobile={isMobile} show={showMenuRight}>
        <Root>
          {typeMenuRight === 'add-article' && <AddArticle listCategory={listCategory} onSuccess={onSuccess} />}
          {typeMenuRight === 'update-article' && (
            <UpdateArticle
              itemSelected={itemSelected}
              onSuccess={onSuccess}
              listCategory={listCategory}
              onRemove={onRemove}
            />
          )}
        </Root>
      </MenuDetail>
      {isMobile && showMenuRight && <BackDrop onClick={onCloseMenuRight} />}
    </>
  )
}

export default MenuRight

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  display: flex;
  background: #fff;
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 92;
`

// const Content = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   padding-bottom: 100px;
//   overflow-y: scroll;
// `

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
