export function is_touch_enabled() {
    if (typeof window === 'undefined') {
      return false;
    }
    return ( 'ontouchstart' in window ) ||  
           ( navigator.maxTouchPoints > 0 ) ||  
           ( navigator.msMaxTouchPoints > 0 ); 
} 

export function check(iObj) {
  if (typeof iObj !== 'object' || iObj === null) {
    return 0;
  }
  if (Array.isArray(iObj)) {
    return 2;
  }
  return 1;
}

export function map(iObj, cb) {
  const type = check(iObj);
  if (typeof cb !== 'function' || type < 1) {
    return false;
  }
  const arr = [];
  const isTwo = cb.length === 2 ? true : false;
  if (type === 2) {
    for(let i = 0; i < iObj.length; i++) {
      if (!iObj.hasOwnProperty(i)) {
        continue;
      }
      const data = isTwo ? cb(iObj[i], i) : cb(iObj[i]);
      arr.push(data);
      if (data === false) {
        break;
      }
    }
    return arr;
  }
  for(let i in iObj) {
    if (!iObj.hasOwnProperty(i)) {
      continue;
    }
    const data = isTwo ? cb(iObj[i], i) : cb(iObj[i]);
    arr.push(data);
    if (data === false) {
      break;
    }
  }
  return arr;
}

export function range(numEnd, numStart = 0, cb) {
  const rules = {numEnd, numStart};
  for (let i = 0; i < rules.length; i++) {
    if (typeof rules[i] !== 'number' || isNaN(rules[i]) || Number.isFinite(rules[i]) || !Number.isInteger(rules[i])) {
      return false;
    }
  }
  if (typeof cb === 'function') {
    for(let i = numStart; i < numEnd; i++) {
      if (cb(i) === false) {
        break;
      }
    }
    return true;
  }
  const arr = [];
  for(let i = numStart; i < numEnd; i++) {
    arr.push(i);
  }
  return arr;
}

export function convertStyle(iStyle) {
  const dataWebProps = {};
  if (typeof iStyle === 'undefined' || iStyle === null) {
    return dataWebProps;
  }
  let style = iStyle;
  if (typeof iStyle === 'object') {
    if (Array.isArray(style)) {
      let hasString = false;
      for (let index = 0; index < style.length; index++) {
        if (typeof style[index] !== 'string') {
          continue;
        }
        hasString = true;
        break;
      }
      if (hasString) {
        const styles = [];
        const stylesAfter = [];
        const dataWebPropsStyle = [];
        for (let index = 0; index < style.length; index++) {
          if (typeof style[index] !== 'string') {
            if (typeof style[index] === 'undefined') {
              continue
            }

            const tmps = {}
            for (let j in style[index]) {
              if (typeof j !== 'string' || !style[index].hasOwnProperty(j) || j === 'flex') {
                continue;
              }
              if (typeof style[index][j] === 'undefined') {
                continue
              }

              if (typeof style[index][j] !== 'object') {
                // tplit to 2 case
                if (/^[0-9]+$/.test(j) ) {
                  stylesAfter.push(style[index][j]);
                } else {
                  tmps[j] = style[index][j]
                }
                continue
              }
              for (let m in style[index][j]) {

                if (typeof m !== 'string' || !style[index][j].hasOwnProperty(m) || m === 'flex') {
                  continue;
                }
                tmps[m] = style[index][j][m]
              }
            }

            dataWebPropsStyle.push(tmps);
            continue;
          }
          if (style[index] === '') {
            continue;
          }
          styles.push(style[index]);
        }
        if (styles.length > 0) {
          dataWebProps.className = Object.assign([],
            stylesAfter,
            styles
          ).join(' ');
        }
        if (dataWebPropsStyle.length > 0) {
          hasString = false;
          style = [...dataWebPropsStyle];
        } else {
          style = {};
        }
      }
      if (!hasString) {
        const styles = {};
        let isExists = false;
        for (let index = 0; index < style.length; index++) {

          if (typeof style[index] !== 'object') {
            continue;
          }
          for (let j in style[index]) {
            if (typeof j !== 'string' || !style[index].hasOwnProperty(j) || j === 'flex') {
              continue;
            }
            styles[j] = style[index][j];
            isExists = true;
          }
        }
        if (isExists) {
          style = styles;
        } else {
          style = {};
        }
        
      }
    }
  }
  if (typeof iStyle === 'string') {
    dataWebProps.className = iStyle;
  } else if (Object.keys(style).length > 0) {
    dataWebProps.style = style;
  }
  return dataWebProps;
}

export function objectCompare(obj1, obj2) {
  if (
    typeof obj1 === 'undefined' &&
    typeof obj2 === 'undefined'
  ) {
    return true;
  }
  if (
    obj1 === null &&
    obj2 === null
  ) {
    return true;
  }

  if (
    typeof obj1 !== typeof obj2
  ) {
    return false;
  }
  
  for (let i in obj1) {
    if (obj1.hasOwnProperty(i)) {
      if (!obj2.hasOwnProperty(i)) return false;
      if (obj1[i] != obj2[i]) return false;
    }
  }
  for (let i in obj2) {
    if (obj2.hasOwnProperty(i)) {
      if (!obj1.hasOwnProperty(i)) return false;
      if (obj1[i] != obj2[i]) return false;
    }
  }
  return true;
}

export function hasKey(iObj, min = 1) {
  const type = check(iObj);
  if (type < 1) {
    return false;
  }
  if (type === 2) {
    return iObj.length >= min ? true : false;
  }
  if (typeof iObj.hasOwnProperty !== 'function') {
    return (iObj.length > 0)
  }

  let n = 0;
  for(let i in iObj) {
    if (!iObj.hasOwnProperty(i)) {
      continue;
    }
    n += 1;
    if (n !== min) {
      continue;
    }
    return true;
    break;
  }
  return false;
}

export function compare(obj1, obj2) {
  if (
    typeof obj1 === 'undefined' &&
    typeof obj2 === 'undefined'
  ) {
    return true;
  }

  if (
    typeof obj1 !== typeof obj2
  ) {
    if (
      typeof obj2 === 'undefined' &&
      typeof obj1 === 'object' &&
      !hasKey(obj1)
    ) {
      return true;
    } else if (
      typeof obj1 === 'undefined' &&
      typeof obj2 === 'object' &&
      !hasKey(obj2)
    ) {
      return true;
    }
    return false;
  }
  if (typeof obj1 !== 'object') {
    return obj1 === obj2;
  }

  if (
    obj1 === null &&
    obj2 === null
  ) {
    return true;
  }

  if (
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const isArray = Array.isArray(obj1);

  if (
    (
      isArray &&
      !Array.isArray(obj2)
    )
    ||
    (
      obj1.length !== obj2.length
    )
  ) {
    return false;
  }

  if (isArray) {
    for (let i = 0; i < obj1.length; i++) {
      if (obj1[i] != obj2[i]) {
        if (typeof obj1[i] === 'object') {
          const r = compare(obj1[i], obj2[i]);
          if (r !== false) {
            continue;
          }
          return r;
        }
        return false;
      }
    }
    return true;
  }
  if (
    typeof obj1.hasOwnProperty !== typeof obj2.hasOwnProperty
  ) {
    return true;
  }
  if(typeof obj1.hasOwnProperty !== 'function') {
    return true;
  }

  const obj2Checked = [];
  for (let i in obj1) {
    if (!obj1.hasOwnProperty(i)) {
      continue;
    }
    if (!obj2.hasOwnProperty(i)) {
      return false;
    }
    obj2Checked.push(i);
    if (obj1[i] != obj2[i]) {
      if (typeof obj1[i] === 'object') {
        const r = compare(obj1[i], obj2[i]);
        if (r !== false) {
          continue;
        }
        return r;
      }
      return false;
    }
  }
  for (let i in obj2) {
    if (!obj2.hasOwnProperty(i)) {
      continue;
    }
    if (obj2Checked.indexOf(i) > -1) {
      continue;
    }
    if (!obj1.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
}