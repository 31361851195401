import { getToken } from '../../helpers/storage'
import { AuthActionTypes } from './action'

const initState = {
  profile: undefined,
  isAuth: !!getToken(),
  activeAccountSuccess: false,
}

export default function AuthReducer(state = initState, action) {
  switch (action.type) {
    case AuthActionTypes.SET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
        isAuth: !!getToken(),
      }
    }

    case AuthActionTypes.SET_IS_AUTH: {
      return {
        ...state,
        isAuth: action.payload,
      }
    }
    case AuthActionTypes.ACTIVE_SUCCESS: {
      return {
        ...state,
        activeAccountSuccess: action.payload?.userId ? true : false,
      }
    }
    default: {
      return state
    }
  }
}
