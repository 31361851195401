import React from 'react'

export function GridPhoto({ children }) {
  return (
    <div
      style={{
        display: 'grid',
        gap: 10,
        gridTemplateColumns: 'repeat(auto-fill, minmax(162px, 1fr))',
        // gridAutoRows: 'minmax(162px, 1fr)',
      }}
    >
      {children}
    </div>
  )
}
