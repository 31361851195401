import styled from 'styled-components'

const WrapIconList = styled.div`
  width: 24px !important;
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff6600;
  border-radius: 50%;
  z-index: 9;

  svg {
    height: 12px;
    color: #fff;
  }
`

const WrapperIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
`

const WrapperCenter = styled.div`
  display: flex;
  justify-content: center;
  div {
    width: auto !important;
  }
`

const WrapperTitle = styled.div`
  width: 100%;
  height: 100%;
`

const WrapIconCopy = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 22px;
    color: #ff6600;
  }
`

const WrapperScheduleRow = styled.div`
  font-weight: 400;
  margin: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-style: normal;
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0px;
  border-right: 2px solid #f3f3f3;
  /* overflow-x: auto; */
  cursor: pointer;
  .justify-content-center {
    justify-content: center !important;
  }
  .justify-content-between {
    justify-content: between !important;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

const WrapperDnd = styled.div`
  -webkit-user-drag: element !important;
  -khtml-user-drag: element !important;
  -moz-user-drag: element !important;
  -o-user-drag: element !important;
  user-drag: element !important;
  user-select: none;
  * {
    -webkit-user-drag: element !important;
    -khtml-user-drag: element !important;
    -moz-user-drag: element !important;
    -o-user-drag: element !important;
    user-drag: element !important;
    user-select: none;
  }

  /* background-color: aliceblue; */
`

export { WrapIconCopy, WrapIconList, WrapperDnd, WrapperIcon, WrapperScheduleRow, WrapperCenter, WrapperTitle }
