import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Root } from '../../../../Home/components/ContentHomePage'
import AddDriver from './components/AddDriver'
import AddFile from './components/AddFile'
import PlaylistSetting from './components/PlaylistSetting'
import ViewDetail from './components/ViewDetail'

function MenuLeft({
  isMobile,
  showMenuDetail,
  onClose,
  type = 'playlistSetting',
  itemSelect,
  multiSelect,
  playlistDetail,
  onSelectFile,
  onBlockFile,
  onUnblockFile,
  onRemoveFile,
  onChangeDuration,
  onSaveFile,
  moveItemToTop,
  showPreviewModal,
  onChangeSlide,
  onUpdatePlaylistSuccess,
}) {
  const [renderContent, setRenderContent] = useState('playlistSetting')
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    setRenderContent(type)
  }, [type, itemSelect])

  const onPreview = (preview) => {
    setPreview(preview)
    showPreviewModal(preview)
  }

  const renderPlaylistSetting = useMemo(() => {
    return <PlaylistSetting playlistDetail={playlistDetail} onUpdatePlaylistSuccess={onUpdatePlaylistSuccess} />
  }, [playlistDetail])

  return (
    <>
      <MenuDetail preview={preview} mobile={isMobile} show={showMenuDetail}>
        <Root>
          <Content>
            {renderContent === 'playlistSetting' && renderPlaylistSetting}
            {renderContent === 'addFilles' && <AddFile playlistDetail={playlistDetail} onSelectFile={onSelectFile} />}
            {renderContent === 'itemDetail' && (
              <ViewDetail
                itemSelect={itemSelect}
                AddPlayList={() => {}}
                onBlockFile={onBlockFile}
                onUnblockFile={onUnblockFile}
                onRemoveFile={onRemoveFile}
                onSaveFile={onSaveFile}
                multiSelect={multiSelect}
                moveItemToTop={moveItemToTop}
                onPreview={onPreview}
                listFile={playlistDetail?.files || []}
                onChangeSlide={onChangeSlide}
                onChangeDuration={onChangeDuration}
              />
            )}

            {renderContent === 'addDriver' && <AddDriver />}
          </Content>
        </Root>
      </MenuDetail>
      {isMobile && showMenuDetail && !preview && <BackDrop onClick={onClose} />}
    </>
  )
}

export default MenuLeft

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: ${({ preview, mobile }) => (preview && mobile ? '100%' : '320px')};
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 92;
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
