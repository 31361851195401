import styled from 'styled-components'

const Root = styled.div`
  height: auto;
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  align-items: center;
  a,
  button {
    font-family: 'Roboto', sans-serif;
  }

  .projects,
  .products {
    position: relative;
  }

  .projects ul,
  .products ul {
    margin-top: 10px;
    position: absolute;
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    min-width: 180px;
    min-height: fit-content;
    background: rgb(183, 183, 183);
    right: 0px;
    list-style: none;
    border-radius: 5px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
    transition: all 0.4s ease;
    z-index: 110;
  }
  .projects li,
  .products li {
    width: 100%;
    height: 100%;
    display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    :first-child {
      margin: 0;
    }
  }

  .projects li:hover,
  .products li:hover {
    background-color: #5f5f64;
  }

  .projects li:hover a,
  .products li:hover a {
    color: white;
  }

  .projects a,
  .products a {
    padding: 16px;
    color: black;
    text-decoration: none;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  .active {
    opacity: 1 !important;
    pointer-events: all !important;
    transform: translateY(0px) !important;
  }

  .mr-1 {
    margin-right: 8px;
  }
`

const Button = styled.button`
  min-width: 50px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: none;
  padding: 4px 0 4px 12px;
  cursor: pointer;
`

export { Root, Button }
