import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import useOnClickOutside from 'src/hooks/useClickoutside'
import { Button, Root } from './styled'

function Dropdown({ dataSource, isShow, label, icon, menu }) {
  const [show, setShow] = useState(isShow)
  const ref = useRef(null)
  const handleShow = () => {
    setShow(!show)
  }

  useOnClickOutside(ref, () => setShow(false))

  return (
    <Root ref={ref}>
      <div className="projects">
        <Button onClick={handleShow}>
          {label}
          {icon}
        </Button>
        <ul className={show ? 'active' : ''}>
          {(dataSource ?? []).map((val, idx) => {
            return <li key={idx.toString()}>{val}</li>
          })}
          {(menu ?? []).map((val, idx) => {
            return (
              <li key={val.id + idx.toString()}>
                <Link to={val.url} onClick={handleShow}>
                  <FontAwesomeIcon icon={val.icon} className={'mr-1'} />
                  {val.name}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </Root>
  )
}

export default Dropdown
