import { faCircleArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import TimePicker from 'rc-time-picker'
import moment from 'moment'

function ScrollToTop({ refScroll }) {
  const scrollToTop = () => {
    refScroll.current.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <Root>
      <FontAwesomeIcon color="rgb(255, 102, 0)" onClick={scrollToTop} icon={faCircleArrowUp} />
    </Root>
  )
}

export default ScrollToTop

const Root = styled.div`
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 24px;
  height: 24px;
  transition: all 0.15s ease-in 0s;
  z-index: 99999;
  cursor: pointer;

  & .svg-inline--fa {
    width: 24px;
    height: 24px;
  }
`
