import React, { useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

function DndContainer({ isMobile, children }) {
  const options = {
    scrollAngleRanges: [{ start: 300 }, { end: 60 }, { start: 120, end: 240 }],
  }

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} options={options}>
      {children}
    </DndProvider>
  )
}

export default DndContainer
