import appConstants from "../constants";
import HttpRequest from "./HttpRequest";

export const playerApi = Object.freeze({
  getListLog: (id) =>
    HttpRequest.request({
      method: "GET",
      url: `${appConstants.apiUrl}/player/logs/${id}`,
    }),
});


