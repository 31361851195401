import React from "react";
import { Button, TextError, WrapInput } from "../styled";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UncontrolInputPassword from "../../../components/UncontrolInput/UncontrolInputPassWord";
import UnControlInputText from "../../../components/UncontrolInput/UncontrolInputText";

const schema = yup
  .object({
    email: yup.string().email().required(),
    phone_number: yup.number().typeError("you must type a number").required(),
  })
  .required();

function ForgotPasswordForm({ handleForgotPassword }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      phone_number: null,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    handleForgotPassword(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <WrapInput>
        <UnControlInputText {...register("email")} placeholder="Email" />
        {errors.email && <TextError>{errors.email.message}</TextError>}
      </WrapInput>
      <WrapInput>
        <UnControlInputText {...register("phone_number", { valueAsNumber: true })} placeholder="Phone Number" type={"number"} />
        {errors.phone_number && <TextError>{errors.phone_number.message}</TextError>}
      </WrapInput>
      <WrapInput>
        <Button type="submit">Submit</Button>
      </WrapInput>
    </form>
  );
}

export default ForgotPasswordForm;
