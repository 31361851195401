import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import Image from 'src/components/Image'
import ScrollToTop from 'src/components/ScrollToTop'
import styled, { css, keyframes } from 'styled-components'
import { devicesApi } from '../../../api/devicesApi'
import { handleError } from '../../../utils/handle-error'
import { Root } from '../../Home/components/ContentHomePage'
import ReactPaginate from 'react-paginate'

import MenuLeft from './components/MenuLeft'

const limit = 20
var timeout

function Main({ isMobile }) {
  const navigate = useNavigate()
  let location = useLocation()
  const Param = useParams()
  const detailId = Param?.detailId
  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [listDevices, setListDevices] = useState([])
  const [page, setPage] = useState(1)
  const refScroll = useRef(null)
  const refLoadMore = useRef(null)
  const [isLastPage, setIsLastPage] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  useEffect(() => {
    setShowMenuDetail(false)
  }, [isMobile])

  useEffect(() => {
    handleGetListDevices(page, limit)
  }, [location])

  useEffect(() => {
    if (isLastPage) {
      setLoadingText('- END -')
    }
    if (refLoadMore && refLoadMore.current && !isLastPage) {
      refLoadMore.current.addEventListener('scroll', handleScroll, false)
    }
    return () => {
      if (refLoadMore.current) {
        refLoadMore.current.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [listDevices])

  const handleGetListDevices = (page = 1, limit) => {
    devicesApi
      .getListDevices(page, limit)
      .then((res) => {
        // if (!res.success) {
        //   throw res
        // }
        const { list } = res.data
        if (list.length < limit) {
          setIsLastPage(true)
        }
        setListDevices(list)
      })
      .catch((error) => {
        handleError('getListDevices - 1', error || 'ERROR')
      })
  }

  const handleScroll = (e) => {
    if (isLastPage) {
      setLoadingText('- END -')
      return
    }

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 300) {
        setLoadingText('Loading ...')
        handeLoadMore()
      }
    }, 300)
  }

  const handeLoadMore = () => {
    const pageNum = page + 1
    setPage(pageNum)

    devicesApi
      .getListDevices(pageNum, limit)
      .then((res) => {
        if (!res.success) {
          throw res
        }
        const { list } = res.data
        if (list.length < limit) {
          setIsLastPage(true)
        }
        setListDevices([...listDevices, ...list])
      })
      .catch((error) => {
        if (error) {
          handleError('getListDevices - 2', error)
        }
      })
  }

  return (
    <>
      {!detailId ? (
        <WrapContent>
          <WrapRoot>
            <Root ref={refScroll}>
              <Content ref={refLoadMore}>
                <Title>
                  DEVICES
                  {isMobile && (
                    <WrapIcon onClick={() => setShowMenuDetail(true)}>
                      <FontAwesomeIcon icon={faPlus} />
                    </WrapIcon>
                  )}
                </Title>
                {listDevices.map((elm) => {
                  return (
                    <Item key={elm.uuid} onClick={() => navigate(`${elm.id}`)}>
                      <WrapImg>
                        <Image src={elm?.is_online ? imgOnline : img} />
                        {/* <img src={elm.logo || img} /> */}
                      </WrapImg>
                      <span>{elm.name}</span>
                    </Item>
                  )
                })}
                {loadingText !== '' && (
                  <WrapLoadingBottom>
                    <WrapLoading>
                      {loadingText !== '- END -' && (
                        <Loader>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderText></LoaderText>
                        </Loader>
                      )}
                      {loadingText === '- END -' && <WrapEnd>- END -</WrapEnd>}
                    </WrapLoading>
                  </WrapLoadingBottom>
                )}
              </Content>

              <ScrollToTop refScroll={refLoadMore} />
            </Root>
          </WrapRoot>
          <MenuLeft
            isMobile={isMobile}
            showMenuDetail={showMenuDetail}
            onClose={() => setShowMenuDetail(false)}
            onSaveSuccess={handleGetListDevices}
          />
        </WrapContent>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default Main

const WrapContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`
const WrapRoot = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  color: #999999;
  text-transform: uppercase;
  margin-bottom: 30px;
`

const WrapIcon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgb(255, 102, 0);
  color: rgb(255, 102, 0);
  cursor: pointer;
`

const Item = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 12px;
  cursor: pointer;

  span {
    font-size: 16px;
    font-weight: 500;
    color: rgb(134, 134, 134);
  }
`

const WrapImg = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 4px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: rgb(220, 220, 220);
  margin-right: 12px;

  img {
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`

const WrapLoadingBottom = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
`
const WrapLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = styled.div`
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const LoadingAniText = keyframes`
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
`

const LoaderAniDot = keyframes`
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
`

const LoaderDot = styled.div`
  animation-name: ${LoaderAniDot};
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;

  :first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }
  :nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  :nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  :nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  :nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  :nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }
`
const LoaderText = styled.div`
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
  :after {
    content: 'Loading';
    font-weight: bold;
    animation-name: ${LoadingAniText};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`
const WrapEnd = styled.div`
  margin-top: 40px;
  font-weight: 500;
`
const imgOnline =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KICB2aWV3Qm94PSIwIDAgMzcyLjY1OSAzNzIuNjU5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNzIuNjU5IDM3Mi42NTk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KICA8cGF0aCBmaWxsPSIjRkY2NjAwIiBkPSJNMzU2LjQxLDk2LjAxOWgtMTUybDczLjYtODEuNmMzLjU0NS0yLjYzNyw0LjI4MS03LjY0OCwxLjY0NC0xMS4xOTRzLTcuNjQ4LTQuMjgxLTExLjE5NC0xLjY0NCBjLTAuNzgsMC41OC0xLjQ0OCwxLjI5OC0xLjk3MSwyLjExOGwtODAsODhsLTgwLTg4Yy0yLjc2Ni0zLjQ0NS03LjgwMi0zLjk5NS0xMS4yNDctMS4yMjljLTMuNDQ1LDIuNzY2LTMuOTk1LDcuODAyLTEuMjI5LDExLjI0NyBjMC4xOTgsMC4yNDYsMC40MSwwLjQ4MSwwLjYzNSwwLjcwMmw3My42LDgxLjZoLTE1MmMtOC44MzcsMC0xNiw3LjE2My0xNiwxNnYyNDQuNjRjMCw4LjgzNyw3LjE2MywxNiwxNiwxNmgzNDAuMTYgYzguODM3LDAsMTYtNy4xNjMsMTYtMTZ2LTI0NC44OEMzNzIuMjc5LDEwMy4wMzcsMzY1LjE1Myw5Ni4wMTksMzU2LjQxLDk2LjAxOXogTTM1Ni40MSwzNTYuNDE5SDE3LjEzdi0yNDQuNjRoMTY5LjJoMC43MmgwLjcyIGgxNjguOTZMMzU2LjQxLDM1Ni40MTl6Ii8+DQo8L2c+DQo8Zz4NCiAgPHBhdGggZmlsbD0iI0ZGNjYwMCIgZD0iTTMxNi4xNywxMjguNjZINTYuODFjLTguNzQzLDAuMTMxLTE1Ljc2MSw3LjI1Ni0xNS43NiwxNnYxNzkuMTJjMCw4LjgzNyw3LjE2MywxNiwxNiwxNmgyNTkuMTJjOC44MzcsMCwxNi03LjE2MywxNi0xNiBWMTQ0LjY2QzMzMi4xNywxMzUuODIzLDMyNS4wMDYsMTI4LjY2LDMxNi4xNywxMjguNjZ6IE0zMTUuOTMsMzIzLjQ1OUg1Ni45N3YtMTc4LjhoMjU5LjJMMzE1LjkzLDMyMy40NTl6Ii8+DQo8L2c+DQo8dGV4dCB4PSI5MCIgeT0iMjg1IiBmaWxsPSIjRkY2NjAwIiBzdHlsZT0iZm9udC1zaXplOiAxNDA7IGZvbnQtd2VpZ2h0OiBib2xkIj5PbjwvdGV4dD4NCjwvc3ZnPg=='
const img =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KICB2aWV3Qm94PSIwIDAgMzcyLjY1OSAzNzIuNjU5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNzIuNjU5IDM3Mi42NTk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KICA8cGF0aCBmaWxsPSIjQ0NDQ0NDIiBkPSJNMzU2LjQxLDk2LjAxOWgtMTUybDczLjYtODEuNmMzLjU0NS0yLjYzNyw0LjI4MS03LjY0OCwxLjY0NC0xMS4xOTRzLTcuNjQ4LTQuMjgxLTExLjE5NC0xLjY0NCBjLTAuNzgsMC41OC0xLjQ0OCwxLjI5OC0xLjk3MSwyLjExOGwtODAsODhsLTgwLTg4Yy0yLjc2Ni0zLjQ0NS03LjgwMi0zLjk5NS0xMS4yNDctMS4yMjljLTMuNDQ1LDIuNzY2LTMuOTk1LDcuODAyLTEuMjI5LDExLjI0NyBjMC4xOTgsMC4yNDYsMC40MSwwLjQ4MSwwLjYzNSwwLjcwMmw3My42LDgxLjZoLTE1MmMtOC44MzcsMC0xNiw3LjE2My0xNiwxNnYyNDQuNjRjMCw4LjgzNyw3LjE2MywxNiwxNiwxNmgzNDAuMTYgYzguODM3LDAsMTYtNy4xNjMsMTYtMTZ2LTI0NC44OEMzNzIuMjc5LDEwMy4wMzcsMzY1LjE1Myw5Ni4wMTksMzU2LjQxLDk2LjAxOXogTTM1Ni40MSwzNTYuNDE5SDE3LjEzdi0yNDQuNjRoMTY5LjJoMC43MmgwLjcyIGgxNjguOTZMMzU2LjQxLDM1Ni40MTl6Ii8+DQo8L2c+DQo8Zz4NCiAgPHBhdGggZmlsbD0iI0NDQ0NDQyIgZD0iTTMxNi4xNywxMjguNjZINTYuODFjLTguNzQzLDAuMTMxLTE1Ljc2MSw3LjI1Ni0xNS43NiwxNnYxNzkuMTJjMCw4LjgzNyw3LjE2MywxNiwxNiwxNmgyNTkuMTJjOC44MzcsMCwxNi03LjE2MywxNi0xNiBWMTQ0LjY2QzMzMi4xNywxMzUuODIzLDMyNS4wMDYsMTI4LjY2LDMxNi4xNywxMjguNjZ6IE0zMTUuOTMsMzIzLjQ1OUg1Ni45N3YtMTc4LjhoMjU5LjJMMzE1LjkzLDMyMy40NTl6Ii8+DQo8L2c+DQo8dGV4dCB4PSI4MCIgeT0iMjg1IiBmaWxsPSIjQ0NDQ0NDIiBzdHlsZT0iZm9udC1zaXplOiAxNDA7IGZvbnQtd2VpZ2h0OiBib2xkIj5PZmY8L3RleHQ+DQo8L3N2Zz4='
