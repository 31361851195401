import { faCirclePlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import Image from 'src/components/Image'
import ModalPreviewFile from 'src/components/ModalPreviewFile/ModalPreviewFile'
import styled from 'styled-components'
import canvasApi from '../../../api/canvas'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../utils/handle-error'
import { v4 as uuidv4 } from 'uuid'
import { WrapTimePicker } from 'src/components/SchedulePower'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import { getNumberFromTime, returnTimeDate } from 'src/utils/render-time'
import { useDispatch } from 'react-redux'
import UrlAction from 'src/redux/Url/action'

export default function AddCanvas({ onAddSuccess }) {
  const [canvaUrl, setCanvaUrl] = useState('')
  const [canvaID, setCanvaID] = useState('')
  const [validUrl, setValidUrl] = useState(false)
  const [inputsInfo, setInputsInfo] = useState({
    title: '',
  })
  const [preview, setPreview] = useState(false)
  const [startTime, setStartTime] = useState(null)
  const [duration, setDuration] = useState(0)

  const dispatch = useDispatch()
  const handleChangeCanvaUrl = (e) => {
    const value = e.target.value
    const link = value.substr(value.lastIndexOf('design/') + 7, 34)
    const link2 = value.substr(value.lastIndexOf('design/') + 7, 11)
    dispatch(UrlAction.postHaveChange('add-canva', 'Canva link', 'Canva link'))

    if (link.length === 34) {
      setCanvaID(link)
      setValidUrl(true)
      setCanvaUrl(link2)
    } else {
      setCanvaID('')
      setValidUrl(false)
      setCanvaUrl(value)
    }
  }

  const handleChangeInfo = (e) => {
    const name = e.target.name
    const value = e.target.value
    dispatch(UrlAction.postHaveChange('add-canva', 'Canva title', 'Canva title'))

    setInputsInfo({
      ...inputsInfo,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    const { title } = inputsInfo
    if (!title) {
      return handleError('Name is required!')
    }
    if (!duration) {
      return handleError('Duration is required!')
    }
    if (!canvaID) {
      return handleError('Canva URL is required!')
    }

    if (!validUrl) {
      return handleError('Canva URL invalid!')
    }

    const data = {
      url_link: canvaID,
      type: 'canva',
      thumbnail: `https://www.canva.com/design/${canvaID}/screen`,
      title,
      duration: duration,
      status: 1,
    }

    canvasApi
      .createCanva(data)
      .then((res) => {
        if (res.success) {
          setCanvaUrl('')
          setInputsInfo({
            title: '',
          })
          setDuration(0)
          setStartTime(null)
          setCanvaID('')
          setValidUrl(false)
          handleSuccess('Create canva success!')

          // handle create success
          if (res.data.id) {
            data.id = res.data.id
          }

          dispatch(UrlAction.resetHaveChange('add-canva'))
          // window.removeChange('add-canva')
          onAddSuccess?.(data)
        }
      })
      .catch((err) => handleError('createCanva', err))
  }
  const handleShowPreview = (preview) => {
    setPreview(preview)
  }

  const hanldeChangeDuration = (v) => {
    dispatch(UrlAction.postHaveChange('add-canva', 'Canva duration', 'Canva duration'))
    const d = getNumberFromTime(v)
    setDuration(d)
    setStartTime(v)
  }

  const handleExport = () => {}

  return (
    <>
      <WrapImage>
        {!validUrl && <Image src={require('../../../assets/image/canva-default.png')} />}

        {validUrl && (
          <>
            <img src={`https://www.canva.com/design/${canvaID}/screen`} />
            {/* <iframe
              width="100%"
              height="100%"
              loading="lazy"
              src={`https://www.canva.com/design/${canvaID}/watch?embed&autoplay=0`}
              allow="autoplay"
            /> */}

            {/* <WrapIcon>
              <FontAwesomeIcon icon={faCirclePlay} onClick={() => handleShowPreview(true)} />
            </WrapIcon> */}
          </>
        )}
      </WrapImage>
      <TitleDetail>
        <span>Add Your Canva</span>
      </TitleDetail>
      <TitleItem>
        <span>Canva link:</span>
      </TitleItem>
      <WrapInput>
        <Input
          type="text"
          name="canvaUrl"
          placeholder="your URL canva"
          value={canvaUrl}
          onChange={handleChangeCanvaUrl}
        />
      </WrapInput>
      <TitleItem>
        <span>Canva title:</span>
      </TitleItem>
      <WrapInput>
        <Input type="text" name="title" placeholder="Title" value={inputsInfo.title} onChange={handleChangeInfo} />
      </WrapInput>
      {/* <WrapInput>
        <Input
          type="number"
          name="duration"
          placeholder="Duration"
          value={inputsInfo.duration}
          onChange={handleChangeInfo}
        />
      </WrapInput> */}
      {/* <WrapInput>
        <ButtonOutLine onClick={handleExport} title={'Export to File'} />
      </WrapInput> */}
      <TitleItem>
        <span>Canva duration:</span>
      </TitleItem>
      <WrapTime>
        <WrapTimePicker>
          <TimePicker
            value={startTime ? moment(startTime) : null}
            name="duration"
            placeholder="Select time"
            showSecond={true}
            onChange={(e) => hanldeChangeDuration(e?._d)}
            inputReadOnly={true}
            defaultOpenValue={moment().startOf('day')}
            // format="HH:mm:ss"
          />
        </WrapTimePicker>
      </WrapTime>

      <WrapInput>
        <ButtonOutLine onClick={handleSubmit} title={'Save Info'} />
      </WrapInput>
      {preview && (
        <ModalPreviewFile
          from="canva-item-preview"
          listDataSource={[
            {
              id: 1,
              url_link: `https://www.canva.com/design/${canvaID}/watch?embed&autoplay=0`,
              title: 'title',
              type: 'canva',
            },
          ]}
          selectedItem={[
            {
              id: 1,
              url_link: `https://www.canva.com/design/${canvaID}/watch?embed&autoplay=0`,
              title: 'title',
              type: 'canva',
            },
          ]}
          onClose={() => handleShowPreview(false)}
        />
      )}
    </>
  )
}

const WrapImage = styled.div`
  flex: 1;
  height: 180px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px;
  transform: translateY(-10px);
  background-color: #ccc;
  cursor: pointer;
  position: relative;

  img {
    height: 100%;
    width: auto;
    max-width: 100%;
  }
`

const WrapIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(1, 1, 1, 0.6);
  border-radius: 50%;
  bottom: 4px;
  left: 4px;
`

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const TitleItem = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #7c7a7a;
  padding: 8px;
  margin-top: 4px;
`

const WrapTime = styled.div`
  width: 100%;
  padding: 0 8px;
  margin-top: -12px;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  /* margin-top: 12px; */
  :last-child {
    margin-top: 20px;
  }
  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 12px;
  margin-left: 12px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin-left: 5px;
`

const ButtonID = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d9af93;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 10px;
  }
  :hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    span {
      color: #fff;
    }
  }
`
