import createAction from '../action'
import axios from 'axios'
import appConstants from 'src/constants'
import * as storage from '../../helpers/storage'
import { useDispatch } from 'react-redux'

export const FileUploadActionType = Object.freeze({
  ADD_FILES_UPLOAD: 'fileUpload/ADD_FILES_UPLOAD',
  ADD_FILES_UPLOAD_PROCESS: 'fileUpload/ADD_FILES_UPLOAD_PROCESS',
  ATIVE_POPUP: 'file/ATIVE_POPUP',
  RESET_FILE_UPLOAD: 'file/RESET_FILE_UPLOAD',
  FILE_UPLOAD_DONE: 'file/FILE_UPLOAD_DONE',
  RESET_FILE_UPLOAD_DONE: 'file/RESET_FILE_UPLOAD_DONE',
  RESET_FILE_PROCESSING: 'file/RESET_FILE_PROCESSING',
  CHANGE_BLOCKING_STATUS: 'file/CHANGE_BLOCKING_STATUS',
  //   SET_IS_AUTH: 'user/SET_IS_AUTH',
})

const addFilesUpload = (filesData, key) => createAction(FileUploadActionType.ADD_FILES_UPLOAD, filesData, key)

const addFilesUploadProcess = (files, key) => createAction(FileUploadActionType.ADD_FILES_UPLOAD_PROCESS, files, key)

const activePopup = (active) => createAction(FileUploadActionType.ATIVE_POPUP, active)

const showFileUploadDone = (files) => createAction(FileUploadActionType.FILE_UPLOAD_DONE, files)

const resetFileUploadDone = () => createAction(FileUploadActionType.RESET_FILE_UPLOAD_DONE, [])

const resetFileProcessing = () => createAction(FileUploadActionType.RESET_FILE_PROCESSING, [])

const changeBlockingStatus = (status) => createAction(FileUploadActionType.CHANGE_BLOCKING_STATUS, status)

// const resetFilesUpload = (files, key) => createAction(FileUploadActionType.RESET_FILE_UPLOAD, files, key)

const FileUploadAction = {
  addFilesUpload,
  addFilesUploadProcess,
  activePopup,
  showFileUploadDone,
  resetFileUploadDone,
  changeBlockingStatus,
  resetFileProcessing,
}

export default FileUploadAction
