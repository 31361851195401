import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useCallbackPrompt } from 'src/hooks/useCallbackPrompt'
import UrlAction from 'src/redux/Url/action'

const NavigationGuard = () => {
  let statusHaveChange = useSelector((state) => state.url.haveChange)
  console.log('🚀 ~ file: index.js ~ line 9 ~ NavigationGuard ~ statusHaveChange', statusHaveChange)

  useCallbackPrompt(statusHaveChange)
}

export default NavigationGuard
