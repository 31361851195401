import { faCheck, faMinus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ButtonOutLine from '../../../../../../components/ButtonOutLine'
import DatePicker from 'react-datepicker'
import { partNumberToDateTime } from '../../../../../../utils/render-time'
import TimePicker from 'rc-time-picker'
import { WrapTimePicker } from '../../../../../../components/SchedulePower'
import moment from 'moment'

function Schedule({ valueSchedule, onDeleteItemSchedule, onUpdateValue }) {
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  const [fromTime, setFromTime] = useState('')
  const [toTime, setToTime] = useState('')

  const [hasAllTime, setHasAllTime] = useState(true)

  const [listDate, setListDate] = useState([])

  useEffect(() => {
    const { days, end_date, end_time, start_date, start_time } = valueSchedule

    setHasAllTime(end_date === 0 && start_date === 0)

    setFromDate(partNumberToDateTime(start_date, 'dd'))
    setToDate(partNumberToDateTime(end_date, 'dd'))

    setFromTime(partNumberToDateTime(start_time, 'h'))
    setToTime(partNumberToDateTime(end_time, 'h'))

    setListDate(days || [])
  }, [
    valueSchedule.days,
    valueSchedule.end_date,
    valueSchedule.end_time,
    valueSchedule.start_date,
    valueSchedule.start_time,
  ])

  const FromCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <ButtonOutLine title={value || 'from date'} onClick={onClick} ref={ref} />
  ))

  const ToCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <ButtonOutLine title={value || 'to date'} onClick={onClick} ref={ref} />
  ))

  return (
    <Root>
      <Title>
        Days
        <FontAwesomeIcon icon={faTrashCan} onClick={() => onDeleteItemSchedule?.()} />
      </Title>

      <WrapTime>
        {hasAllTime ? (
          <ButtonAllTime
            onClick={() => {
              onUpdateValue('all-date', false)
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
            All date
          </ButtonAllTime>
        ) : (
          <>
            <WrapIconMinus
              onClick={() => {
                onUpdateValue('all-date', true)
              }}
            >
              <FontAwesomeIcon icon={faMinus} />
            </WrapIconMinus>
            <WrapBtnTime>
              <DatePicker
                selected={fromDate}
                onChange={(date) => {
                  setFromDate(date)
                  onUpdateValue('start_date', date)
                }}
                customInput={<FromCustomInput />}
              />
            </WrapBtnTime>
            <WrapBtnTime>
              <DatePicker
                selected={toDate}
                onChange={(date) => {
                  setToDate(date)
                  onUpdateValue('end_date', date)
                }}
                customInput={<ToCustomInput />}
              />
            </WrapBtnTime>
          </>
        )}
      </WrapTime>

      <WrapItemDay>
        {listDateData.map((elm, index) => {
          return (
            <Item key={elm.id} select={listDate.includes(elm.id)} onClick={() => onUpdateValue('list_date', elm.id)}>
              {elm.title}
            </Item>
          )
        })}
      </WrapItemDay>

      <WrapButtonTime>
        <WrapTimePicker>
          <TimePicker
            value={fromTime ? moment(fromTime) : null}
            placeholder="Select time"
            showSecond={false}
            onChange={(e) => {
              // return

              setFromTime(e?._d)
              onUpdateValue('start_time', e?._d)
            }}
            inputReadOnly={true}
          />
        </WrapTimePicker>

        <WrapTimePicker>
          <TimePicker
            value={toTime ? moment(toTime) : null}
            placeholder="Select time"
            showSecond={false}
            onChange={(e) => {
              setToTime(e?._d)
              onUpdateValue('end_time', e?._d)
            }}
            inputReadOnly={true}
          />
        </WrapTimePicker>
      </WrapButtonTime>
    </Root>
  )
}

export default Schedule

const listDateData = [
  {
    id: 0,
    title: 'Su',
  },
  {
    id: 1,
    title: 'Mo',
  },
  {
    id: 2,
    title: 'Tu',
  },
  {
    id: 3,
    title: 'We',
  },
  {
    id: 4,
    title: 'Th',
  },
  {
    id: 5,
    title: 'Fr',
  },
  {
    id: 6,
    title: 'Sa',
  },
]

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`

const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;

  svg {
    color: rgb(255, 102, 0);
    margin-left: 10px;
    cursor: pointer;
  }
`

const ButtonAllTime = styled.div`
  width: 130px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  padding: 0 10px;
  border: 1px solid #d9af93;
  border-radius: 20px;
  cursor: pointer;

  svg {
    height: 24px;
    color: rgb(255, 102, 0);
    margin-right: 8px;
  }
`

const WrapTime = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 8px 0;
`

const WrapIconMinus = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: rgb(255, 102, 0);
  margin-right: 8px;
  cursor: pointer;
`

const WrapBtnTime = styled.div`
  width: 110px;
  display: flex;
  margin-right: 12px;
`

const WrapItemDay = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`

const Item = styled.div`
  width: 32px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 1px solid #ff6600;
  border-radius: 15px;
  color: ${({ select }) => (select ? '#fff' : '#000')};
  background-color: ${({ select }) => (select ? '#ff6600' : 'none')};
  cursor: pointer;
`

const WrapButtonTime = styled.div`
  width: 100%;
  margin-top: 12px;
`
