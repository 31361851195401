import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SelectedImage from 'src/components/SelectedImage/SelectdImage'
import StackGridList from 'src/components/StackGridList'

export default function ViewGrid({ dataSource, onSelect, onLoading, fromPage, onCheckFavourite }) {
  
  const [items, setItems] = useState(dataSource)

  useEffect(() => {
    const newData = dataSource.map((elm) => {
      return {
        ...elm,
        src: elm.thumbnail,
      }
    })
    setItems(newData)
  }, [dataSource])
  return (
    <Root>
      <StackGridList
        dataSource={items}
        onSelectedItem={onSelect}
        onLoading={onLoading}
        CustomRender={SelectedImage}
        dndLayout
        fromPage={fromPage}
        onCheckFavourite={onCheckFavourite}
      />
    </Root>
  )
}

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  & span {
    opacity: 1 !important;
  }
`
