const DEVICE_KEY = 'getDevice';
const TOKEN_KEY = 'token_key';
const USER_INFO = "user_info";

export function getDevice() {
  return localStorage.getItem(DEVICE_KEY) || '';
}
export function setDevice(value) {
  return localStorage.setItem(DEVICE_KEY, value || '');
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY) || '';
}
export function setToken(value) {
  return localStorage.setItem(TOKEN_KEY, value || '');
}

export function getUserInfo() {
  return localStorage.getItem(USER_INFO) || '';
}
export function setUserInfo(value) {
  return localStorage.setItem(USER_INFO, value || '');
}
