import { mediaApi } from '../api/mediaApi'

export const handleUploadFile = async (iObj, p) => {
  const file = iObj || {}
  const pid = p || -1
  const fileName = file.name
  const id = 'tmp_' + new Date().getTime()

  const formData = new FormData()
  formData.append('file', file)
  formData.append('_fileName', fileName)
  formData.append('_id', id)
  formData.append('pid', pid)

  return await mediaApi.uploadMedia(formData, id)
}
