import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ScheduleList from 'src/components/ScheduleList'
import GlobalAction from 'src/redux/global/action'
import UrlAction from 'src/redux/Url/action'
import calculateMB, { formatKBytes } from 'src/utils/calculateMB'
import styled from 'styled-components'
import { playlistApi } from '../../../../../api/playlistApi'
import ButtonOutLine from '../../../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../../../utils/handle-error'

function SettingPlaylist({ files, playlistSelect, filesInPlaylist, onUpdatePlaylistSuccess, onRemovePlaylist }) {
  const navigate = useNavigate()
  const [allTime, setAllTime] = useState(true)
  const [listSchedule, setListSchedule] = useState([])
  const Param = useParams()
  const detailId = Param?.detailId
  const location = useLocation()

  const dispatch = useDispatch()

  useEffect(() => {
    const idPlaylist = playlistSelect?.id
    if (!idPlaylist) return
    if (location.pathname.indexOf('/setting-playlist') < 0) return
    playlistApi
      .getPlaylistDetail(idPlaylist)
      .then((res) => {
        if (!res.success) {
          throw res
        }
        if (typeof res !== 'object') return

        const { data } = res

        if (data?.times.length) {
          const list =
            data?.times.map((elm, index) => {
              return {
                id: index,
                ...elm,
              }
            }) || []
          setListSchedule(list)
          setAllTime(false)
        } else {
          setListSchedule([])
          setAllTime(true)
        }
      })
      .catch((err) => {
        if (err) {
          handleError('getPlaylistDetail - 1', err)
        }
      })
  }, [playlistSelect])

  const handleSaveSchedule = useCallback(
    (data) => {
      playlistApi
        .updatePlaylist(playlistSelect?.id, data)
        .then((res) => {
          handleSuccess('Success')
          dispatch(UrlAction.resetHaveChange('device-setting-menuright'))
          const info = { id: playlistSelect?.id, info: data }
          onUpdatePlaylistSuccess?.(info)
        })
        .catch((err) => {
          if (err) {
            handleError('updatePlaylist', err)
          }
        })
    },
    [playlistSelect?.id]
  )

  const totalFile = React.useMemo(() => {
    if (filesInPlaylist) return filesInPlaylist[playlistSelect?.id]?.length
    return null
  }, [filesInPlaylist, playlistSelect?.id])

  const totalSize = React.useMemo(() => {
    if (filesInPlaylist && playlistSelect) {
      const statement = filesInPlaylist[playlistSelect?.id]
      if (Array.isArray(statement) && Array.isArray(files)) {
        try {
          const total = files
            .filter((val) => statement.includes(val.id))
            .map((data) => data.size)
            .reduce((a, b) => a + b)
          return formatKBytes(total, 1)
        } catch (error) {
          handleError('totalSize', error)
        }
      }
    }
    return null
  }, [files, filesInPlaylist, playlistSelect])

  const handleClickBtnAllTime = useCallback(() => {
    if (allTime) {
      setAllTime(false)
      return
    }
    setAllTime(true)
  }, [allTime])

  return (
    <Root>
      <Content>
        <TitleDetail
          onClick={() => {
            navigate(`/playlist/${playlistSelect?.id}`)
            dispatch(GlobalAction.activeTabMenuBar('playlist'))
          }}
        >
          <span>Playlist Setting</span>
          <FontAwesomeIcon icon={faPen} />
        </TitleDetail>
        <Label>Name: {playlistSelect?.name}</Label>
        <Label>Status: {playlistSelect?.status === 1 ? 'Unblock' : 'Block'}</Label>
        <Label>Total files in playlist: {totalFile}</Label>
        <Label>Total size in playlist: {totalSize}</Label>

        <WrapInput>
          <ButtonOutLine
            title={'Remove Playlist from Device'}
            onClick={() => {
              onRemovePlaylist?.(playlistSelect?.id)
            }}
          />
        </WrapInput>
      </Content>

      <ScheduleList
        allTime={allTime}
        listSchedule={listSchedule}
        onClickBtnAllTime={handleClickBtnAllTime}
        onSaveSchedule={handleSaveSchedule}
      />
    </Root>
  )
}

export default SettingPlaylist

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(204, 204, 204);

  :last-child {
    border-bottom: none;
  }
`

const TitleDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 12px;
  cursor: pointer;

  svg {
    height: 22px;
    margin-left: 12px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }
`

const Label = styled.p`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 4px;
`

const WrapInput = styled.div`
  width: 100%;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`
