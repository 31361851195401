import appConstants from '../constants'
import HttpRequest from './HttpRequest'

const apiUrl = `${appConstants.apiUrl}/theme`
const articleApi = Object.freeze({
  getListAricle: (page = 1, limit = 10, pid, orientation, isNew) =>
    HttpRequest.request({
      method: 'GET',
      url: `${apiUrl}/item`,
      params: {
        page,
        limit,
        pid,
        orientation,
        isNew,
      },
    }),
  getItemAricle: (id) =>
    HttpRequest.request({
      method: 'GET',
      url: `${apiUrl}/item/${id}`,
    }),
  // getListAricleFilter: (pid, orientation = 0, isNew = 1, litmit = 5) =>
  //   HttpRequest.request({
  //     method: 'GET',
  //     url: `${apiUrl}/item?pid=${pid}&orientation=${orientation}&isNew=${isNew}&limit=${litmit}`,
  //   }),
  createArticle: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${apiUrl}/item`,
      data: data,
    }),
  updateArticle: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${apiUrl}/item`,
      data: data,
    }),
  uploadThumbnail: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/media/upload`,
      data: data,
    }),

  deleteItem: (data) =>
    HttpRequest.request({
      method: 'DELETE',
      url: `${apiUrl}/item`,
      data: data,
    }),
})

export default articleApi
