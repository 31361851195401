import React, { useEffect, useState } from 'react'
import ViewGrid from './ContentView/ViewGrid'
import styled, { css, keyframes } from 'styled-components'
import ViewList from './ContentView/ViewList'
import ViewTable from './ContentView/ViewTable'
import { v4 as uuidv4 } from 'uuid'

var timeout

export default function GridFileList({
  dataSource,
  refLoadMore,
  onLoadMore,
  isLastPage,
  statusView,
  onSelectItem,
  // selectedItem,
  fromPage,
  onCheckFavourite,
}) {
  // const [listData, setListData] = useState(dataSource)
  // const [isLoading, setIsLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [imgLoadingDone, setImgLoadingDone] = useState(false)

  useEffect(() => {
    if (isLastPage) {
      setLoadingText('- END -')
    }
  }, [isLastPage])

  useEffect(() => {
    if (isLastPage) {
      setLoadingText('- END -')
    }
    if (refLoadMore && !isLastPage) {
      refLoadMore.current.addEventListener('scroll', handleScroll, false)

      if (imgLoadingDone && statusView === 0) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          const scrollHeight = refLoadMore.current.scrollHeight
          const clientHeight = refLoadMore.current.clientHeight

          hanldeNeedLoadMore(scrollHeight, clientHeight)
        }, 500)
      }
    }
    return () => {
      if (refLoadMore.current) {
        refLoadMore.current.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [dataSource, imgLoadingDone])

  const hanldeNeedLoadMore = (a, b) => {
    if (!isLastPage && dataSource.length > 0 && a <= b) {
      onLoadMore()
    }
  }

  const handleImgLoadingDone = (index) => {
    if (index === dataSource.length - 1) {
      setTimeout(() => {
        setImgLoadingDone(true)
      }, 200)
    }
  }

  const handleScroll = (e) => {
    if (isLastPage) {
      setLoadingText('- END -')
      return
    }

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 300) {
        setLoadingText('Loading ...')
        onLoadMore()
      }
    }, 300)
  }

  return (
    <>
      {statusView === 0 && (
        <ViewGrid
          dataSource={dataSource}
          onSelect={onSelectItem}
          onLoading={handleImgLoadingDone}
          fromPage={fromPage}
          onCheckFavourite={onCheckFavourite}
        />
      )}
      {statusView === 1 && <ViewList dataSource={dataSource} onSelect={onSelectItem} fromPage={fromPage} />}
      {statusView === 2 && <ViewTable dataSource={dataSource} onSelect={onSelectItem} />}
      {loadingText !== '' && (
        <WrapLoadingBottom>
          <WrapLoading>
            {loadingText !== '- END -' && (
              <Loader>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderText></LoaderText>
              </Loader>
            )}
            {loadingText === '- END -' && <WrapEnd>- END -</WrapEnd>}
          </WrapLoading>
        </WrapLoadingBottom>
      )}
    </>
  )
}

const WrapLoadingBottom = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
`
const WrapLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = styled.div`
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const LoadingAniText = keyframes`
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
`

const LoaderAniDot = keyframes`
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
`

const LoaderDot = styled.div`
  animation-name: ${LoaderAniDot};
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;

  :first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }
  :nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  :nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  :nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  :nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  :nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }
`
const LoaderText = styled.div`
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
  :after {
    content: 'Loading';
    font-weight: bold;
    animation-name: ${LoadingAniText};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`
const WrapEnd = styled.div`
  margin-top: 40px;
  font-weight: 500;
`
