import { faLock, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-regular-svg-icons'
// import {} from '@fortawesome/free-thin-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState, forwardRef } from 'react'
import { getHHMMSSfromDurationNumber } from 'src/utils/render-time'
import styled from 'styled-components'
import Image from '../Image'
import useCheckIsMobile from 'src/hooks/useCheckIsMobile'
import ImgaeV2 from '../ImageV2'
import { checkTouchScreen } from 'src/utils/checkTouchScreen'

const Checkmark = ({ selected }) => (
  <div style={selected ? { left: '1px', top: '1px', position: 'absolute', zIndex: '11' } : { display: 'none' }}>
    <svg style={{ fill: 'white', position: 'absolute' }} width="24px" height="24px">
      <circle cx="12.5" cy="12.2" r="8.292" />
    </svg>
    <svg style={{ fill: '#06befa', position: 'absolute' }} width="24px" height="24px">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
)

const defaultImgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
  objectFit: 'contain',
  width: '100%',
  height: 'auto',
  minWidth: '25%',
}
const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
}
const cont = {
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
}

export const Photo = forwardRef(({ style, photo, selected, onSelectedItem, isDragging, ...props }, ref) => {
  const [isSelected, setIsSelected] = useState(photo?.select)

  const handleOnClick = () => {
    console.log('photo on click')
    onSelectedItem?.(photo)
    setIsSelected(!isSelected)
  }

  const isMobile = useCheckIsMobile()

  useEffect(() => {
    setIsSelected(photo?.select)
  }, [photo])

  const imageName = photo?.name ?? photo?.title
  // console.log('drag --- ', isDragging)
  const enableTouchProps = checkTouchScreen() ? {} : { ...props }
  const isEnableTouch = checkTouchScreen()

  return (
    <Root isMobile={isMobile} ref={ref} style={style} onClick={handleOnClick} {...enableTouchProps}>
      <div className={!isSelected ? 'not-selected' : 'style-warp-img'}>
        <WrapContentImg isMobile={isMobile}>
          <ItemSelected selected={isSelected}>
            <IconChecked>
              <FontAwesomeIcon icon={faCheck} />
            </IconChecked>
          </ItemSelected>
          {/* <Checkmark selected={isSelected} /> */}
          {photo?.status === 0 && (
            <Block onClick={handleOnClick}>
              <FontAwesomeIcon icon={faLock} />
            </Block>
          )}
          {isEnableTouch && (
            <WrapDragIcon
              {...props}
              onTouchEnd={
                isDragging
                  ? () => {
                      return
                    }
                  : handleOnClick
              }
            >
              <FontAwesomeIcon icon={faClone} />
            </WrapDragIcon>
          )}

          <ImgaeV2 item={photo} handleOnClick={handleOnClick} />
        </WrapContentImg>
      </div>
      <style>{`.style-warp-img,.not-selected{position: relative};.not-selected:hover{outline:2px solid #06befa}`}</style>
    </Root>
  )
})

const Root = styled.div`
  width: 100%;
  height: 100%;
`

const ItemSelected = styled.div`
  display: ${(props) => (props.selected ? 'flex' : 'none')};

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 30px);
  border-radius: 15px 15px 0px 0px;
  background-color: rgba(56, 175, 202, 0.7);
  z-index: 1;
`

const IconChecked = styled.div`
  margin: auto;
  color: white;

  svg {
    width: 50px;
    height: 50px;
  }
`

const WrapContentImg = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props) => (props.isMobile ? '162px' : '192px')};
`

const WrapDragIcon = styled.div`
  position: absolute;
  top: calc((100% - 50px) / 2);
  left: calc((100% - 50px) / 2);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.3); */
  border-radius: 8px;
  cursor: pointer;
  svg {
    width: 50px;
    height: 50px;
    color: #fff;
    /* border: 1px solid #fff; */
  }

  /* pointer-events: all; */
`

const WrapImage = styled.div`
  width: 100%;
  height: 162px;
  background-color: rgb(220, 220, 220);
  border-radius: 15px 15px 0px 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Img = styled.img`
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
  padding: 4px;
  border-radius: 4px;
`
const ImgMobile = styled.img`
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
  padding: 4px;
  border-radius: 4px;
`

const WrapName = styled.p`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    height: 100%;
    color: rgb(134, 134, 134);
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px;
  }
`

const Block = styled.div`
  position: absolute;
  bottom: 40px;
  right: 15px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
    width: 24px;
    height: 24px;
  }
`
