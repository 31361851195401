import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'src/components/Image'
import SwiperSlideList from 'src/components/SwiperSlideList/SwiperSlideList'
import { useEffect, useState } from 'react'

function PlayListItem({ playlist, onClickItem }) {
  const [items, setItems] = useState(playlist?.files)
  useEffect(() => {
    const newData = playlist?.files?.map((elm, index) => {
      let src = ''
      if (elm?.type === 'web') {
        if (elm?.ext === 'canv') {
          src = `https://www.canva.com/design/${elm?.url}/screen`
        } else {
          src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
        }
      } else {
        src = elm?.path_thumbnail
      }
      return {
        ...elm,
        path_thumbnail: src,
      }
    })

    setItems(newData)
  }, [playlist])
  return (
    <Root>
      <Header>
        <Title>{playlist?.name || 'n/a'}</Title>
      </Header>
      <WrapContentList>
        {items?.length > 0 ? (
          <SwiperSlideList
            fromPage="form_file__add_playlist"
            activeKeyboard={false}
            listDataSource={items}
            isShowIconBlock={true}
            onClickSlideItem={() => onClickItem(playlist?.id)}
            isBorderSlide={true}
          />
        ) : (
          <ContentEmpty onClick={() => onClickItem(playlist?.id)}>
            <span>Empty</span>
          </ContentEmpty>
        )}
      </WrapContentList>
      <LineBottom />
      <Count>{playlist?.files?.length || 0}</Count>
    </Root>
  )
}

export default PlayListItem

const Root = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
`

const WrapContentList = styled.div`
  position: relative;
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  & .swiper {
    margin-left: 0;
    margin-right: 0;
  }

  & .swiper-slide {
    width: 140px !important;
    height: 140px !important;
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-next:after,
  .swiper-button-prev:after {
    color: #ff6600;
    font-size: 24px;
    font-weight: 800;
    pointer-events: all;
  }
`

const WrapContentImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const ContentEmpty = styled.div`
  flex: 1;
  display: flex;
  font-size: 24px;
  font-weight: 500;
  span {
    margin: auto;
  }
`
const LineBottom = styled.div`
  margin: 22px 0;
  width: 100%;
  height: 1px;
  background: #ccc;
`
const Count = styled.div`
  position: absolute;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #fff;
  background-color: rgba(255, 102, 0, 0.9);
  border-radius: 50%;
  bottom: 25px;
  right: 5px;
  z-index: 9;
`
