import appConstants from '../constants'
import HttpRequest from './HttpRequest'

export const playlistApi = Object.freeze({
  getPlaylist: (page = 1, limit = 50) =>
    HttpRequest.request({
      method: 'GET',
      url: `${appConstants.apiUrl}/playlist`,
      params: {
        page,
        limit,
      },
    }),
  getPlaylistDetail: (id) =>
    HttpRequest.request({
      method: 'GET',
      url: `${appConstants.apiUrl}/playlist/${id}`,
    }),
  createPlaylist: (name) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/playlist/-1`,
      params: {
        name,
      },
    }),
  updatePlaylist: (id, data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/playlist/${id}`,
      data,
    }),
  updateFileToPlaylist: (id, data) =>
    HttpRequest.request({
      method: 'PUT',
      url: `${appConstants.apiUrl}/playlist/${id}/file`,
      data,
    }),
  addFileToPlaylist: (id, data) =>
    HttpRequest.request({
      method: 'PUT',
      url: `${appConstants.apiUrl}/playlist/${id}/append-file`,
      data,
    }),
  addDeviceToPlaylist: (id, data) =>
    HttpRequest.request({
      method: 'PUT',
      url: `${appConstants.apiUrl}/playlist/${id}/device`,
      data,
    }),

  savePublishPlayList: (id, data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/device-playlist/${id}`,
      data,
    }),
  deletePlaylist: (id) =>
    HttpRequest.request({
      method: 'DELETE',
      url: `${appConstants.apiUrl}/playlist/${id}`,
    }),
})
