import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ButtonOutLine from '../../../../../components/ButtonOutLine'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPen, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { mediaApi } from '../../../../../api/mediaApi'
import { handleError, handleSuccess } from '../../../../../utils/handle-error'
import { useDispatch } from 'react-redux'
import UrlAction from 'src/redux/Url/action'

function FolderDetail({ folderSelect, updateNameSuccess, onDeleteFolder }) {
  const [currentName, setCurrentName] = useState('')
  const [nameToChange, setNameToChange] = useState()
  const [showEdit, setShowEdit] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setCurrentName(folderSelect?.name)
    setNameToChange(folderSelect?.name)
    setShowEdit(false)
  }, [folderSelect])

  const handleSaveName = () => {
    const data = { name: nameToChange }
    mediaApi
      .updateFolderName(folderSelect?.id, data)
      .then((res) => {
        const item = { id: folderSelect.id, name: nameToChange }
        setCurrentName(nameToChange)
        dispatch(UrlAction.resetHaveChange('file-update-folder-name'))
        handleSuccess('Update success !')
        updateNameSuccess?.(item)
        setShowEdit(false)
      })
      .catch((err) => {
        handleError('updateFolderName', err)
      })
  }

  const handleCloseEdit = () => {
    setShowEdit(false)
    setNameToChange(currentName)
  }

  return (
    <Root>
      <WrapContent>
        {!showEdit ? (
          <WrapNameShow>
            <span>{nameToChange}</span>
            <BtnEdit onClick={() => setShowEdit(true)}>
              <FontAwesomeIcon icon={faPen} />
            </BtnEdit>
          </WrapNameShow>
        ) : (
          <WrapNameEdit>
            <Input
              value={nameToChange}
              onChange={(e) => {
                dispatch(UrlAction.postHaveChange('file-update-folder-name'))
                setNameToChange(e.target?.value)
              }}
            />
            <BtnEdit onClick={handleCloseEdit}>
              <FontAwesomeIcon icon={faXmark} className="faXmark" />
            </BtnEdit>
            <BtnEdit onClick={handleSaveName}>
              <FontAwesomeIcon icon={faCheckCircle} />
            </BtnEdit>
          </WrapNameEdit>
        )}

        <WrapImgInfo>
          <Row>
            <span>Type</span>
            <p>dir</p>
          </Row>
          <Row>
            <span>Created</span>
            <p>11-05-2022 10:25:29</p>
          </Row>
        </WrapImgInfo>
      </WrapContent>
      <WrapBottom>
        <WrapButton>
          <ButtonOutLine title="Delete Folder" onClick={onDeleteFolder} />
        </WrapButton>
      </WrapBottom>
    </Root>
  )
}

export default FolderDetail

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const WrapContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const WrapNameShow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 22px;
    color: #999999;
  }
`

const BtnEdit = styled.button`
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    height: 18px;
    color: #ff6600;
  }

  .faXmark {
    height: 24px;
  }
`

const WrapNameEdit = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ccc;
`
const Input = styled.input`
  flex: 1;
  background: none;
  border: none;

  :focus {
    outline: none;
  }
`

const WrapImgInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 8px;

  span {
    font-size: 16px;
    color: #999999;
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }
`
const WrapBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  border-top: 1px solid #ccc;
  margin-top: auto;
`

const WrapButton = styled.div`
  width: 100%;
  margin-bottom: 12px;
`
