import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AuthRoute from '../route/AuthRoute'

import Login from './Login'
import ActiveAccount from './Login/ActiveAccount'

export default function MainRoute() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="ActiveAccount/:code" element={<ActiveAccount />} />
        <Route path="*" element={<AuthRoute />} />
      </Routes>
    </BrowserRouter>
  )
}
