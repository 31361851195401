import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { PlaylistItemDrag } from './PlaylistItemDrag'

export const SortableList = ({ playlistItem, ...props }) => {
  const sortable = useSortable({ id: playlistItem.id })
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = sortable

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <PlaylistItemDrag
      ref={setNodeRef}
      style={style}
      col={playlistItem}
      isDragging={isDragging}
      {...attributes}
      {...listeners}
      {...props}
    />
  )
}
