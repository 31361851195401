import { combineReducers } from 'redux'

import AuthReducer from './auth/reducer'
import FileUploadReducer from './FileUpload/reducer'
import GlobalReducer from './global/reducer'
import UrlReducer from './Url/reducer'

const rootReducer = combineReducers({
  auth: AuthReducer,
  fileUpload: FileUploadReducer,
  url: UrlReducer,
  global: GlobalReducer,
})

export default rootReducer
