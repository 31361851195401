import { faCheck, faMagnifyingGlass, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState, useRef } from 'react'
import ReactPaginate from 'react-paginate'
import { useSelector } from 'react-redux'
import ScrollToTop from 'src/components/ScrollToTop'
import Tooltipv2 from 'src/components/ToolTipv2'
import styled from 'styled-components'
import userApi from '../../api/users'
import CustomModalConfirm from '../../components/CustomModalConfirm'
import { getUserInfo } from '../../helpers/storage'
import { handleError, handleSuccess } from '../../utils/handle-error'
import UserMenuRight from './UserMenuRight'

export default function User({ isMobile }) {
  const [userList, setUserList] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [typeMenuRight, setTypeMenuRight] = useState('add-user')
  const [showModalDelele, setShowModalDelete] = useState(false)
  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [paginate, setPaginate] = useState({
    current_page: 1,
    per_page: 50,
    toatal: 1,
  })
  const refScroll = useRef(null)

  // listen redux
  const userAssigned = useSelector((state) => state.global.userAssigned)

  useEffect(() => {
    const { per_page, current_page } = paginate
    fetchListUser(per_page, current_page)
  }, [])

  useEffect(() => {
    setShowMenuDetail(false)
  }, [isMobile])

  const fetchListUser = (per_page, current_page) => {
    userApi
      .getListUser(per_page, current_page)
      .then((res) => {
        const data = res.data
        const { current_page, per_page, total } = data
        setPaginate({
          current_page,
          per_page,
          total,
        })
        setUserList(data.data)
      })
      .catch((err) => {
        handleError('getListUser', err)
      })
  }

  const onSuccess = () => {
    fetchListUser(paginate.per_page, 1)
  }

  const onSearchSuccess = (listUser) => {
    setUserList(listUser)
  }

  const handleSelectedItem = (user) => {
    if (selectedItem.id === user.id) {
      setShowMenuDetail(true)
      setSelectedItem({})
      setTypeMenuRight('add-user')
    } else {
      setShowMenuDetail(true)
      setSelectedItem(user)
      setTypeMenuRight('edit-user')
    }
  }

  const handleOpenTabAddUser = () => {
    setShowMenuDetail(true)
    setTypeMenuRight('add-user')
    setSelectedItem({})
  }

  const handleOpenTabSearch = () => {
    setShowMenuDetail(true)
    setTypeMenuRight('search-user')
    setSelectedItem({})
  }

  const handleDelete = () => {
    userApi
      .userDelete(selectedItem.id)
      .then((res) => {
        handleSuccess(res.msg || 'Delete success!')
        setShowModalDelete(false)
        setSelectedItem({})
        setTypeMenuRight('add-user')
        fetchListUser(paginate.per_page, 1)
      })
      .catch((err) => {
        handleError('userDelete', err.msg || 'Error')
      })
  }

  const onCloseMenuRight = () => {
    setShowMenuDetail(false)
    setSelectedItem({})
  }

  // Hanlde Pagination
  const pageCount = useMemo(() => {
    const { total, per_page } = paginate
    return Math.ceil(total / Number(per_page))
  }, [paginate.per_page])

  const handlePageClick = ({ selected: selectedPage }) => {
    fetchListUser(paginate.per_page, selectedPage + 1)
  }

  return (
    <WrapRoot>
      <UserContentLeft>
        <Header>
          <TextHeader>USERS</TextHeader>
          <WrapIconHeader>
            <WrapIcon onClick={handleOpenTabAddUser}>
              <Tooltipv2 overlay="Add user" placement="top">
                <FontAwesomeIcon icon={faPlusCircle} />
              </Tooltipv2>
            </WrapIcon>
            <WrapIcon onClick={handleOpenTabSearch}>
              <Tooltipv2 overlay="Search" placement="top">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Tooltipv2>
            </WrapIcon>
          </WrapIconHeader>
        </Header>
        <AssignUser>Assign user: {userAssigned}</AssignUser>
        <WrapListUser>
          <WrapUserItem ref={refScroll}>
            {userList.map((elm) => {
              return (
                <UserItem isMobile={isMobile} key={elm.id} onClick={() => handleSelectedItem(elm)}>
                  <ItemAvatar>
                    <img src={elm.avatar}></img>
                  </ItemAvatar>
                  <ItemSelected selected={elm.id === selectedItem.id}>
                    <IconChecked>
                      <FontAwesomeIcon icon={faCheck} />
                    </IconChecked>
                  </ItemSelected>
                  <ItemName>{elm.name}</ItemName>
                </UserItem>
              )
            })}
          </WrapUserItem>

          <ScrollToTop refScroll={refScroll} />
        </WrapListUser>

        <WrapFullHeight />
        <WrapBottom>
          <WrapPaginate>
            <ReactPaginate
              previousLabel={'← Previous'}
              nextLabel={'Next →'}
              pageCount={pageCount || 1}
              onPageChange={handlePageClick}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </WrapPaginate>
        </WrapBottom>
      </UserContentLeft>
      <UserMenuRight
        onCloseMenuRight={onCloseMenuRight}
        isMobile={isMobile}
        showMenuDetail={showMenuDetail}
        onSuccess={onSuccess}
        selectedItem={selectedItem}
        typeMenuRight={typeMenuRight}
        onSearchSuccess={(list) => onSearchSuccess(list)}
        onDeleteUser={() => {
          setShowModalDelete(true)
        }}
      />

      <CustomModalConfirm
        type="delete"
        title={'Are you sure to execute ?'}
        onClose={() => setShowModalDelete(false)}
        onYes={handleDelete}
        isShow={showModalDelele}
      />
    </WrapRoot>
  )
}

const WrapRoot = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`

const UserContentLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 10px 20px;
`

const Header = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TextHeader = styled.div`
  font-size: 22px;
  font-weight: 500;
  color: #999999;
  text-transform: uppercase;
`

const WrapIconHeader = styled.div`
  margin: 0 10px;
  color: rgb(255, 102, 0);
  margin-top: 10px;
`

const WrapIcon = styled.div`
  min-width: 30px;
  height: 30px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  color: rgb(255, 102, 0);
  padding: 0 2px;
  border-radius: 4px;
  background-color: ${({ select }) => (select ? 'rgba(255, 102, 0, 0.3)' : 'none')};
  /* margin: 0 10px; */
  cursor: pointer;
`

const AssignUser = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  margin: 10px 0;
`

const WrapUserItem = styled.div`
  /* position: relative; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  padding-right: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const WrapListUser = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const WrapFullHeight = styled.div`
  flex: 1;
`

const UserItem = styled.div`
  position: relative;
  /* width: ${(props) => (props.isMobile ? '155px' : '220px')}; */
  /* height: ${(props) => (props.isMobile ? '142px' : '162px')}; */
  width: 100%;
  height: 150px;
  border: 1px solid #ddd;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 10px;
  overflow: hidden;
`

const ItemAvatar = styled.div`
  width: 100%;
  height: 160px;
  background-color: rgb(220, 220, 220);
`

const ItemName = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  color: rgb(134, 134, 134);
  cursor: pointer;
  padding: 10px 10px 0;
`

const ItemSelected = styled.div`
  display: ${(props) => (props.selected ? 'flex' : 'none')};

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 160px;
  background-color: rgba(56, 175, 202, 0.7);
  z-index: 1;
`

const IconChecked = styled.div`
  margin: auto;
  color: white;
`
const WrapBottom = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const WrapPaginate = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  & .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
  }

  & .pagination a {
    padding: 10px;
    border-radius: 5px;
    margin: 0 2px;
    border: 1px solid rgb(255, 102, 0);
    color: rgb(255, 102, 0);
  }

  & .pagination__link {
    font-weight: bold;
  }

  & .pagination__link--active a {
    color: #fff;
    background: rgb(255, 102, 0);
  }

  & .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }
`
