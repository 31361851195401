import appConstants from '../constants'
import HttpRequest from './HttpRequest'

export const mediaApi = Object.freeze({
  getListDirectoryByPid: (id = 0) =>
    HttpRequest.request({
      method: 'GET',
      url: `${appConstants.apiUrl}/media/directory`,
      params: {
        pid: id,
      },
    }),
  getFileMediaOnPid: (pid = 0, page = 1, order_by = 'id', sort_za = 1, keyword, type_search, app_id = 2, limit = 50) =>
    HttpRequest.request({
      method: 'GET',
      url: `${appConstants.apiUrl}/media`,
      params: {
        pid,
        page,
        app_id,
        limit,
        order_by,
        sort_za,
        keyword,
        type_search,
      },
    }),
  createMediaFolder: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/media/directory/create`,
      data,
    }),
  uploadMedia: (data, _v) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/media/upload`,
      data,
      params: {
        _v,
      },
    }),
  updateMediaFile: (data, method = 'DELETE') =>
    HttpRequest.request({
      method,
      url: `${appConstants.apiUrl}/media/files/list/`,
      data,
    }),
  updateFolderName: (id, data) =>
    HttpRequest.request({
      method: 'PUT',
      url: `${appConstants.apiUrl}/media/directory/${id}`,
      data,
    }),
  updateFileName: (id, data) =>
    HttpRequest.request({
      method: 'PUT',
      url: `${appConstants.apiUrl}/media/file/${id}`,
      data,
    }),
  deteleFolder: (id) =>
    HttpRequest.request({
      method: 'DELETE',
      url: `${appConstants.apiUrl}/media/directory/${id}`,
    }),
})
