import { getUserInfo } from '../helpers/storage'

export const checkIsAdmin = () => {
  let userInfo = getUserInfo()
  if (!userInfo) {
    return false
  }
  userInfo = JSON.parse(userInfo)
  if (userInfo) {
    return userInfo.user.is_admin
  } else {
    return false
  }
}
