import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

function ViewTable({ data, onSelect }) {
  const [items, setItems] = useState(data)
  useEffect(() => {
    const newData = data.map((elm) => {
      let src = ''
      if (elm?.type === 'web') {
        if (elm?.ext === 'canv') {
          src = `https://www.canva.com/design/${elm?.url}/screen`
        } else {
          src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
        }
      } else {
        src = elm?.path_thumbnail
      }
      return {
        ...elm,
        path_thumbnail: src,
      }
    })
    setItems(newData)
  }, [data])

  return (
    <Root>
      <WrapHeader>
        <div className="header-name">
          <p>Name</p>
        </div>
        <div className="header-center">
          <p>type</p>
        </div>
      </WrapHeader>
      {items.map((elm, index) => {
        return (
          <Item key={uuidv4()} onClick={() => onSelect?.(elm)}>
            <WrapContent>
              <WrapName>
                <WrapImgName>
                  <img src={elm?.path_thumbnail} />
                </WrapImgName>
                <span>{elm?.name}</span>
              </WrapName>
              <WrapType>{elm?.type}</WrapType>
            </WrapContent>
          </Item>
        )
      })}
    </Root>
  )
}

export default ViewTable

const Root = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const WrapHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 15px 15px 0 0;

  p {
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
  }

  .header-name {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-top-left-radius: 15px;
    padding: 0 10px;
  }

  .header-center {
    width: 30%;
    min-width: 55px;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-top-right-radius: 15px;

    p {
      text-align: center;
    }
  }
`

const Item = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  border: 1px solid #f4f4f4;
  cursor: pointer;

  :hover {
    background-color: #e6f7ff;
  }
`

const WrapImgName = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(220, 220, 220);
  border-radius: 15px;
  position: relative;

  img {
    height: 100%;
    width: fit-content;
    max-width: 100%;
    border-radius: 15px;
  }
`

const Select = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgba(56, 175, 202, 0.7);
  border-radius: 15px;

  svg {
    font-size: 40px;
    color: #fff;
  }
`

const WrapContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const WrapName = styled.div`
  width: 70%;
  max-width: 70%;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(134, 134, 134);
  overflow: hidden;

  span {
    width: calc(100% - 80px);
    padding-left: 10px;
    word-wrap: break-word;
    :last-child {
      padding-right: 0;
    }
  }
`

const WrapType = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(134, 134, 134);
`

const WrapTime = styled.div`
  width: 20%;
  min-width: 100px;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(134, 134, 134);
`
