import appConstants from '../constants'
import HttpRequest from './HttpRequest'

const apiUrl = `${appConstants.apiUrl}/theme/favourite`
const favouriteApi = Object.freeze({
  getListFavourite: (page = 1, limit = 10, pid, orientation, isNew) =>
    HttpRequest.request({
      method: 'GET',
      url: apiUrl,
      params: {
        page,
        limit,
        pid,
        orientation,
        isNew,
      },
    }),
  addFavouriteItem: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: apiUrl,
      data: data,
    }),
  deleteItem: (data) =>
    HttpRequest.request({
      method: 'DELETE',
      url: apiUrl,
      data: data,
    }),

  getImg: (link) =>
    HttpRequest.request({
      method: 'GET',
      url: link,
    }),
})

export default favouriteApi
