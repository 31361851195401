import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ViewGrid from './components/ViewGrid'
import ViewList from './components/ViewList'
import ViewTable from './components/ViewTable'

function ContentFile({ statusView = 0, itemSelect, listDataMedia = [], playlistDetail }) {
  const [listData, setListData] = useState([])

  useEffect(() => {
    const newListDataMedia = listDataMedia.map((elm) => {
      return {
        ...elm,
        width: elm.width,
        height: elm.height,
      }
    })

    if (playlistDetail.files) {
      newListDataMedia.forEach((item) => {
        let num = 0
        num = playlistDetail?.files.filter((elm) => elm?.id === item?.id).length
        item.countInPlayList = num
      })
    }

    setListData(newListDataMedia)
  }, [listDataMedia])

  return (
    <Root>
      {statusView === 0 && <ViewGrid data={listData} onSelect={(item) => itemSelect(item)} />}

      {statusView === 1 && <ViewList data={listData} onSelect={(item) => itemSelect(item)} />}

      {statusView === 2 && <ViewTable data={listData} onSelect={(item) => itemSelect(item)} />}
    </Root>
  )
}

export default ContentFile

const Root = styled.div`
  flex: 1;
  display: flex;
  margin-top: 20px;

  align-items: flex-end;
  flex-direction: row-reverse;
  justify-content: space-between;

  order: -1;
`
