import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ViewGrid from './components/ViewGrid'
import ViewList from './components/ViewList'
import ViewTable from './components/ViewTable'

function ContentYoutube({ statusView = 0, isMobile, onSelectItem, listCanvas, showMenuDetail }) {
  const [listData, setListData] = useState([])

  useEffect(() => {
    if (listData.length && showMenuDetail === false) {
      setListData(
        listData.map((elm) => {
          return {
            ...elm,
            select: false,
          }
        })
      )
    }
  }, [showMenuDetail])

  useEffect(() => {
    const newList = listCanvas.map((elm) => {
      return {
        ...elm,
        width: elm.width,
        height: elm.height,
      }
    })
    setListData(newList)
  }, [listCanvas])

  const handleSelectItem = (item) => {
    const newList = listData.map((elm) => {
      if (elm.id === item.id) {
        return {
          ...elm,
          select: elm.select ? false : true,
        }
      }
      return {
        ...elm,
        select: false,
      }
    })
    setListData(newList)

    const itemSelected = newList.find((elm) => {
      return elm.select === true
    })

    onSelectItem(itemSelected)
  }

  return (
    <Root>
      {statusView === 0 && <ViewGrid listCanvas={listData} onSelect={onSelectItem} />}

      {statusView === 1 && <ViewList listCanvas={listData} onSelect={onSelectItem} />}

      {statusView === 2 && <ViewTable listCanvas={listData} onSelect={onSelectItem} />}
    </Root>
  )
}

export default ContentYoutube

const Root = styled.div`
  flex: 1;
  display: flex;
  margin-top: 20px;
`
