import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { keyframes } from "styled-components";

function Loading() {
  return (
    <Root>
      <FontAwesomeIcon icon={faSpinner} />
    </Root>
  );
}

export default Loading;

const rotate = keyframes`
0%{
    transform: rotate(0deg);
};
100%{
    transform: rotate(360deg);
}
`;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 95;

  svg {
    height: 34px;
    color: #ff6600;
    animation: ${rotate} 1.5s linear infinite;
  }
`;
