import { UrlActionType } from './action'

const initState = {
  haveChange: false,
  currentPath: '',
  nextPath: '',
}

export default function UrlReducer(state = initState, action) {
  switch (action.type) {
    case UrlActionType.POST_HAVE_CHANGE: {
      return {
        ...state,
        haveChange: true,
      }
    }

    case UrlActionType.RESET_HAVE_CHANGE: {
      return {
        ...state,
        haveChange: false,
      }
    }

    // case UrlActionType.CURRENT_PATH: {

    //   return {
    //     ...state,
    //     currentPaht: action.payload,
    //   }
    // }

    case UrlActionType.NEXT_PATH: {
      return {
        ...state,
        currentPath: action.payload.current,
        nextPath: action.payload.next,
      }
    }

    default: {
      return state
    }
  }
}
