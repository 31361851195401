import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ViewGrid from './components/ViewGrid'
import ViewList from './components/ViewList'
import { v4 as uuidv4 } from 'uuid'
import ViewTable from './components/ViewTable'

function ContentFile({
  statusView = 0,
  multiSelect,
  onShowModal,
  isMobile,
  itemSelect,
  listDataMedia = [],
  onSelectFileItem,
  onLoading,
  listFileTotal = [],
}) {

  const [listData, setListData] = useState([])
  const [listData2, setListData2] = useState([])

  // useEffect(() => {
  //   if (!multiSelect && listData.length) {
  //     setListData(
  //       listData.map((elm) => {
  //         return {
  //           ...elm,
  //           select: false,
  //         }
  //       })
  //     )
  //   }
  // }, [multiSelect])

  // useEffect(() => {
  //   const newListDataMedia = listDataMedia.map((elm) => {
  //     return {
  //       ...elm,
  //       width: elm.width,
  //       height: elm.height,
  //     }
  //   })
  //   setListData(newListDataMedia)
  // }, [listDataMedia])

  useEffect(() => {
    const newListDataMedia = listFileTotal
      .filter((v) => v?.isFileUpload !== true)
      .map((elm) => {
        return {
          ...elm,
          width: elm.width,
          height: elm.height,
        }
      })
    setListData2(newListDataMedia)
  }, [listFileTotal])

  const handleSelectItem = (id, index, elm) => {
    if (isMobile && !multiSelect) {
      onShowModal?.()
    }
    const newList = listData.map((elm, inx) => {
      if (index === inx || elm.id === id) {
        return {
          ...elm,
          select: elm.select ? false : true,
        }
      }
      if (multiSelect) {
        return elm
      }
      return {
        ...elm,
        select: false,
      }
    })
    setListData(newList)
    itemSelect(newList)
  }

  return (
    <Root>
      {statusView === 0 && (
        <ViewGrid
          data={listData2}
          onSelect={(elm) => onSelectFileItem(elm)}
          onLoading={onLoading}
          handleDragDrop={(lisItem) => setListData(lisItem)}
        />
      )}

      {statusView === 1 && <ViewList data={listData2} onSelect={(elm) => onSelectFileItem(elm)} />}

      {statusView === 2 && <ViewTable data={listData2} onSelect={(elm) => onSelectFileItem(elm)} />}
    </Root>
  )
}

export default ContentFile
const Root = styled.div`
  flex: 1;
  display: flex;
`
