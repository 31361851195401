import createAction from '../action'
import axios from 'axios'
import appConstants from 'src/constants'
import * as storage from '../../helpers/storage'
import { useDispatch } from 'react-redux'

export const GlobalActionType = Object.freeze({
  ACTIVE_TAB_MENUBAR: 'global/ACTIVE_TAB_MENUBAR',
  USER_ASSIGNED: 'global/USER_ASSIGNED',
})

const activeTabMenuBar = (tab) => createAction(GlobalActionType.ACTIVE_TAB_MENUBAR, tab)
const userAssigned = (userName) => createAction(GlobalActionType.USER_ASSIGNED, userName)

const GlobalAction = {
  activeTabMenuBar,
  userAssigned,
}

export default GlobalAction
