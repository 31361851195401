import { faClock } from '@fortawesome/free-regular-svg-icons'
import {
  faArrowUpAZ,
  faBraille,
  faCirclePlus,
  faList,
  faMagnifyingGlass,
  faTable,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import youtubeApi from 'src/api/youtube'
import CustomModalConfirm from 'src/components/CustomModalConfirm'
import GridFileList from 'src/components/GridFileList/GridFileList'
import ScrollToTop from 'src/components/ScrollToTop'
import Tooltipv2 from 'src/components/ToolTipv2'
import styled from 'styled-components'
import canvasApi from '../../api/canvas'
import Loading from '../../components/Loading'
import { handleError } from '../../utils/handle-error'
import { Root } from '../Home/components/ContentHomePage'
import MenuRight from './MenuRight'

const limit = 50

function Canvas({ isMobile }) {
  const Param = useParams()
  const pathName = Param?.pathName || 'add-canva'
  const navigate = useNavigate()

  const [statusView, setStatusView] = useState(0)
  const [typeMenuRight, setTypeMenuRight] = useState('add-canva')
  const [listCanvas, setListCanvas] = useState([])
  const [selectedCanvaItem, setSelectedCanvaItem] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [sort, setSort] = useState({
    order_by: 'id',
    sort_za: 1,
  })
  const [page, setPage] = useState(1)
  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [isLastPage, setIsLastPage] = useState(false)
  const refLoadMore = useRef()
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)
  const [preview, setPreview] = useState(false)

  useEffect(() => {
    canvasApi
      .getListCanva(page, limit)
      .then((res) => {
        const data = res.data
        if (data.length < limit) {
          setIsLastPage(true)
        }
        setListCanvas(data)
      })
      .catch((err) => {
        handleError('getListCanva', err)
      })
  }, [])

  const handeLoadMore = () => {
    const pageNum = page + 1
    setPage(pageNum)

    canvasApi
      .getListCanva(pageNum, limit)
      .then((res) => {
        const data = res.data

        if (data.length < limit) {
          setIsLastPage(true)
        }

        setListCanvas([...listCanvas, ...data])
      })
      .catch((err) => {
        if (err) {
          handleError('getListCanva', err)
        }
      })
  }

  useEffect(() => {
    setShowMenuDetail(false)
  }, [isMobile])

  const hanldeSelectItem = (item) => {
    const newListData = [...listCanvas]

    const newList = newListData.map((elm) => {
      if (elm.id === item?.id) {
        return {
          ...elm,
          select: elm.select ? false : true,
        }
      }
      return {
        ...elm,
        select: false,
      }
    })
    const itemSelected = newList.filter((elm) => elm.select === true)

    if (itemSelected.length < 1) {
      navigate('add-canva')
    } else {
      navigate('update-canva')
    }

    setSelectedCanvaItem(itemSelected)
    setShowMenuDetail(true)
    setListCanvas(newList)
  }

  const handleOpenTabAddUser = () => {
    const newListData = [...listCanvas]

    const newList = newListData.map((elm) => {
      return {
        ...elm,
        select: false,
      }
    })
    setShowMenuDetail(true)
    navigate('add-canva')
    setSelectedCanvaItem([])
    setListCanvas(newList)
  }

  const onEditSuccess = (item) => {
    console.log('item --> ', item)
    let tmp = [...listCanvas]
    let index = tmp.findIndex((t) => t.id === item.id)
    if (index > -1) {
      tmp[index] = item
    }
    navigate('add-canva')
    setShowMenuDetail(false)
    setListCanvas(tmp)
    setSelectedCanvaItem([])
  }

  const onCloseMenuRight = () => {
    const newListData = [...listCanvas]

    const newList = newListData.map((elm) => {
      return {
        ...elm,
        select: false,
      }
    })
    setListCanvas(newList)
    navigate('add-canva')
    setShowMenuDetail(false)
    setSelectedCanvaItem([])
  }

  const onAddSuccess = (item) => {
    setShowMenuDetail(false)
    setListCanvas([...[item], ...listCanvas])
  }

  const handleChangeSlide = (currentIdx) => {
    const tmpList = [...listCanvas]
    const list = tmpList.map((elm, index) => {
      if (index === currentIdx) {
        return {
          ...elm,
          select: true,
        }
      }
      return {
        ...elm,
        select: false,
      }
    })

    setSelectedCanvaItem(list.filter((v) => v.select === true))
    setListCanvas(list)
  }

  const handleRemoveItem = () => {
    const data = { id: selectedCanvaItem[0]?.id }

    setIsShowModalDelete(false)
    youtubeApi
      .deleteItem(data)
      .then((res) => {
        if (res.success) {
          const list = [...listCanvas]
          const idx = list.findIndex((v) => v.id === data.id)
          if (idx > -1) {
            list.splice(idx, 1)
          }

          setListCanvas(list)
          setSelectedCanvaItem([])
          navigate('add-canva')
          setShowMenuDetail(false)
        }
      })
      .catch((err) => handleError('deleteItem', err))
  }

  const renderScrollToTOp = useCallback(() => {
    let flag = true
    if (isMobile && showMenuDetail) {
      flag = false
    }

    return flag ? <ScrollToTop refScroll={refLoadMore} /> : ''
  }, [showMenuDetail, isMobile])

  return (
    <>
      <WrapContent>
        <WrapRoot>
          <Root>
            <Content ref={refLoadMore}>
              <Title>
                <WrapTitle>
                  <span>CANVAS</span>
                  <WrapIcon onClick={handleOpenTabAddUser}>
                    <Tooltipv2 overlay="Add canva" placement="top">
                      <FontAwesomeIcon icon={faCirclePlus}></FontAwesomeIcon>
                    </Tooltipv2>
                  </WrapIcon>
                </WrapTitle>

                <WrapListIcon>
                  {listView.map((elm) => {
                    return (
                      <WrapIcon
                        onClick={() => {
                          setStatusView(elm.id)
                        }}
                        key={elm.id}
                        select={elm.id === statusView}
                      >
                        <Tooltipv2 overlay={elm?.tooltip} placement="top">
                          <FontAwesomeIcon icon={elm.icon} />
                        </Tooltipv2>
                      </WrapIcon>
                    )
                  })}
                  {/* <Line />
                  <WrapIcon>
                    <Tooltipv2 overlay="Sort time" placement="top">
                      <FontAwesomeIcon icon={faClock} />
                    </Tooltipv2>
                  </WrapIcon>
                  <WrapIcon>
                    <Tooltipv2 overlay="desc" placement="top">
                      <FontAwesomeIcon icon={faArrowUpAZ} />
                    </Tooltipv2>
                  </WrapIcon> */}
                  {/* <WrapIcon
                    onClick={() => {
                      console.log('search')
                      
                    }}
                  >
                    <Tooltipv2 overlay="Search" placement="top">
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Tooltipv2>
                  </WrapIcon> */}
                </WrapListIcon>
              </Title>

              <GridFileList
                dataSource={listCanvas || []}
                onLoadMore={handeLoadMore}
                refLoadMore={refLoadMore}
                isLastPage={isLastPage}
                statusView={statusView}
                onSelectItem={(item) => hanldeSelectItem(item)}
                selectedItem={selectedCanvaItem}
                fromPage="web"
              />
              {/* <ContentCanvas
                isMobile={isMobile}
                onSelectItem={(item) => hanldeSelectItem(item)}
                selectedCanvaItem={selectedCanvaItem}
                listCanvas={listCanvas || []}
                statusView={statusView}
              /> */}
            </Content>
            {!preview ? renderScrollToTOp() : ''}
          </Root>
        </WrapRoot>
        <MenuRight
          // onSuccess={onSuccess}
          listCanvas={listCanvas}
          isMobile={isMobile}
          showMenuDetail={showMenuDetail}
          selectedCanvaItem={selectedCanvaItem}
          typeMenuRight={pathName}
          onCloseMenuRight={onCloseMenuRight}
          onEditSuccess={onEditSuccess}
          onAddSuccess={onAddSuccess}
          onChangeSlide={handleChangeSlide}
          onRemoveItem={() => setIsShowModalDelete(true)}
          onPreview={(v) => setPreview(v)}
          // onSearchSuccess={(list) => onSearchSuccess(list)}
          // onDeleteUser={() => {
          //   setShowModalDelete(true)
          // }}
        />
      </WrapContent>

      {isLoading && <Loading />}

      <CustomModalConfirm
        type="delete"
        title={' Are you sure ?'}
        onClose={() => setIsShowModalDelete(false)}
        onYes={handleRemoveItem}
        isShow={isShowModalDelete}
      />
      {/* <CustomModalConfirm
        title={'Unlock this file also affect Playlists & Devices. Are you sure to execute ?'}
        onClose={() => setIsShowModalUnBlock(false)}
        onYes={handleUnBlockFile}
        isShow={isShowModalUnBlock}
      />
      <CustomModalConfirm
        title={'Lock this file also affect Playlists & Devices. Are you sure to execute ?'}
        onClose={() => setIsShowModalBlock(false)}
        onYes={handleBlockFile}
        isShow={isShowModalBlock}
      /> */}
    </>
  )
}

export default Canvas

const listView = [
  {
    id: 0,
    icon: faBraille,
    tooltip: 'View as Grid',
  },
  {
    id: 1,
    icon: faList,
    tooltip: 'View as List',
  },
  {
    id: 2,
    icon: faTable,
    tooltip: 'View as Table',
  },
]

const WrapContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`
const WrapRoot = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  /* background: red; */
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  color: #999999;
  margin-bottom: 12px;
`
const WrapTitle = styled.div`
  display: flex;
`
const WrapListIcon = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`

const WrapIcon = styled.div`
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 102, 0);
  padding: 0 2px;
  border-radius: 4px;
  background-color: ${({ select }) => (select ? 'rgba(255, 102, 0, 0.3)' : 'none')};
  margin-left: 8px;
  cursor: pointer;
`

const Line = styled.div`
  width: 2px;
  height: 20px;
  margin-left: 8px;
  background-color: #999999;
`

const WrapButtonTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const WrapButton = styled.div`
  width: 80px;
  margin: 0 4px;
  :first-child {
    width: 120px;
  }
  :last-child {
    width: 120px;
  }
  .styled_button {
    background: ${({ select }) => (select ? '#FF6600' : 'none')};
    color: ${({ select }) => (select ? '#fff' : '#000')};

    :hover {
      background-color: rgb(255, 102, 0);
      color: #fff;
    }
  }
`

const WrapNameListFolder = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
`

const WrapFolder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 8px;
  max-width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;

  .root-folder {
    color: #38afca;
  }

  .next-folder {
    color: #999999;
    margin: 0 4px;
  }

  .name-folder {
    color: #000;
    white-space: nowrap;
  }
`

const WrapFolderTop = styled.div`
  width: 100%;
  min-height: 130px;
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
  position: relative;
  overflow: hidden;
`

const ContentFolder = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: auto;
  transition: 0.3s;
`
