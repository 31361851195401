import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { WrapContent, Content, Logo, Root, Title } from './styled'
import IconLogo from '../../assets/image/logo-be-earning.png'
import authApi from '../../api/auth'
import * as storage from '../../helpers/storage'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SignInForm from './SignInForm'
import SignUpForm from './SignUpForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import ActiveAccount from './ActiveAccount'
function Login() {
  const navigator = useNavigate()
  const refTimeout = useRef()
  const [contentView, setContentView] = useState(0)

  useEffect(() => {
    const userInfo = JSON.parse(storage.getUserInfo() || '{}')
    if (userInfo?.user?.uuid) {
      return navigator('/')
    }

    return () => {
      clearTimeout(refTimeout.current)
    }
  }, [navigator])

  const handleLogin = useCallback(
    ({ email, password }) => {
      const data = {
        email,
        password,
      }
      authApi
        .login(data)
        .then((res) => {
          if (!res.success) {
            throw res
          }
          const { data } = res
          const { token } = data
          storage.setToken(token)
          storage.setUserInfo(JSON.stringify(data))
          toast.success('Login success!')
          refTimeout.current = setTimeout(() => {
            navigator('/')
          }, 500)
        })
        .catch((err) => {
          toast.error(err?.msg || 'Error!')
        })
    },
    [navigator]
  )

  const handleRegister = useCallback((value) => {
    authApi
      .register(value)
      .then((res) => {
        if (!res.success) {
          throw res
        }
        toast.success('Register success!')
        console.log('data', res)
      })
      .catch((err) => {
        toast.error(err?.msg || 'Error!')
      })
  }, [])

  const handleForgotpassword = useCallback((value) => {
    authApi
      .forgotPassword(value)
      .then((res) => {
        if (!res.success) {
          throw res
        }
        const { data } = res
        const { token } = data
        storage.setToken(token)
        storage.setUserInfo(JSON.stringify(data))
        toast.success('Success!')
      })
      .catch((err) => {
        toast.error(err?.msg || 'Error!')
      })
  }, [])

  const handleActiveAccount = useCallback((value) => {
    console.log('active account process')
    // authApi
    //   .forgotPassword(value)
    //   .then((res) => {
    //     if (!res.success) {
    //       throw res
    //     }
    //     const { data } = res
    //     const { token } = data
    //     storage.setToken(token)
    //     storage.setUserInfo(JSON.stringify(data))
    //     toast.success('Success!')
    //   })
    //   .catch((err) => {
    //     toast.error(err?.msg || 'Error!')
    //   })
  }, [])

  const renderContent = useMemo(() => {
    switch (contentView) {
      case 0:
        return <SignInForm handleLogin={handleLogin} />
      case 1:
        return <SignUpForm handleRegister={handleRegister} />
      case 2:
        return <ForgotPasswordForm handleForgotPassword={handleForgotpassword} />
      default:
        return <SignInForm handleLogin={handleLogin} />
    }
  }, [contentView, handleForgotpassword, handleLogin, handleRegister, handleActiveAccount])

  const renderNavigator = useMemo(() => {
    const forgotPasswordNavigate = <Title onClick={() => setContentView(2)}>Forgot password?</Title>
    const signUpNavigate = <Title onClick={() => setContentView(1)}>Sign up</Title>
    const signInNavigate = <Title onClick={() => setContentView(0)}>Sign in</Title>
    const active = (
      <Title onClick={() => navigator('/ActiveAccount/salt=1def9d7501f84c9e45fd6f75f2dcb372&key=1661248675&userId=50')}>
        Active account
      </Title>
    )

    switch (contentView) {
      case 0:
        return (
          <>
            {forgotPasswordNavigate}
            {signUpNavigate}
            {/* {active} */}
          </>
        )
      case 1:
        return (
          <>
            {forgotPasswordNavigate}
            {signInNavigate}
          </>
        )
      case 2:
        return (
          <>
            {signUpNavigate}
            {signInNavigate}
          </>
        )

      default:
        return (
          <>
            {forgotPasswordNavigate}
            {signUpNavigate}
          </>
        )
    }
  }, [contentView])

  return (
    <Root>
      <WrapContent>
        <Logo>
          <img src={IconLogo} alt="logo" />
        </Logo>
        <Content>
          {renderContent}
          {renderNavigator}
        </Content>
      </WrapContent>
    </Root>
  )
}

export default Login
