import React, { useCallback, useEffect, useState } from 'react'
import SelectedImage from 'src/components/SelectedImage/SelectdImage'
import styled from 'styled-components'
import StackGridList from '../../../../../components/StackGridList'

function ViewGrid({ data, onSelect, handleDragDrop, multiSelect, onLoading }) {
 
  const [items, setItems] = useState(data)

  useEffect(() => {
    const newData = data.map((elm) => {
      let src = ''
      if (elm?.type === 'web') {
        if (elm?.ext === 'canv') {
          src = `https://www.canva.com/design/${elm?.url}/screen`
        } else {
          src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
        }
      } else {
        src = elm?.path_thumbnail
      }
      // const src = elm?.type === 'web' ? `https://img.youtube.com/vi/${elm?.url}/0.jpg` : elm?.path_thumbnail
      return {
        ...elm,
        src: src,
        width: elm.width || 1,
        height: elm.height || 2,
      }
    })
    setItems(newData)
  }, [data])

  const onSortEnd = useCallback(
    (array) => {
      handleDragDrop(array)
    },
    [handleDragDrop]
  )

  return (
    <Root>
      <StackGridList
        handleDragDrop={onSortEnd}
        dataSource={items}
        onSelectedItem={onSelect}
        onLoading={onLoading}
        CustomRender={SelectedImage}
        dndLayout
        fromPage="file"
      />
    </Root>
  )
}

export default ViewGrid
const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  & span {
    opacity: 1 !important;
  }
`

const WrapButton = styled.div`
  width: 120px;
  height: 46px;
  margin-bottom: 12px;

  .styled_button {
    background: ${({ drag }) => (drag ? '#FF6600' : ' none')};
    color: ${({ drag }) => (drag ? '#fff' : ' #000')};
  }
`
