import { faCheckCircle, faCirclePlay, faPen, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ButtonOutLine from '../../../../../components/ButtonOutLine'
import ModalPreviewItem from './ModalPreviewItem'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
// import required modules
import handleError from 'src/api/handle-error'
import { mediaApi } from 'src/api/mediaApi'
import { handleSuccess } from 'src/utils/handle-error'
import { Navigation } from 'swiper'
import Image from 'src/components/Image'
import { getHHMMSSfromDurationNumber } from 'src/utils/render-time'
import SwiperSlideList from 'src/components/SwiperSlideList/SwiperSlideList'
import ModalPreviewFile from 'src/components/ModalPreviewFile/ModalPreviewFile'
import { formatKBytes } from 'src/utils/calculateMB'
import Tooltipv2 from 'src/components/ToolTipv2'
import { checkIsAdmin } from 'src/utils/checkIsAdmin'

function ViewDetail({
  itemSelect,
  multiSelect,
  AddPlayList,
  onDeleteFile,
  onBlockFile,
  onUnBlockFile,
  listDataMedia,
  onPreview,
  onChangeSlide,
  updateFileNameSuccess,
  listFileTotal,
}) {
  const refLink = useRef()
  const [showModalPreview, setShowModalPreview] = useState(false)
  const [currentName, setCurrentName] = useState('')
  const [nameToChange, setNameToChange] = useState()
  const [showEdit, setShowEdit] = useState(false)
  const [showBtnBlock, setShowBtnBlock] = useState(true)
  const [showBtnUnBlock, setShowBtnUnBlock] = useState(false)
  const [totalSize, setTotalSize] = useState(0)
  const [indexSelectedSlide, setIndexSelectSlide] = useState(-1)
  const isAdmin = checkIsAdmin()

  console.log('isAdmin -->', isAdmin)

  useEffect(() => {
    if (!itemSelect.length) {
      return
    }
    const newName = itemSelect[0]?.name

    let isShowBtnBlock = false
    let isShowBtnUnBlock = false
    const lengthListItemSelect = itemSelect.length

    for (let index = 0; index < lengthListItemSelect; index++) {
      const element = itemSelect[index]
      if (isShowBtnBlock && isShowBtnUnBlock) {
        break
      }
      if (element?.status === 1) {
        isShowBtnBlock = true
      }
      if (element?.status === 0) {
        isShowBtnUnBlock = true
      }
    }
    setShowBtnBlock(isShowBtnBlock)
    setShowBtnUnBlock(isShowBtnUnBlock)
    setCurrentName(newName)
    setNameToChange(newName)
    setShowEdit(false)
  }, [itemSelect])

  useEffect(() => {
    let total = 0
    if (itemSelect.length > 1) {
      total = itemSelect.reduce((acc, cur) => {
        return acc + cur.size
      }, 0)
    }
    const idx = listFileTotal.findIndex((f) => f.id === itemSelect[0]?.id)
    setIndexSelectSlide(idx)
    setTotalSize(total)
  }, [itemSelect])

  const handleSaveName = () => {
    const fileId = itemSelect[0]?.id
    const data = { list: [fileId], name: nameToChange }
    mediaApi
      .updateFileName(fileId, data)
      .then((res) => {
        const item = { id: fileId, name: nameToChange }
        setCurrentName(nameToChange)
        updateFileNameSuccess?.(item)
        setShowEdit(false)
        handleSuccess('Success!')
      })
      .catch((err) => {
        handleError('updateFileName', err)
      })
  }

  const handleCloseEdit = () => {
    setShowEdit(false)
    setNameToChange(currentName)
  }

  const handleShowPreview = (preview) => {
    setShowModalPreview(preview)
    onPreview?.(preview)
  }

  return (
    <Root>
      <WrapContent>
        {itemSelect?.length === 1 && indexSelectedSlide > -1 && (
          <>
            <WrapImage>
              <SwiperSlideList
                fromPage="from_file_item"
                onClickSlideItem={() => handleShowPreview(true)}
                listDataSource={listFileTotal}
                selectedItem={itemSelect}
                onChangeSlide={onChangeSlide}
                slidesPerView={1}
                isShowIconPreview={true}
                indexSelectedSlide={indexSelectedSlide}
              />
            </WrapImage>

            {!showEdit ? (
              <WrapNameShow>
                <span>{nameToChange}</span>
                <BtnEdit onClick={() => setShowEdit(true)}>
                  <Tooltipv2 overlay="Rename" placement="top">
                    <FontAwesomeIcon icon={faPen} />
                  </Tooltipv2>
                </BtnEdit>
              </WrapNameShow>
            ) : (
              <WrapNameEdit>
                <Input value={nameToChange} onChange={(e) => setNameToChange(e.target?.value)} />
                <BtnEdit onClick={handleCloseEdit}>
                  <FontAwesomeIcon icon={faXmark} className="faXmark" />
                </BtnEdit>
                <BtnEdit onClick={handleSaveName}>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </BtnEdit>
              </WrapNameEdit>
            )}

            <WrapButton>
              <ButtonOutLine title="Download File" onClick={() => refLink.current?.click()} />
              <a href={`${itemSelect[0]?.path}`} target="_blank" ref={refLink} style={{ display: 'none' }} />
            </WrapButton>

            <WrapImgInfo>
              <Row>
                <span>Type</span>
                <p>{itemSelect[0]?.type}</p>
              </Row>
              <Row>
                <span>Extension</span>
                <p>
                  {itemSelect[0]?.type == 'web'
                    ? itemSelect[0].ext == 'canv'
                      ? 'canva'
                      : 'youtube'
                    : itemSelect[0].ext}
                </p>
              </Row>
              <Row>
                <span>Status</span>
                <p>{itemSelect[0]?.status === 1 ? 'Unblock' : 'Block'}</p>
              </Row>
              {(itemSelect[0]?.type === 'video' || itemSelect[0]?.type === 'web') && (
                <Row>
                  <span>Duration</span>
                  <p>{getHHMMSSfromDurationNumber(itemSelect[0]?.duration)}s</p>
                </Row>
              )}
              <Row>
                <span>Size</span>
                <p>{formatKBytes(itemSelect[0]?.size, 1)}</p>
              </Row>
              <Row>
                <span>Resolution</span>
                <p>
                  {itemSelect[0]?.width || 1}W x {itemSelect[0]?.height || 2}H
                </p>
              </Row>
              {!itemSelect[0]?.isFileUpload && (
                <Row>
                  <span>Total bitrate</span>
                  <p>{(itemSelect[0]?.bit_rate / 1024).toFixed(0) || '0'}mbps</p>
                </Row>
              )}
              {!itemSelect[0]?.isFileUpload && (
                <Row>
                  <span>Frame rate</span>
                  <p>{itemSelect[0]?.frame_rate || '0'}fps</p>
                </Row>
              )}

              <Row>
                <span>Created</span>
                <p>{itemSelect[0]?.updated_at}</p>
              </Row>
            </WrapImgInfo>
          </>
        )}
        {itemSelect?.length > 1 && (
          <>
            <SelectMultiItem>
              Select {itemSelect?.length} items
              <p>Total Size: {formatKBytes(totalSize, 1)}</p>
            </SelectMultiItem>
          </>
        )}
      </WrapContent>

      {isAdmin && (
        <WrapDeleteAdmin>
          <WrapButton>
            <ButtonOutLine title="Delete Without Remove File" onClick={() => onDeleteFile('admin')} />
          </WrapButton>
        </WrapDeleteAdmin>
      )}

      <WrapBottom>
        <WrapButton>
          <ButtonOutLine title="Add to Playlist" onClick={() => AddPlayList?.()} />
        </WrapButton>
        {showBtnBlock && (
          <WrapButton>
            <ButtonOutLine title="Block" onClick={onBlockFile} />
          </WrapButton>
        )}
        {showBtnUnBlock && (
          <WrapButton>
            <ButtonOutLine title="Unblock" onClick={onUnBlockFile} />
          </WrapButton>
        )}
        <WrapButton>
          <ButtonOutLine title="Delete" onClick={() => onDeleteFile('normal')} />
        </WrapButton>
      </WrapBottom>

      {showModalPreview && (
        <ModalPreviewFile
          from="from_file_preview_item"
          onChangeSlide={onChangeSlide}
          listDataSource={listFileTotal}
          selectedItem={itemSelect}
          onClose={() => handleShowPreview(false)}
          indexSelectedSlide={indexSelectedSlide}
        />
      )}
    </Root>
  )
}

export default ViewDetail

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const WrapContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const WrapImage = styled.div`
  position: relative;
  width: 320px;
  height: 180px;
  margin-left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-10px);
  background-color: #ccc;
  cursor: pointer;

  & .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-prev:after {
    color: #ff6600;
    pointer-events: all;
  }

  & .swiper-button-next:after {
    color: #ff6600;
    pointer-events: all;
  }
`
const WrapDeleteAdmin = styled.div`
  width: 100%;

  margin-top: auto;
`

const WrapNameShow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  span {
    font-size: 22px;
    color: #999999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const BtnEdit = styled.button`
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    height: 18px;
    color: #ff6600;
  }

  .faXmark {
    height: 24px;
  }
`

const WrapNameEdit = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
`
const Input = styled.input`
  flex: 1;
  background: none;
  border: none;

  :focus {
    outline: none;
  }
`

const SelectMultiItem = styled.p`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-top: 12px;
  margin-left: 4px;
`

const WrapImgInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 8px;

  span {
    font-size: 14px;
    color: #999999;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }
`
const WrapBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  border-top: 1px solid #ccc;
`

const WrapButton = styled.div`
  width: 100%;
  margin-bottom: 12px;
`
