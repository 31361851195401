import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { forwardRef } from 'react'
import styled from 'styled-components'
import Image from 'src/components/Image'

const ItemDetail = forwardRef((props, ref) => {
  const { item, selectItem } = props

  return (
    <Root ref={ref} {...props} onClick={() => selectItem?.(item)}>
      <WrapImgName>
        <WrapImg>
          <Image alt={item?.fileName ?? item?.title} src={item?.path_thumbnail} RenderImg={Img}></Image>
          {item?.select && (
            <Select>
              <FontAwesomeIcon icon={faCheck} />
            </Select>
          )}

          {item?.status === 0 && (
            <Block>
              <FontAwesomeIcon icon={faLock} />
            </Block>
          )}
        </WrapImg>
        <Name>{item?.name}</Name>
      </WrapImgName>
      <WrapCenter>{item?.type}</WrapCenter>
      <WrapCenter>{item?.size} KB</WrapCenter>
      <WrapTime>{item?.updated_at}</WrapTime>
    </Root>
  )
})

export default ItemDetail
const Root = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 10px;

  border: 1px solid #ddd;
  border-radius: 20px;

  background-color: #fff;
  margin-bottom: 12px;
  cursor: pointer;
`

const WrapImgName = styled.div`
  width: 40%;
  height: 100%;

  display: flex;
  align-items: center;

  position: relative;
`

const WrapImg = styled.div`
  width: 80px;
  min-width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgb(220, 220, 220);
  border-radius: 15px;
  position: relative;
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 15px;
`

const Block = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
  }
`

const Select = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 175, 202, 0.7);

  svg {
    height: 30px;
    color: #fff;
  }
`

const Name = styled.p`
  margin-left: 8px;
  color: rgb(134, 134, 134);
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
`

const WrapCenter = styled.div`
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(134, 134, 134);
  font-size: 16px;
  font-weight: 500;
  padding: 0 4px;
`

const WrapTime = styled(WrapCenter)`
  width: 30%;
`
