import styled from 'styled-components'

const WrapperDnd = styled.div`
  * {
    user-drag: ${({ dragDrop }) => (dragDrop ? 'element' : 'none')} !important;
    user-select: none !important;
    -moz-user-select: none;
    -webkit-user-select: none !important;
    -ms-user-select: none;
    
  }

  
`

export { WrapperDnd }
