import {
  faTrashCan,
  faPlus,
  faXmark,
  faPen,
  faChevronDown,
  faChevronUp,
  faCircleMinus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { forwardRef, useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { devicesApi } from '../../../../api/devicesApi'
import ButtonOutLine from '../../../../components/ButtonOutLine'
import SchedulePower, { WrapTimePicker } from '../../../../components/SchedulePower'
import { changeUuid } from '../../../../utils/change-uuid'
import { handleError, handleSuccess } from '../../../../utils/handle-error'
import { Root } from '../../../Home/components/ContentHomePage'
import AddFile from '../../../Playlists/Detail/components/MenuLeft/components/AddFile'
import DuplicateDevice from './DuplicateDevice'
import LogStatus from './LogStatus'
import OrientationList from './OrientationList'
import ReplaceDevice from './ReplaceDevice'
import SettingLogo from './SettingLogo'
import SettingWifiItem from './SettingWifiItem'
import CustomModalConfirm from '../../../../components/CustomModalConfirm'
import { useModal } from 'react-hooks-use-modal'
import {
  changeTimeRenderUI,
  getTimeFromNumber,
  partNumberToDateTime,
  returnDataDate,
  returnTimeDate,
} from '../../../../utils/render-time'
import SettingPlaylist from './SettingPlaylist'
import ShowPlayListMenu from './ShowPlayListMenu'
import timezones from 'timezones.json'
import InputText from '../../../../components/InputText'
import PlayLists from './PlayLists'
import moment from 'moment'
import calculateMB, { formatKBytes } from 'src/utils/calculateMB'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import UrlAction from 'src/redux/Url/action'
import DatePicker from 'react-datepicker'
import TimePicker from 'rc-time-picker'

function MenuLeft({
  isMobile,
  showMenuDetail,
  onClose,
  typeContentView = 'setting',
  deviceDetail,
  detailId,
  playlistSelect,
  onUpdatePlaylistSuccess,
  onRemovePlaylist,
  onSelectItemAdd,
  onSuccess,
  onReplaceDeviceIdSuccess,
}) {
  const { durations, files, filesInPlaylist, info, now, playlists, times } = deviceDetail
  const navigate = useNavigate()
  const [typeContent, setTypeContent] = useState(typeContentView)
  const [listSchedule, setListSchedule] = useState([])
  const [positionAndLogo, setPositionAndDriver] = useState({})
  const [typeConfirm, setTypeConfirm] = useState('')
  const [wifiList, setWifiList] = useState([])

  const [valueZone, setValueZone] = useState(null)
  const [nameDevice, setNameDevice] = useState('')
  const [isUpdateName, setIsUpdateName] = useState(false)

  const [showModalDelete, setShowModalDelete] = useState(false)

  const [showModalReset, setShowModalReset] = useState(false)

  const [isShowWifi, setIsShowWifi] = useState(false)

  const [timeLocked, setTimeLocked] = useState({
    date: null,
    time: null,
  })

  const [dayLockedNet, setDayLockedNet] = useState(null)

  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })

  const dispatch = useDispatch()

  const options = []

  timezones.forEach((v) => {
    const value = v.value
    if (value === info?.extra_time_zone && !valueZone) {
      setValueZone({ value, extraData: v.value, label: v.text })
    }
    options.push({
      value: v.offset + '|' + v.value,
      extraData: v.value,
      label: v.text,
    })
  })

  useEffect(() => {
    setTypeContent(typeContentView)
  }, [typeContentView])

  useEffect(() => {
    if (info?.schedule_poweroff?.length) {
      const newData = info?.schedule_poweroff.map((elm) => {
        const id = Math.random()
        return {
          id,
          ...elm,
          start_time: changeTimeRenderUI(elm.start_time),
        }
      })
      setListSchedule(newData)
    }
    const newPositionAndLogo = {
      logo: info?.logo,
      logo_file_id: info?.logo_id,
      logo_position: info?.logo_position || {},
    }

    if (files?.length > 0) {
      const added = files.reduce((acc, cur) => {
        return acc + cur.size
      }, 0)
    }

    let timeLokedInfo = info?.time_locked
    if (timeLokedInfo) {
      let firstIndexSpace = timeLokedInfo.lastIndexOf(' ')
      let timePickedDate = timeLokedInfo.slice(0, firstIndexSpace)
      let timePickedTime = timeLokedInfo.slice(firstIndexSpace + 1, timeLokedInfo.length)

      setTimeLocked({
        date: new Date(timePickedDate),
        time: changeTimeRenderUI(timePickedTime),
      })
    }

    setDayLockedNet(info?.day_lock_without_net)
    setNameDevice(info?.name || '')
    setPositionAndDriver(newPositionAndLogo)
    setWifiList(info?.wifi_list || [])
  }, [deviceDetail])

  const handelAddSchedule = () => {
    // dispatch(UrlAction.postHaveChange('device-schedule-menuleft', 'add-device', 'your schedule')
    dispatch(UrlAction.postHaveChange('device-schedule-menuleft'))
    const newSchedule = {
      id: new Date().getTime(),
      days: ['0', '1', '2', '3', '4', '5', '6'],
      start_time: '',
    }
    setListSchedule([...listSchedule, newSchedule])
  }

  const handleChangeSelectTime = (id, value, index) => {
    dispatch(UrlAction.postHaveChange('device-schedule-menuleft', 'select-time-schedule', 'your time schedule'))
    if (!listSchedule[index]?.days.includes(value)) {
      listSchedule[index]?.days.push(value)
    } else {
      const newItemSchedule = listSchedule[index]?.days.filter((elm) => elm !== value)
      listSchedule[index].days = newItemSchedule
    }

    return setListSchedule([...listSchedule])
  }

  const handleSaveSchedule = () => {
    if (checkDuplicateTimeInSchedule()) {
      return toast.error('Schedule list duplicate time.')
    }

    if (checkHasInputTimeInSchedule()) {
      return toast.error('Schedule time not select.')
    }

    const newListSchedule = listSchedule.map((elm) => {
      return {
        days: elm.days,
        start_time: returnTimeDate(elm.start_time),
      }
    })

    const data = { schedule_poweroff: newListSchedule }

    // dispatch(UrlAction.resetHaveChange('device-schedule-menuleft')
    dispatch(UrlAction.resetHaveChange('device-schedule-menuleft'))

    handleUpdateData(data)
  }

  const handleSelectOrientation = (id) => {
    const data = { orientation: id }
    handleUpdateData(data)
  }

  const checkDuplicateTimeInSchedule = () => {
    let listDays = []

    listSchedule.map((elm) => {
      listDays = [...listDays, ...elm.days]
    })
    return new Set(listDays).size !== listDays.length
  }

  const checkHasInputTimeInSchedule = () => {
    let result = false
    const len = listSchedule.length
    for (let index = 0; index < len; index++) {
      const element = listSchedule[index]
      if (!element.start_time) {
        result = true
        break
      }
    }
    return result
  }

  const handleDelete = (index) => {
    dispatch(UrlAction.postHaveChange('device-schedule-menuleft', 'delete-schedule', 'your schedule'))
    listSchedule.splice(index, 1)
    setListSchedule([...listSchedule])
  }

  const handleChangeTime = (index, time) => {
    dispatch(UrlAction.postHaveChange('device-schedule-menuleft', 'change-time-schedule', 'your time schedule'))
    const newData = listSchedule.map((elm, idx) => {
      if (index === idx) {
        return {
          ...elm,
          start_time: time,
        }
      }
      return elm
    })

    setListSchedule(newData)
  }

  const handelAddWifi = () => {
    const id = new Date().getTime()
    const newWifi = {
      id,
      name: '',
      password: '',
    }
    dispatch(UrlAction.postHaveChange('device-setting-wifi', 'add-wifi', 'WIFI List'))

    setIsShowWifi(true)
    setWifiList([...wifiList, newWifi])
  }

  const handleDeleteWifi = (index) => {
    wifiList.splice(index, 1)
    dispatch(UrlAction.postHaveChange('device-setting-wifi', 'delete-wifi', 'WIFI List'))

    setWifiList([...wifiList])
  }

  const handleSaveListWifi = () => {
    let data = ''
    if (wifiList.length) {
      if (!handleCheckValidateListWifi()) return
      data = { wifi_list: wifiList }
    } else {
      data = { wifi_list: { name: '', password: '' } }
    }
    dispatch(UrlAction.resetHaveChange('device-setting-wifi'))
    handleUpdateData(data)
  }

  const handleCheckValidateListWifi = () => {
    let result = true
    const len = wifiList.length
    for (let index = 0; index < len; index++) {
      const element = wifiList[index]
      if (!element.name) {
        handleError(`Name wifi row ${index + 1} is empty!`)
        result = false
        break
      }
      if (!element.password) {
        handleError(`Pass wifi row ${index + 1} is empty!`)
        result = false
        break
      }
    }

    return result
  }

  const handleOnchangeName = (value, index) => {
    const newList = wifiList.map((elm, idx) => {
      if (idx === index) {
        return {
          ...elm,
          name: value,
        }
      }
      return elm
    })
    dispatch(UrlAction.postHaveChange('device-setting-wifi', 'edit-wifi', 'WIFI'))

    setWifiList(newList)
  }

  const handleOnchangePass = (value, index) => {
    const newList = wifiList.map((elm, idx) => {
      if (idx === index) {
        return {
          ...elm,
          password: value,
        }
      }
      return elm
    })
    dispatch(UrlAction.postHaveChange('device-setting-wifi', 'edit-wifi', 'WIFI'))
    setWifiList(newList)
  }

  const handleUpdateData = (data, cb) => {
    devicesApi
      .updateDeviceDetail(detailId, data)
      .then((res) => {
        // if (!res?.success) {
        //   throw res
        // }
        // console.log('res', res)
        typeof cb === 'function' && cb(true)
        handleSuccess('Success!')
      })
      .catch((error) => {
        if (error) {
          handleError('updateDeviceDetail', error)
        }
      })
  }

  const handleSelectFile = (file) => {
    const newPositionAndLogo = {
      ...positionAndLogo,
      logo: file?.path_thumbnail,
      logo_file_id: file?.id,
    }

    setPositionAndDriver(newPositionAndLogo)
    setTypeContent('setting')
  }

  const handleChangePosition = (title, v) => {
    dispatch(UrlAction.postHaveChange('device-logo-menuleft', 'logo-deice-setting', 'your logo position'))
    const newPositionAndLogo = {
      ...positionAndLogo,
    }

    newPositionAndLogo.logo_position[title] = v * 1

    setPositionAndDriver(newPositionAndLogo)
  }

  const handleSavePositionAndLogo = () => {
    // console.log('positionAndLogo -->', positionAndLogo)
    const data = positionAndLogo
    dispatch(UrlAction.resetHaveChange('device-logo-menuleft'))
    handleUpdateData(data, (isSuccess) => {
      if (isSuccess && isMobile) {
        onSuccess()
      }
    })
  }

  const handleConfirmReset = () => {
    setShowModalReset(false)
    switch (typeConfirm) {
      case 'reset_app_all':
        handleUpdateData({ time_reset_app: 0 })
        break
      case 'reset_app':
        handleUpdateData({ time_restart_app: 0 })
        break
      case 'reset_os':
        handleUpdateData({ time_restart_os: 0 })
        break

      default:
        handleUpdateData({ time_shutdown_os: 0 })
        break
    }
  }

  const handleConfirmDelete = () => {
    devicesApi
      .deleteDevice(detailId)
      .then((res) => {
        handleSuccess('Delete Success!')
        setShowModalDelete(false)
        navigate('/device')
      })
      .catch((err) => {
        if (err) {
          handleError('deleteDevice', err)
        }
      })
  }

  const handleSaveInfoDevice = () => {
    if (!nameDevice) {
      return handleError('Name device is empty!')
    }
    const timezone = parseInt(valueZone.value.substr(0, valueZone.value.lastIndexOf('|'))) * 60
    const extraTimezone = valueZone.extraData
    const name = nameDevice
    const data = { timezone, extraTimezone, name }
    // dispatch(UrlAction.resetHaveChange('device-detail-timezone')

    handleUpdateData(data, (isSuccess) => {
      dispatch(UrlAction.resetHaveChange('device-timezone'))
    })
  }

  const infoUsedDevice = deviceDetail?.info?.device_info_used
    ? formatKBytes(deviceDetail?.info?.device_info_used, 2)
    : 'null'
  const infoTotalDevice = deviceDetail?.info?.device_info_total
    ? formatKBytes(deviceDetail?.info?.device_info_total, 2)
    : 'null'

  const totalSize = deviceDetail?.files?.map((item) => item.size).reduce((a, b) => a + b)

  const CustomInputDatetime = forwardRef(({ value, onClick }, ref) => (
    <ButtonInputDateTime onClick={onClick} ref={ref}>
      {timeLocked.date ? <span>{value}</span> : <span>select date</span>}
      {/* <span>{timeLocked ? value : 'select date'}</span> */}
    </ButtonInputDateTime>
  ))

  const handleChangeTimeLocked = (date) => {
    setTimeLocked({
      ...timeLocked,
      date: date,
    })
  }

  const handleChangeTimePickerLocked = (e) => {
    setTimeLocked({
      ...timeLocked,
      time: e?._d,
    })
  }

  const handleSaveTimeLocked = () => {
    let param = '-1'
    if (timeLocked.date && timeLocked.time) {
      let date = timeLocked.date
      const y = `${date.getFullYear()}`
      const m = ('0' + (date.getMonth() + 1)).slice(-2)
      const d = ('0' + date.getDate()).slice(-2)
      date = `${y}-${m}-${d}`
      let time = returnTimeDate(timeLocked.time)

      param = `${date} ${time}:00`
    }

    const data = { time_locked: param }
    handleUpdateData(data, (isSuccess) => {
      dispatch(UrlAction.resetHaveChange('device-time-locked'))
    })
  }

  const handleSaveDaysLocked = () => {
    const data = { day_lock_without_net: parseInt(dayLockedNet) }
    handleUpdateData(data)
  }

  return (
    <>
      <MenuDetail mobile={isMobile} show={showMenuDetail}>
        <Root>
          <WrapRoot>
            {typeContent !== 'add_playlist' && typeContent !== 'setting-playlist' && (
              <TitleDetail>
                <span>DEVICE SETTINGS</span>
                {/* <FontAwesomeIcon icon={faTrashCan} onClick={() => setShowModalDelete(true)} /> */}
              </TitleDetail>
            )}
            {typeContent === 'setting' && (
              <>
                <Content>
                  <WrapInput>
                    <span>ID: {changeUuid(info?.uuid)}</span>
                    <FontAwesomeIcon icon={faTrashCan} onClick={() => setShowModalDelete(true)} />
                  </WrapInput>
                  {!isUpdateName ? (
                    <WrapInput>
                      <WrapInputText>
                        <span>Name: {nameDevice}</span>
                        <FontAwesomeIcon icon={faPen} onClick={() => setIsUpdateName(true)} />
                      </WrapInputText>
                    </WrapInput>
                  ) : (
                    <WrapInput>
                      <WrapInputText>
                        <InputText
                          value={nameDevice}
                          onChange={(v) => {
                            dispatch(UrlAction.postHaveChange('device-timezone'))
                            setNameDevice(v)
                          }}
                        />
                        <FontAwesomeIcon icon={faXmark} onClick={() => setIsUpdateName(false)} />
                      </WrapInputText>
                    </WrapInput>
                  )}
                  {/* <WrapInput>
                  <span>Total size of device: {totalSize ? calculateMB(totalSize) + ' MB' : 'null'}</span>
                </WrapInput> */}
                  <WrapInput>
                    <span>Operating system: : {deviceDetail?.info?.device_info_os ?? 'null'}</span>
                  </WrapInput>
                  <WrapInput>
                    <span>Player version: {deviceDetail?.info?.device_info_player_version ?? 'null'}</span>
                  </WrapInput>
                  <WrapInput>
                    <span>Total Size Playlist Added: {totalSize ? formatKBytes(totalSize, 1) : 'null'}</span>
                  </WrapInput>
                  <WrapInput>
                    <span>
                      Time of last sync: {partNumberToDateTime(deviceDetail?.info?.last_time_active) ?? 'null'}
                    </span>
                  </WrapInput>
                  <WrapInput>
                    <span>Screen resolution: {deviceDetail?.info?.device_info_screen_resolution ?? 'null'}</span>
                  </WrapInput>
                  <WrapInput>
                    <span>Used / Total: {infoUsedDevice + ' / ' + infoTotalDevice}</span>
                  </WrapInput>

                  <WrapInput>
                    <span>Time zone:</span>
                  </WrapInput>
                  <WrapInput>
                    <ReactSelect
                      value={valueZone}
                      options={options}
                      styles={customStyles}
                      onChange={(e) => {
                        // dispatch(UrlAction.postHaveChange("device-detail-timezone", "timezone", "your timezone")
                        dispatch(UrlAction.postHaveChange('device-timezone'))
                        setValueZone(e)
                      }}
                    />
                  </WrapInput>
                  <WrapInput>
                    <ButtonOutLine title={'Save Info'} onClick={handleSaveInfoDevice} />
                  </WrapInput>
                </Content>

                <Content>
                  <TitleDetail className="none-line">
                    <span>Schedule Power Off</span>
                    <WrapIcon>
                      <FontAwesomeIcon icon={faPlus} onClick={handelAddSchedule} />
                    </WrapIcon>
                  </TitleDetail>
                  {listSchedule.map((elm, index) => {
                    return (
                      <WrapInput key={elm.id}>
                        <SchedulePower
                          valueSchedule={elm}
                          onSelectTile={(value, id) => handleChangeSelectTime(value, id, index)}
                          onDelete={() => handleDelete(index)}
                          onChangeTime={(t) => handleChangeTime(index, t)}
                        />
                      </WrapInput>
                    )
                  })}
                  <WrapInput>
                    <ButtonOutLine title={'Save Schedule'} onClick={handleSaveSchedule} />
                  </WrapInput>
                </Content>

                <Content>
                  <TitleDetail className="none-line">
                    <span>Orientation</span>
                  </TitleDetail>
                  <OrientationList orientation={info?.orientation} onSelectOrientation={handleSelectOrientation} />
                </Content>

                <Content>
                  <TitleDetail className="none-line">
                    <span>Logo</span>
                  </TitleDetail>
                  <WrapInput>
                    <SettingLogo
                      data={positionAndLogo}
                      onChangeLogo={() => setTypeContent('add_file')}
                      onDeleteLogo={() => {
                        setPositionAndDriver({
                          ...positionAndLogo,
                          logo: '',
                          logo_id: '',
                        })
                      }}
                      onChangePosition={handleChangePosition}
                    />
                  </WrapInput>
                  <WrapInput>
                    <ButtonOutLine title={'Apply to this device'} onClick={handleSavePositionAndLogo} />
                  </WrapInput>
                </Content>

                <Content>
                  <TitleDetail className="none-line">
                    <span>Time Locked</span>
                  </TitleDetail>
                  <WrapSelectTimeLocked>
                    <ButtonMinus onClick={() => setTimeLocked({ ...timeLocked, date: null })}>
                      <FontAwesomeIcon icon={faCircleMinus} />
                    </ButtonMinus>
                    <WrapDatePicker>
                      <DatePicker
                        selected={timeLocked.date}
                        customInput={<CustomInputDatetime value={timeLocked.date} />}
                        onChange={(date) => {
                          dispatch(UrlAction.postHaveChange('device-time-locked'))
                          handleChangeTimeLocked(date)
                        }}
                      />
                    </WrapDatePicker>
                    <WrapTimePickerLocked>
                      <WrapTimePicker>
                        <TimePicker
                          value={timeLocked.time ? moment(timeLocked.time) : null}
                          placeholder="Select time"
                          showSecond={false}
                          onChange={(e) => {
                            dispatch(UrlAction.postHaveChange('device-time-locked'))
                            handleChangeTimePickerLocked(e)
                          }}
                        />
                      </WrapTimePicker>
                    </WrapTimePickerLocked>
                  </WrapSelectTimeLocked>

                  <WrapInput>
                    <ButtonOutLine title={'Save'} onClick={handleSaveTimeLocked} />
                  </WrapInput>
                </Content>

                <Content>
                  <WrapInput>
                    <ButtonOutLine
                      title={'Duplicate Device'}
                      onClick={() => {
                        setTypeContent('duplicate_device')
                      }}
                    />
                  </WrapInput>
                  <WrapInput>
                    <ButtonOutLine
                      title={'Replace Device'}
                      onClick={() => {
                        setTypeContent('replace_device')
                      }}
                    />
                  </WrapInput>
                  <WrapInput>
                    <ButtonOutLine
                      title={'Reset App (erase all data)'}
                      onClick={() => {
                        setShowModalReset(true)
                        setTypeConfirm('reset_app_all')
                      }}
                    />
                  </WrapInput>
                  <WrapInput>
                    <ButtonOutLine
                      title={'Restart App'}
                      onClick={() => {
                        setShowModalReset(true)
                        setTypeConfirm('reset_app')
                      }}
                    />
                  </WrapInput>
                  <WrapInput>
                    <ButtonOutLine
                      title={'Restart OS'}
                      onClick={() => {
                        setShowModalReset(true)
                        setTypeConfirm('reset_os')
                      }}
                    />
                  </WrapInput>
                  <WrapInput>
                    <ButtonOutLine
                      title={'Shutdown OS'}
                      onClick={() => {
                        setShowModalReset(true)
                        setTypeConfirm('shutdown')
                      }}
                    />
                  </WrapInput>
                </Content>

                <Content>
                  <TitleDetail className="none-line">
                    <span>Day Lock Without Net</span>
                  </TitleDetail>
                  <WrapInputV2>
                    <InputText
                      placeholder={'day(s)'}
                      valueDefault={dayLockedNet}
                      type="number"
                      onChange={(e) => {
                        setDayLockedNet(e)
                      }}
                    />
                  </WrapInputV2>
                  <WrapInput>
                    <ButtonOutLine title={'Save'} onClick={handleSaveDaysLocked} />
                  </WrapInput>
                </Content>

                <Content>
                  <TitleDetail className="none-line">
                    <span>WIFI List</span>
                    <WrapIcon>
                      <FontAwesomeIcon icon={faPlus} onClick={handelAddWifi} />
                    </WrapIcon>
                    <WrapIcon>
                      <FontAwesomeIcon
                        icon={!isShowWifi ? faChevronUp : faChevronDown}
                        onClick={() => setIsShowWifi(!isShowWifi)}
                      />
                    </WrapIcon>
                  </TitleDetail>
                  {isShowWifi && (
                    <WrapInput>
                      {wifiList.map((elm, index) => {
                        return (
                          <SettingWifiItem
                            key={elm.id + index}
                            wifi={elm}
                            onDelete={() => handleDeleteWifi(index)}
                            onChangeName={(v) => handleOnchangeName(v, index)}
                            onChangePass={(v) => handleOnchangePass(v, index)}
                          />
                        )
                      })}
                    </WrapInput>
                  )}

                  <WrapInput>
                    <ButtonOutLine title={'Save WIFI'} onClick={handleSaveListWifi} />
                  </WrapInput>
                </Content>

                <Content>
                  <LogStatus detailId={detailId} />
                </Content>
              </>
            )}

            {typeContent === 'add_playlist' && <PlayLists onSelectItem={onSelectItemAdd} />}

            {typeContent === 'add_file' && (
              <Content>
                <AddFile onSelectFile={handleSelectFile} playlistDetail={{}} />
              </Content>
            )}

            {typeContent === 'duplicate_device' && (
              <Content>
                <DuplicateDevice info={info} onClose={() => setTypeContent('setting')} />
              </Content>
            )}

            {typeContent === 'replace_device' && (
              <Content>
                <ReplaceDevice
                  info={info}
                  onClose={() => setTypeContent('setting')}
                  onReplaceDeviceIdSuccess={onReplaceDeviceIdSuccess}
                />
              </Content>
            )}

            {typeContent === 'setting-playlist' && (
              <Content>
                <SettingPlaylist
                  files={files}
                  playlistSelect={playlistSelect}
                  filesInPlaylist={filesInPlaylist}
                  onUpdatePlaylistSuccess={onUpdatePlaylistSuccess}
                  onRemovePlaylist={onRemovePlaylist}
                />
              </Content>
            )}
            {typeContent === 'show-playlist-menu' && (
              <Content>
                <ShowPlayListMenu playlistSelect={playlistSelect} />
              </Content>
            )}
          </WrapRoot>
        </Root>
      </MenuDetail>

      {isMobile && showMenuDetail && <BackDrop onClick={onClose} />}

      <CustomModalConfirm isShow={showModalReset} onYes={handleConfirmReset} onClose={() => setShowModalReset(false)} />

      <CustomModalConfirm
        type="delete"
        title={`Are you sure you want to delete Device? (You can't undo this action.)`}
        isShow={showModalDelete}
        onYes={handleConfirmDelete}
        onClose={() => setShowModalDelete(false)}
      />
    </>
  )
}

export default MenuLeft

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 42,
    borderColor: '#d9af93',
    borderRadius: 6,
    // background: 'none',
    boxShadow: 'none',
  }),
}

const WrapSelectTimeLocked = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  margin-top: 10px;
`

const ButtonMinus = styled.div`
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  /* margin: 0 10px; */
  margin-left: 10px;
  margin-right: 6px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    color: #ff6600;
  }
`

const ButtonInputDateTime = styled.div`
  width: 99px;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: rgb(255, 102, 0);

    span {
      color: #fff;
    }
  }

  span {
    font-size: 14px;
    color: rgb(116, 116, 116);
    min-width: 100px;
    font-weight: normal;
    display: flex;
    justify-content: center;
  }
`

const WrapTimePickerLocked = styled.div`
  width: 129px;
  height: 42px;
  margin-top: -24px;
  margin-left: 6px;

  & .rc-time-picker .rc-time-picker-input {
    font-size: 14px !important;
  }
`
const WrapDatePicker = styled.div`
  width: 99px;
  height: 42px;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(204, 204, 204);

  :last-child {
    border-bottom: none;
  }

  .none-line {
    border-bottom: none;
  }
`

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 92;
`

const WrapRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`

const TitleDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
  border-bottom: 1px solid rgb(204, 204, 204);

  svg {
    height: 22px;
    margin-left: 12px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }
`

const WrapIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(255, 102, 0);
  border-radius: 50%;
  margin-left: 12px;

  svg {
    height: 18px;
    margin: 0;
    cursor: pointer;
  }
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }

  svg {
    height: 22px;
    margin-left: 12px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }
`

const WrapInputText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    height: 22px;
    margin-left: 14px;
    color: #ff6600;
    cursor: pointer;
  }
`

const WrapInputV2 = styled.div`
  width: 100%;
  margin-top: 8px;

  .styled-input-custom {
    background: none;
    border: 1px solid;
    border-color: #d9af93;

    :focus {
      border-color: #ff6600;
    }
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
