/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import CustomModalConfirm from 'src/components/CustomModalConfirm'
import UrlAction from 'src/redux/Url/action'
import { useBlocker } from './useBlocker'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import styled from 'styled-components'
import './styleModal.css'

export function useCallbackPrompt(when) {
  // console.log("when", when)
  const navigate = useNavigate()
  const location = window?.location
  const [lastLocation, setLastLocation] = useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const dispatch = useDispatch()

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        if (
          (nextLocation.location.pathname.indexOf('playlist/') > -1 && location.pathname.indexOf('playlist/') > -1) ||
          (nextLocation.location.pathname.indexOf('device/') > -1 && location.pathname.indexOf('device/') > -1)
        ) {
          setConfirmedNavigation(true)

          setLastLocation(nextLocation)

          requestAnimationFrame(() => {
            setConfirmedNavigation(false)
          })

          return false
        }
        requestAnimationFrame(() => {
          confirmAlert({
            customUI: ({ onClose }) => {
              let isPlaylistPage = false
              let titlePopup = 'Do you want to skip saving your changes?'
              let style = {}
              if (location.pathname.indexOf('playlist/') > -1) {
                isPlaylistPage = true
                titlePopup = 'PLAYLIST NOT SAVE, '
                style = { padding: '9px 2px', textTransform: 'none', fontSize: '13px' }
              }
              return (
                <div className="react-confirm-alert">
                  <div className="react-confirm-alert-body">
                    <p>{titlePopup}</p>
                    <p>{isPlaylistPage ? 'Do you want save your changes before exiting?' : ''}</p>
                    <div className="react-confirm-alert-button-group">
                      <button
                        style={style}
                        className="react-confirm-alert-button-group-btn-yes"
                        onClick={() => {
                          setConfirmedNavigation(true)

                          setLastLocation(nextLocation)

                          requestAnimationFrame(() => {
                            setConfirmedNavigation(false)
                          })

                          dispatch(UrlAction.resetHaveChange())
                          onClose()
                        }}
                      >
                        {isPlaylistPage ? `Don't Save` : 'Yes'}
                      </button>
                      <button
                        className="react-confirm-alert-button-group-btn-no"
                        onClick={() => {
                          setConfirmedNavigation(false)

                          onClose()
                          return false
                        }}
                      >
                        {isPlaylistPage ? `CANCEL` : 'No'}
                      </button>
                    </div>
                  </div>
                </div>
              )
            },
          })
        })

        return false
      }
      return true
    },
    [confirmedNavigation]
  )

  useEffect(() => {
    if (confirmedNavigation && lastLocation && lastLocation.location.pathname !== location.pathname) {
      navigate(lastLocation.location.pathname)
    }
  }, [confirmedNavigation, lastLocation])

  useBlocker(handleBlockedNavigation, when)

  return []
}
