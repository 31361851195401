import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useMemo, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { playlistApi } from '../../../../../../api/playlistApi'
import ButtonOutLine from '../../../../../../components/ButtonOutLine'
import Loading from '../../../../../../components/Loading'
import { handleError, handleSuccess } from '../../../../../../utils/handle-error'
import { serialize } from '../../../../../../utils/serialize'
import PlayListItem from './components/PlayListItem'
import ReactPaginate from 'react-paginate'
import { useDispatch } from 'react-redux'
import UrlAction from 'src/redux/Url/action'

const limit = 5
var timeout

function PlayLists({ onClose, itemSelect, isMobile }) {
  const [isLoading, setIsLoading] = useState(false)
  const [playlist, setPlayList] = useState([])
  const [addPlayListName, setAddPlayListName] = useState('')
  const refLoadMore = useRef()
  const [isLastPage, setIsLastPage] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [page, setPage] = useState(1)

  const dispatch = useDispatch()

  useEffect(() => {
    handleGetPlaylist(page, limit)
  }, [])

  useEffect(() => {
    if (isLastPage) {
      setLoadingText('- END -')
    }
    if (refLoadMore.current && !isLastPage) {
      refLoadMore.current.addEventListener('scroll', handleScroll, false)
    }
    return () => {
      if (refLoadMore.current) {
        refLoadMore.current.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [playlist])

  const handleClickItem = (id) => {
    const data = handelReturnData()
    playlistApi
      .addFileToPlaylist(id, data)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        handleSuccess('Add success!')
        onClose?.()
      })
      .catch((err) => {
        handleError('addFileToPlaylist', 'Please select files')
      })
  }

  const handelReturnData = () => {
    const newData = itemSelect.map((elm) => {
      return {
        file_id: elm.id,
        order: 0,
      }
    })
    return { list: newData }
  }

  const handleGetPlaylist = (page = 1, limit) => {
    setIsLoading(true)
    playlistApi
      .getPlaylist(page, limit)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const { playlists } = res.data
        if (playlists.length < limit) {
          setIsLastPage(true)
        }
        setPlayList(playlists)
      })
      .catch((err) => {
        handleError('getPlaylist', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleScroll = (e) => {
    if (isLastPage) {
      setLoadingText('- END -')
      return
    }

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 300) {
        setLoadingText('Loading ...')
        handeLoadMore()
      }
    }, 300)
  }

  const handeLoadMore = () => {
    const pageNum = page + 1
    setPage(pageNum)

    playlistApi
      .getPlaylist(pageNum, limit)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const { playlists } = res.data
        if (playlists.length < limit) {
          setIsLastPage(true)
        }
        setPlayList([...playlist, ...playlists])
      })
      .catch((err) => {
        if (err) {
          handleError('getPlaylist', err)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleAddPlaylist = () => {
    if (!addPlayListName) {
      return handleError('Name is required!')
    }
    playlistApi
      .createPlaylist(addPlayListName)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const { data } = res

        const tmp = [...playlist]
        tmp.unshift(data)
        handleClickItem(data?.id)
        setPlayList(tmp)
        setAddPlayListName('')
        // window.removeChange('file-add-playlist')
        dispatch(UrlAction.resetHaveChange('file-add-playlist'))
      })
      .catch((err) => {
        handleError('createPlaylist', err)
      })
  }

  return (
    <Root>
      <WrapTitle>
        <span>Add to PLAYLISTS</span>
        <FontAwesomeIcon icon={faXmark} onClick={onClose} />
      </WrapTitle>
      <WrapListItem ref={refLoadMore} isMobile={isMobile}>
        {!!playlist.length &&
          playlist.map((elm, index) => {
            return <PlayListItem key={index} playlist={elm} onClickItem={handleClickItem} />
          })}
        {loadingText !== '' && (
          <WrapLoadingBottom>
            <WrapLoading>
              {loadingText !== '- END -' && (
                <Loader>
                  <LoaderDot></LoaderDot>
                  <LoaderDot></LoaderDot>
                  <LoaderDot></LoaderDot>
                  <LoaderDot></LoaderDot>
                  <LoaderDot></LoaderDot>
                  <LoaderDot></LoaderDot>
                  <LoaderText></LoaderText>
                </Loader>
              )}
              {loadingText === '- END -' && <WrapEnd>- END -</WrapEnd>}
            </WrapLoading>
          </WrapLoadingBottom>
        )}
      </WrapListItem>

      <WrapAddPlayList>
        <TitleDetail>
          <span>Create New Playlist</span>
        </TitleDetail>
        <WrapInput>
          <Input
            placeholder="type your playlist name"
            value={addPlayListName}
            onChange={(e) => {
              dispatch(UrlAction.postHaveChange('file-add-playlist', 'add-playlist', 'Create New Playlist'))
              setAddPlayListName(e.target.value)
            }}
          />
        </WrapInput>
        <WrapInput>
          <ButtonOutLine title={'Add PlayList'} onClick={handleAddPlaylist} />
        </WrapInput>
      </WrapAddPlayList>
      {isLoading && <Loading />}
    </Root>
  )
}

export default PlayLists

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const WrapTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  span {
    font-size: 22px;
    color: #333;
    font-weight: 500;
  }

  svg {
    height: 40px;
    color: rgb(255, 102, 0);
    cursor: pointer;
    padding: 4px 0 4px 8px;
  }
`
const WrapListItem = styled.div`
  width: 100%;
  min-height: 350px;
  max-height: ${(props) => (props.isMobile ? '500px' : '700px')};
  height: 100%;
  overflow-y: auto;
`

const WrapAddPlayList = styled.div`
  flex: 1;
  margin: 20px 0 40px 0;
`
const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapLoadingBottom = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
`
const WrapLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = styled.div`
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const LoadingAniText = keyframes`
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
`

const LoaderAniDot = keyframes`
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
`

const LoaderDot = styled.div`
  animation-name: ${LoaderAniDot};
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;

  :first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }
  :nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  :nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  :nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  :nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  :nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }
`
const LoaderText = styled.div`
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
  :after {
    content: 'Loading';
    font-weight: bold;
    animation-name: ${LoadingAniText};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`
const WrapEnd = styled.div`
  margin-top: 40px;
  font-weight: 500;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`
