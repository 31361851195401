// function calculateMB(number) {
//   return ((number * 1) / 1024).toFixed(2)
// }

// export default calculateMB

export function formatKBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 KB'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`

  //   if (typeof formatKBytes === 'undefined' || bytes === 0) {
  //       return 0 + ' ' + 'KB';
  //   }

  //  var k = 1024,
  //      dm = decimals <= 0 ? 0 : decimals || 2,
  //      sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
  //      i = Math.floor(Math.log(bytes) / Math.log(k));
  //  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
