import React, { useState, useEffect } from 'react'
import historyApi from 'src/api/history'
import ButtonOutLine from 'src/components/ButtonOutLine'
import ModalPreviewFile from 'src/components/ModalPreviewFile/ModalPreviewFile'
import SwiperSlideList from 'src/components/SwiperSlideList/SwiperSlideList'
import { handleSuccess } from 'src/utils/handle-error'
import styled from 'styled-components'

export default function ViewDetail({ selectedItem, listArticle, onChangeSlide, onPreview }) {
  const [preview, setPreview] = useState(false)
  const [indexSelectedSlide, setIndexSelectSlide] = useState(-1)

  useEffect(() => {
    const idx = listArticle.findIndex((f) => f?.id === selectedItem[0]?.id)
    setIndexSelectSlide(idx)
  }, [selectedItem, listArticle])

  const handleShowPreview = (preview) => {
    setPreview(preview)
    if (onPreview && typeof onPreview === 'function') {
      onPreview(preview)
    }
  }

  const handleAddHistory = () => {
    const data = { id: selectedItem[0]?.id }
    historyApi
      .addHistoryItem(data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))

    handleShowPreview(true)
  }

  return (
    <>
      <WrapImage>
        <SwiperSlideList
          fromPage="template_item"
          listDataSource={listArticle}
          selectedItem={selectedItem}
          onChangeSlide={onChangeSlide}
          onClickSlideItem={handleAddHistory}
          slidesPerView={1}
          isShowIconPreview={true}
          indexSelectedSlide={indexSelectedSlide}
        />
      </WrapImage>

      <WrapImgInfo>
        <Row>
          <span>Title</span>
          <p>{selectedItem[0]?.title}</p>
        </Row>
        <Des>
          <span>Description</span>
          <p>{selectedItem[0]?.description}</p>
        </Des>
        <Row>
          <span>Status</span>
          <p>{selectedItem[0]?.status === 1 ? 'Unblock' : 'Block'}</p>
        </Row>
        <Row>
          <span>Orientation</span>
          <p>{selectedItem[0]?.orientation}</p>
        </Row>
        <Row>
          <span>Priority</span>
          <p>{selectedItem[0]?.priority}</p>
        </Row>
      </WrapImgInfo>

      <WrapButton href={selectedItem[0]?.url_link}>
        <ButtonOutLine title="Use this template" />
      </WrapButton>
      <WrapButton href={selectedItem[0]?.preview}>
        <ButtonOutLine title="Preview" />
      </WrapButton>
      {preview && (
        <ModalPreviewFile
          from="template_item_preview"
          listDataSource={listArticle}
          selectedItem={selectedItem}
          onChangeSlide={onChangeSlide}
          onClose={() => handleShowPreview(false)}
          indexSelectedSlide={indexSelectedSlide}
        />
      )}
    </>
  )
}

const WrapImage = styled.div`
  flex: 1;
  height: 180px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px;
  transform: translateY(-10px);
  background-color: #ccc;
  cursor: pointer;
  position: relative;

  & .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-prev:after {
    color: #ff6600;
    pointer-events: all;
  }

  & .swiper-button-next:after {
    color: #ff6600;
    pointer-events: all;
  }

  /* img {
    height: 100%;
    width: auto;
    max-width: 100%;
  } */
`

const WrapButton = styled.a`
  width: 100%;
  margin-top: 24px;
`
const WrapFavourite = styled.div`
  width: 100%;
  margin-top: 24px;
`

const WrapImgInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const Row = styled.div`
  width: 100%;
  /* height: 34px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 8px;

  span {
    font-size: 14px;
    color: #999999;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }
`

const Des = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;

  padding: 0 4px;
  margin-bottom: 8px;

  span {
    font-size: 14px;
    color: #999999;
  }

  p {
    margin-left: 10px;
    text-align: end;
    font-size: 14px;
    font-weight: 500;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
