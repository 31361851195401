import { Navigate } from "react-router-dom";
import HomePage from "../page/Home";

function AuthRoute({ element: Component, isAuth = true, ...rest }) {
  return (
    <>
      {isAuth ? (
        <HomePage />
      ) : (
        <Navigate
          to={{
            pathname: "login",
            state: {
              prevLocation: rest.location,
            },
          }}
        />
      )}
    </>
  );
}

export default AuthRoute;
