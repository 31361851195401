import React from 'react'

export function GridItem({ children }) {
  return (
    <div
      style={{
        display: 'wrap',
        gridTemplateColumns: `repeat(1, 1fr)`,
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  )
}
