import React, { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'
import defaultImage from '../../assets/image/defaultImage.png'
import { after } from 'underscore'

const Image = ({ src, alt, style, RenderImg, onClick, onLoading }) => {
  const [srcImg, setSrcImage] = useState(src)

  useEffect(() => {
    setSrcImage(src)
  }, [src])

  const handleError = (e) => {
    setSrcImage(defaultImage)
  }

  // console.log(style)

  const propsType = {
    src: srcImg,
    style,
    alt,
    onClick,
    onError: handleError,
    onLoad: onLoading,
  }

  if (RenderImg) return <RenderImg {...propsType} />

  return <img {...propsType} />
}

export default Image
