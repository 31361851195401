import { toast } from 'react-toastify'

export default function handleError(type, err) {
  if (err?.msg) {
    toast.error(type + ': ' + err.msg)
    return
  }
  if (err?.message === 'Network Error') {
    toast.error('no network')
    return
  }
  if (err.message.indexOf('timeout') !== -1 && err.code === 'ECONNABORTED') {
    toast.error('slow network')
    return
  }
  // toast.error('Đã xảy ra lỗi bất ngờ')
}
