import React, { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import PlayListItem from './components/PlayListItem'

function PlayListsContent({ playlist }) {
  const navigate = useNavigate()

  return (
    <Root>
      {!!playlist?.length &&
        playlist.map((elm, index) => {
          return <PlayListItem key={elm.id} playlist={elm} onClickItem={() => navigate(`${elm.id}`)} />
        })}
    </Root>
  )
}

export default PlayListsContent

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
