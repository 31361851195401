import { faLock, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Image from 'src/components/Image'
import styled from 'styled-components'
import { playlistApi } from '../../../../../api/playlistApi'
import { handleError } from '../../../../../utils/handle-error'

function ShowPlayListMenu({ playlistSelect }) {
  const navigate = useNavigate()
  const [listFile, setListFile] = useState([])
  const location = useLocation()
  // console.log('🚀 ~ file: index.js ~ line 14 ~ ShowPlayListMenu ~ location', location)

  useEffect(() => {
    if (location.pathname.indexOf('/show-playlist-menu') < 0) return
    const id = playlistSelect?.id
    if (!id) return
    playlistApi
      .getPlaylistDetail(id)
      .then((res) => {
        if (!res.success) {
          throw res
        }
        if (typeof res !== 'object') return

        const { data } = res
        const newData = data?.files.map((elm, index) => {
          let src = ''
          if (elm?.type === 'web') {
            if (elm?.ext === 'canv') {
              src = `https://www.canva.com/design/${elm?.url}/screen`
            } else {
              src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
            }
          } else {
            src = elm?.path_thumbnail
          }

          return {
            ...elm,
            path_thumbnail: src,
            status: elm.status === 0 ? elm.status : data.statuses[index],
          }
        })
        setListFile(newData || [])
      })
      .catch((err) => {
        if (err) {
          handleError('getPlaylistDetail - 2', err)
        }
      })
  }, [playlistSelect?.id])

  return (
    <Root>
      <Content>
        <TitleDetail
          onClick={() => {
            navigate(`/playlist/${playlistSelect?.id}`)
            // dispatch(GlobalAction.activeTabMenuBar('playlist'))
          }}
        >
          <span>Playlist Setting</span>
          <FontAwesomeIcon icon={faPen} />
        </TitleDetail>
        <Label>Name: {playlistSelect?.name}</Label>
        <Label>Status: {playlistSelect?.status === 1 ? 'Unblock' : 'Block'}</Label>
      </Content>
      <Content>
        <WrapHeader>
          <WrapLeft>
            <p>NAME</p>
          </WrapLeft>
          <WrapRight>
            <p>TYPE</p>
          </WrapRight>
        </WrapHeader>

        {!!listFile.length &&
          listFile.map((elm, index) => {
            return (
              <Row key={index}>
                <WrapLeft>
                  <WrapImg>
                    <Image src={elm.path_thumbnail} />
                    {elm.status === 0 && (
                      <WrapLock>
                        <FontAwesomeIcon icon={faLock} />
                      </WrapLock>
                    )}
                  </WrapImg>
                  <span>{elm.name}</span>
                </WrapLeft>
                <WrapRight>
                  <p>{elm.type}</p>
                </WrapRight>
              </Row>
            )
          })}
      </Content>
    </Root>
  )
}

export default ShowPlayListMenu

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(204, 204, 204);

  :last-child {
    border-bottom: none;
  }
`

const TitleDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 12px;
  cursor: pointer;

  svg {
    height: 22px;
    margin-left: 12px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }
`

const Label = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 4px;
`

const WrapHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 20px;
  background-color: #ccc;
`

const WrapLeft = styled.div`
  height: 100%;
  width: 220px;
  display: flex;
  align-items: center;
  border: 1px ridge #ddd;
  padding: 5px 10px;

  p {
    width: 100%;
    font-weight: 500;
  }

  span {
    margin-left: 10px;
    flex: 1;
    word-break: break-all;
  }
`

const WrapImg = styled.div`
  height: 80px;
  width: 80px;
  min-width: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ddd;

  position: relative;

  img,
  video {
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
    border-radius: 10px;
  }
`

const WrapLock = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #ebe7e79e;

  svg {
    color: #ff6600;
  }
`

const WrapRight = styled.div`
  height: 100%;
  width: calc(100% - 220px);
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 5px 10px;
  p {
    width: 100%;
    font-weight: 500;
    text-align: center;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  height: 90px;
  cursor: pointer;

  :hover {
    background-color: #e6f7ff;
  }
`
