import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import ItemDetail from './component/ItemDetail'

export default function ViewList({ data, handleDragDrop, onSelectFileItem }) {
  const [list, setList] = useState(data)
  useEffect(() => {
    const newData = data.map((elm) => {
      let src = ''
      if (elm?.type === 'web') {
        if (elm?.ext === 'canv') {
          src = `https://www.canva.com/design/${elm?.url}/screen`
        } else {
          src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
        }
      } else {
        src = elm?.path_thumbnail
      }
      return {
        ...elm,
        path_thumbnail: src,
      }
    })
    setList(newData)
  }, [data])
  function handleOnDragEnd(result) {
    if (!result.destination) return

    const items = Array.from(data)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    handleDragDrop(items)
  }

  return (
    <Root>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <WrapItem className="characters" {...provided.droppableProps} ref={provided.innerRef}>
              {list.map((elm, index) => {
                return (
                  <Draggable key={index} draggableId={index + ''} index={index}>
                    {(provided) => (
                      <ItemDetail
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        item={elm}
                        selectItem={(item) => {
                          onSelectFileItem(item)
                        }}
                      ></ItemDetail>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </WrapItem>
          )}
        </Droppable>
      </DragDropContext>
    </Root>
  )
}

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`

const WrapItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
`
