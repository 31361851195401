import { GlobalActionType } from './action'

const initState = {
  activeTabMenuBar: '/home',
  userAssigned: localStorage.getItem('userAssigned') || 'n/a',
}

export default function GlobalReducer(state = initState, action) {
  switch (action.type) {
    case GlobalActionType.ACTIVE_TAB_MENUBAR: {
      return {
        ...state,
        activeTabMenuBar: action.payload,
      }
    }
    case GlobalActionType.USER_ASSIGNED: {
      return {
        ...state,
        userAssigned: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
