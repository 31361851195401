import React, { useRef } from 'react'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollToTop from 'src/components/ScrollToTop'
import styled from 'styled-components'
import panel from '../../../assets/image/panel.png'
import panel2 from '../../../assets/image/panel2.png'
import step1 from '../../../assets/image/step1.png'
import step2 from '../../../assets/image/step2.png'
import step3 from '../../../assets/image/step3.png'
import step4 from '../../../assets/image/step4.png'

import tu1 from '../../../assets/image/tu1.png'
import tu2 from '../../../assets/image/tu2.png'
import tu3 from '../../../assets/image/tu3.png'
import tu4 from '../../../assets/image/tu4.png'

function ContentHomePage({ mobileContent }) {
  const refScroll = useRef(null)
  return (
    <Root>
      <Content ref={refScroll}>
        <Tutorial href="https://youtube.com/playlist?list=PL7RD6TAhQN2_UI62IXaifs6_ayNT7VUEu" target="_blank">
          <IconTick>
            <FontAwesomeIcon icon={faCircleCheck} />
          </IconTick>
          <Text className="txtBold">Tutorials</Text>
        </Tutorial>
        <Panel>
          <img src={mobileContent ? panel2 : panel} />
        </Panel>
        <Step>
          {ListStep.map((elm) => {
            return (
              <ItemStep key={elm.id} mobile={mobileContent}>
                <img src={elm.img} />
                <Title>{elm.Title}</Title>
              </ItemStep>
            )
          })}
        </Step>

        <Step>
          {ListTutorial.map((elm) => {
            return (
              <ItemTutorial key={elm.id} mobile={mobileContent}>
                <img src={elm.img} />
                <Title>{elm.title}</Title>
              </ItemTutorial>
            )
          })}
        </Step>
      </Content>

      <ScrollToTop refScroll={refScroll} />
    </Root>
  )
}

export default ContentHomePage

const ListStep = [
  {
    id: 1,
    Title: 'Step 1',
    img: step1,
  },
  {
    id: 2,
    Title: 'Step 2',
    img: step2,
  },
  {
    id: 3,
    Title: 'Step 3',
    img: step3,
  },
  {
    id: 4,
    Title: 'Step 4',
    img: step4,
  },
]

const ListTutorial = [
  {
    id: 1,
    title: 'Step 1 - Upload Pictures/Videos',
    img: tu1,
  },
  {
    id: 2,
    title: 'Step 2 - Create Playlist',
    img: tu2,
  },
  {
    id: 3,
    title: 'Step 3 - Setup Schedule',
    img: tu3,
  },
  {
    id: 4,
    title: 'Step 4 - PUBLISH',
    img: tu4,
  },
]

export const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  top: 0;
  left: 0;
  overflow-y: hidden;
  display: flex;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  scroll-behavior: smooth;
  flex-direction: column;
`

const Tutorial = styled.a`
  position: absolute;
  top: 20px;
  left: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`

const IconTick = styled.div`
  width: 35px;
  height: 35px;
  /* background: red; */

  svg {
    color: #6ea69b;
    width: 100%;
    height: 100%;
  }
`

const Text = styled.div`
  font-size: 20px;
  /* font-family: Roboto-Medium; */
  font-weight: 500;
  margin-left: 8px;
  color: #000;
`

const Panel = styled.div`
  width: 100%;

  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`

const Step = styled.div`
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`

const ItemStep = styled.div`
  width: ${({ mobile }) => (mobile ? '50%' : '25%')};
  display: flex;
  flex-direction: column;
  padding: 20px;
  img {
    width: 100%;
    height: 100%;
  }
`

const Title = styled.p`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-top: auto;
`

const ItemTutorial = styled.div`
  width: ${({ mobile }) => (mobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 30px;

  img {
    width: 100%;
  }
`
