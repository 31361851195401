import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

function ViewList({ data, onSelect }) {
  const [items, setItems] = useState(data)
  useEffect(() => {
    const newData = data.map((elm) => {
      let src = ''
      if (elm?.type === 'web') {
        if (elm?.ext === 'canv') {
          src = `https://www.canva.com/design/${elm?.url}/screen`
        } else {
          src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
        }
      } else {
        src = elm?.path_thumbnail
      }
      return {
        ...elm,
        path_thumbnail: src,
      }
    })
    setItems(newData)
  }, [data])

  return (
    <Root>
      {items.map((elm, index) => {
        return (
          <Item key={uuidv4()} onClick={() => onSelect?.(elm)}>
            <WrapImg>
              <img src={elm?.path_thumbnail} />
            </WrapImg>
            <WrapContent>
              <WrapName>{elm?.name}</WrapName>
              <WrapType>{elm?.type}</WrapType>
            </WrapContent>
          </Item>
        )
      })}
    </Root>
  )
}

export default ViewList

const Root = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Item = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-bottom: 12px;
  cursor: pointer;

  :hover {
    background-color: #e6f7ff;
  }
`

const WrapImg = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(220, 220, 220);
  border-radius: 15px;
  position: relative;

  img {
    height: 100%;
    width: fit-content;
    max-width: 100%;
    border-radius: 15px;
  }
`

const Select = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgba(56, 175, 202, 0.7);
  border-radius: 15px;

  svg {
    font-size: 40px;
    color: #fff;
  }
`

const WrapContent = styled.div`
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
  align-items: center;
  p {
    :last-child {
      padding-right: 0;
    }
  }
`

const WrapName = styled.p`
  width: 60%;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(134, 134, 134);
`

const WrapType = styled(WrapName)`
  width: 40%;
`

const WrapTime = styled(WrapName)``
