import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import handleError from 'src/api/handle-error'
import CustomModalConfirm from 'src/components/CustomModalConfirm'
import Tooltipv2 from 'src/components/ToolTipv2'
import styled from 'styled-components'
import categoryApi from '../../api/category'
import { convertObjectToArray, getListAllCategory } from '../../utils/convertListCategory'
import AddCategory from './components/AddCategory'
import UpdateCategory from './components/UpdateCategory'

export default function Categories({ isMobile }) {
  const [listCategory, setListCategory] = useState([])
  const [added, setAdded] = useState(false)
  const [itemSelected, setItemSelected] = useState({})
  const [typeMenuRight, setTypeMenuRight] = useState('add-cate')
  const [showMenuRight, setShowMenuRight] = useState(false)
  const [listCreate, setListCreate] = useState([])
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)

  const [columnDefs, setColumnDefs] = useState([
    { headerName: 'Category ID', field: 'id', sortable: true },
    { headerName: 'Category Name', field: 'title', filter: true },
  ])

  useEffect(() => {
    categoryApi
      .getListCategory()
      .then((res) => {
        const list = convertObjectToArray(res.data.children)
        const rs = list.map((element) => {
          if (element.children && element.children.length) {
            element.children.forEach((child) => {
              child.parentId = element.id
            })
          }
          element.parentId = '-1'
          return element
        })
        const result = getListAllCategory(rs)
        setListCreate(list.filter((res) => res.isParent))

        const resultCate = result.map((elm) => {
          return { ...elm, select: false }
        })

        setListCategory(resultCate)
      })
      .catch((err) => {
        handleError('getListCategory', err)
      })
  }, [added])

  useEffect(() => {
    setShowMenuRight(false)
  }, [isMobile])

  const onAddSuccess = (value) => {
    setAdded((pre) => !pre)
    setShowMenuRight(false)
    if (isMobile) {
      setTypeMenuRight('add-cate')
    }
    // setItemSelected({})
  }

  const onCloseMenuRight = () => {
    setTypeMenuRight('add-cate')
    setShowMenuRight(false)
    setItemSelected({})
  }

  const handleOpenTabAddCate = () => {
    setShowMenuRight(true)
    setTypeMenuRight('add-cate')
    setItemSelected({})
  }

  const handleRemoveCategory = () => {
    const data = { id: itemSelected.id }
    setIsShowModalDelete(false)

    categoryApi
      .deleteItem(data)
      .then((res) => {
        if (res.success) {
          const list = [...listCategory]
          const idx = list.findIndex((v) => v.id === data.id)
          if (idx > -1) {
            list.splice(idx, 1)
          }
          setListCategory(list)
          setItemSelected({})
          setTypeMenuRight('add-cate')
          setShowMenuRight(false)
        }
      })
      .catch((err) => {
        handleError('deleteItem', err)
      })
  }

  const cellClickedListener = useCallback(
    (event) => {
      const newList = [...listCategory]
      const item = event.data
      newList.forEach((elm) => {
        if (elm.id === item.id) {
          if (elm.select) {
            elm.select = false
            setItemSelected({})
            setShowMenuRight(false)
            setTypeMenuRight('add-cate')
          } else {
            elm.select = true
            setItemSelected(elm)
            setShowMenuRight(true)
            setTypeMenuRight('update-cate')
          }
        } else {
          elm.select = false
        }
      })

      setListCategory(newList)
    },
    [listCategory]
  )

  const gridRef = useRef()
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit()
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit()
      })
    })

    gridRef.current.api.sizeColumnsToFit()
  }, [])

  return (
    <>
      <Root>
        <WrapContent notSelect={Object.keys(itemSelected).length === 0}>
          <WrappHeader>
            <Title>LIST CATEGORY</Title>
            <WrapIcon onClick={handleOpenTabAddCate}>
              <Tooltipv2 overlay="Add category" placement="top">
                <FontAwesomeIcon icon={faCirclePlus}></FontAwesomeIcon>
              </Tooltipv2>
            </WrapIcon>
          </WrappHeader>
          <div style={containerStyle}>
            <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
              <div style={{ overflow: 'hidden', flexGrow: '1' }}>
                <div className="ag-theme-alpine" style={gridStyle}>
                  <AgGridReact
                    rowData={listCategory}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    ref={gridRef}
                    onCellClicked={cellClickedListener}
                  />
                </div>
              </div>
            </div>
          </div>
        </WrapContent>

        {typeMenuRight === 'add-cate' ? (
          <AddCategory
            listCategory={listCreate}
            onAddSuccess={(value) => onAddSuccess(value)}
            isMobile={isMobile}
            showMenuRight={showMenuRight}
            onCloseMenuRight={onCloseMenuRight}
            // onClose={() => setShowMenuDetail(false)}
          />
        ) : (
          <UpdateCategory
            itemSelected={itemSelected}
            listCategory={listCategory}
            isMobile={isMobile}
            showMenuRight={showMenuRight}
            onCloseMenuRight={onCloseMenuRight}
            onAddSuccess={(value) => onAddSuccess(value)}
            onRemove={() => setIsShowModalDelete(true)}
          />
        )}
      </Root>
      <CustomModalConfirm
        type="delete"
        title={'Are you sure ?'}
        onClose={() => setIsShowModalDelete(false)}
        onYes={handleRemoveCategory}
        isShow={isShowModalDelete}
      />
    </>
  )
}

const Root = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`
const WrappHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
const Title = styled.div`
  font-size: 22px;
  color: #999999;
  text-transform: uppercase;
  padding: 10px;
`

const WrapIcon = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  min-width: 30px;
  height: 30px;
  color: rgb(255, 102, 0);
  border-radius: 4px;
  cursor: pointer;
`

const WrapContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  & .ag-center-cols-viewport {
    overflow: hidden;
  }

  & .ag-theme-alpine .ag-row {
    cursor: pointer;
  }

  & .ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: transparent;
  }

  & .ag-theme-alpine .ag-row-focus {
    background: ${(props) => (!props.notSelect ? '#d3d3d3' : 'transparent')};
  }
`
