import { editorStateFromRaw, editorStateToJSON, MegadraftEditor } from 'megadraft'
import 'megadraft/dist/css/megadraft.css'
import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import categoryApi from '../../../api/category'
import { convertObjectToArray, getListAllCategory } from '../../../utils/convertListCategory'
import { handleError, handleSuccess } from '../../../utils/handle-error'
import articleApi from '../../../api/article'
import pluginImage from './ImagePlugin/plugin'
import { convertToRaw, convertFromRaw } from 'draft-js'
export default class CreateArticle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: editorStateFromRaw(null),
      title: '',
      urlLink: '',
      previewLink: '',
      description: '',
      content: '',
      listCategory: [],
      selectedOptions: [],
    }
    this.handleInputsChange = this.handleInputsChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    categoryApi.getListCategory().then((res) => {
      const list = convertObjectToArray(res.data.children)
      const result = getListAllCategory(list)
      this.setState({
        listCategory: result,
      })
    })
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  }

  handleInputsChange = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = () => {
    const { editorState, title, urlLink, previewLink, description, selectedOptions } = this.state
    let thumnailUrl = ''
    const content = convertToRaw(editorState.getCurrentContent())

    const contentArr = content

    // get thumnail Url
    contentArr.blocks.map((elm) => {
      if (elm.data.src !== undefined) {
        thumnailUrl = elm.data.src
      }
    })

    if (!title) {
      return handleError('Error', 'Title is required!')
    } else {
      if (!urlLink) {
        return handleError('Error', 'URL link is required!')
      } else {
        if (!previewLink) {
          return handleError('Error', 'Preview link is required!', '')
        } else {
          if (!description) {
            return handleError('Error', 'Description is required!', '')
          }
        }
      }
    }

    const param = {
      title,
      url_link: urlLink,
      thumbnail: thumnailUrl,
      preview: previewLink,
      description,
      content: '',
      status: 1,
      priority: 2,
    }

    const parentIDs = selectedOptions.reduce((acc, cur, index) => {
      acc[`parent_id_${index + 1}`] = parseInt(cur.value)
      return acc
    }, {})

    const newParam = {
      ...param,
      ...parentIDs,
    }

    articleApi
      .createArticle(newParam)
      .then((res) => {
        if (res.success) {
          this.setState({
            editorState: editorStateFromRaw(null),
            title: '',
            urlLink: '',
            previewLink: '',
            description: '',
            content: '',
            listCategory: [],
            selectedOptions: [],
          })
          handleSuccess('Successfully!')
        }
      })
      .catch((err) => handleError('createArticle', err || 'Error'))
  }

  render() {
    const { title, urlLink, previewLink, description, listCategory, selectedOptions } = this.state

    const customStyle = {
      control: (base, state) => ({
        ...base,
        border: '1px solid #999999',
        height: 42,
        boxShadow: 'none',
        '&:hover': {
          border: '1px solid #999999',
        },
      }),
    }

    const options = listCategory.map((elm) => {
      return { value: elm.id, label: elm.title }
    })
    return (
      <Root>
        <Title>CREATE TEMPLATE</Title>
        {/* <WrapMegaDraft>
          <SubTitle>Choose Thumbnail</SubTitle>
          <MegadraftEditor
            editorState={this.state.editorState}
            onChange={this.onChange}
            plugins={[pluginImage]}
            movableBlocks={true}
            hideSidebarOnBlur={true}
          />
        </WrapMegaDraft> */}
        <WrapSelect>
          <SubTitleSlect>Article Parents</SubTitleSlect>
          <Select
            closeMenuOnSelect={false}
            value={selectedOptions}
            onChange={(value) => {
              this.setState({ selectedOptions: value })
            }}
            isMulti
            options={options}
            styles={customStyle}
            // onChange={handleChange}
            isOptionDisabled={() => selectedOptions.length >= 3}
          ></Select>
        </WrapSelect>
        <WrapInputs>
          <SubTitle>Article Title</SubTitle>
          <Inputs name="title" value={title} onChange={this.handleInputsChange} placeholder="Type ..."></Inputs>
        </WrapInputs>
        <WrapInputs>
          <SubTitle>URL Link</SubTitle>
          <Inputs
            name="urlLink"
            value={urlLink}
            onChange={this.handleInputsChange}
            placeholder="http://url_link"
          ></Inputs>
        </WrapInputs>
        <WrapInputs>
          <SubTitle>Preview Link</SubTitle>
          <Inputs
            name="previewLink"
            value={previewLink}
            onChange={this.handleInputsChange}
            placeholder="http://preview"
          ></Inputs>
        </WrapInputs>
        <WrapInputs>
          <SubTitle>Description</SubTitle>
          <Textarea
            name="description"
            value={description}
            onChange={this.handleInputsChange}
            placeholder="Type ..."
          ></Textarea>
        </WrapInputs>
        <WrapButton>
          <ButtonSubmit onClick={this.handleSubmit}>SUBMIT</ButtonSubmit>
        </WrapButton>
      </Root>
    )
  }
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  overflow-y: scroll;
`

const Title = styled.div`
  width: 100%;
  font-size: 22px;
  color: #999999;
  text-transform: uppercase;
  margin-bottom: 30px;
  padding: 10px;
`

const WrapInputs = styled.div`
  width: 100%;
  padding: 0 80px;
`

const WrapSelect = styled.div`
  width: 100%;
  padding: 0 80px;
  margin-bottom: 20px;
`

const SubTitle = styled.div`
  width: 100%;
  font-size: 20px;
  color: #000;
`
const SubTitleSlect = styled.div`
  width: 100%;
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
`

const Inputs = styled.input`
  width: 100%;
  padding: 0px 10px;
  margin: 10px 0 20px 0;
  height: 42px;
  border: 1px solid #999999;
  border-radius: 6px;
  outline: none;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  margin: 10px 0 20px 0;
  border: 1px solid #999999;
  border-radius: 6px;
  outline: none;
`

const WrapMegaDraft = styled.div`
  /* width: 100%; */
  height: auto;
  margin: 0 80px;
`

const WrapButton = styled.div`
  width: 100%;
  min-height: 42px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
const ButtonSubmit = styled.div`
  width: 40%;
  height: 100%;
  font-weight: 500;
  border: 1px solid #d9af93;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    cursor: pointer;
  }
`
