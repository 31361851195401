import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { Photo } from './Photo'

export const SortablePhoto = ({ photo, onSelectedItem, dragged }) => {
  const sortable = useSortable({ id: photo.idv4 })
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = sortable

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Photo
      ref={setNodeRef}
      style={style}
      onSelectedItem={(v) => {
        // console.log('v ---- >', v)
        onSelectedItem?.(v)
      }}
      photo={photo}
      isDragging={dragged}
      {...attributes}
      {...listeners}
    />
  )
}
