import React, { useState } from 'react'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import ButtonOutLine from '../../../../components/ButtonOutLine'
import { Root } from '../../../Home/components/ContentHomePage'
import timezones from 'timezones.json'
import { handleError, handleSuccess } from '../../../../utils/handle-error'
import { serialize } from '../../../../utils/serialize'
import { devicesApi } from '../../../../api/devicesApi'
import { useDispatch } from 'react-redux'
import FileUploadAction from 'src/redux/FileUpload/action'
import UrlAction from 'src/redux/Url/action'

function MenuLeft({ isMobile, showMenuDetail, onClose, onSaveSuccess }) {
  const [timezone, setTimezone] = useState('')
  const [deviceID, setDeviceID] = useState('')
  const [deviceName, setDeviceName] = useState('')

  const dispatch = useDispatch()

  const options = []
  timezones.forEach((v) => {
    options.push({
      value: v.offset + '|' + v.value,
      extraData: v.value,
      label: v.text,
    })
  })

  const handleSaveDevice = () => {
    if (!handleValidate()) {
      handleError('handleSaveDevice')
      return
    }

    const data = {
      uuid: deviceID,
      extraTimezone: timezone,
      name: deviceName,
      isDuplicate: 0,
      timezone: 0,
    }

    devicesApi
      .createDevice(data)
      .then((res) => {
        if (!res.success) {
          throw res
        }
        handleSuccess('Save success!')
        // window.removeChange("add-device")
        dispatch(UrlAction.resetHaveChange('add-device'))
        onSaveSuccess?.()
      })
      .catch(handleError)
  }

  const handleValidate = () => {
    let result = true
    if (!deviceID) {
      handleError('Device ID is empty!')
      return false
    }
    if (!deviceName) {
      handleError('Your Name is empty!')
      return false
    }

    return result
  }

  return (
    <>
      <MenuDetail mobile={isMobile} show={showMenuDetail}>
        <Root>
          <Content>
            <TitleDetail>
              <span>Add Your Device ID</span>
            </TitleDetail>
            <WrapInput>
              <Input
                placeholder="your device ID"
                value={deviceID}
                onChange={(e) => {
                  setDeviceID(e.target?.value)
                  dispatch(UrlAction.postHaveChange('add-device'))
                  // dispatch(UrlAction.postHaveChange("add-device", "device-id", "your device ID")
                }}
              />
            </WrapInput>
            <WrapInput>
              <Input
                placeholder="your name of device"
                value={deviceName}
                onChange={(e) => {
                  // dispatch(UrlAction.postHaveChange("add-device", "device-name", "your device name")
                  setDeviceName(e.target?.value)
                  dispatch(UrlAction.postHaveChange('add-device'))
                }}
              />
            </WrapInput>
            <WrapInput>
              <span>Time zone:</span>
            </WrapInput>
            <WrapInput>
              <ReactSelect
                options={options}
                styles={customStyles}
                onChange={(v) => {
                  // dispatch(UrlAction.postHaveChange("add-device", "device-timezone", "Time zone")
                  dispatch(UrlAction.postHaveChange('add-device'))
                  setTimezone(v?.extraData || '')
                }}
              />
            </WrapInput>
            <WrapInput>
              <ButtonOutLine title={'Save Info'} onClick={handleSaveDevice} />
            </WrapInput>
          </Content>
        </Root>
      </MenuDetail>
      {showMenuDetail && <BackDrop onClick={onClose} />}
    </>
  )
}

export default MenuLeft

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 42,
    borderColor: '#d9af93',
    borderRadius: 6,
    background: 'none',
    boxShadow: 'none',
  }),
}

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 92;
`

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
  border-bottom: 1px solid rgb(204, 204, 204);
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
