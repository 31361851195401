import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'
import { Root } from '../../../Home/components/ContentHomePage'
import FolderDetail from './components/FolderDetail'
import NewFolder from './components/NewFolder'
import PlayLists from './components/PlayLists'
import Search from './components/Search'
import UploadFile from './components/UploadFile'
import ViewDetail from './components/ViewDetail'

const MenuLeft = forwardRef(
  (
    {
      isMobile,
      showMenuDetail,
      onClose,
      type = 'upload',
      itemSelect,
      multiSelect,
      folderSelect,
      onSearch,
      pid,
      createFolderSuccess,
      uploadDone,
      onDeleteFile,
      onBlockFile,
      onUnBlockFile,
      onUpload,
      updateNameSuccess,
      listDataMedia,
      onChangeSlide,
      onProcess,
      onSendFiles,
      updateFileNameSuccess,
      showPreviewModal,
      isOpenSelectFileUpload,
      listFileTotal,
      onDeleteFolder,
      onAddFileToPlaylistDone,
    },
    ref
  ) => {
    const [renderContent, setRenderContent] = useState('upload')
    const [preview, setPreview] = useState(false)
    const refUpload = useRef(null)

    useImperativeHandle(ref, () => ({
      cancelUpload: (id) => {
        refUpload.current.cancelUpload(id)
      },
    }))

    useEffect(() => {
      if (type === 'itemDetail' && !itemSelect.length && !isMobile) {
        return setRenderContent('upload')
      }
      setRenderContent(type)
    }, [type, itemSelect])

    const onPreview = (preview) => {
      setPreview(preview)
      showPreviewModal(preview)
    }

    return (
      <>
        <MenuDetail preview={preview} mobile={isMobile} show={showMenuDetail} renderContent={renderContent}>
          <Root>
            <Content mobile={isMobile}>
              {renderContent === 'upload' && (
                <UploadFile
                  ref={refUpload}
                  key={'upload-1'}
                  pid={pid}
                  uploadDone={uploadDone}
                  onUpload={onUpload}
                  onProcess={onProcess}
                  onSendFiles={onSendFiles}
                  isOpenSelectFileUpload={isOpenSelectFileUpload}
                />
              )}
              {renderContent === 'search' && <Search onSearch={onSearch} />}
              {renderContent === 'folderDetail' && (
                <FolderDetail
                  folderSelect={folderSelect}
                  updateNameSuccess={updateNameSuccess}
                  onDeleteFolder={onDeleteFolder}
                />
              )}
              {renderContent === 'newFolder' && (
                <NewFolder pid={pid} createFolderSuccess={createFolderSuccess} isMobile={isMobile} />
              )}
              {renderContent === 'itemDetail' && (
                <ViewDetail
                  listDataMedia={listDataMedia}
                  listFileTotal={listFileTotal}
                  itemSelect={itemSelect}
                  multiSelect={multiSelect}
                  AddPlayList={() => setRenderContent('addPlaylist')}
                  onDeleteFile={onDeleteFile}
                  onBlockFile={onBlockFile}
                  onUnBlockFile={onUnBlockFile}
                  onPreview={onPreview}
                  onChangeSlide={onChangeSlide}
                  updateFileNameSuccess={updateFileNameSuccess}
                />
              )}

              {renderContent === 'addPlaylist' && (
                <PlayLists
                  onClose={() => {
                    setRenderContent('upload')
                    onAddFileToPlaylistDone?.()
                  }}
                  itemSelect={itemSelect}
                  isMobile={isMobile}
                  // addNewPlayListSuccess={addNewPlayListSuccess}
                />
              )}
            </Content>
          </Root>
        </MenuDetail>
        {isMobile && showMenuDetail && !preview && (
          <BackDrop
            onClick={() => {
              // setRenderContent('itemDetail')
              onClose?.()
            }}
          />
        )}
      </>
    )
  }
)

export default MenuLeft

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
`

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: ${({ preview, mobile }) => (preview && mobile ? '100%' : '320px')};
  height: 100%;
  display: flex;
  background-color: ${({ renderContent }) => (renderContent === 'upload' ? 'rgb(228, 228, 228)' : '#fff')};
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 101;
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
`
