import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  faHouse,
  faTv,
  faFileLines,
  faList,
  faListOl,
  faNewspaper,
  faClockRotateLeft,
  faHeartCircleCheck,
  faSquarePlus,
  faRectangleList,
  faLayerGroup,
  faIndent,
  faUser,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'
import { checkIsAdmin } from '../../../utils/checkIsAdmin'
import { set } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import GlobalAction from 'src/redux/global/action'
import UrlAction from 'src/redux/Url/action'

export const setTitlePage = (data = {}) => {
  data.title = data.title || 'Default title'
  data.metaDescription = data.metaDescription || 'Default description'

  document.title = data.title
  document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription)
}

function MenuBar({ miniBar = false, onCloseMenu, isMobile = false }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const url = pathname.split('/')[1]

  // global data redux
  const dispatch = useDispatch()
  const activeTab = useSelector((state) => state.global.activeTabMenuBar)
  // end

  const [listMenu, setListMenu] = useState([
    {
      id: 1,
      title: 'HOMEPAGE',
      icon: faHouse,
      url: '/',
      page: '/home',
    },
    {
      id: 2,
      title: 'DEVICES',
      icon: faTv,
      url: 'device',
      page: '/device',
    },
    {
      id: 3,
      title: 'FILES',
      icon: faFileLines,
      url: 'file',
      page: '/file',
    },
    {
      id: 4,
      title: 'PLAYLISTS',
      icon: faList,
      page: '/playlist',
      url: 'playlist',
    },
    {
      id: 5,
      title: 'TEMPLATES',
      icon: faNewspaper,
      url: 'theme-article',
      page: '/theme-article',
    },

    {
      id: 16,
      title: 'MY CANVA',
      icon: faNewspaper,
      url: 'app-canva',
      page: '/app-canva',
    },
    {
      id: 17,
      title: 'YOUTUBE',
      icon: faIndent,
      url: 'app-youtube',
      page: '/app-youtube',
    },
  ])

  useEffect(() => {
    const isAdmin = checkIsAdmin()

    if (isAdmin && listMenu.findIndex((l) => l.id === 13 || l.id === 14) < 0) {
      const listTmp = [...listMenu]
      listTmp.splice(
        7,
        0,
        {
          id: 9,
          title: 'CMS',
          icon: faLayerGroup,
          page: '/cms',
          subMenu: [
            {
              id: 10,
              title: 'TEMPLATE',
              icon: faNewspaper,
              url: '/cms-article',
              page: '/cms',
              parentID: 9,
            },
            {
              id: 11,
              title: 'CATEGORIES',
              icon: faIndent,
              url: '/cms-category',
              page: '/cms',
              parentID: 9,
            },
          ],
        },
        { id: 13, title: 'USERS', icon: faUser, url: 'user', page: '/user' },
        { id: 14, title: 'USERS GROUP', icon: faUserGroup, url: 'user-group', page: '/user-group' }
      )
      setListMenu(listTmp)
    }
  }, [])

  useEffect(() => {
    let title = 'Not Found'
    for (let i = 0; i < listMenu.length; i++) {
      if (listMenu[i].url === url) {
        title = listMenu[i].title
      } else if (listMenu[i].subMenu) {
        let x = listMenu[i].subMenu.find((l) => l.url === url)
        if (x) {
          title = x.title
        }
      } else if (url === 'history') {
        title = 'HISTORYS'
      } else if (url === 'favourite') {
        title = 'FAVOURITES'
      } else if (url === '') {
        title = 'HOMEPAGE'
      }
    }
    setTitlePage({
      title,
    })
  }, [url, listMenu])

  const setUrl = () => {}

  const location1 = useLocation()
  useEffect(() => {
    const url = window.location.pathname

    // console.log('url --->', url)

    listMenu.forEach((item) => {
      if (url.indexOf(item.page) < 0 && item.page != '/home') {
        return
      }

      dispatch(GlobalAction.activeTabMenuBar(item.page))
    })
  }, [location1])

  const handleClickMenuItem = (item) => {
    if (item.url) {
      dispatch(UrlAction.setNextPath({ current: '', next: item.url }))
      navigate(`${item.url}`)
      setTitlePage({
        title: item.title,
      })
    }
    if (isMobile && item.title !== 'APP' && item.title !== 'CMS') {
      onCloseMenu?.()
    }
  }

  return (
    <>
      <Root mini={miniBar} mobile={!!isMobile}>
        <ProSidebar width="100%">
          <Menu iconShape="none">
            {listMenu.map((elm) => {
              return !elm.subMenu ? (
                <MenuItem
                  icon={<FontAwesomeIcon icon={elm.icon} />}
                  className={activeTab === elm?.page ? 'active-tab' : ''}
                  key={elm.id}
                  onClick={() => handleClickMenuItem(elm)}
                >
                  {elm.title}
                </MenuItem>
              ) : (
                <SubMenu
                  key={elm.id}
                  title={elm.title}
                  className={activeTab.includes(elm?.page) ? 'active-tab' : ''}
                  onClick={() => handleClickMenuItem(elm)}
                  icon={<FontAwesomeIcon icon={elm.icon} />}
                >
                  {elm.subMenu.map((item) => {
                    return (
                      <MenuItem
                        icon={<FontAwesomeIcon icon={item.icon} />}
                        key={item.id}
                        onClick={() => handleClickMenuItem(item)}
                      >
                        {item.title}
                      </MenuItem>
                    )
                  })}
                </SubMenu>
              )
            })}
          </Menu>
        </ProSidebar>
      </Root>
      {isMobile && !miniBar && <BackDrop onClick={onCloseMenu} />}
    </>
  )
}

export default MenuBar

const Root = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  width: ${({ mini, mobile }) => (mobile ? '160px' : mini ? '45px' : '160px')};
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  background-color: #5f5f64;
  box-shadow: rgb(0 0 0 / 15%) 2px 2px 4px;
  transition: width 0.3s, transform 0.3s;
  transform: ${({ mobile, mini }) => (mobile && mini ? 'translateX(-160px)' : 'translateX(-0px)')};
  z-index: 92;
  overflow: hidden;

  & .pro-sidebar .pro-menu {
    padding: 0;
  }

  & .pro-sidebar .pro-menu .active-tab {
    background: rgb(255, 102, 0);
    /* :before {
      content: '';
      position: absolute;
      height: 45px;
      width: 3px;
      left: 0;
      top: 0;
      background-color: rgb(255, 102, 0);
    } */
  }

  & .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 0;
    min-height: 45px;
  }

  & .pro-sidebar > .pro-sidebar-inner {
    background: rgb(95, 95, 100);
  }

  & .pro-sidebar .pro-menu .pro-menu-item {
    min-height: 45px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    /* padding: 0px 4px; */
    color: rgb(255, 255, 255);
    box-shadow: grey 0px 4px 2px -2px;
    position: relative;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & svg {
      width: ${({ mini }) => (mini ? '24px' : '16px')};
      height: ${({ mini }) => (mini ? '24px' : '16px')};
    }

    & .pro-menu-item {
      ::before {
        display: none;
      }
    }

    & .pro-menu-item .pro-inner-item {
      padding-left: 20px;
      padding-right: 0;
      .pro-icon-wrapper {
        margin-right: 15px;
      }
    }
  }

  & .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
    padding: 0;
    li {
      display: flex;
    }
  }

  & .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background: #5f5f64;
    padding: 0;
  }

  & .react-slidedown.transitioning {
    overflow-x: hidden;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const Li = styled.div`
  width: 100%;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: ${({ mini, mobile }) => (mobile ? '' : mini ? 'center' : 'flex-start')};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 4px;
  color: #fff;
  box-shadow: 0 4px 2px -2px grey;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
  overflow: hidden;

  svg {
    margin-right: 8px;
    height: 18px;
    margin-left: 8px;
  }

  ::before {
    content: '';
    position: absolute;
    height: 100%;
    width: ${({ select }) => (select ? '3px' : '0px')};
    left: 0;
    top: 0;
    background-color: rgb(255, 102, 0);
  }
`
