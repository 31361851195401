import React, { PureComponent } from 'react'
import { map, hasKey, compare, convertStyle, objectCompare } from './utils'

const isNative = false
/*
import { View, Text, ScrollView, RefreshControl, TouchableOpacity, Animated } from 'react-native';
*/

export default class Div extends PureComponent {
  constructor(props) {
    super(props)

    this._lastActionXY = { x: 0, y: 0 }
    this.currentScroll = 0

    this.refDiv = undefined
    this.scrollTo = this._scrollTo.bind(this)
    this.animated = false
    if (isNative === true && props.animated === true) {
      this.animated = true
    }
    if (hasKey(props.configGradient)) {
      this._configGradient = this._convertGradient(props.configGradient)
    }
    this.state = { hidden: false }
    this._pos = {}
    this._isLoaded = false
    this._handleProps = (props) => {
      if (isNative === true || typeof props === 'undefined') {
        return
      }
      if (typeof this.refDiv !== 'object' || this.refDiv === null) {
        return
      }

      if (typeof props.onResize === 'function') {
        this._ro = new ResizeObserver((entries) => {
          for (let entry of entries) {
            const cr = entry.contentRect

            props.onResize({
              ...cr,
            })
          }
        })
        this._ro.observe(this.refDiv)
      }

      if (this._isLoaded === true) {
        return
      }
      if (typeof props.onLayout === 'function') {
        this._isLoaded = true
        const pos = this._getLayout()
        if (pos.y >= this._pos - 1 && pos.y <= this._pos + 1) {
          pos.y = this._pos.y
        }
        this._pos = pos
        props.onLayout({
          nativeEvent: {
            layout: pos,
          },
        })
      }
    }
    this._handleProps(props)
  }
  componentDidMount() {
    this._handleProps(this.props)
  }
  componentWillUnmount() {
    this._ro && this._ro.disconnect()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!compare(this.props.configGradient, nextProps.configGradient)) {
      this._configGradient = this._convertGradient(nextProps.configGradient)
      this.forceUpdate()
    }
    if (typeof this.props.onLayout !== 'function') {
      return
    }
    this._denyUpdateLayout = true
  }
  componentDidUpdate(prevProps, prevState) {
    if (isNative === true) {
      return
    }

    if (typeof this.props.onLayout !== 'function') {
      return
    }
    if (this._denyUpdateLayout === true) {
      this._denyUpdateLayout = false
      return
    }
    // loi voi Modal
    return
    clearTimeout(this._toLayout)
    this._toLayout = setTimeout(() => {
      if (typeof this.refDiv === 'undefined' || this.refDiv === null) {
        return
      }
      const pos = this._getLayout()
      if (pos.y >= this._pos - 1 && pos.y <= this._pos + 1) {
        pos.y = this._pos.y
      }
      if (objectCompare(this._pos, pos)) {
        return
      }
      this._pos = pos
      this.props.onLayout({
        nativeEvent: {
          layout: pos,
        },
      })
    }, 20)
  }

  _convertGradient = (iObj = {}) => {
    const { angle, colors = [] } = iObj
    if (!hasKey(colors) || colors.length < 2) return {}
    if (isNative === true) {
      let options = {
        colors,
      }
      switch (angle) {
        case 180:
          options = {
            ...options,
            start: { x: 0, y: 0 },
            end: { x: 0, y: 1 },
          }
          break
        case 90:
          options = {
            ...options,
            start: { x: 0, y: 0 },
            end: { x: 1, y: 0 },
          }
          break
        case 270:
          options = {
            ...options,
            start: { x: 1, y: 0 },
            end: { x: 0, y: 0 },
          }
          break
        case 45:
          options = {
            ...options,
            start: { x: 0, y: 2.2 },
            end: { x: 1, y: 0 },
            locations: [0.2, 0.9],
          }
          break
        case 135:
          options = {
            ...options,
            start: { x: 0, y: 2 },
            end: { x: 0.7, y: 3 },
            locations: [0.1, 0.95],
          }
          break
        case 225:
          options = {
            ...options,
            start: { x: 1.2, y: 0 },
            end: { x: 0, y: 0 },
            locations: [0.05, 0.99],
          }
          break
        case 315:
          options = {
            ...options,
            start: { x: 1, y: 3 },
            end: { x: 0, y: 0 },
            locations: [0.2, 0.99],
          }
          break
        default:
          options = {
            ...options,
            start: { x: 0, y: 1 },
            end: { x: 0, y: 0 },
          }
      }
      return options
    }
    return `linear-gradient(${angle}deg, ${colors.join(',')})`
  }
  css = (iObj) => {
    if (isNative === true) {
      if (iObj.hidden !== this.state.hidden) {
        this.setState({
          hidden: iObj.hidden,
        })
      }
      return
    }
    if (typeof this.refDiv === 'undefined' || this.refDiv === null) {
      return
    }
    map(iObj, (v, k) => {
      if (
        [
          'height',
          'width',
          'top',
          'left',
          'bottom',
          'right',
          'marginTop',
          'marginLeft',
          'marginRight',
          'marginBottom',
        ].indexOf(k) > -1
      ) {
        let val = v
        if (typeof val !== 'string') {
          val += 'px'
        }
        this.refDiv.style[k] = val
        return
      }
      this.refDiv.style[k] = v
    })
  }
  getEl = (iObj) => {
    if (isNative === true) {
      return
    }
    if (typeof this.refDiv === 'undefined' || this.refDiv === null) {
      return
    }
    const output = {}
    map(iObj, (v) => {
      output[v] = this.refDiv[v]
    })
    return output
  }

  _getLayout = (arr) => {
    const layout = this.refDiv.getBoundingClientRect()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0

    const scrollbarWidth =
      typeof arr === 'object' && arr !== null && arr.length > 0 && arr.indexOf('width') > -1
        ? typeof window !== 'undefined'
          ? window.innerWidth - document.body.clientWidth
          : 0
        : 0
    const pos = {
      width: Math.round(layout.width) + scrollbarWidth, //
      height: Math.round(layout.height),
      x: Math.round(layout.left + scrollLeft),
      y: Math.round(layout.top), // Math.round(layout.top + scrollTop), // lay current scrollbar cong vao
      scrollTop,
    }
    return pos
  }
  getBoundingClientRect = (arr) =>
    new Promise((resolve) => {
      if (typeof this.refDiv === 'undefined' || this.refDiv === null) {
        resolve({})
      }
      if (isNative === true) {
        if (this.refDiv && this.refDiv.measure) {
          this.refDiv.measure((fx, fy, width, height, px, py) => {
            const pos = {
              width: Math.round(width),
              height: Math.round(height),
              x: Math.round(px),
              y: Math.round(py),
            }
            resolve(pos)
          })
        } else {
          resolve({})
        }
        return
      }
      const pos = this._getLayout(arr)
      if (pos.y >= this._pos - 1 && pos.y <= this._pos + 1) {
        pos.y = this._pos.y
      }

      resolve(pos)
    })
  _onScroll = (event) => {
    if (isNative === true) {
      if (typeof this.props.onScroll === 'function') {
        this.props.onScroll(event)
        return
      }
      const nativeEvent = event.nativeEvent
      if (typeof nativeEvent === 'undefined' || nativeEvent === null) {
        return
      }
      const { height: heightScroll } = nativeEvent.contentSize
      const { height: heightDiv } = nativeEvent.layoutMeasurement
      let pos = heightDiv - heightScroll
      if (pos <= 0) {
        pos = 1
      }
      const { y } = nativeEvent.contentOffset
      this.currentScroll = y
      return
    }
    const pos = (this.height || 0) - (this.heightScroll || 0)

    const percent = event / pos
    this.props.route.callEventListener('scroll', {
      percent: Math.floor(percent * 100, 2),
      y: this.height * percent,
    })
  }
  _scrollTo = (height = 0, width = 0) => {
    if (typeof this.refDiv === 'undefined' || this.refDiv === null) {
      return
    }
    if (isNative === true) {
      if (this.animated) {
        if (typeof this.refDiv._component.scrollTo === 'function') {
          this.refDiv._component.scrollTo({ y: height, x: width })
        }
        return
      }
      if (typeof this.refDiv.scrollTo === 'function') {
        this.refDiv.scrollTo({ y: height })
      }
    } else {
      this.refDiv.scrollTop += height
      this.refDiv.scrollLeft += width
    }
  }
  _onLayout = (e) => {
    const { layout } = e.nativeEvent
    this.layout = layout
    if (typeof this.props.onLayout !== 'undefined') {
      this.props.onLayout(e)
    }
  }

  _onMouseDown = (e) => {
    this.refDiv.classList.add('inputGradientDown')

    // var ripples = e.target.getElementsByClassName('.ripple');
    // if (ripples[0]) {
    //   var ripple = ripples[0];
    //   console.log('ripple', ripple)
    //   ripple.classList.remove('pressed');

    //   console.log('-------_onMouseDown-offsetX', e.clientX, e.offsetX);
    //   console.log('-------_onMouseDown-offsetY', e.clientY, e.offsetY);

    //   ripple.style.paddingLeft = e.offsetX + 'px';
    //   ripple.style.paddingTop = e.offsetY + 'px';
    //   setTimeout(function () {
    //       ripple.classList.add('pressed');
    //   });
    // }

    this.props.onMouseDown && this.props.onMouseDown()
  }
  _onMouseUp = (e) => {
    setTimeout(() => {
      this.refDiv && this.refDiv.classList.remove('inputGradientDown')
    }, 200)
    this.props.onMouseUp && this.props.onMouseUp()
  }

  render() {
    /*
    if (isNative === true) {
      if (this.state.hidden === true) {
        return (
          <View />
        );
      }
      const style =
        this.props.style ||
        (
          (
            this.props.isContainer || this.props.isScrollView) ? {flex: 1, padding: 0} : 
          (this.props.isHeader  ? {justifyContent: 'space-between', backgroundColor: '#CCC', width: null, flexDirection: 'row', height: 64} : {})
        );
      if (typeof this.props.onClick === 'function') {
        return (
          <TouchableOpacity
            activeOpacity={this.props.activeOpacity || 1}
            ref={(ele) => this.refDiv = ele}
            onLayout={this._onLayout}
            onPress={this.props.onClick}
            style={
              this.props.disabled ?
              (
                Array.isArray(style) ?
                  [
                    ...style,
                    {
                      opacity: 0.5,
                    }
                  ] :
                  typeof style === 'object' ?
                    {
                      ...style,
                      opacity: 0.5,
                  } :
                  {
                    opacity: 0.5,
                  }
              ) :
              style
            }
            disabled={this.props.disabled}
            // style={hasKey(this._configGradient) ? {} : style}
          >
            {hasKey(this._configGradient) ?
              <LinearGradient
                style={this.props.styleGradient}
                {...this._configGradient}
              >
                {this.props.children}
              </LinearGradient>
              :
              this.props.children
            }
          </TouchableOpacity>
        );
      }
      if (this.props.isScrollView === true) {
        const contentContainerStyle = this.props.contentContainerStyle || {};
        const scrollEnabled = typeof this.props.scrollEnabled !== 'undefined' ? this.props.scrollEnabled : undefined;
        const scrollEventThrottle = typeof this.props.scrollEventThrottle !== 'undefined' ? this.props.scrollEventThrottle : 16;
        
        if (this.animated === true) {
          return (
            <Animated.ScrollView
              horizontal={this.props.horizontal ? true : false}
              scrollEnabled={scrollEnabled}
              scrollEventThrottle={scrollEventThrottle}
              // showsHorizontalScrollIndicator={this.props.horizontal ? false : true}
              keyboardDismissMode={this.props.keyboardDismissMode}
              keyboardShouldPersistTaps={this.props.keyboardShouldPersistTaps || 'always'}
              removeClippedSubviews={this.props.removeClippedSubviews}
              ref={(ele) => this.refDiv = ele}
              style={style}
              contentContainerStyle={contentContainerStyle}
              onScroll={this.props.onScroll}
              onScrollEnd={this.props.onScrollEnd}
              onLayout={this._onLayout}
              onContentSizeChange={this.props.onContentSizeChange}
            >
              {this.props.children}
            </Animated.ScrollView>
          );
        }
        const dataProps = {};
        if (typeof this.props.refreshControl !== 'undefined' && this.props.refreshControl !== null) {
            dataProps.refreshControl = <RefreshControl
              refreshing={this.props.refreshControl.refreshing}
              onRefresh={this.props.refreshControl.onRefresh}
            />
        }
        
        return (
          <ScrollView
            {...dataProps}
            scrollEnabled={scrollEnabled}
            scrollEventThrottle={scrollEventThrottle}
            horizontal={this.props.horizontal ? true : false}
            showsHorizontalScrollIndicator={this.props.horizontal ? false : true}
            keyboardDismissMode={this.props.keyboardDismissMode}
            keyboardShouldPersistTaps={this.props.keyboardShouldPersistTaps || 'always'}
            removeClippedSubviews={this.props.removeClippedSubviews}
            ref={(ele) => this.refDiv = ele}
            style={style}
            contentContainerStyle={contentContainerStyle}
            onScroll={this.props.onScroll}
            onScrollEnd={this.props.onScrollEnd}
            onLayout={this._onLayout}
            onContentSizeChange={this.props.onContentSizeChange}
          >
            {hasKey(this._configGradient) ?
              <LinearGradient
                style={this.props.styleGradient}
                {...this._configGradient}
              >
                {this.props.children}
              </LinearGradient>
              :
              this.props.children
            }
          </ScrollView>
        );
      }
      if (this.props.isText === true) {
        return (
          <Text ref={(ele) => this.refDiv = ele} style={style} numberOfLines={this.props.numberOfLines}>{this.props.children}</Text>
        );
      }

      const onStartShouldSetResponderCapture = typeof this.props.onStartShouldSetResponderCapture !== 'undefined' ? this.props.onStartShouldSetResponderCapture : null;

      if (this.animated === true) {
        return (
          <Animated.View
            pointerEvents={this.props.pointerEvents}
            // accessibilityComponentType={this.props.accessibilityComponentType}          
            accessibilityLabel={this.props.accessibilityLabel}          
            // accessibilityTraits={this.props.accessibilityTraits}          
            accessible={this.props.accessible}          
            onResponderGrant={this.props.onResponderGrant}          
            onResponderMove={this.props.onResponderMove}          
            onResponderRelease={this.props.onResponderRelease}          
            onResponderTerminate={this.props.onResponderTerminate}          
            onResponderTerminationRequest={this.props.onResponderTerminationRequest}          
            onStartShouldSetResponder={this.props.onStartShouldSetResponder}
            ref={(ele) => this.refDiv = ele}
            onLayout={this._onLayout}
            onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
            style={style}
          >
            {this.props.children}
          </Animated.View>
        );
      }

      if (hasKey(this._configGradient)) {
        return (
          <LinearGradient
            shouldRasterizeIOS={this.props.shouldRasterizeIOS}
            renderToHardwareTextureAndroid={this.props.renderToHardwareTextureAndroid}
            needsOffscreenAlphaCompositing={this.props.renderToHardwareTextureAndroid}
            pointerEvents={this.props.pointerEvents}
            // accessibilityComponentType={this.props.accessibilityComponentType}          
            accessibilityLabel={this.props.accessibilityLabel}          
            // accessibilityTraits={this.props.accessibilityTraits}          
            accessible={this.props.accessible}          
            onResponderGrant={this.props.onResponderGrant}          
            onResponderMove={this.props.onResponderMove}          
            onResponderRelease={this.props.onResponderRelease}          
            onResponderTerminate={this.props.onResponderTerminate}          
            onResponderTerminationRequest={this.props.onResponderTerminationRequest}          
            onStartShouldSetResponder={this.props.onStartShouldSetResponder}
            ref={(ele) => this.refDiv = ele}
            onLayout={this._onLayout}
            onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
            style={style}
            {...this._configGradient}
          >
            {this.props.children}
          </LinearGradient>
        )
      }
      return (
        <View
          shouldRasterizeIOS={this.props.shouldRasterizeIOS}
          renderToHardwareTextureAndroid={this.props.renderToHardwareTextureAndroid}
          needsOffscreenAlphaCompositing={this.props.renderToHardwareTextureAndroid}
          pointerEvents={this.props.pointerEvents}
          // accessibilityComponentType={this.props.accessibilityComponentType}          
          accessibilityLabel={this.props.accessibilityLabel}          
          // accessibilityTraits={this.props.accessibilityTraits}          
          accessible={this.props.accessible}          
          onResponderGrant={this.props.onResponderGrant}          
          onResponderMove={this.props.onResponderMove}          
          onResponderRelease={this.props.onResponderRelease}          
          onResponderTerminate={this.props.onResponderTerminate}          
          onResponderTerminationRequest={this.props.onResponderTerminationRequest}          
          onStartShouldSetResponder={this.props.onStartShouldSetResponder}
          ref={(ele) => this.refDiv = ele}
          onLayout={this._onLayout}
          onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}
          style={style}
        >
          {this.props.children}
        </View>
      );
    }
    */

    const subStyle =
      this.props.isScrollView && this.props.horizontal
        ? {
            overflowX: 'auto',
            overflowY: 'hidden',
          }
        : {}

    const style =
      this.props.isScrollView && this.props.horizontal
        ? Array.isArray(this.props.style)
          ? [...this.props.style, subStyle]
          : [this.props.style, subStyle]
        : this.props.style
    const dataWebProps = convertStyle(style)
    if (typeof this._configGradient === 'string') {
      // if (!hasKey(dataWebProps.style)) {
      //   dataWebProps.style = {};
      // }
      // dataWebProps.style.display = 'block';
      // dataWebProps.style.borderRadius = '5px';
      // dataWebProps.style.border = '1px solid #CCC';
      // dataWebProps.style.backgroundImage = 'none';

      let extraClass = 'inputGradient'

      if (this.props.type === 'input') {
        extraClass += ' inputGradientfix'
      } else {
        extraClass += ' inputGradientBtn'
      }

      if (this.props.isActive === true) {
        extraClass += ' inputGradientActive'
      }

      if (typeof dataWebProps.className === 'string') {
        dataWebProps.className = extraClass + ' ' + dataWebProps.className
      } else {
        dataWebProps.className = extraClass
      }
    }
    if (typeof dataWebProps.style !== 'object' || dataWebProps.style === null) {
      dataWebProps.style = {}
    }
    if (typeof this.props.onClick === 'function') {
      dataWebProps.style.cursor = 'pointer'
    }
    if (this.props.disabled === true) {
      dataWebProps.style.opacity = 0.5
    }

    if (typeof this._configGradient === 'string') {
      return (
        <a
          dataid={this.props.dataid}
          ref={(ele) => (this.refDiv = ele)}
          onClick={this.props.disabled !== true && this.props.onClick}
          draggable={this.props.draggable}
          onMouseDown={this._onMouseDown}
          onMouseMove={this.props.onMouseMove}
          onMouseUp={this._onMouseUp}
          onMouseEnter={this.props.onMouseEnter}
          onMouseLeave={this.props.onMouseLeave}
          onTouchStart={this._onMouseDown}
          onTouchEnd={this._onMouseUp}
          onResize={this.props.onResize}
          {...dataWebProps}
          // #debug
          // onScroll={this.props.isScrollView && this.props.horizontal  ? this.props.onScroll : undefined}
          onScroll={this.props.onScroll}
          title={this.props.title}
          id={this.props.id}
        >
          {this.props.children}
        </a>
      )
    }
    return (
      <div
        dataid={this.props.dataid}
        ref={(ele) => (this.refDiv = ele)}
        onClick={this.props.disabled !== true && this.props.onClick}
        draggable={this.props.draggable}
        onMouseDown={this.props.onMouseDown}
        onMouseMove={this.props.onMouseMove}
        onMouseUp={this.props.onMouseUp}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onResize={this.props.onResize}
        {...dataWebProps}
        // #debug
        // onScroll={this.props.isScrollView && this.props.horizontal  ? this.props.onScroll : undefined}
        onScroll={this.props.onScroll}
        title={this.props.title}
        id={this.props.id}
      >
        {this.props.children}
      </div>
    )
  }
}
