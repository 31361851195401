import React, { useState } from 'react'
import styled from 'styled-components'
import userGroupApi from '../../../api/userGroup'
import userApi from '../../../api/users'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../utils/handle-error'

export default function UserGroupAddTab({ onAddSuccess }) {
  const [inputsInfo, setInputsInfo] = useState({
    name: '',
    durationForEachVid: '',
    sizeForEach: '',
    durationForTotal: '',
    sizeForTotal: '',
    numberForTotalMedia: '',
    numberForTotalDevice: '',
    numberForTotalDeviceRep: '',
    daysLock: '',
  })

  const handleChangeInfo = (e) => {
    const name = e.target.name
    const value = e.target.value

    setInputsInfo({
      ...inputsInfo,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    if (!inputsInfo.name) {
      return handleError('Name is required!')
    }

    const {
      name,
      durationForEachVid,
      sizeForEach,
      durationForTotal,
      sizeForTotal,
      numberForTotalMedia,
      numberForTotalDevice,
      numberForTotalDeviceRep,
      daysLock,
    } = inputsInfo

    const data = {
      name,
      durationForEachVid,
      sizeForEach,
      durationForTotal,
      sizeForTotal,
      numberForTotalMedia,
      numberForTotalDevice,
      numberForTotalDeviceRep,
      daysLock,
    }

    userGroupApi
      .createUserGroup(data)
      .then((res) => {
        handleSuccess('Create user success!')
        onAddSuccess?.()

        setInputsInfo({
          name: '',
          durationForEachVid: '',
          sizeForEach: '',
          durationForTotal: '',
          sizeForTotal: '',
          numberForTotalMedia: '',
          numberForTotalDevice: '',
          numberForTotalDeviceRep: '',
          daysLock,
        })
      })
      .catch((err) => {
        handleError('createUserGroup', err)
      })
  }

  return (
    <>
      <TitleDetail>
        <span>User Group Info</span>
      </TitleDetail>
      <LabelInput>Group ID:</LabelInput>
      <WrapInput>
        <ButtonID>
          <span>ID: -1</span>
        </ButtonID>
      </WrapInput>
      <LabelInput>Group name:</LabelInput>
      <WrapInput>
        <Input type="text" name="name" placeholder="Name" value={inputsInfo.name} onChange={handleChangeInfo} />
      </WrapInput>
      <LabelInput>Duration for Each Video (seconds):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.durationForEachVid}
          name="durationForEachVid"
          placeholder="Duration for Each Video (seconds)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Size for Each Media (MB):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.sizeForEach}
          name="sizeForEach"
          placeholder="Size for Each Media (MB)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Duration for Total Media (seconds):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.durationForTotal}
          name="durationForTotal"
          placeholder="Duration for Total Media (seconds)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Size for Total Media (MB):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.sizeForTotal}
          name="sizeForTotal"
          placeholder="Size for Total Media (MB)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Number for Total Media (max files):</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.numberForTotalMedia}
          name="numberForTotalMedia"
          placeholder="Number for Total Media (maximum files)"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Number for Total Device:</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.numberForTotalDevice}
          name="numberForTotalDevice"
          placeholder="Number for Total Device"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Number for Total Device Replication:</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.numberForTotalDeviceRep}
          name="numberForTotalDeviceRep"
          placeholder="Number for Total Device Replication"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Days lock without net:</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.daysLock}
          name="daysLock"
          placeholder="days lock without net"
          onChange={handleChangeInfo}
        />
      </WrapInput>

      <WrapInput>
        <ButtonOutLine onClick={handleSubmit} title={'Save'} />
      </WrapInput>
    </>
  )
}

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  /* margin-top: 12px; */

  :last-child {
    margin-top: 12px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const LabelInput = styled.div`
  padding: 0 10px;
  font-size: 16px;
  color: #ccc;
  margin-top: 12px;
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 12px;
  margin-left: 12px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin-left: 5px;
`

const ButtonID = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d9af93;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 10px;
  }
  :hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    span {
      color: #fff;
    }
  }
`
