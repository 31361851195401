import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons'
import Image from 'src/components/Image'

export default function ModalPreviewImage({ onClose, itemsSelected, onNextItem, onPreItem }) {
  return (
    <MyModal>
      {/* <Close onClick={onClose}>&times;</Close> */}
      <ContentModal>
        <WrapHeader>
          <WrapText>
            <a href={itemsSelected?.thumbnail} target="_blank" alt="img-thumbnail">
              USE THIS TEMPLATE
            </a>
          </WrapText>
          <WrapAction>
            <WrapIcon onClick={onPreItem}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </WrapIcon>
            <WrapIcon onClick={onClose}>
              <FontAwesomeIcon icon={faXmark} />
            </WrapIcon>
            <WrapIcon onClick={onNextItem}>
              <FontAwesomeIcon icon={faArrowRight} />
            </WrapIcon>
          </WrapAction>
        </WrapHeader>
        <WrapImage>
          <Image src={itemsSelected.thumbnail} alt={itemsSelected.title || 'article thumbnail'} RenderImg={Img}></Image>
        </WrapImage>

        <WrapInfo>
          <InfoItem>
            <Title>Title:&nbsp;</Title>
            <Content>{itemsSelected.title}</Content>
          </InfoItem>
          <InfoItem>
            <Title>Description:&nbsp;</Title>
            <Content>{itemsSelected.description}</Content>
          </InfoItem>
          <InfoItem>
            <Title>Status:&nbsp;</Title>
            <Content>{itemsSelected.status === 1 ? 'Active' : 'Inactive'}</Content>
          </InfoItem>
        </WrapInfo>
      </ContentModal>
    </MyModal>
  )
}

const KeyFrame1 = keyframes`
   0% {
    transform:scale(1);
    opacity:1;
  }
  99.9% {
    transform:scale(2);
    opacity:0;
  }
  100% {
    transform:scale(0);
  }
`
const MyModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  /* animation: ${KeyFrame1} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards; */
`

const Close = styled.span`
  cursor: pointer;
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
`

const ContentModal = styled.div`
  width: 600px;
  height: 600px;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const WrapHeader = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
`

const WrapText = styled.div`
  width: 210px;
  height: 100%;
  border-radius: 4px 4px 0 0;
  background: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;

  a {
    color: #000;
    font-weight: 500;
    margin-left: 5px;
    text-decoration: none;
  }

  :hover {
    background: rgb(255, 102, 0);
    a {
      color: #fff;
    }
  }
`

const WrapAction = styled.div`
  height: 100%;
  display: flex;
`

const WrapIcon = styled.div`
  background: #fff;
  border-radius: 4px 4px 0 0;
  margin: 0 2px;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: rgb(255, 102, 0);
    color: #fff;
  }

  :last-child {
    margin-right: 0px;
  }
`

const WrapImage = styled.div`
  flex: 1;
  max-height: 400px;
`
const Img = styled.img`
  width: 100%;
  max-width: 100%;
  height: 100%;
`
const WrapInfo = styled.div`
  width: 100%;
  border-radius: 0 0 4px 4px;
  background: #fff;
  padding: 10px;
`
const InfoItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  font-size: 16px;
`

const Title = styled.span`
  color: #333;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`
const Content = styled.span`
  font-weight: 500;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`
