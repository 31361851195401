import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import userApi from '../../../api/users'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../utils/handle-error'

export default function UserEditTab({ onSearchSuccess }) {
  const [keyword, setKeyword] = useState()

  const handleSearch = () => {
    userApi
      .searchUser(keyword)
      .then((res) => {
        onSearchSuccess?.(res.data.data)
      })
      .catch((err) => {
        handleError('searchUser', err)
      })
  }

  return (
    <>
      <TitleDetail>
        <span>Search</span>
      </TitleDetail>
      <WrapInput>
        <Input
          type="text"
          placeholder="Name"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
        />
      </WrapInput>
      <WrapInput>
        <ButtonOutLine onClick={handleSearch} title={'Search'} />
      </WrapInput>
    </>
  )
}

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 12px;
  margin-left: 12px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin-left: 5px;
`
