import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import UrlAction from 'src/redux/Url/action'
import styled from 'styled-components'
import { mediaApi } from '../../../../../api/mediaApi'
import ButtonOutLine from '../../../../../components/ButtonOutLine'
import InputText from '../../../../../components/InputText'
import { handleError } from '../../../../../utils/handle-error'
import { serialize } from '../../../../../utils/serialize'

function NewFolder({ pid, createFolderSuccess, isMobile }) {
  const [name, setName] = useState('')

  const dispatch = useDispatch()

  const handleCreateFolder = () => {
    if (!name) {
      return handleError('Name is required!')
    }
    const data = { name, pid }
    mediaApi
      .createMediaFolder(data)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        setName('')
        // dispatch(UrlAction.resetHaveChange('file-add-folder'))
        createFolderSuccess?.(res.data)
      })
      .catch((err) => {
        handleError('createMediaFolder', err)
      })
  }

  return (
    <Root>
      <Title>Folder name:</Title>
      <WrapInput>
        <Input
          autoFocus={!isMobile}
          placeholder="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
        />
      </WrapInput>
      <WrapBtn>
        <ButtonOutLine title="Create folder" onClick={handleCreateFolder} />
      </WrapBtn>
    </Root>
  )
}

export default NewFolder

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .styled-input-custom {
    background: none;
    border: 1px solid #d9af93;
  }
`
const WrapInput = styled.div`
  width: 100%;
  /* padding: 0 10px; */
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const Title = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 14px;
`
const WrapBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
`
