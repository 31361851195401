import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import authApi from '../../../api/auth'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { getUserInfo } from '../../../helpers/storage'
import { handleError } from '../../../utils/handle-error'
import { Root } from '../../Home/components/ContentHomePage'

function MenuLeft({ isMobile, showMenuDetail, onClose }) {
  const [useInfo, setUseInfo] = useState({})

  useEffect(() => {
    authApi
      .getProfile()
      .then((res) => {
        if (!res.success) {
          throw res
        }

        const { data } = res
        setUseInfo(data)
      })
      .catch(handleError)
  }, [])

  return (
    <>
      <MenuDetail mobile={isMobile} show={showMenuDetail}>
        <Root>
          <Content>
            <TitleDetail>
              <span>User Info:</span>
            </TitleDetail>
            <WrapInput>
              <span>Name: {useInfo?.name}</span>
            </WrapInput>
            <WrapInput>
              <span>Address: {useInfo?.address}</span>
            </WrapInput>
            <WrapInput>
              <span>Email: {useInfo?.email}</span>
            </WrapInput>
            <WrapInput>
              <span>Phone Number: {useInfo?.phone_number}</span>
            </WrapInput>
            <WrapInput>
              <span>Status: {useInfo?.is_active === 1 ? 'Active' : 'Inactive'}</span>
            </WrapInput>

            <TitleDetail>
              <span>Rules:</span>
            </TitleDetail>
            <WrapInput>
              <span>Group: {useInfo?.role?.name}</span>
            </WrapInput>
            <WrapInput>
              <span>Duration for Each Video (s): {useInfo?.role?.allow_duration_each_video}</span>
            </WrapInput>
            <WrapInput>
              <span>Size for Each Media: {((useInfo?.role?.allow_size_each_media * 1)).toFixed(2)} MB</span>
            </WrapInput>
            <WrapInput>
              <span>
                Duration for Total Media (s): {useInfo?.used?.total_duration}
                /{useInfo?.role?.allow_duration_total_video}
              </span>
            </WrapInput>
            <WrapInput>
              <span>Storage: {((useInfo?.used?.total_size * 1) / 1024).toFixed(2)} MB/{((useInfo?.role?.allow_size_total_media * 1)).toFixed(2)} MB</span>
            </WrapInput>
            <WrapInput>
              <span>Number for Total Media: {useInfo?.used?.total_file}/{useInfo?.role?.allow_total_media}</span>
            </WrapInput>
            <WrapInput>
              <span>Number for Total Device: {useInfo?.used?.total_device}/{useInfo?.role?.allow_total_device}</span>
            </WrapInput>
          </Content>
        </Root>
      </MenuDetail>
      {showMenuDetail && <BackDrop onClick={onClose} />}
    </>
  )
}

export default MenuLeft

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 92;
`

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`
const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;

  span {
    font-size: 16px;
    font-weight: 400;
    color: #000;
  }
`
const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
