import appConstants from "../constants";
import HttpRequest from "./HttpRequest";

const apiUrl = `${appConstants.apiUrl}/theme/history`;
const historyApi = Object.freeze({
  getListHistory: () =>
    HttpRequest.request({
      method: "GET",
      url: apiUrl,
    }),
  addHistoryItem: (data) =>
    HttpRequest.request({
      method: "POST",
      url: apiUrl,
      data: data,
    }),
});

export default historyApi;
