import React from 'react'
import { Button, TextError, WrapInput } from '../styled'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import UncontrolInputPassword from '../../../components/UncontrolInput/UncontrolInputPassWord'
import UnControlInputText from '../../../components/UncontrolInput/UncontrolInputText'

const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
    repassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match!'),
    phone_number: yup.number().typeError('you must type a number').required(),
  })
  .required()

function SignUpForm({ handleRegister }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone_number: null,
      password: '',
      repassword: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (data) => {
    handleRegister(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <WrapInput>
        <UnControlInputText {...register('name')} placeholder="Fullname" />
        {errors.name && <TextError>{errors.name.message}</TextError>}
      </WrapInput>
      <WrapInput>
        <UnControlInputText {...register('email')} placeholder="Email" />
        {errors.email && <TextError>{errors.email.message}</TextError>}
      </WrapInput>
      <WrapInput>
        <UnControlInputText
          {...register('phone_number', { valueAsNumber: true })}
          placeholder="Phone Number"
          type={'number'}
        />
        {errors.phone_number && <TextError>{errors.phone_number.message}</TextError>}
      </WrapInput>
      <WrapInput>
        <UncontrolInputPassword {...register('password')} placeholder="Password" />
        {errors.password && <TextError>{errors.password.message}</TextError>}
      </WrapInput>
      <WrapInput>
        <UncontrolInputPassword {...register('repassword')} placeholder="Confirm password" />
        {errors.repassword && <TextError>{errors.repassword.message}</TextError>}
      </WrapInput>
      <WrapInput>
        <Button type="submit" onClick={() => console.log('clicking')}>
          Sign up
        </Button>
      </WrapInput>
    </form>
  )
}

export default SignUpForm
