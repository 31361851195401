import React, { memo, useCallback, useEffect, useState } from 'react'
import TimeSchedulev2 from 'src/components/TimeSchedulev2'
import TimeSchedule from '../../../../components/TimeSchedule'
import { map } from '../../../../components/TimeSchedule/components/utils'

function TimeScheduleContent({
  deviceDetail,
  selectedDay,
  onClickPlaylist,
  onClickMenu,
  onClickSchedule,
  onClickConflict,
  onClickPriorityFirst,
  isMobile,
  onChangeOrder,
}) {
  const [playlist, setPlaylist] = useState([])
  const [statusLoadSchedule, setstatusLoadSchedule] = useState(0)
  const [daysSelect, setdaysSelect] = useState(selectedDay)

  useEffect(() => {
    if (deviceDetail?.playlists) {
      setPlaylist(deviceDetail?.playlists)
    }
  }, [deviceDetail?.playlists])

  useEffect(() => {
    if (selectedDay) {
      setdaysSelect(selectedDay)
    }
  }, [selectedDay])

  const _parseTimePlaylists = (playlists) => {
    // parse time pattern
    let isExistsTimepattern = false
    const daysSelect = {}
    map(playlists, (playlist, ind) => {
      if (isExistsTimepattern) {
        return false
      }
      if (typeof playlist !== 'object' || playlist === null) {
        return
      }

      const time_pattern = playlist.time_pattern
      if (!Array.isArray(time_pattern) || time_pattern.length < 1) {
        // all date and time
        // const today = new Date();
        const start_time = 0
        const end_time = 1439
        map(this._listShortDays, (v, i) => {
          if (!Array.isArray(daysSelect[i])) {
            daysSelect[i] = []
          }
          daysSelect[i].push({
            start_time,
            end_time,
            ind,
          })
        })
      } else {
        map(time_pattern, (tp) => {
          if (typeof tp !== 'object' || tp === null) {
            return
          }

          const { start_time, days, end_time } = tp

          map(days, (v) => {
            if (v < 0 || v > 6) {
              return
            }

            if (!Array.isArray(daysSelect[v])) {
              daysSelect[v] = []
            } else {
              // kiem tra co bi xung dot, neu co thi bo qua luon
              // map(daysSelect[v], inDay => {
              //   if (
              //     end_time < inDay.start_time ||
              //     start_time > inDay.end_time
              //   ) {
              //     return;
              //   }
              //   isExistsTimepattern = true;
              //   return false;
              // })
              // bo qua phan detect trung thoi gian
              // if (isExistsTimepattern) {
              //   return false
              // }
            }

            daysSelect[v].push({
              start_time,
              end_time,
              ind,
            })
          })
        })
      }
    })
    if (isExistsTimepattern) {
      return { daysSelect: false }
    }

    return { daysSelect }
  }

  const _handleAction = useCallback(
    (dataAction) => {
      if (playlist.length > 0) {
        const { action, subAction, data } = dataAction
        if (action === 'addPlaylist') {
          const value = data[0]
          let isExists = false
          map(playlist, (p, index) => {
            if (p.id !== value.id) {
              return
            }
            isExists = true
            return false
          })

          if (isExists) {
            // this.props.dispatch(openNotiBox('duplicate playlist: ' + value.name))
            return
          }

          // co quyen trung playlist, nen se khong check
          const newPlaylist = [
            ...playlist,
            {
              ...value,
              main_image: value.image,
            },
          ]
          // const { daysSelect } = _parseTimePlaylists(playlist)

          if (daysSelect === false) {
            // this.props.dispatch(openNotiBox('playlist with duplicate time pattern (3)'))
            return
          }

          setstatusLoadSchedule(1)
          // statusLoadSchedule: 1,
          // setdaysSelect(daysSelect),
          setPlaylist(newPlaylist)
          // this.props.handleChoiseImage(value);

          // this.props.route.addEventListener('beforeunload', this._checkBeforeUnload, {
          //   target: 'devicedetail',
          // })
        } else if (action === 'removePlaylist') {
          const value = data[0]
          let isExists = false
          const newPlaylist = []
          map(playlist, (p, index) => {
            if (p.id !== value.id) {
              newPlaylist.push({ ...p })
              return
            }
            isExists = true
            // return false
          })

          if (!isExists) {
            // this.props.dispatch(openNotiBox('remove playlist: ' + value.name))
            return
          }

          // const { daysSelect } = this._parseTimePlaylists(playlist)

          if (daysSelect === false) {
            // this.props.dispatch(openNotiBox('playlist with duplicate time pattern (3)'))
            return
          }

          // this.props.route.addEventListener('beforeunload', this._checkBeforeUnload, {
          //   target: 'devicedetail',
          // })

          // this.setState(
          //   {
          //     statusLoadSchedule: 0,
          //   },
          //   () => {
          //     this.setState({
          //       statusLoadSchedule: 1,
          //       daysSelect,
          //       playlist,
          //     })
          //   }
          // )
          setPlaylist(newPlaylist)

          // this.props.handleChoiseImage(value);
        } else if (action === 'reloadPlaylist') {
          if (subAction === 'changeSchedule') {
            // tien hanh bo sung lai schdule lich moi
            const value = data[0]
            let isExists = false

            const newPlaylist = []
            map(playlist, (p, index) => {
              if (p.id !== value.id) {
                newPlaylist.push({ ...p })
                return
              }

              newPlaylist.push({
                ...p,
                ...value,
              })
              isExists = true
              // return false
            })

            if (!isExists) {
              // this.props.dispatch(openNotiBox('not found playlist: ' + value.name))
              return
            }
            // const { daysSelect } = this._parseTimePlaylists(playlist)

            if (daysSelect === false) {
              // this.props.dispatch(openNotiBox('playlist with duplicate time pattern (3)'))
              return
            }

            // this.setState(
            //   {
            //     statusLoadSchedule: 0,
            //   },
            //   () => {
            //     this.setState({
            //       statusLoadSchedule: 1,
            //       daysSelect,
            //       playlist,
            //     })
            //   }
            // )
            setPlaylist(newPlaylist)
            return
          }
          // reload data
          // this._loaded = true
          // this._getData()
        } else if (action === 'changeOrderPlaylist') {
          let newDeviceDetail = { ...deviceDetail }
          newDeviceDetail.playlists = data
          // setPlaylist(data)
          onChangeOrder?.(newDeviceDetail)
          // this.props.route.addEventListener('beforeunload', this._checkBeforeUnload, {
          //   target: 'devicedetail',
          // })
        }
      }
    },
    [daysSelect, deviceDetail, onChangeOrder, playlist]
  )

  const _checkBeforeUnload = (val) => {
    // Alert.confirm('Schedule not save, Are you sure?', (isOk) => {
    //   if (isOk !== true) {
    //     return;
    //   }
    //   this.props.route.removeEventListener('beforeunload', {
    //     target: 'devicedetail',
    //   })
    //   if (val.f === 'setLocation') {
    //     this.props.route.setLocation({...val.data});
    //   } else if (val.f === 'back') {
    //     this.props.onOpenBack(
    //       val.data.numberBack,
    //       val.data.isCheckModal,
    //     );
    //   } else {
    //     this.props.dispatch(openNotiBox('ignore save'))
    //   }
    // });
  }

  const typeDevice = isMobile ? 'mobile' : 'desktop'

  if (playlist.length === 0) return null

  return (
    <TimeSchedulev2
      isMobile={isMobile}
      typeDevice={typeDevice}
      data={playlist || []}
      click={(v) => {
        onClickPlaylist?.(v)
      }}
      clickMenu={(v) => onClickMenu?.(v)}
      clickSchedule={(v) => onClickSchedule?.(v)}
      clickConflict={(v) => onClickConflict?.(v)}
      clickPriorityFirst={(v) => onClickPriorityFirst?.(v)}
      onChangePosition={(data) => {
        _handleAction({
          action: 'changeOrderPlaylist',
          data,
        })
      }}
      defaultDate={daysSelect}
    />
  )
}

export default memo(TimeScheduleContent)
