export const convertObjectToArray = (obj, isChild) => {
  if (!obj) return []
  if (Array.isArray(obj)) return []
  if (Object.keys(obj).length <= 0) return []
  return Object.keys(obj).reduce((cur, key) => {
    cur.push({
      id: key,
      title: isChild ? `---${obj[key].title}` : obj[key].title,
      children: convertObjectToArray(obj[key].children, true),
      isParent: !isChild,
    })
    return cur
  }, [])
}

export const getListAllCategory = (data, output = []) => {
  data.forEach((element) => {
    output.push({ id: element.id, title: element.title, isParent: element.isParent, parentId: element.parentId })
    if (element.children && element.children.length > 0) return getListAllCategory(element.children, output)
  })

  return output
}
