import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ScheduleList from 'src/components/ScheduleList'
import Tooltipv2 from 'src/components/ToolTipv2'
import UrlAction from 'src/redux/Url/action'
import styled from 'styled-components'
import { playlistApi } from '../../../../../../api/playlistApi'
import ButtonOutLine from '../../../../../../components/ButtonOutLine'
import CustomModalConfirm from '../../../../../../components/CustomModalConfirm'
import InputText from '../../../../../../components/InputText'
import { handleError, handleSuccess } from '../../../../../../utils/handle-error'
import { serialize } from '../../../../../../utils/serialize'
import { Root } from '../../../../../Home/components/ContentHomePage'

function PlaylistSetting({ playlistDetail, onUpdatePlaylistSuccess }) {
  const dispatch = useDispatch()
  const navigator = useNavigate()
  const [allTime, setAllTime] = useState(true)
  const [listSchedule, setListSchedule] = useState([])

  const [namePlaylist, setNamePlaylist] = useState('')
  const [status, setStatus] = useState(1)

  const [isShowModal, setIsShowModal] = useState(false)

  const { durations, files, info, playlists, times } = playlistDetail

  useEffect(() => {
    const idPlaylist = info?.id
    setNamePlaylist(info?.name || '')
    setStatus(info?.status || 0)

    if (idPlaylist) {
      playlistApi
        .getPlaylistDetail(idPlaylist)
        .then((res) => {
          // if (!res.success) {
          //   throw res
          // }
          const { data } = res

          if (data?.times.length) {
            setListSchedule(
              data?.times.map((elm, index) => {
                return {
                  id: index,
                  ...elm,
                }
              }) || []
            )
            setAllTime(false)
          } else {
            setListSchedule([])
            setAllTime(true)
          }
        })
        .catch((err) => {
          if (err) {
            handleError('getPlaylistDetail - 3', err || 'Error')
          }
        })
    }
  }, [playlistDetail])

  const handleSaveInfo = () => {
    if (!namePlaylist) {
      return handleError('Name playlist is empty!')
    }
    // window.removeChange('playlist-detail-info')
    dispatch(UrlAction.resetHaveChange('playlist-detail-info'))
    handleUpdateData({ name: namePlaylist, status, order: 0 })
  }

  const handleUpdateData = (data) => {
    playlistApi
      .updatePlaylist(info?.id, data)
      .then((res) => {
        onUpdatePlaylistSuccess(data)
        handleSuccess('Save success!')
      })
      .catch(handleError)
  }

  const handleClickBtnAllTime = useCallback(() => {
    if (allTime) {
      setAllTime(false)
      return
    }
    setAllTime(true)
  }, [allTime])

  const handleSaveSchedule = useCallback(
    (data) => {
      playlistApi
        .updatePlaylist(info?.id, data)
        .then((res) => {
          if (!res.success) {
            throw res
          }
          handleSuccess('Success')
          // window.removeChange('device-setting-menuright')
          dispatch(UrlAction.resetHaveChange('device-setting-menuright'))
          // onUpdatePlaylistSuccess?.();
        })
        .catch(handleError)
    },
    [info?.id]
  )

  const handleDeletePlaylist = () => {
    setIsShowModal(false)
    playlistApi
      .deletePlaylist(info?.id)
      .then((res) => {
        if (!res.success) {
          throw res
        }
        handleSuccess('Delete success!')
        navigator('/playlist')
      })
      .catch(handleError)
  }

  return (
    <>
      <Root>
        <Wrapper>
          <Content>
            <TitleDetail>
              <span>Delete Playlist</span>
              <Tooltipv2 overlay="Delete playlist" placement="top">
                <FontAwesomeIcon icon={faTrashCan} onClick={() => setIsShowModal(true)} />
              </Tooltipv2>
            </TitleDetail>
            <InputText
              placeholder={'Your playlist'}
              valueDefault={namePlaylist}
              onChange={(e) => {
                dispatch(UrlAction.postHaveChange('playlist-detail-info', 'update-playlist-info', 'Playlist Setting'))
                setNamePlaylist(e)
              }}
            />

            <WrapButtonBlock status={status}>
              <ButtonOutLine
                title="Block"
                onClick={() => {
                  dispatch(
                    UrlAction.postHaveChange('playlist-detail-info', 'update-playlist-status', 'Playlist Setting')
                  )

                  setStatus(0)
                }}
              />
              <ButtonOutLine
                title="Unblock"
                onClick={() => {
                  dispatch(
                    UrlAction.postHaveChange('playlist-detail-info', 'update-playlist-status', 'Playlist Setting')
                  )

                  setStatus(1)
                }}
              />
            </WrapButtonBlock>
            <WrapButton>
              <ButtonOutLine title="Save Info" onClick={handleSaveInfo} />
            </WrapButton>
          </Content>
          <ScheduleList
            allTime={allTime}
            listSchedule={listSchedule}
            onClickBtnAllTime={handleClickBtnAllTime}
            onSaveSchedule={handleSaveSchedule}
          />
        </Wrapper>
      </Root>

      <CustomModalConfirm
        title={'Are you sure you want to detele the Playlist?'}
        isShow={isShowModal}
        onYes={handleDeletePlaylist}
        onClose={() => setIsShowModal(false)}
      />
    </>
  )
}

export default PlaylistSetting

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #ccc;

  :last-child {
    border-bottom: none;
  }

  .styled-input-custom {
    background: none;
    border: 1px solid #d9af93;
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const TitleDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
  margin-bottom: 8px;

  svg {
    height: 22px;
    margin-left: 12px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }
`

const WrapButtonBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8px 0;

  .styled_button {
    width: 100px;
    margin: 0 4px;

    :first-child {
      background: ${({ status }) => (status === 0 ? ' #FF6600' : 'none')};
      color: ${({ status }) => (status === 0 ? ' #fff' : 'black')};
    }

    :last-child {
      background: ${({ status }) => (status === 1 ? ' #FF6600' : 'none')};
      color: ${({ status }) => (status === 1 ? ' #fff' : 'black')};
    }

    :hover {
      background: #ff6600;
      color: #fff;
    }
  }
`

const WrapButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  .button-save-schedule {
    font-weight: 500;
  }
`

const TitleSchedule = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
`

const WrapIconPlus = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(255, 102, 0);
  border-radius: 50%;
  color: rgb(255, 102, 0);
  margin-left: 8px;
  cursor: pointer;
`

const BtnAllTime = styled.div`
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #d9af93;
  font-weight: 500;
  font-size: 16px;
  border-radius: 25px;
  padding: 0 14px;
  margin-top: 8px;
  cursor: pointer;

  svg {
    height: 20px;
    margin-right: 8px;
    color: rgb(255, 102, 0);
  }
`
