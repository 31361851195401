export function serialize(obj) {
    if (typeof obj !== 'object') {
      return ((obj === null) ? '' : obj.toString());
    }
  
    let query = '', name, value, fullSubName, subName, subValue, innerObj, i; // eslint-disable-line one-var
  
    for (name in obj) {
      if (!Object.hasOwnProperty.call(obj, name)) {
        continue;
      }
      value = obj[name];
      if (value instanceof Array) {
        let hasValue = false;
        for (i in value) {
          if (Object.hasOwnProperty.call(value, i)) {
            hasValue = true;
            subValue = value[i];
            fullSubName = name + '[' + i + ']';
            innerObj = {};
            innerObj[fullSubName] = subValue;
            query += serialize(innerObj) + '&';
          }
        }
        if (hasValue === false) {
          query += name + '=&';
        }
      } else if (value instanceof Object) {
        let hasValue = false;
        for (subName in value) {
          if (Object.hasOwnProperty.call(value, subName)) {
            hasValue = true;
            subValue = value[subName];
            fullSubName = name + '[' + subName + ']';
            innerObj = {};
            innerObj[fullSubName] = subValue;
            query += serialize(innerObj) + '&';
          }
        }
        if (hasValue === false) {
          query += name + '=&';
        }
      } else if (typeof value !== 'undefined' && value !== null) {
        query += name + '=' + encodeURIComponent(value) + '&'; // query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
      }
    }
    return query.length ? query.substr(0, query.length - 1) : query;
  }
  