import SelectedImage from '../../../page/File/components/ContentFile/components/SelectdImage'
import { WrapperDnd } from './styled'
import { useDrag, useDrop } from 'react-dnd'
import React from 'react'

const DND_ITEM_TYPE = 'photo'

const StackItem = ({
  isMobile,
  val,
  index,
  moveRow,
  onSelectedItem,
  onLoading,
  dragDrop,
  CustomRender,
  fromPage,
  onCheckFavourite,
}) => {
  const dropRef = React.useRef(null)
  const dragRef = React.useRef(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.id
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
    // collect: (monitor) => {
    //   const item = monitor.getItem();
    //   if (item) {
    //     // console.log("collect", item);
    //   }
    //   return {
    //     isOver: monitor.isOver(),
    //     canDrop: monitor.canDrop(),
    //   };
    // },
  })

  // const [{ isDragging, opacity }, drag, preview] = useDrag({
  //   type: DND_ITEM_TYPE,
  //   item: () => {
  //     return { id: val.id, index }
  //   },
  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging(),
  //     opacity: monitor.isDragging() ? 0.4 : 1,
  //   }),
  //   end: (item, monitor) => {
  //     const { id: droppedId, index } = item
  //     const didDrop = monitor.didDrop()
  //     if (!didDrop) {
  //       moveRow(droppedId, index)
  //     }
  //   },
  // })

  // preview(drop(dropRef))
  // drag(dragRef)

  return (
    <WrapperDnd key={val?.id + index}>
      <CustomRender
        // opacity={opacity}
        margin={'2px'}
        index={index}
        photo={val}
        // left={left}
        // top={top}
        onSelectedItem={(v) => {
          onSelectedItem?.(v)
        }}
        onLoading={onLoading}
        selected={val?.select}
        truncate={3}
        fromPage={fromPage}
        dragRef={dragRef}
        // style={{ opacity, cursor: isDragging ? 'grabbing' : 'grab' }}
        onCheckFavourite={onCheckFavourite}
      />
    </WrapperDnd>
  )
}

export default React.memo(StackItem)
