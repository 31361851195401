import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ViewGrid from './components/ViewGrid'
import ViewList from './components/ViewList'
import ViewTable from './components/ViewTable'
import { useNavigate } from 'react-router-dom'

function ContentArticle({ statusView, isMobile, listArticle, onSelectedItem }) {
  const navigate = useNavigate()

  return (
    <Root>
      {/* {statusView === 0 && <ViewGrid listArticle={listArticle} onSelectedItem={onSelectedItem} />} */}

      {/* {statusView === 1 && <ViewList listArticle={listArticle} onSelectedItem={onSelectedItem} />}

      {statusView === 2 && <ViewTable listArticle={listArticle} onSelectedItem={onSelectedItem} />} */}
    </Root>
  )
}

export default ContentArticle
const Root = styled.div`
  flex: 1;
  display: flex;
  margin-top: 20px;
`
