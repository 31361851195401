import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { Root } from '../../Home/components/ContentHomePage'
import MenuLeft from './components/MenuLeft'
import DatePicker from 'react-datepicker'
import { devicesApi } from '../../../api/devicesApi'
import { handleError, handleSuccess } from '../../../utils/handle-error'
import TimeScheduleContent from './components/TimeSchedule'
import { serialize } from '../../../utils/serialize'
import { playlistApi } from '../../../api/playlistApi'
import CustomModalConfirm from '../../../components/CustomModalConfirm'
import update from 'immutability-helper'
import ScrollToTop from 'src/components/ScrollToTop'
import useUnload from 'src/hooks/useUnload'
import { useDispatch } from 'react-redux'
import UrlAction from 'src/redux/Url/action'
import { getNumberFromTime } from 'src/utils/render-time'

function Detail({ isMobile }) {
  const Param = useParams()
  const detailId = Param?.detailId
  const pathName = Param?.pathName || 'setting'

  const navigate = useNavigate()

  const [deviceDetail, setDeviceDetail] = useState({})
  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [typeContentMenuLeft, setTypeContentMenuLeft] = useState('setting')
  const [startDate, setStartDate] = useState('')
  const [playlistSelect, setPlaylistSelect] = useState({})
  const [isShowModal, setIsShowModal] = useState(false)
  const [deviceDetailFirst, setDeviceDetailFirst] = useState({})
  const refScroll = useRef(null)
  const [textEnd, setTextEnd] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    setShowMenuDetail(false)
  }, [isMobile])

  useEffect(() => {
    // window.removeChange("")
    handleGetDeviceDetail(detailId)
    setTextEnd('- END -')
  }, [])

  const handleGetDeviceDetail = (id) => {
    devicesApi
      .getDeviceDetail(id)
      .then((res) => {
        // if (!res.success) {
        //   throw res
        // }
        const { data } = res
        const { info, playlists, files, times } = data

        const playlist = []
        playlists.forEach((d, index) => {
          let image = ''
          try {
            image = files[d.id].path_thumbnail
          } catch (e) {}

          const time_pattern = times[d.id] || []

          const colorItem = typeof d.color_theme === 'string' ? JSON.parse(d.color_theme) : {}
          playlist.push({
            id: d.id,
            name: d.name,
            order: d.order,
            status: d.status,
            time_pattern: time_pattern,
            // priority: index === 2 ? 1 : 0,
            priority: d.priority,
          })
        })

        setDeviceDetail({ ...data, playlists: playlist })
        setDeviceDetailFirst({ ...data, playlists: playlist })
      })
      .catch((error) => {
        if (error) {
          handleError('getDeviceDetail', error)
        }
      })
  }

  const handleChangeOrder = useCallback((v) => {
    setDeviceDetail(v)
  }, [])

  const handleClickPriorityFirst = useCallback(
    (v) => {
      const findPlaylist = deviceDetail.playlists.map((p) => {
        if (p.id === v.id) {
          return {
            ...v,
            priority: v.priority === 0 ? 1 : 0,
          }
        }
        return { ...p, priority: 0 }
      })
      const playlists = { ...deviceDetail, playlists: findPlaylist }
      setDeviceDetail(playlists)
    },
    [deviceDetail]
  )

  const handleClickPlaylist = (v) => {
    navigate('setting-playlist')
    if (v.id !== playlistSelect.id) setPlaylistSelect(v)
    setShowMenuDetail(true)
  }

  const handleClickMenu = (v) => {
    navigate('show-playlist-menu')
    if (v.id !== playlistSelect.id) setPlaylistSelect(v)
    setShowMenuDetail(true)
  }

  // const handleClickMenu = useCallback(
  //   (v) => {
  //     console.log('melu')
  //     // navigate('show-playlist-menu')
  //     // if (v.id !== playlistSelect.id) setPlaylistSelect(v)
  //     // setShowMenuDetail(true)
  //   },
  //   [playlistSelect]
  // )

  const debounce = (func) => {
    let timer
    return function (...args) {
      const context = this
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 500)
    }
  }

  const optimizedFnClickMenu = useCallback(debounce(handleClickMenu), [playlistSelect])
  const optimizedFnClickPlaylist = useCallback(debounce(handleClickPlaylist), [playlistSelect])

  const handleClickSchedule = useCallback(
    (v) => {
      navigate('setting-playlist')
      if (v.id !== playlistSelect.id) setPlaylistSelect(v)
      setShowMenuDetail(true)
    },
    [playlistSelect]
  )

  const handleClickConflict = useCallback(
    (v) => {
      navigate('setting-playlist')
      if (v.id !== playlistSelect.id) setPlaylistSelect(v)
      setShowMenuDetail(true)
    },
    [playlistSelect]
  )

  const handleRemovePlaylist = (id) => {
    const playlists = deviceDetail.playlists.filter((elm) => elm.id !== id)

    dispatch(UrlAction.postHaveChange('device-detail-content'))
    // dispatch(UrlAction.postHaveChange("device-detail-content", "remove-playlist", "remove playlist from device")
    setDeviceDetail({
      ...deviceDetail,
      playlists,
    })
    navigate('setting')
  }

  const handleSaveAndPublish = () => {
    const listPlayListID = deviceDetail.playlists.map((elm) => elm.id)
    const playlist_priority = deviceDetail.playlists.find((elm) => {
      if (elm.priority === 1) return elm
      return undefined
    })?.id
    const data = { list: listPlayListID, playlist_priority: [playlist_priority] }

    playlistApi
      .savePublishPlayList(detailId, data)
      .then((res) => {
        // if (!res.success) {
        //   throw res
        // }
        dispatch(UrlAction.resetHaveChange('device-detail-content'))
        // window.removeChange("device-detail-content")
        // window.removeChange("device-remove-playlist-menuleft")

        handleSuccess('Publish Success!')
      })
      .catch((err) => {
        if (err) {
          handleError('savePublishPlayList', err)
        }
      })
  }

  const handleAcitonSuccess = () => {
    navigate('setting-playlist')
    setShowMenuDetail(false)
  }

  const handleAddPlaylist = (playlist) => {
    const playlistId = playlist?.id
    if (!playlist.priority) {
      playlist.priority = 0
    }
    const itemDuplicate = deviceDetail.playlists?.filter((elm) => elm.id === playlistId) || []

    if (itemDuplicate.length) {
      return handleError(`Duplicate playlist ${playlist?.name}`)
    }
    const newPlaylist = [...deviceDetail.playlists, playlist]
    dispatch(UrlAction.postHaveChange('device-detail-content'))

    // dispatch(UrlAction.postHaveChange("device-detail-content", "deice-detail-addplaylist", "Add Playlists")

    setDeviceDetail({ ...deviceDetail, playlists: newPlaylist })
    handleSuccess('Add Playlist Success !')
    // deviceDetail.playlists.push(playlist)
    // setDeviceDetail({ ...deviceDetail })
  }

  const handleRepacleDeviceIdSuccess = (id) => {
    const newInfo = { ...deviceDetail.info }
    newInfo.uuid = id.replace(/-/g, '')
    setDeviceDetail({ ...deviceDetail, info: newInfo })
  }

  const handleUpdateTimeSchedule = (data) => {
    if (data.info.times != '-1') {
      data.info.times[0].start_time = getTimeNumber(data.info.times[0].start_time)
      data.info.times[0].end_time = getTimeNumber(data.info.times[0].end_time)
      data.info.times[0].start_date = 0
      data.info.times[0].end_date = 0
    } else {
      data.info.times = []
    }

    // console.log('aaasasdd -->', aaasasdd)
    const playlist = [...deviceDetail.playlists]
    const newPlaylist = playlist.map((elm) => {
      if (elm.id === data.id) {
        elm.time_pattern = data.info.times
        return elm
      }
      return elm
    })

    setDeviceDetail({ ...deviceDetail, playlists: newPlaylist })
  }

  const getTimeNumber = (time) => {
    const t = time.slice(0, 5)
    const a = time.split(':')

    const seconds = +a[0] * 60 + +a[1]
    return seconds
  }

  const renderScrollToTOp = useCallback(() => {
    let flag = true
    if (isMobile && showMenuDetail) {
      flag = false
    }

    return flag ? <ScrollToTop refScroll={refScroll} /> : ''
  }, [showMenuDetail, isMobile])

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <ButtonCustom onClick={onClick}>{value ? value : 'select date'}</ButtonCustom>
  ))

  return (
    <>
      <WrapContent>
        <WrapRoot>
          <Root>
            <Content ref={refScroll}>
              <Title>
                {deviceDetail?.info?.name ?? 'DEVICE SETTINGS'}
                <WrapIcon
                  onClick={() => {
                    setShowMenuDetail(true)
                    navigate('setting')
                  }}
                >
                  <FontAwesomeIcon icon={faPen} />
                </WrapIcon>
              </Title>
              <WrapButtonTop>
                <ButtonAdd>
                  <ButtonOutLine
                    title={'Add Playlist'}
                    onClick={() => {
                      setShowMenuDetail(true)
                      navigate('add_playlist')
                    }}
                  />
                  <IconPlus>
                    <FontAwesomeIcon icon={faPlus} />
                  </IconPlus>
                </ButtonAdd>
                <ButtonSave>
                  <ButtonOutLine title={'SAVE & PUBLISH'} onClick={() => setIsShowModal(true)} />
                </ButtonSave>
              </WrapButtonTop>

              {/* <Label>Schedule Playlists</Label> */}
              {/* <WrapButtonSchedule isMobile={isMobile}>
                <WrapBtnTime>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date)
                    }}
                    customInput={<ExampleCustomInput />}
                  />
                </WrapBtnTime>
              </WrapButtonSchedule> */}

              <WrapTimeSchedule>
                <TimeScheduleContent
                  isMobile={isMobile}
                  selectedDay={startDate}
                  deviceDetail={deviceDetail}
                  onChangeOrder={handleChangeOrder}
                  onClickPriorityFirst={handleClickPriorityFirst}
                  onClickPlaylist={optimizedFnClickPlaylist}
                  onClickMenu={optimizedFnClickMenu}
                  onClickSchedule={handleClickSchedule}
                  onClickConflict={handleClickConflict}
                />
              </WrapTimeSchedule>
              <WrapEnd> {textEnd}</WrapEnd>
            </Content>
            {renderScrollToTOp()}
          </Root>
        </WrapRoot>
        <MenuLeft
          deviceDetail={deviceDetail}
          detailId={detailId}
          playlistSelect={playlistSelect}
          isMobile={isMobile}
          showMenuDetail={showMenuDetail}
          onClose={() => setShowMenuDetail(false)}
          typeContentView={pathName}
          onUpdatePlaylistSuccess={(id) => handleUpdateTimeSchedule(id)}
          onRemovePlaylist={handleRemovePlaylist}
          onSelectItemAdd={handleAddPlaylist}
          onSuccess={handleAcitonSuccess}
          onReplaceDeviceIdSuccess={handleRepacleDeviceIdSuccess}
        />
      </WrapContent>

      <CustomModalConfirm
        isShow={isShowModal}
        onYes={() => {
          handleSaveAndPublish()
          setIsShowModal(false)
        }}
        onClose={() => {
          setIsShowModal(false)
        }}
      />
    </>
  )
}

export default Detail

const WrapContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`
const WrapRoot = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  font-size: 22px;
  color: #999999;
  margin-bottom: 30px;
`

const WrapIcon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 102, 0);
  margin-left: 12px;

  cursor: pointer;

  svg {
    height: 24px;
  }
`

const WrapButtonTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ButtonAdd = styled.div`
  position: relative;
  width: 300px;
`

const IconPlus = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 11px;
  right: 88px;

  svg {
    width: 100%;
    height: 100%;
    color: #ff6600;
  }
`

const ButtonSave = styled.div`
  width: 300px;
  margin-top: 16px;
`

const Label = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-top: 12px;
  margin-bottom: 8px;
`

const WrapButtonSchedule = styled.div`
  position: absolute;
  top: 190px;
  width: ${({ isMobile }) => (isMobile ? '150px' : '200px')};
  display: flex;
  justify-content: center;
  z-index: 90;
  color: #000;
  background-color: #fff;
`

const WrapBtnTime = styled.div`
  width: ${({ isMobile }) => (isMobile ? '150px' : '200px')};
  display: flex;
  /* margin-right: 12px; */

  .react-datepicker-popper {
    z-index: 999;
  }
`

const WrapTimeSchedule = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-top: 20px;

  /* div {
    width: 100%;
  } */
`
const ButtonCustom = styled.button`
  width: 200px;
  height: 60px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #000;
`

const WrapEnd = styled.div`
  margin-top: 40px;
  font-weight: 500;
  text-align: center;
`
