import { faCirclePlay, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ButtonOutLine from '../../../../../../../components/ButtonOutLine'
import ModalPreviewItem from './ModalPreviewItem'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
// import required modules
import Image from 'src/components/Image'
import { Navigation } from 'swiper'
import SwiperSlideList from 'src/components/SwiperSlideList/SwiperSlideList'
import ModalPreviewFile from 'src/components/ModalPreviewFile/ModalPreviewFile'
import { WrapTimePicker } from 'src/components/SchedulePower'
import TimePicker from 'rc-time-picker'
import { getNumberFromTime, getTimeFromNumber } from 'src/utils/render-time'
import moment from 'moment'
import UrlAction from 'src/redux/Url/action'
import { useDispatch } from 'react-redux'

function ViewDetail({
  itemSelect,
  onBlockFile,
  onUnblockFile,
  onRemoveFile,
  onSaveFile,
  moveItemToTop,
  onChangeDuration,
  onPreview,
  listFile,
  onChangeSlide,
  multiSelect,
}) {
  const [showModalPreview, setShowModalPreview] = useState(false)
  const [name, setName] = useState('')
  const [duration, setDuration] = useState()
  const [isShowBtnBlock, setIsShowBtnBlock] = useState(false)
  const [isShowBtnUnBlock, setIsShowBtnUnBlock] = useState(false)
  const [indexSelectedSlide, setIndexSelectSlide] = useState(-1)
  const [startTime, setStartTime] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    let block = false
    let unBlock = false

    if (itemSelect.length) {
      itemSelect.map((elm) => {
        if (elm.status === 1) {
          block = true
        }
        if (elm.status === 0) {
          unBlock = true
        }
      })
    }

    // console.log('itemSelect[0]?.duration ------> ', itemSelect[0]?.duration)
    const newName = itemSelect[0]?.name
    setDuration(itemSelect[0]?.duration)
    setName(newName)
    setIsShowBtnBlock(block)
    setIsShowBtnUnBlock(unBlock)
    setStartTime(getTimeFromNumber(itemSelect[0]?.duration))
  }, [itemSelect])

  useEffect(() => {
    const idx = listFile.findIndex((f) => f.idv4 === itemSelect[0]?.idv4)
    setIndexSelectSlide(idx)
  }, [listFile, itemSelect])

  // const handleChangeDuration = (e) => {
  //   const value = e.target?.value
  //   setDuration(value)
  //   onChangeDuration?.(value)
  // }

  const handleChangeDuration = (v) => {
    const value = v.target?.value
    setDuration(value)
    onChangeDuration?.(value)
    // const d = getNumberFromTime(v)
    // setStartTime(v)
    // onChangeDuration?.(d)
  }

  const handleShowPreview = (preview) => {
    setShowModalPreview(preview)
    onPreview?.(preview)
  }

  return (
    <Root>
      <WrapContent>
        {multiSelect && <SelectMultiItem>Select {itemSelect?.length} items</SelectMultiItem>}
        {itemSelect?.length === 1 && !multiSelect && (
          <>
            <WrapImage>
              <SwiperSlideList
                fromPage="from_playlist_detail_item"
                listDataSource={listFile}
                selectedItem={itemSelect}
                onChangeSlide={onChangeSlide}
                onClickSlideItem={() => handleShowPreview(true)}
                slidesPerView={1}
                isShowIconPreview={true}
                indexSelectedSlide={indexSelectedSlide}
              />
            </WrapImage>
          </>
        )}

        <WrapNameShow>
          <span>{itemSelect[0]?.name}</span>
        </WrapNameShow>

        <WrapButton>
          <ButtonOutLine title="Move to Top" onClick={(items) => moveItemToTop?.(itemSelect)} />
        </WrapButton>

        {isShowBtnBlock && (
          <WrapButton>
            <ButtonOutLine title="Block Files from Playlist" onClick={onBlockFile} />
          </WrapButton>
        )}

        {isShowBtnUnBlock && (
          <WrapButton>
            <ButtonOutLine title="Unblock Files from Playlist" onClick={onUnblockFile} />
          </WrapButton>
        )}

        <WrapButton>
          <ButtonOutLine title="Remove File from Playlist" onClick={onRemoveFile} />
        </WrapButton>

        {itemSelect?.length === 1 && (
          <WrapButton>
            <Input
              type="number"
              placeholder="Duration, default 5 seconds"
              value={duration === 0 ? '' : duration}
              onChange={handleChangeDuration}
              min={0}
            />
          </WrapButton>
        )}

        {/* {itemSelect?.length === 1 && itemSelect[0].type === 'image' && (
          <WrapDuration>
            <WrapTimePicker>
              <TimePicker
                value={startTime ? moment(startTime) : null}
                name="duration"
                placeholder="Duration, default 5 seconds"
                showSecond={true}
                onChange={(e) => handleChangeDuration(e?._d)}
                inputReadOnly={true}
                // format="HH:mm:ss"
              />
            </WrapTimePicker>
          </WrapDuration>
        )} */}

        <WrapButton>
          <ButtonOutLine title="Save Time Duration" onClick={onSaveFile} />
        </WrapButton>

        {itemSelect?.length === 1 && (
          <WrapImgInfo>
            <Row>
              <span>Status in Playlist</span>
              <p>{itemSelect[0]?.lock || 'UnBlock'}</p>
            </Row>
            <Row>
              <span>Type</span>
              <p>{itemSelect[0]?.type}</p>
            </Row>
            <Row>
              <span>Extension</span>
              <p>
                {itemSelect[0]?.type == 'web' ? (itemSelect[0].ext == 'canv' ? 'canva' : 'youtube') : itemSelect[0].ext}
              </p>
            </Row>
            <Row>
              <span>Status</span>
              <p>{itemSelect[0]?.status === 1 ? 'Unblock' : 'Block'}</p>
            </Row>
            <Row>
              <span>Size</span>
              <p>{itemSelect[0]?.size} KB</p>
            </Row>
            <Row>
              <span>Created</span>
              <p>{itemSelect[0]?.updated_at}</p>
            </Row>
          </WrapImgInfo>
        )}
      </WrapContent>

      {showModalPreview && (
        <ModalPreviewFile
          from="from_playlist_detail_item_preview"
          listDataSource={listFile}
          selectedItem={itemSelect}
          onChangeSlide={onChangeSlide}
          onClose={() => handleShowPreview(false)}
          indexSelectedSlide={indexSelectedSlide}
        />
      )}
    </Root>
  )
}

export default ViewDetail

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const WrapContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const WrapImage = styled.div`
  position: relative;
  width: 320px;
  height: 180px;
  margin-left: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-10px);
  background-color: #ccc;
  cursor: pointer;

  & .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-prev:after {
    color: #ff6600;
    pointer-events: all;
  }

  & .swiper-button-next:after {
    color: #ff6600;
    pointer-events: all;
  }
`

const Img = styled.img`
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
`

const WrapDuration = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
`

const WrapIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(1, 1, 1, 0.6);
  border-radius: 50%;
  bottom: 4px;
  left: 4px;
`

const WrapNameShow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  span {
    font-size: 22px;
    color: #999999;
  }
`

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 42px;
  background: none;
  border: 1px solid #d9af93;
  border-radius: 6px;
  padding: 0 10px;

  :focus {
    outline: none;
  }
`

const SelectMultiItem = styled.p`
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  margin-top: 12px;
  margin-left: 4px;
  margin-bottom: 100px;
`

const WrapImgInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 8px;

  span {
    font-size: 16px;
    color: #999999;
  }

  p {
    font-size: 16px;
    font-weight: 500;
  }
`
const WrapButton = styled.div`
  width: 100%;
  margin-bottom: 12px;
`
