import React, { useCallback, useEffect, useRef } from 'react'
import StackGrid from 'react-stack-grid'
import { withSize } from 'react-sizeme'
import { DndProvider } from 'react-dnd'
import update from 'immutability-helper'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { v4 as uuidv4 } from 'uuid'

import StackItem from './StackItem'

const StackGridList = ({
  isMobile,
  dragDrop,
  handleDragDrop,
  size,
  dataSource,
  onSelectedItem,
  onLoading,
  CustomRender,
  children,
  dndLayout,
  fromPage,
  onCheckFavourite,
}) => {
  const width = size.width

  const options = {
    scrollAngleRanges: [{ start: 300 }, { end: 60 }, { start: 120, end: 240 }],
  }

  const grid = useRef(null)

  const findCard = useCallback(
    (id) => {
      const card = dataSource.filter((c) => c.id === id)[0]
      return {
        card,
        index: dataSource.indexOf(card),
      }
    },
    [dataSource]
  )

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (dragDrop) {
        const { card, index } = findCard(dragIndex)
        handleDragDrop(
          update(dataSource, {
            $splice: [
              [index, 1],
              [hoverIndex, 0, card],
            ],
          })
        )
      }
    },
    [dragDrop, dataSource, findCard, handleDragDrop]
  )

  const columnWidth = React.useMemo(() => {
    if (width < 1) {
      return 0
    }
    const numCol = Math.ceil(width / 230)
    return `${Math.ceil(100 / numCol)}%`
  }, [width])

  useEffect(() => {
    if (dataSource && dataSource.length > 0 && grid.current) {
      grid.current.updateLayout()
    }
  }, [dataSource, columnWidth])

  const stackGridProps = React.useMemo(
    () => ({
      columnWidth,
      gridRef: (ref) => {
        grid.current = ref
      },
      monitorImagesLoaded: true,
      gutterWidth: 10,
      gutterHeight: 10,
    }),
    [columnWidth]
  )

  const renderGridItem = React.useMemo(() => {
    return (
      <StackGrid {...stackGridProps}>
        {dataSource &&
          dataSource.map((item, idx) => {
            return (
              <StackItem
                key={item.id}
                isMobile={isMobile}
                dragDrop={dragDrop}
                index={idx}
                val={item}
                moveRow={moveRow}
                onSelectedItem={onSelectedItem}
                onLoading={onLoading}
                CustomRender={CustomRender}
                fromPage={fromPage}
                onCheckFavourite={onCheckFavourite}
              />
            )
          })}
      </StackGrid>
    )
  }, [CustomRender, dragDrop, isMobile, moveRow, onSelectedItem, dataSource, stackGridProps, fromPage])

  if (dndLayout)
    return (
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} options={options}>
        {renderGridItem}
      </DndProvider>
    )
  return <StackGrid {...stackGridProps}>{CustomRender ?? children}</StackGrid>
}

export default withSize()(StackGridList)
