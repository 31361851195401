import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ButtonOutLine from '../../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../../utils/handle-error'
import { Root } from '../../../Home/components/ContentHomePage'
import { paramCase } from 'change-case'
import { toNonAccentVietnamese } from '../../../../utils/remove-alias'
import SelectBox from '../SelectBox'
import categoryApi from '../../../../api/category'
import { serialize } from '../../../../utils/serialize'
import { useEffect } from 'react'

export default function UpdateCategory({
  listCategory,
  itemSelected,
  onAddSuccess,
  isMobile,
  showMenuRight,
  onCloseMenuRight,
  onRemove,
}) {
  console.log('🚀 ~ file: index.js ~ line 24 ~ itemSelected', itemSelected)
  const navigate = useNavigate()
  const [categoryName, setCategoryName] = useState('')
  const [id, setID] = useState('')
  const [parentID, setParentID] = useState('-1')
  const [urlNameMapping, setUrlNameMapping] = useState('')
  const [defaultParentCategory, setDefaultParentCategory] = useState({ title: '-Select parent category-', id: '-1' })

  useEffect(() => {
    const valueConvert = toNonAccentVietnamese(itemSelected?.title)

    if (itemSelected.parentId !== '-1') {
      const idx = listCategory.find((el) => el.id == itemSelected.parentId)
      setDefaultParentCategory({ title: idx?.title, id: idx.id })
    }
    setUrlNameMapping(paramCase(valueConvert))
    setCategoryName(itemSelected?.title)
    setID(itemSelected?.id)
  }, [itemSelected])

  const handleUpdate = () => {
    if (!categoryName) {
      return handleError('Name is required!')
    }

    const data = {
      id: id,
      title: categoryName,
      parent_id: parentID,
      priority: 2,
    }

    categoryApi
      .updateCategory(data)
      .then((res) => {
        if (res.success) {
          setParentID('-1')
          setCategoryName('')
          setUrlNameMapping('')
          onAddSuccess?.(data)
          handleSuccess('Successfully!')
        }
      })
      .catch((err) => handleError('updateCategory', err))
  }

  const handleChangeCategoryName = (e) => {
    const value = e.target.value
    setCategoryName(value)
    const valueConvert = toNonAccentVietnamese(value)
    setUrlNameMapping(paramCase(valueConvert))
  }

  const handleChangeParentID = (value) => {
    setParentID(value.id)
  }

  return (
    <>
      <MenuDetail mobile={isMobile} show={showMenuRight}>
        <Root>
          <Content>
            <TitleDetail>
              <span>Update Category</span>
            </TitleDetail>
            <WrapSelect>
              <SelectBox
                listOption={listCategory}
                border="#d9af93"
                onSelected={(value) => handleChangeParentID(value)}
                defaultValue={defaultParentCategory}
                maxHeight={500}
              />
            </WrapSelect>
            <WrapInput>
              <Input placeholder="Type name of category" value={categoryName} onChange={handleChangeCategoryName} />
            </WrapInput>
            <WrapInput>
              <Input value={urlNameMapping} disabled={true} placeholder="Your URL mapping with category name" />
            </WrapInput>
            <WrapInput>
              <ButtonOutLine title={'SAVE CHANGES'} onClick={handleUpdate} />
            </WrapInput>
            <WrapInput>
              <ButtonOutLine title={'DELETE CATEGORY'} onClick={onRemove} />
            </WrapInput>
          </Content>
        </Root>
      </MenuDetail>
      {isMobile && showMenuRight && <BackDrop onClick={onCloseMenuRight} />}
    </>
  )
}

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 92;
`

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapSelect = styled.div`
  width: 100%;
  height: 42px;
  padding: 0 10px;
  margin-top: 12px;
  /* border-radius: 6px; */
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
