import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { playerApi } from '../../../../api/playerApi'
import { handleError } from '../../../../utils/handle-error'

function LogStatus({ detailId }) {
  const [isShowLog, setIsShowLog] = useState(false)
  const [listLog, setListLog] = useState([])
  useEffect(() => {
    if (!isShowLog) return
    handleGetLog()
  }, [isShowLog])

  const handleGetLog = () => {
    playerApi
      .getListLog(detailId)
      .then((res) => {
        if (!res.success) {
          throw res
        }

        const { data } = res

        setListLog(data)
      })
      .catch((err) => {
        handleError('getListLog', err)
      })
  }

  return (
    <>
      <TitleDetail className="none-line">
        <span>Log</span>
        <WrapIcon>
          <FontAwesomeIcon icon={isShowLog ? faChevronDown : faChevronUp} onClick={() => setIsShowLog(!isShowLog)} />
        </WrapIcon>
      </TitleDetail>
      {isShowLog && (
        <>
          {listLog.map((elm) => {
            return (
              <WrapContent key={elm.id}>
                <Top>
                  <span>{elm.created_at}</span>
                  <span>{elm.code}</span>
                </Top>
                <Bottom>{elm.msg}</Bottom>
              </WrapContent>
            )
          })}
        </>
      )}
    </>
  )
}

export default LogStatus

const TitleDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
  border-bottom: 1px solid rgb(204, 204, 204);

  svg {
    height: 22px;
    margin-left: 12px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }
`

const WrapIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(255, 102, 0);
  border-radius: 50%;
  margin-left: 12px;

  svg {
    height: 18px;
    margin: 0;
    cursor: pointer;
  }
`

const WrapContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin-top: 12px;
`

const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 500;
    color: #999999;
  }
`

const Bottom = styled.p`
  width: 100%;
  padding-left: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-top: 2px;
`
