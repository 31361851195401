import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import UrlAction from 'src/redux/Url/action'
import styled from 'styled-components'
import { playlistApi } from '../../../../../api/playlistApi'
import ButtonOutLine from '../../../../../components/ButtonOutLine'
import { handleError } from '../../../../../utils/handle-error'
import { Root } from '../../../../Home/components/ContentHomePage'

function MenuLeft({ isMobile, showMenuDetail, onClose }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [name, setName] = useState('')

  const handleAddPlaylist = () => {
    if (!name) {
      return handleError('Name is required!')
    }
    playlistApi
      .createPlaylist(name)
      .then((res) => {
        dispatch(UrlAction.resetHaveChange('playlist-add-playlist'))
        const { id } = res.data
        requestAnimationFrame(() => {
          navigate(`${id}`)
        })
      })
      .catch((err) => {
        handleError('createPlaylist', err)
      })
  }

  return (
    <>
      <MenuDetail mobile={isMobile} show={showMenuDetail}>
        <Root>
          <Content>
            <TitleDetail>
              <span>Create New Playlist</span>
            </TitleDetail>
            <WrapInput>
              <Input
                placeholder="type your playlist name"
                value={name}
                onChange={(e) => {
                  dispatch(UrlAction.postHaveChange('playlist-add-playlist', 'add-playlist', 'Create New Playlist'))
                  setName(e.target.value)
                }}
              />
            </WrapInput>
            <WrapInput>
              <ButtonOutLine title={'Add PlayList'} onClick={handleAddPlaylist} />
            </WrapInput>
          </Content>
        </Root>
      </MenuDetail>
      {showMenuDetail && <BackDrop onClick={onClose} />}
    </>
  )
}

export default MenuLeft

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 92;
`

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
