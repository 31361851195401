import appConstants from '../constants'
import HttpRequest from './HttpRequest'

const authApi = Object.freeze({
  initDevice: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/init-device`,
      data,
    }),
  login: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/login`,
      data,
    }),
  activeAccount: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/active-user`,
      data,
    }),
  forgotPassword: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/forgot-password`,
      data,
    }),
  forgotPasswordActive: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/forgot-password-active`,
      data,
    }),
  register: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/register`,
      data,
    }),
  getProfile: () =>
    HttpRequest.request({
      method: 'GET',
      url: `${appConstants.apiUrl}/profile`,
    }),
  profile: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/profile`,
      data,
    }),
  logout: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${appConstants.apiUrl}/logout      `,
      data,
    }),

  // createAuthRequest: (phoneNumber) =>
  //   HttpRequest.request({
  //     method: 'POST',
  //     url: `${appConstants.apiUrl}/auth/auth-request`,
  //     params: {
  //       version: '2.3.2',
  //       dynamic_config_version: '1.0.1',
  //     },
  //     data: {
  //       phone_number: phoneNumber,
  //     },
  //   }),
  // getAuthRequest: ({ phone_number, uid }) =>
  //   HttpRequest.request({
  //     method: 'GET',
  //     url: `${appConstants.apiUrl}/auth/auth-request`,
  //     params: {
  //       phone_number,
  //       uid,
  //     },
  //   }),
  // getProfile: () =>
  //   HttpRequest.request({
  //     method: 'GET',
  //     url: `${appConstants.apiUrl}/auth/profile`,
  //   }),
  // putCancelAuthRequest: ({ phone_number, uid }) =>
  //   HttpRequest.request({
  //     method: 'PUT',
  //     url: `${appConstants.apiUrl}/auth/auth-request/cancel`,
  //     data: {
  //       phone_number,
  //       uid,
  //     },
  //   }),
})

export default authApi
