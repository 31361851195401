import React, { useState } from 'react'
import InputPassWord from '../../../components/InputPassWord'
import InputText from '../../../components/InputText'
import { Button, Label, WrapInput } from '../styled'
import styled from 'styled-components'

function SignInForm({ handleLogin }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleLogin({ email, password })
      }}
    >
      <WrapInput>
        <InputText placeholder="Email" valueDefault={email} onChange={(v) => setEmail(v)} />
      </WrapInput>
      <WrapInput>
        <InputPassWord placeholder="Password" valueDefault={password} onChange={(v) => setPassword(v)} />
      </WrapInput>
      <WrapInputRemember>
        <CheckBox type="checkbox" /> <Label>Remember account</Label>
      </WrapInputRemember>
      <WrapInput>
        <Button type="submit">Login</Button>
      </WrapInput>
    </form>
  )
}

export default React.memo(SignInForm)

const WrapInputRemember = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`
