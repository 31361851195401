import { faCircleMinus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled from 'styled-components'
import { devicesApi } from '../../../../api/devicesApi'
import ButtonOutLine from '../../../../components/ButtonOutLine'
import { changeUuid } from '../../../../utils/change-uuid'
import { handleError, handleSuccess } from '../../../../utils/handle-error'
import { serialize } from '../../../../utils/serialize'

function ReplaceDevice({ info, onClose, onReplaceDeviceIdSuccess }) {
  const [driverId, setDriverId] = useState('')

  const handleReplay = () => {
    if (!driverId) {
      return handleError('Your device ID is empty!')
    }

    const uuid = driverId
    const name = info?.name
    const fromId = info?.id
    const isDuplicate = 0
    const data = { uuid, name, fromId, isDuplicate }
    devicesApi
      .createDevice(data)
      .then((res) => {
        // if (!res.success) {
        //   throw res
        // }
        handleSuccess('Replace success!')
        onReplaceDeviceIdSuccess(uuid)
        onClose?.()
      })
      .catch((err) => {
        if (err) {
          handleError('createDevice', err)
        }
      })
  }
  return (
    <Root>
      <WrapTitleDuplicate>
        <FontAwesomeIcon icon={faCircleMinus} onClick={onClose} />
        <span>Replace for ID: {changeUuid(info?.uuid)}</span>
      </WrapTitleDuplicate>
      <WrapInput>
        <Input placeholder="your device ID" value={driverId} onChange={(e) => setDriverId(e.target?.value)} />
      </WrapInput>
      <WrapInput>
        <ButtonOutLine title={'Save Info'} onClick={handleReplay} />
      </WrapInput>
    </Root>
  )
}

export default ReplaceDevice

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TitleDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
  border-bottom: 1px solid rgb(204, 204, 204);

  svg {
    height: 22px;
    margin-left: 12px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }
`

const WrapTitleDuplicate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid rgb(255, 102, 0);
  border-radius: 25px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
  }

  svg {
    height: 24px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }

  :hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    svg {
      color: #fff;
    }
  }
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`
