import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import GlobalAction from 'src/redux/global/action'
import styled from 'styled-components'
import authApi from '../../api/auth'
import ButtonOutLine from '../../components/ButtonOutLine'
import { handleError, handleRemoveLocal, handleSuccess } from '../../utils/handle-error'
import { serialize } from '../../utils/serialize'

import { Root } from '../Home/components/ContentHomePage'

import MenuLeft from './component/MenuLeft'

function Logout({ isMobile }) {
  const navigator = useNavigate()
  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [currentPass, setCurrentPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')
  const [hiddenPass, setHiddenPass] = useState({
    newPass: true,
    confirmPass: true,
  })

  const dispatch = useDispatch()

  const handleResetPass = () => {
    if (!currentPass) {
      return handleError('Current Password is empty!')
    }
    if (!newPass) {
      return handleError('New Password is empty!')
    }

    if (newPass !== confirmPass) {
      return handleError('New Password not match')
    }

    const data = serialize({
      current_password: currentPass,
      password: newPass,
    })

    authApi
      .profile(data)
      .then((res) => {
        if (!res.success) {
          throw res
        }
        handleSuccess('Change password success!')
      })
      .catch((err) => {
        handleError('profile', err)
      })
  }

  const handleLogOut = () => {
    authApi
      .logout(serialize({ isAll: 0 }))
      .then((res) => {
        if (!res.success) {
          throw res
        }
        handleRemoveLocal()
        dispatch(GlobalAction.userAssigned('n/a'))
        navigator('/login')
      })
      .catch((err) => {
        handleError('logout', err)
      })
  }

  return (
    <>
      <WrapContent>
        <WrapRoot>
          <Root>
            <Content>
              <Title>
                Change Password
                {/* {isMobile && (
                    <WrapIcon onClick={() => setShowMenuDetail(true)}>
                      <FontAwesomeIcon icon={faPlus} />
                    </WrapIcon>
                  )} */}
              </Title>
              <WrapInput>
                <Input
                  placeholder="Current Password"
                  value={currentPass}
                  onChange={(e) => setCurrentPass(e.target.value)}
                />
              </WrapInput>
              <WrapInput>
                <Input
                  placeholder="New Password"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                  type={hiddenPass.newPass ? 'password' : 'text'}
                />
                <FontAwesomeIcon
                  icon={hiddenPass.newPass ? faEyeSlash : faEye}
                  onClick={() => {
                    setHiddenPass({
                      ...hiddenPass,
                      newPass: !hiddenPass.newPass,
                    })
                  }}
                />
              </WrapInput>
              <WrapInput>
                <Input
                  placeholder="Repeat New Password"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                  type={hiddenPass.confirmPass ? 'password' : 'text'}
                />
                <FontAwesomeIcon
                  icon={hiddenPass.confirmPass ? faEyeSlash : faEye}
                  onClick={() => {
                    setHiddenPass({
                      ...hiddenPass,
                      confirmPass: !hiddenPass.confirmPass,
                    })
                  }}
                />
              </WrapInput>
              <WrapInput>
                <ButtonOutLine title="Save Password" onClick={handleResetPass} />
              </WrapInput>
              <Version>version: 1.5</Version>
              <WrapInput>
                <ButtonOutLine title="LogOut" onClick={handleLogOut} />
              </WrapInput>
            </Content>
          </Root>
        </WrapRoot>
        <MenuLeft isMobile={isMobile} showMenuDetail={showMenuDetail} onClose={() => setShowMenuDetail(false)} />
      </WrapContent>
    </>
  )
}

export default Logout

const WrapContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`
const WrapRoot = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: #999999;
  text-transform: uppercase;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;
  position: relative;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 20px;
    cursor: pointer;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 40px 0 12px;

  :focus {
    outline: none;
  }
`

const Version = styled.p`
  width: 100%;
  text-align: center;
  font-weight: 500;
  margin-top: 8px;
`

// const WrapIcon = styled.div`
//   width: 28px;
//   height: 28px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-radius: 50%;
//   border: 1px solid rgb(255, 102, 0);
//   color: rgb(255, 102, 0);
//   cursor: pointer;
// `;

// const Item = styled.div`
//   width: 100%;
//   height: 100px;
//   display: flex;
//   align-items: center;
//   border: 1px solid #ddd;
//   border-radius: 10px;
//   padding: 10px;
//   margin-bottom: 12px;
//   cursor: pointer;

//   span {
//     font-size: 16px;
//     font-weight: 500;
//     color: rgb(134, 134, 134);
//   }
// `;

// const WrapImg = styled.div`
//   width: 80px;
//   height: 80px;
//   border-radius: 4px;
//   border-top-left-radius: 16px;
//   border-top-right-radius: 16px;
//   background-color: rgb(220, 220, 220);
//   margin-right: 12px;

//   img {
//     height: 100%;
//     width: 100%;
//     border-radius: 4px;
//     border-top-left-radius: 16px;
//     border-top-right-radius: 16px;
//   }
// `;

// const img =
//   "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KICB2aWV3Qm94PSIwIDAgMzcyLjY1OSAzNzIuNjU5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzNzIuNjU5IDM3Mi42NTk7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KICA8cGF0aCBmaWxsPSIjQ0NDQ0NDIiBkPSJNMzU2LjQxLDk2LjAxOWgtMTUybDczLjYtODEuNmMzLjU0NS0yLjYzNyw0LjI4MS03LjY0OCwxLjY0NC0xMS4xOTRzLTcuNjQ4LTQuMjgxLTExLjE5NC0xLjY0NCBjLTAuNzgsMC41OC0xLjQ0OCwxLjI5OC0xLjk3MSwyLjExOGwtODAsODhsLTgwLTg4Yy0yLjc2Ni0zLjQ0NS03LjgwMi0zLjk5NS0xMS4yNDctMS4yMjljLTMuNDQ1LDIuNzY2LTMuOTk1LDcuODAyLTEuMjI5LDExLjI0NyBjMC4xOTgsMC4yNDYsMC40MSwwLjQ4MSwwLjYzNSwwLjcwMmw3My42LDgxLjZoLTE1MmMtOC44MzcsMC0xNiw3LjE2My0xNiwxNnYyNDQuNjRjMCw4LjgzNyw3LjE2MywxNiwxNiwxNmgzNDAuMTYgYzguODM3LDAsMTYtNy4xNjMsMTYtMTZ2LTI0NC44OEMzNzIuMjc5LDEwMy4wMzcsMzY1LjE1Myw5Ni4wMTksMzU2LjQxLDk2LjAxOXogTTM1Ni40MSwzNTYuNDE5SDE3LjEzdi0yNDQuNjRoMTY5LjJoMC43MmgwLjcyIGgxNjguOTZMMzU2LjQxLDM1Ni40MTl6Ii8+DQo8L2c+DQo8Zz4NCiAgPHBhdGggZmlsbD0iI0NDQ0NDQyIgZD0iTTMxNi4xNywxMjguNjZINTYuODFjLTguNzQzLDAuMTMxLTE1Ljc2MSw3LjI1Ni0xNS43NiwxNnYxNzkuMTJjMCw4LjgzNyw3LjE2MywxNiwxNiwxNmgyNTkuMTJjOC44MzcsMCwxNi03LjE2MywxNi0xNiBWMTQ0LjY2QzMzMi4xNywxMzUuODIzLDMyNS4wMDYsMTI4LjY2LDMxNi4xNywxMjguNjZ6IE0zMTUuOTMsMzIzLjQ1OUg1Ni45N3YtMTc4LjhoMjU5LjJMMzE1LjkzLDMyMy40NTl6Ii8+DQo8L2c+DQo8dGV4dCB4PSI4MCIgeT0iMjg1IiBmaWxsPSIjQ0NDQ0NDIiBzdHlsZT0iZm9udC1zaXplOiAxNDA7IGZvbnQtd2VpZ2h0OiBib2xkIj5PZmY8L3RleHQ+DQo8L3N2Zz4=";
