import { faBraille, faList, faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ScrollToTop from 'src/components/ScrollToTop'
import Tooltipv2 from 'src/components/ToolTipv2'
import styled from 'styled-components'
import { playlistApi } from '../../../api/playlistApi'
import ButtonOutLine from '../../../components/ButtonOutLine'
import Loading from '../../../components/Loading'
import { handleError, handleSuccess } from '../../../utils/handle-error'
import { Root } from '../../Home/components/ContentHomePage'
import ContentView from './components/ContentView'
import MenuLeft from './components/MenuLeft'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch } from 'react-redux'
import UrlAction from 'src/redux/Url/action'
import { set } from 'react-hook-form'

var arrItem = []
var keyItems = 0

function PlaylistDetail({ isMobile }) {
  const Param = useParams()
  const navigate = useNavigate()
  const playlistId = Param?.playlistId
  const pathName = Param?.pathName || 'playlistSetting'

  const [playlistDetail, setPlayListDetail] = useState({})
  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [typeShowDetail, setTypeShowDetail] = useState('playlistSetting')
  const [typeViewContent, setTypeViewContent] = useState('grid')
  const [multiSelect, setMultiSelect] = useState(false)
  const [itemSelect, setItemSelect] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const refScroll = useRef(null)
  const [totalFile, setTotalFile] = useState(0)
  const [totalSize, setTotalSize] = useState(0)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [namePlaylist, setNamePlaylist] = useState('')

  const currentPath = window?.location.pathname
  const idx = currentPath.lastIndexOf('/')
  const isPath = currentPath.slice(idx + 1, currentPath.length)

  useEffect(() => {
    handleGetPlayListDetail()
  }, [])

  const dispatch = useDispatch()

  const handleGetPlayListDetail = () => {
    setIsLoading(true)
    playlistApi
      .getPlaylistDetail(playlistId)
      .then((res) => {
        // if (!res.success) {
        //   throw res
        // }
        const { data } = res
        const newFiles = data.files.map((elm, index) => {
          return {
            ...elm,
            status: elm.status === 0 ? elm.status : data.statuses[index],
            duration: data.durations[index] || elm?.duration || 0,
            idv4: uuidv4(),
          }
        })

        const totalSizeOfFiles = data.files.reduce((acc, cur) => {
          return acc + cur.size
        }, 0)
        setNamePlaylist(data.info.name)
        setTotalSize(totalSizeOfFiles)
        setTotalFile(newFiles.length)
        arrItem = newFiles
        setPlayListDetail({
          ...data,
          files: newFiles,
        })
      })
      .catch((err) => {
        if (err) {
          handleError('getPlaylistDetail - 3', err)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleReturnItemSelect = (listItem) => {
    const newList = listItem.filter((elm) => elm.select === true)
    if (newList.length === 0) {
      navigate('playlistSetting')
    } else {
      navigate('itemDetail')
    }
    setItemSelect(newList)
  }

  const handleSelectFile = (file) => {
    const cloneData = [...arrItem]

    cloneData.push({ ...file, idv4: `${cloneData.length}_${new Date().getTime()}` })
    arrItem = cloneData
    handleSuccess(`Add success: ${file?.name}.${file?.ext}`)
    dispatch(UrlAction.postHaveChange('playlist-grid-files', 'add-files-playlist', 'Add files to playlist'))

    setPlayListDetail({
      ...playlistDetail,
      files: cloneData,
    })
  }

  const handleSaveAndPublish = () => {
    const files = [...playlistDetail.files]
    const newPlaylist = files.reverse().map((file, index) => {
      return {
        file_id: file.id,
        duration: file.duration,
        status: file.status,
        order: index + 1,
      }
    })

    const totalSizeOfFiles = files.reduce((acc, cur) => {
      return acc + cur.size
    }, 0)
    setTotalSize(totalSizeOfFiles)
    setTotalFile(newPlaylist.length)
    setMultiSelect(false)
    if (isMobile) {
      setShowMenuDetail(false)
    }

    const data = { list: newPlaylist }
    playlistApi
      .updateFileToPlaylist(playlistId, data)
      .then((res) => {
        if (!res.success) {
          throw res
        }
        dispatch(UrlAction.resetHaveChange('playlist-grid-files'))
        handleSuccess('Save success!')
      })
      .catch((err) => {
        if (err) {
          handleError('updateFileToPlaylist', err)
        }
      })
  }

  // handleError('test')

  const handleBlockOrUnblockFile = (type = 'block') => {
    navigate('playlistSetting')
    const tmpListFile = [...playlistDetail.files]

    if (type === 'block') {
      itemSelect.map((elm) => {
        const idx = tmpListFile.findIndex((v) => v?.idv4 === elm?.idv4)
        playlistDetail.files[idx].status = 0
      })
    }
    if (type === 'unblock') {
      itemSelect.map((elm) => {
        const idx = tmpListFile.findIndex((v) => v?.idv4 === elm?.idv4)
        playlistDetail.files[idx].status = 1
      })
    }

    tmpListFile.forEach((elm) => {
      elm.select = false
    })

    dispatch(UrlAction.postHaveChange('playlist-grid-files', 'change-file-status', 'Block or Unblock file'))
    setMultiSelect(false)
    setItemSelect([])
    if (isMobile) {
      setShowMenuDetail(false)
    }
    setPlayListDetail({
      ...playlistDetail,
      files: tmpListFile,
    })
  }

  const handleRemoveFile = () => {
    navigate('playlistSetting')
    const cloneData = [...arrItem]

    const list = playlistDetail.files.map((elm, index) => {
      return {
        ...elm,
        select: false,
      }
    })

    if (itemSelect.length > 0) {
      itemSelect.map((elm) => {
        const idx = list.findIndex((v) => v.idv4 === elm.idv4)
        cloneData.splice(idx, 1)
        list.splice(idx, 1)
      })
    }
    arrItem = cloneData
    setMultiSelect(false)
    setItemSelect([])
    dispatch(UrlAction.postHaveChange('playlist-grid-files', 'remove-file', 'Remove file from playlist'))
    if (isMobile) {
      setShowMenuDetail(false)
    }
    setPlayListDetail({ ...playlistDetail, files: list })
  }

  const handleAfterDragDrop = (items) => {
    dispatch(UrlAction.postHaveChange('playlist-grid-files', 'drag-file', 'Positon of files after drag'))

    playlistDetail.files = items
    arrItem = [...items]
  }

  const moveItemToTop = (items) => {
    const newList = { ...playlistDetail }
    let files = newList.files
    items.forEach((elm) => {
      const itemIndex = files.findIndex((index) => index.id == elm.id)
      files.splice(itemIndex, 1)
      files.unshift(elm)
    })
    files.forEach((elm) => {
      elm.select = false
    })
    navigate('playlistSetting')
    setMultiSelect(false)
    setPlayListDetail({
      ...playlistDetail,
      files: files,
    })
    if (isMobile) {
      setShowMenuDetail(false)
    }
    dispatch(UrlAction.postHaveChange('playlist-grid-files', 'move-file', 'Move to top'))
  }

  const onChangeSlide = (currentIdx) => {
    const tmpList = { ...playlistDetail }
    const list = tmpList.files.map((elm, index) => {
      if (index === currentIdx) {
        return {
          ...elm,
          select: true,
        }
      }
      return {
        ...elm,
        select: false,
      }
    })
    setItemSelect(list.filter((v) => v.select === true))
    setPlayListDetail({
      ...playlistDetail,
      files: list,
    })
  }

  const handleSelectFileItem = (item) => {
    const tmpList = { ...playlistDetail }
    const newList = tmpList.files.map((elm) => {
      if (item.idv4 === elm.idv4) {
        return { ...elm, select: elm?.select ? false : true }
      }

      if (multiSelect) return elm
      return { ...elm, select: false }
    })

    setItemSelect(newList.filter((elm) => elm.select === true))

    navigate('itemDetail')
    if (!multiSelect) {
      setShowMenuDetail(true)
    }
    setPlayListDetail({
      ...playlistDetail,
      files: newList,
    })
  }

  const handleCloseMenuRight = () => {
    const tmpList = { ...playlistDetail }
    const list = tmpList.files.map((elm, index) => {
      return {
        ...elm,
        select: false,
      }
    })

    setPlayListDetail({
      ...playlistDetail,
      files: list,
    })

    navigate('playlistSetting')
    setItemSelect([])
    setShowMenuDetail(false)
  }

  const handleChangeTypeSelect = () => {
    playlistDetail.files.forEach((elm, index) => {
      elm.select = false
    })

    setItemSelect([])
    navigate('playlistSetting')
    setMultiSelect((pre) => !pre)
  }

  const handleChangeDuration = (value) => {
    const tmpList = { ...playlistDetail }
    let duration = isNaN(value) ? 0 : value
    const idx = tmpList.files.findIndex((v) => v.idv4 === itemSelect[0].idv4)
    if (idx > 0) {
      tmpList.files[idx].duration = duration
      dispatch(UrlAction.postHaveChange('playlist-grid-files', 'duration-file', 'Duration'))

      setPlayListDetail({
        ...playlistDetail,
        files: tmpList.files,
      })
    }
  }

  const renderScrollToTOp = useCallback(() => {
    let flag = true
    if (isMobile && showMenuDetail) {
      flag = false
    }

    return flag ? <ScrollToTop refScroll={refScroll} /> : ''
  }, [showMenuDetail, isMobile])

  return (
    <WrapContent>
      <WrapRoot>
        <Root>
          <Content ref={refScroll}>
            <Title>
              {namePlaylist}
              <WrapIcon
                onClick={() => {
                  setShowMenuDetail(true)

                  navigate('playlistSetting')
                }}
              >
                <Tooltipv2 overlay="Edit info" placement="top">
                  <FontAwesomeIcon icon={faPen} />
                </Tooltipv2>
              </WrapIcon>
            </Title>

            <WrapBtnControl>
              <BtnControl>
                <ButtonOutLine
                  title="Add Files"
                  onClick={() => {
                    navigate('addFilles')
                    setShowMenuDetail(true)
                  }}
                />
                <IconPlus>
                  <FontAwesomeIcon icon={faPlus} />
                </IconPlus>
              </BtnControl>
              <BtnControl>
                <ButtonOutLine
                  title="Add to Device"
                  onClick={() => {
                    navigate('addDriver')
                    setShowMenuDetail(true)
                  }}
                />
              </BtnControl>
            </WrapBtnControl>
            <WrapBtnControl>
              <BtnControl>
                <ButtonOutLine title="SAVE & PUBLISH" onClick={handleSaveAndPublish} />
              </BtnControl>
              <BtnControl select={multiSelect}>
                <ButtonOutLine title={multiSelect ? 'Deselect' : 'Select'} onClick={handleChangeTypeSelect} />
              </BtnControl>

              {isMobile && multiSelect && (
                <BtnControl>
                  <ButtonOutLine title="Action" onClick={() => setShowMenuDetail(true)} />
                </BtnControl>
              )}
            </WrapBtnControl>
            <WrapViewAndInfo>
              <WrapView>
                <WrapIconControl select={typeViewContent === 'grid'} onClick={() => setTypeViewContent('grid')}>
                  <Tooltipv2 overlay="View as Grid" placement="top">
                    <FontAwesomeIcon icon={faBraille} />
                  </Tooltipv2>
                </WrapIconControl>
                <WrapIconControl select={typeViewContent === 'list'} onClick={() => setTypeViewContent('list')}>
                  <Tooltipv2 overlay="View as List" placement="top">
                    <FontAwesomeIcon icon={faList} />
                  </Tooltipv2>
                </WrapIconControl>
              </WrapView>
              <WrapInfoPlaylist>
                <InfoRight>
                  <span>Total Files: {totalFile}</span>
                  <span>Total Size: {(totalSize / 1024).toFixed(2)} MB</span>
                </InfoRight>
              </WrapInfoPlaylist>
            </WrapViewAndInfo>

            <ContentView
              typeView={typeViewContent}
              playlist={playlistDetail?.files || []}
              multiSelect={multiSelect}
              onShowModal={() => {
                setShowMenuDetail(true)
              }}
              itemSelect={(elmList) => {
                handleReturnItemSelect(elmList)
              }}
              isMobile={isMobile}
              onDragDrop={(files) => {
                handleAfterDragDrop(files)
              }}
              onSelectFileItem={(item) => handleSelectFileItem(item)}
            />

            {!isLoading && <WrapEnd> - END - </WrapEnd>}
          </Content>

          {!showPreviewModal ? renderScrollToTOp() : ''}
          {/* <ScrollToTop refScroll={refScroll} /> */}
        </Root>
      </WrapRoot>
      <MenuLeft
        playlistDetail={playlistDetail}
        isMobile={isMobile}
        showMenuDetail={showMenuDetail}
        multiSelect={multiSelect}
        itemSelect={itemSelect}
        onClose={handleCloseMenuRight}
        type={pathName}
        onSelectFile={(file) => {
          handleSelectFile(file, playlistDetail.files)
        }}
        onBlockFile={() => handleBlockOrUnblockFile('block')}
        onUnblockFile={() => handleBlockOrUnblockFile('unblock')}
        onRemoveFile={handleRemoveFile}
        onSaveFile={handleSaveAndPublish}
        moveItemToTop={moveItemToTop}
        showPreviewModal={(preview) => setShowPreviewModal(preview)}
        onChangeSlide={onChangeSlide}
        onChangeDuration={handleChangeDuration}
        onUpdatePlaylistSuccess={(data) => setNamePlaylist(data.name)}
      />
      {isLoading && <Loading />}
    </WrapContent>
  )
}

export default PlaylistDetail

const WrapContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`
const WrapRoot = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  position: relative;
  max-width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`

const Title = styled.div`
  width: 100%;
  display: flex;
  font-size: 22px;
  color: #999999;
  /* margin-bottom: 30px; */
`

const WrapIcon = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: rgb(255, 102, 0);
  cursor: pointer;
  margin-left: 12px;

  svg {
    height: 20px;
  }
`

const WrapInfoPlaylist = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
`

const InfoLeft = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    font-weight: 500;
    margin-right: 8px;
  }
`

const ButtonAddFile = styled.div`
  display: flex;
  padding: 4px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`

const IconAdd = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(255, 102, 0);
  border-radius: 50%;
  margin-left: 6px;

  svg {
    color: rgb(255, 102, 0);
  }
`

const InfoRight = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    font-weight: 500;
    color: #999999;
  }
`

const WrapViewAndInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const WrapBtnControl = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 0;
`

const WrapView = styled.div`
  flex: 0 0 calc(50% + 42px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 4px 0;
`

const BtnControl = styled.div`
  position: relative;
  max-width: 150px;
  width: 100%;
  font-size: 14px;
  margin: 0 4px;

  .styled_button {
    font-size: 14px;
    background: ${({ select }) => (select ? '#ff6600' : 'none')};
    color: ${({ select }) => (select ? '#fff' : 'black')};

    :hover {
      background-color: rgb(255, 102, 0);
      color: #fff;
    }
  }
`

const IconPlus = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  top: 13px;
  right: 28px;

  svg {
    width: 100%;
    height: 100%;
    color: #ff6600;
  }
`

const WrapIconControl = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${({ select }) => (select ? 'rgba(255, 102, 0, 0.3)' : 'none')};
  svg {
    color: #ff6600;
    height: 20px;
  }
`

const WrapEnd = styled.div`
  margin-top: 40px;
  font-weight: 500;
  text-align: center;
`
