import appConstants from '../constants'
import HttpRequest from './HttpRequest'

const apiUrl = `${appConstants.apiUrl}/theme`
const categoryApi = Object.freeze({
  getListCategory: () =>
    HttpRequest.request({
      method: 'GET',
      url: `${apiUrl}/category`,
    }),
  createCategory: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${apiUrl}/category`,
      data: data,
    }),
  updateCategory: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${apiUrl}/category`,
      data: data,
    }),
  deleteItem: (data) =>
    HttpRequest.request({
      method: 'DELETE',
      url: `${apiUrl}/category`,
      data: data,
    }),
})

export default categoryApi
