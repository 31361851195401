import { useEffect, useState } from 'react'
import useCheckIsMobile from 'src/hooks/useCheckIsMobile'
import styled from 'styled-components'
import 'swiper/css'
import 'swiper/css/navigation'
import SwiperPlaylistItem from './SwiperPlaylistItem'

function PlayListItem({ playlist, onClickItem }) {
  const [items, setItems] = useState(playlist?.files)

  const isMobile = useCheckIsMobile()

  useEffect(() => {
    const newData = playlist?.files?.map((elm) => {
      let src = ''
      if (elm?.type === 'web') {
        if (elm?.ext === 'canv') {
          src = `https://www.canva.com/design/${elm?.url}/screen`
        } else {
          src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
        }
      } else {
        src = elm?.path_thumbnail
      }
      return {
        ...elm,
        path_thumbnail: src,
      }
    })

    setItems(newData)
  }, [playlist])

  return (
    <Root>
      <Header>
        <Title>{playlist?.name || 'n/a'}</Title>
        <SeeAll onClick={() => onClickItem(playlist?.id)}>See all</SeeAll>
      </Header>

      {
        <WrapContentList>
          {playlist?.files?.length > 0 ? (
            <SwiperPlaylistItem listDataSource={items} onClickSlideItem={() => onClickItem(playlist?.id)} />
          ) : (
            <ContentEmpty onClick={() => onClickItem(playlist?.id)} isMobile={isMobile}>
              <span>Empty</span>
            </ContentEmpty>
          )}
        </WrapContentList>
      }
    </Root>
  )
}

export default PlayListItem

const Root = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const SeeAll = styled.div`
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #de7b3d;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
`

const WrapContentList = styled.div`
  position: relative;
  width: 100%;
  /* height: 180px; */
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  & .swiper {
    margin-left: 0;
    margin-right: 0;
  }

  & .swiper-slide {
    width: auto;
    height: fit-content !important;
  }

  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 24px;
    font-weight: 800;
    pointer-events: all;
    color: #ff6600;
  }
`

const ContentEmpty = styled.div`
  flex: 1;
  height: ${(props) => (props.isMobile ? '132px' : '180px')};

  display: flex;
  font-size: 24px;
  font-weight: 500;
  span {
    margin: auto;
  }
`
