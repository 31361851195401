import { toast } from 'react-toastify'

export const handleError = (type, error) => {
  let er = error || 'Error!'
  if (typeof error === 'object' && error !== null) {
    if (error.msg) {
      er = error.msg
    }
    if (error.message) {
      er = error.message
    }
  }

  if (type.indexOf(' not read properties ') > -1) {
    console.error(type);
    return;
  }
  
  toast.error(type + ': ' + er)
  if (error?.message === 'Forbidden' || error?.msg === 'Forbidden') {
    handleRemoveLocal()
    setTimeout(() => {
      window.location.href = 'login'
    }, 300)
  }
}

export const handleSuccess = (title) => {
  toast.success(title)
}

export const handleRemoveLocal = () => {
  localStorage.removeItem('DEVICE_KEY')
  localStorage.removeItem('token_key')
  localStorage.removeItem('user_info')
  localStorage.removeItem('userAssigned')
}
