import FileUploadAction, { FileUploadActionType } from './action'

const initState = {
  key: '',
  filesUpload: [],
  filesProcess: [],
  active: false,
  pid: 0,
  fileUploadDone: [],
  listFileUploadDone: [],
}

export default function FileUploadReducer(state = initState, action) {
  switch (action.type) {
    case FileUploadActionType.ADD_FILES_UPLOAD: {
      return {
        ...state,
        filesUpload: action.payload,
        key: action.key,
        active: true,
        pid: action.payload[0].pid,
      }
    }

    case FileUploadActionType.ADD_FILES_UPLOAD_PROCESS: {
      const list = [...state.filesProcess]

      const idxItem = list.findIndex((elm) => elm.id === action.payload.id)

      if (idxItem !== -1) {
        list[idxItem].per = action.payload.per
      } else {
        list.push(action.payload)
      }

      return {
        ...state,
        filesProcess: list,
      }
    }

    case FileUploadActionType.ATIVE_POPUP: {
      const active = action.payload
      return {
        ...state,
        active: active,
        fileUploadDone: [],
        filesProcess: active ? [...state.filesProcess] : [],
        listFileUploadDone: [],
        filesUpload: [],
      }
    }

    case FileUploadActionType.FILE_UPLOAD_DONE: {
      return {
        ...state,
        fileUploadDone: action.payload,
        listFileUploadDone: [...state.listFileUploadDone, ...action.payload],
      }
    }

    case FileUploadActionType.RESET_FILE_UPLOAD_DONE: {
      return {
        ...state,
        fileUploadDone: [],
        filesProcess: [],
        listFileUploadDone: [],
      }
    }

    // case FileUploadActionType.CHANGE_BLOCKING_STATUS: {
    //     return {
    //         ...state,
    //         blockingStatus: action.payload,
    //     }
    // }

    default: {
      return state
    }
  }
}
