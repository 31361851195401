import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const InputText = React.forwardRef(({ placeholder, valueDefault, onChange, ...rest }, ref) => {
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue(valueDefault)
  }, [valueDefault])
  return (
    <Wrap>
      <Input
        ref={ref}
        className="styled-input-custom"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target?.value)
          onChange(e.target?.value)
        }}
        {...rest}
        // style={{ paddingLeft: '8px' }}
      />
    </Wrap>
  )
})

export default InputText

const Wrap = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

const Input = styled.input`
  width: 100%;
  min-height: 48px;
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 0 8px;
  border-radius: 10px;
  /* background: red; */
  :focus {
    outline: none;
  }
`
