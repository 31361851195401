import React, { useState } from 'react'
import styled from 'styled-components'
import userApi from '../../../api/users'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../utils/handle-error'

export default function UserAddTab({ onAddSuccess }) {
  const [isAdmin, setIsAdmin] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [inputsInfo, setInputsInfo] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    userGroup: '',
    password: '',
  })

  const handleChangeInfo = (e) => {
    const name = e.target.name
    const value = e.target.value

    setInputsInfo({
      ...inputsInfo,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    if (!inputsInfo.name) {
      return handleError('Name is required!')
    }
    if (!inputsInfo.address) {
      return handleError('Address is required!')
    }
    if (!inputsInfo.email) {
      return handleError('Email is required!')
    }
    if (!inputsInfo.phone) {
      return handleError('Phone number is required!')
    }
    if (!inputsInfo.userGroup) {
      return handleError('User group is required!')
    }
    if (!inputsInfo.password) {
      return handleError('Password is required!')
    }

    const { name, email, phone, address, userGroup, password } = inputsInfo

    const data = {
      email,
      phone_number: phone,
      address,
      name,
      password,
      role_id: parseInt(userGroup),
      is_active: isActive,
      is_admin: isAdmin,
    }

    userApi
      .createUser(data)
      .then((res) => {
        handleSuccess('Create user success!')
        onAddSuccess?.()

        setInputsInfo({
          name: '',
          email: '',
          phone: '',
          address: '',
          userGroup: '',
          password: '',
        })

        setIsActive(false)
        setIsAdmin(false)
      })
      .catch((err) => {
        handleError('createUser', err)
      })
  }

  return (
    <>
      <TitleDetail>
        <span>User Info</span>
      </TitleDetail>
      <WrapCheckbox>
        <CheckBox checked={isAdmin} type="checkbox" onChange={() => setIsAdmin((pre) => !pre)} />
        <TextCheckbox>Admin</TextCheckbox>
      </WrapCheckbox>
      <LabelInput>User name:</LabelInput>
      <WrapInput>
        <Input type="text" name="name" placeholder="Name" value={inputsInfo.name} onChange={handleChangeInfo} />
      </WrapInput>
      <LabelInput>Business address:</LabelInput>
      <WrapInput>
        <Input
          type="text"
          value={inputsInfo.address}
          name="address"
          placeholder="Address"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>User group:</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.userGroup}
          name="userGroup"
          placeholder="usergroup"
          onChange={handleChangeInfo}
          autoComplete={false}
        />
      </WrapInput>
      <LabelInput>Email:</LabelInput>
      <WrapInput>
        <Input
          type="email"
          name="email"
          value={inputsInfo.email}
          placeholder="Email"
          // autocomplete="false"
          onChange={handleChangeInfo}
          autoComplete={false}
        />
      </WrapInput>
      <LabelInput>Phone number:</LabelInput>
      <WrapInput>
        <Input
          type="tel"
          value={inputsInfo.phone}
          name="phone"
          placeholder="Phone Number"
          onChange={handleChangeInfo}
          autoComplete={false}
        />
      </WrapInput>
      <WrapCheckbox>
        <CheckBox checked={isActive} type="checkbox" onChange={() => setIsActive((pre) => !pre)} />
        <TextCheckbox>Active (disable account and all devices)</TextCheckbox>
      </WrapCheckbox>
      {/* <LabelInput>Group ID:</LabelInput> */}
      <WrapInput>
        <Input
          type="password"
          value={inputsInfo.password}
          name="password"
          placeholder="Password"
          onChange={handleChangeInfo}
          // autocomplete="false"
          autoComplete="new-password"
        />
      </WrapInput>
      <WrapInput>
        <ButtonOutLine onClick={handleSubmit} title={'Add'} />
      </WrapInput>
    </>
  )
}

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  /* margin-top: 12px; */
  :last-child {
    margin-top: 12px;
  }
  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`
const LabelInput = styled.div`
  padding: 0 10px;
  font-size: 16px;
  color: #ccc;
  margin-top: 12px;
`
const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 12px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin-left: 5px;
`
