import authApi from '../api/auth'
import { handleError } from './handle-error'

export const dataSizeRunningOut = () => {
  authApi.getProfile().then((res) => {
    const data = res.data
    if (data.warning.length > 0) {
      return handleError('Your data size is runnung out')
    }
  })
}
