import React, { Component, useRef } from 'react'
import { insertDataBlock } from 'megadraft'
import Icon from './icon.js'
import axios from 'axios'
import articleApi from '../../../../api/article.js'
import handleError from 'src/api/handle-error.js'

export default class Button extends Component {
  constructor(props) {
    super(props)
    this.inputOpenFileRef = React.createRef()
  }

  onChangeFile(event) {
    event.stopPropagation()
    event.preventDefault()
    var file = event.target.files[0]
    const fileType = file['type']
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
    if (!validImageTypes.includes(fileType)) {
      return handleError('Error', 'File not match!')
    }
    var formData = new FormData()
    formData.append('file', file)
    formData.append('filename', file.name)
    formData.append('cms', 1)
    formData.append('pid', -1)

    // post img to server
    articleApi
      .uploadThumbnail(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        const data = { type: 'image', src: res.data.path }
        this.props.onChange(insertDataBlock(this.props.editorState, data))
      })
      .catch((err) => {
        handleError('uploadThumbnail', err || 'Error')
      })
  }

  render() {
    // const disabled = Object.keys(this.props.data).length > 0;

    return (
      <>
        <button
          className={this.props.className}
          onClick={() => {
            this.upload.click()
          }}
          // disabled={disabled}
        >
          <Icon className="sidemenu__button__icon" />
        </button>
        <input
          type="file"
          id="file"
          ref={(ref) => (this.upload = ref)}
          style={{ display: 'none' }}
          onChange={this.onChangeFile.bind(this)}
        />
      </>
    )
  }
}
