import moment from 'moment'

export const changeTimeRenderUI = (time) => {
  if (!time) return
  const str = `${time}`
  str.replace(/-/g, '/')
  return moment(str, 'hh:mm')
}

export const returnTimeDate = (time, type = 0) => {
  if (typeof time === 'number') {
    if (time === 0) return time
    const h = ('0' + Math.floor(time / 60)).slice(-2)
    const m = ('0' + Math.floor(time % 60)).slice(-2)
    return `${h}:${m}`
  }

  if (type === 1) {
    const hh = ('0' + Math.floor(time / 3600)).slice(-2)
    const mm = ('0' + Math.floor((time - hh * 3600) / 60)).slice(-2)
    const ss = ('0' + Math.floor(time - hh * 3600 - mm * 60)).slice(-2)
    return `${hh}:${mm}:${ss}`
  }
  const date = new Date(time)

  const h = ('0' + date.getHours()).slice(-2)
  const m = ('0' + date.getMinutes()).slice(-2)
  return `${h}:${m}`
}

export const returnDataDate = (time) => {
  if (time === 0) return '-1'

  const date = new Date(time)
  const y = `${date.getFullYear()}`.slice(-2)
  const m = ('0' + (date.getMonth() + 1)).slice(-2)
  const d = ('0' + date.getDate()).slice(-2)

  return `${y}${m}${d}` * 1
}

export const partNumberToDateTime = (value, type = 'd') => {
  if (typeof value !== 'number') {
    return value
  }

  if (type === 'dd') {
    if (value === 0) {
      return null
    }
    const Year = `${new Date().getFullYear()}`.substring(0, 2)
    const newValue = `${value}`
    const y = Year + newValue.substring(0, 2)
    const m = newValue.substring(2, 4)
    const d = newValue.substring(4, 6)

    return new Date(`${y}-${m}-${d}`)
  }

  if (type === 'd') {
    if (value === 0) {
      return null
    }

    return moment.unix(value).format('MM/DD/YYYY')
  }

  if (type === 'h') {
    const h = ('0' + Math.floor(value / 60)).slice(-2)
    const m = ('0' + Math.floor(value % 60)).slice(-2)
    const str = `${h}:${m}`
    str.replace(/-/g, '/')
    return moment(str, 'hh:mm')
  }
}

export const getNumberFromTime = (time) => {
  const date = new Date(time)
  const hh = date.getHours()
  const mm = date.getMinutes()
  const ss = date.getSeconds()

  return hh * 3600 + mm * 60 + ss
}

export const getTimeFromNumber = (number) => {
  const hh = ('0' + Math.floor(number / 3600)).slice(-2)
  const mm = ('0' + Math.floor((number - hh * 3600) / 60)).slice(-2)
  const ss = ('0' + Math.floor(number - hh * 3600 - mm * 60)).slice(-2)
  const str = `${hh}:${mm}:${ss}`
  str.replace(/-/g, '/')

  return moment(str, 'hh:mm:ss')
}

export const getHHMMSSfromDurationNumber = (duration) => {
  var sec_num = parseInt(duration, 10) // don't forget the second param
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - hours * 3600) / 60)
  var seconds = sec_num - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  return hours + ':' + minutes + ':' + seconds
}
