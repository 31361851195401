import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Gallery from 'react-photo-gallery'
import SelectedImage from './SelectdImage'
import { v4 as uuidv4 } from 'uuid'

function ViewGrid({ data, onSelect }) {
  const [items, setItems] = useState(data)

  useEffect(() => {
    const newData = data.map((elm) => {
      let src = ''
      if (elm?.type === 'web') {
        if (elm?.ext === 'canv') {
          src = `https://www.canva.com/design/${elm?.url}/screen`
        } else {
          src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
        }
      } else {
        src = elm?.path_thumbnail
      }
      return {
        ...elm,
        src: src,
      }
    })
    setItems(newData)
  }, [data])

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => {
      return (
        <SelectedImage
          key={uuidv4()}
          index={index}
          photo={photo}
          left={left}
          top={top}
          imgStyle={null}
          onSelectedItem={(v) => {
            onSelect(v)
          }}
          truncate={2}
          variant="secondary"
          fromPage="file"
        />
      )
    },
    [items]
  )

  return (
    <Root>
      <Gallery photos={items} renderImage={imageRenderer} />
    </Root>
  )
}

export default ViewGrid
const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
