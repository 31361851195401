import { faCircle, faLock, faVideoCamera, faHeart, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { getHHMMSSfromDurationNumber } from 'src/utils/render-time'
import styled from 'styled-components'
import Image from '../Image'
import YoutubeLogo from '../../assets/image/youtube.svg'
import CanvaLogo from '../../assets/image/canva.svg'

const Checkmark = ({ selected }) => (
  <div style={selected ? { left: '1px', top: '1px', position: 'absolute', zIndex: '11' } : { display: 'none' }}>
    <svg style={{ fill: 'white', position: 'absolute' }} width="24px" height="24px">
      <circle cx="12.5" cy="12.2" r="8.292" />
    </svg>
    <svg style={{ fill: '#06befa', position: 'absolute' }} width="24px" height="24px">
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
)

const defaultImgStyle = {
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
  objectFit: 'contain',
  width: '100%',
  height: 'auto',
  minWidth: '25%',
}
const selectedImgStyle = {
  transform: 'translateZ(0px) scale3d(0.9, 0.9, 1)',
  transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s',
}
const cont = {
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
}

const SelectedImage = ({
  variant = 'normal',
  index,
  photo,
  margin,
  direction,
  top,
  left,
  onSelectedItem,
  onLoading,
  selected,
  truncate,
  imgStyle = defaultImgStyle,
  fromPage = '',
  onCheckFavourite,
}) => {
  // console.log('🚀 ~ file: SelectdImage.js ~ line 54 ~ fromPage', fromPage)
  //calculate x,y scale
  const [isSelected, setIsSelected] = useState(selected)

  const sx = (100 - (30 / (photo?.width ?? 1)) * 100) / 100
  const sy = (100 - (30 / (photo?.height ?? 1)) * 100) / 100
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`

  if (direction === 'column') {
    cont.position = 'absolute'
    cont.left = left
    cont.top = top
  }

  const handleOnClick = (e) => {
    onSelectedItem?.(photo)
    setIsSelected(!isSelected)
  }

  useEffect(() => {
    setIsSelected(photo?.select)
  }, [photo])

  const imageName = photo?.name || photo?.title || photo?.fileName || ''

  const Root = useMemo(() => {
    switch (variant) {
      case 'normal':
        return Normal
      case 'secondary':
        return Secondary

      default:
        break
    }
  }, [variant])

  return (
    <Root>
      <div style={{ margin, ...cont }}>
        {/* <Checkmark selected={isSelected ? true : false} /> */}

        {fromPage === 'file' && (photo?.type === 'video' || photo?.type === 'web') && (
          <>
            <TimeOfVideo>{getHHMMSSfromDurationNumber(photo.duration)}</TimeOfVideo>
            {photo?.type !== 'web' && (
              <IconVideo>
                <FontAwesomeIcon icon={faVideoCamera} />
              </IconVideo>
            )}
          </>
        )}
        {fromPage === 'web' && (
          <>
            <TimeOfVideo>{getHHMMSSfromDurationNumber(photo?.duration)}</TimeOfVideo>
          </>
        )}
        {fromPage === 'file' && photo?.ext === 'canv' && <Logo src={CanvaLogo}>{/* <img src={CanvaLogo} /> */}</Logo>}
        {fromPage === 'file' && photo?.ext === 'yout' && (
          <Logo src={YoutubeLogo}>{/* <img src={YoutubeLogo} /> */}</Logo>
        )}
        {fromPage === 'template' && (
          <IconFavourite
            onClick={(e) => {
              onCheckFavourite(photo)
            }}
            isFavourite={photo?.favourite}
          >
            <FontAwesomeIcon icon={faHeart} />
          </IconFavourite>
        )}
        {photo?.status === 0 && (
          <Block onClick={handleOnClick}>
            <FontAwesomeIcon icon={faLock} />
          </Block>
        )}
        <ItemSelected selected={isSelected} onClick={handleOnClick}>
          <IconChecked>
            <FontAwesomeIcon icon={faCheck} />
          </IconChecked>
        </ItemSelected>
        {/* {fromPage !== 'file' && fromPage !== 'web' && <WrapName onClick={handleOnClick}>{imageName}</WrapName>} */}
        <Image
          alt={imageName}
          style={imgStyle}
          {...photo}
          select=""
          RenderImg={Img}
          onClick={handleOnClick}
          onLoading={() => onLoading?.(index)}
        />
      </div>
      <style>{`.style-warp-img,.not-selected{position: relative};.not-selected:hover{outline:2px solid #06befa}`}</style>
    </Root>
  )
}

export default SelectedImage

const Normal = styled.div``

const ItemSelected = styled.div`
  display: ${(props) => (props.selected ? 'flex' : 'none')};

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 4px);
  border-radius: 10px;
  background-color: rgba(56, 175, 202, 0.7);
  z-index: 1;
`

const IconChecked = styled.div`
  margin: auto;
  color: white;

  svg {
    width: 50px;
    height: 50px;
  }
`

const Secondary = styled.div`
  position: relative;
  padding: 2px;
  display: flex;
  width: 50%;
  height: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > div {
    padding: 8px;
    height: 100%;
    :hover {
      background: #e6f7ff !important;
    }
  }

  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
`

const WrapName = styled.p`
  position: absolute;
  bottom: 6%;
  left: 0;
  right: 0;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-weight: 700;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 9;
`

const IconVideo = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 40px;
  display: flex;
  border-radius: 50%;
  height: 40px;
  background-color: #ccc;
  z-index: 10;
  pointer-events: none;
  svg {
    margin: auto;
    color: #fff;
  }
`

const IconFavourite = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  border-radius: 50%;
  z-index: 10;
  /* pointer-events: all; */
  svg {
    margin: auto;
    color: ${(props) => (props.isFavourite ? 'rgb(255,102,0)' : '#fff')};
    width: 24px;
    height: 24px;
  }
`

const Img = styled.img`
  border-radius: 8px;
  border: 1px solid #ccc;
`

const ImgNoneStyle = styled.img``

const Block = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
    width: 24px;
    height: 24px;
  }
`

const TimeOfVideo = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  width: 65px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #626262;
  border-radius: 5px;
  z-index: 10;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
`
const Logo = styled.img`
  position: absolute;
  bottom: 2px;
  left: 5px;
  width: auto;
  height: 24px;
  z-index: 10;
  pointer-events: none;
`
const TotalRate = styled.div`
  position: absolute;
  top: 25px;
  right: 20px;
  width: 65px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #626262;
  border-radius: 5px;
  z-index: 10;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
`
