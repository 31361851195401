import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import userGroupApi from '../../api/userGroup'
import CustomModalConfirm from '../../components/CustomModalConfirm'
import { handleError, handleSuccess } from '../../utils/handle-error'
import UserGroupMenuRight from './UserGroupMenuRight'

export default function UserGroup({ isMobile }) {
  const [userGroupList, setUserGroupList] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [typeMenuRight, setTypeMenuRight] = useState('add-user-group')
  const [showModalDelele, setShowModalDelete] = useState(false)
  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [paginate, setPaginate] = useState({
    current_page: 1,
    per_page: 10,
    toatal: 1,
  })

  useEffect(() => {
    const { per_page, current_page } = paginate
    fetchListUserGroup(per_page, current_page)
  }, [])

  useEffect(() => {
    setShowMenuDetail(false)
  }, [isMobile])

  const fetchListUserGroup = (per_page, current_page) => {
    userGroupApi
      .getListUserGroup(per_page, current_page)
      .then((res) => {
        const data = res.data
        const { current_page, per_page, total } = data
        setPaginate({
          current_page,
          per_page,
          total,
        })
        setUserGroupList(data.data)
      })
      .catch((err) => {
        handleError('getListUserGroup', err)
      })
  }

  const onSuccess = () => {
    fetchListUserGroup(paginate.per_page, 1)
    setSelectedItem({})
    setTypeMenuRight('add-user-group')
  }

  const handleSelectedItem = (userGroup) => {
    if (selectedItem.id === userGroup.id) {
      setShowMenuDetail(true)
      setSelectedItem({})
      setTypeMenuRight('add-user-group')
    } else {
      setShowMenuDetail(true)
      setSelectedItem(userGroup)
      setTypeMenuRight('edit-user-group')
    }
  }

  const handleDelete = () => {
    userGroupApi
      .userGroupDelete(selectedItem.id)
      .then((res) => {
        handleSuccess(res.msg || 'Delete success!')
        setShowModalDelete(false)
        setSelectedItem({})
        setTypeMenuRight('add-user-group')
        fetchListUserGroup(paginate.per_page, 1)
      })
      .catch((err) => {
        handleError('userGroupDelete', err.msg || 'Error')
      })
  }

  const onCloseMenuRight = () => {
    setShowMenuDetail(false)
    setSelectedItem({})
  }
  const pageCount = useMemo(() => {
    const { total, per_page } = paginate
    return Math.ceil(total / Number(per_page))
  }, [paginate.per_page])

  const handlePageClick = ({ selected: selectedPage }) => {
    fetchListUserGroup(paginate.per_page, selectedPage + 1)
  }

  return (
    <WrapRoot>
      <UserContentLeft>
        <Header>
          <TextHeader>USERS GROUP</TextHeader>
          {isMobile && (
            <WrapIcon onClick={() => setShowMenuDetail(true)}>
              <FontAwesomeIcon icon={faPlus} />
            </WrapIcon>
          )}
        </Header>
        <WrapUserItem>
          {userGroupList.map((elm) => {
            return (
              <UserItem key={elm.id} onClick={() => handleSelectedItem(elm)}>
                <ItemAvatar>
                  <img src={elm.avatar}></img>
                </ItemAvatar>
                <ItemSelected selected={elm.id === selectedItem.id}>
                  <IconChecked>
                    <FontAwesomeIcon icon={faCheck} />
                  </IconChecked>
                </ItemSelected>
                <ItemName>{elm.name}</ItemName>
              </UserItem>
            )
          })}
        </WrapUserItem>
        <WrapFullHeight />
        <WrapBottom>
          <WrapPaginate>
            <ReactPaginate
              previousLabel={'← Previous'}
              nextLabel={'Next →'}
              pageCount={pageCount || 1}
              onPageChange={handlePageClick}
              breakLabel={'...'}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </WrapPaginate>
        </WrapBottom>
      </UserContentLeft>
      <UserGroupMenuRight
        onCloseMenuRight={onCloseMenuRight}
        isMobile={isMobile}
        showMenuDetail={showMenuDetail}
        onSuccess={onSuccess}
        selectedItem={selectedItem}
        typeMenuRight={typeMenuRight}
        onDeleteUserGroup={() => {
          setShowModalDelete(true)
        }}
      />
      <CustomModalConfirm
        type="delete"
        title={'Are you sure to execute ?'}
        onClose={() => setShowModalDelete(false)}
        onYes={handleDelete}
        isShow={showModalDelele}
      />
    </WrapRoot>
  )
}

const WrapRoot = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`

const UserContentLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 10px 20px;
`

const Header = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TextHeader = styled.div`
  font-size: 22px;
  font-weight: 500;
  color: #999999;
  text-transform: uppercase;
`

const WrapFullHeight = styled.div`
  flex: 1;
`

const WrapIcon = styled.div`
  min-width: 30px;
  height: 30px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  color: rgb(255, 102, 0);
  padding: 0 2px;
  border-radius: 4px;
  background-color: ${({ select }) => (select ? 'rgba(255, 102, 0, 0.3)' : 'none')};
  /* margin: 0 10px; */
  cursor: pointer;
`

const AssignUser = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #999999;
  margin: 10px 0;
`

const WrapUserItem = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 10px -10px 10px 10px; */
  margin-left: -10px;
  margin-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`

const UserItem = styled.div`
  position: relative;
  width: 98%;
  min-height: 100px;
  border: 1px solid #ddd;
  border-radius: 15px;
  cursor: pointer;
  background: #fff;
  margin: 10px;
  padding-right: 30px;
  display: flex;
  align-items: center;
`

const ItemAvatar = styled.div`
  margin-left: 20px;
  width: 80px;
  height: 80px;
  background-color: rgb(220, 220, 220);
  border-radius: 10px;
`

const ItemName = styled.div`
  margin-left: 20px;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  color: rgb(134, 134, 134);
  cursor: pointer;
`

const ItemSelected = styled.div`
  display: ${(props) => (props.selected ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background-color: rgba(56, 175, 202, 0.7);
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 10px;
  z-index: 1;
`

const IconChecked = styled.div`
  margin: auto;
  color: white;
`

const WrapBottom = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const WrapPaginate = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  & .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    cursor: pointer;
  }

  & .pagination a {
    padding: 10px;
    border-radius: 5px;
    margin: 0 2px;
    border: 1px solid rgb(255, 102, 0);
    color: rgb(255, 102, 0);
  }

  & .pagination__link {
    font-weight: bold;
  }

  & .pagination__link--active a {
    color: #fff;
    background: rgb(255, 102, 0);
  }

  & .pagination__link--disabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
  }
`
