import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ViewGrid from './components/ViewGrid'
import ViewList from './components/ViewList'

function ContentView({
  isMobile,
  typeView,
  multiSelect,
  itemSelect,
  onShowModal,
  playlist,
  onDragDrop,
  onSelectFileItem,
}) {
  const [listData, setListData] = useState([])

  useEffect(() => {
    if (!multiSelect && listData.length) {
      setListData(
        listData.map((elm) => {
          return {
            ...elm,
            select: false,
          }
        })
      )
    }
  }, [multiSelect])

  useEffect(() => {
    console.log('🚀 ~ file: index.js ~ line 33 ~ useEffect ~ isMobile', isMobile)
  }, [])

  useEffect(() => {
    const newListDataMedia = playlist.map((elm, index) => {
      return {
        ...elm,
        width: elm.width,
        height: elm.height,
      }
    })
    setListData(newListDataMedia)
  }, [JSON.stringify(playlist)])

  return (
    <Root>
      <Wrapper>
        {typeView === 'grid' && (
          <ViewGrid
            isMobile={isMobile}
            data={listData}
            onSelectFileItem={onSelectFileItem}
            handleDragDrop={(lisItem) => {
              setListData(lisItem)
              onDragDrop?.(lisItem)
            }}
          />
        )}

        {typeView === 'list' && (
          <ViewList
            isMobile={isMobile}
            data={listData}
            onSelectFileItem={onSelectFileItem}
            handleDragDrop={(lisItem) => {
              setListData(lisItem)
              onDragDrop?.(lisItem)
            }}
          />
        )}
      </Wrapper>
    </Root>
  )
}

export default ContentView

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`
