import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import SwiperSlideList from '../SwiperSlideList/SwiperSlideList'
import SwiperPreview from '../SwiperSlideList/SwiperPreview'
import { isMobile } from 'react-device-detect'

export default function ModalPreviewFile({
  onClose,
  selectedItem,
  listDataSource,
  onChangeSlide,
  from,
  indexSelectedSlide,
}) {
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onClose?.()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  // useEffect(() => {
  //   if (from === 'from_playlist_detail_item_preview') {
  //     const idx = listDataSource.findIndex((f) => f.idv4 === selectedItem[0].idv4)
  //     setIndexSelectSlide(idx)
  //   } else {
  //     const idx = listDataSource.findIndex((f) => f.id === selectedItem[0].id)
  //     setIndexSelectSlide(idx)
  //   }
  // }, [listDataSource, selectedItem])

  console.log('preview')

  const handleClosePreview = () => {
    onClose?.()
  }

  return (
    <MyModal>
      <ContentModal>
        <WrapHeader>
          <WrapButton isMobile={isMobile} onClick={handleClosePreview}>
            <ButtonItems>ESC</ButtonItems>
            <ButtonItems>
              <FontAwesomeIcon color="red" icon={faXmark} />
            </ButtonItems>
          </WrapButton>
        </WrapHeader>
        <WrapContent>
          <SwiperPreview
            // fromPage={from}
            listDataSource={listDataSource}
            selectedItem={selectedItem}
            onChangeSlide={onChangeSlide}
            // slidesPerView={1}
            // isPreview={true}
            activeKeyboard={false}
            indexSelectedSlide={indexSelectedSlide}
          />
        </WrapContent>
      </ContentModal>
    </MyModal>
  )
}

const MyModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  z-index: 99999999;
`
const WrapHeader = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  background: transparent;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 99;
`

const WrapButton = styled.div`
  width: 80px;
  height: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? '50px' : '10px')};
  margin-right: ${({ isMobile }) => (isMobile ? '0px' : '20px')};
  border: 1px solid #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99;
`
const ButtonItems = styled.div`
  margin: 0 5px;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
`

const ContentModal = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
`

const WrapContent = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;

  & .swiper {
    width: 100% !important;
    height: 100% !important;
  }

  & .swiper-slide img,
  video {
    display: block;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }

  & .swiper-button-prev:after {
    color: #ff6600;
  }

  & .swiper-button-next:after {
    color: #ff6600;
  }
`
const Img = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
`

const Video = styled.video`
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
`
