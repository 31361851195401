import styled from 'styled-components'

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #ccc;

  :last-child {
    border-bottom: none;
  }

  .styled-input-custom {
    background: none;
    border: 1px solid #d9af93;
  }
`

const TitleDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
  margin-bottom: 8px;

  svg {
    height: 22px;
    margin-left: 12px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }
`

const WrapButtonBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 8px 0;

  .styled_button {
    width: 100px;
    margin: 0 4px;

    :first-child {
      background: ${({ status }) => (status === 0 ? ' #FF6600' : 'none')};
      color: ${({ status }) => (status === 0 ? ' #fff' : 'black')};
    }

    :last-child {
      background: ${({ status }) => (status === 1 ? ' #FF6600' : 'none')};
      color: ${({ status }) => (status === 1 ? ' #fff' : 'black')};
    }

    :hover {
      background: #ff6600;
      color: #fff;
    }
  }
`

const WrapButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0;

  .button-save-schedule {
    font-weight: 500;
  }
`

const TitleSchedule = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
`

const WrapIconPlus = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(255, 102, 0);
  border-radius: 50%;
  color: rgb(255, 102, 0);
  margin-left: 8px;
  cursor: pointer;
`

const BtnAllTime = styled.div`
  height: 42px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #d9af93;
  font-weight: 500;
  font-size: 16px;
  border-radius: 25px;
  padding: 0 14px;
  margin-top: 8px;
  cursor: pointer;

  svg {
    height: 20px;
    margin-right: 8px;
    color: rgb(255, 102, 0);
  }
`
export { BtnAllTime, Content, TitleDetail, TitleSchedule, WrapButton, WrapButtonBlock, WrapIconPlus }
