import React, { useEffect, useState } from 'react'
import InputText from '../../../components/InputText'
import { Button, Label, Root, WrapInput, WrapContent, Logo, Content, Title } from '../styled'
import IconLogo from '../../../assets/image/logo.png'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import authApi from 'src/api/auth'
import { handleError, handleSuccess } from 'src/utils/handle-error'
import { useDispatch } from 'react-redux'
import AuthActions from 'src/redux/auth/action'

function ActiveAccount() {
  const param = useParams()
  const navigator = useNavigate()
  const dispatch = useDispatch()

  const [salt, setSalt] = useState('')
  const [key, setKey] = useState('')

  useEffect(() => {
    let paramUrl = param?.code
    let firstIndexOfAndCharacter = paramUrl.indexOf('&')
    let lastIndexOfAndCharacter = paramUrl.lastIndexOf('&')
    let saltTpm = paramUrl.slice(5, firstIndexOfAndCharacter)
    let keyTpm = paramUrl.slice(firstIndexOfAndCharacter + 5, lastIndexOfAndCharacter)

    setSalt(saltTpm)
    setKey(keyTpm)
  }, [])

  const handleActiveAccount = () => {
    let userId = param?.code.substr(-2)
    const data = { salt: salt, key: key, userId: userId }
    //  dispatch(AuthActions.activeAccountSuccess({ userId: userId }))

    authApi
      .activeAccount(data)
      .then((res) => {
        handleSuccess('Active user success!')
        navigator('/login')

        // console.log('res ---> ', res)
      })
      .catch((err) => {
        handleError('active user', err)

        // console.log('err ----> ', err)
      })
    // console.log(salt, key, userId)
  }

  return (
    <Root>
      <WrapContent>
        <Logo>
          <img src={IconLogo} alt="logo" />
        </Logo>
        <TextP>Active</TextP>
        <Content>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleActiveAccount()
            }}
          >
            <WrapInput>
              <InputText valueDefault={salt} onChange={(v) => setSalt(v)} />
            </WrapInput>
            <WrapInput>
              <InputText valueDefault={key} onChange={(v) => setKey(v)} />
            </WrapInput>
            <WrapInput>
              <Button type="submit">Active</Button>
            </WrapInput>
          </form>
          <Title onClick={() => console.log(2)}>Forgot password?</Title>
          <Title onClick={() => console.log(2)}>Sign in?</Title>
        </Content>
      </WrapContent>
    </Root>
  )
}

export default React.memo(ActiveAccount)

const TextP = styled.div`
  margin-top: 10px;
  text-align: center;
`
