import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState, useRef } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { playlistApi } from '../../../../../api/playlistApi'
import Loading from '../../../../../components/Loading'
import { handleError, handleSuccess } from '../../../../../utils/handle-error'
import { serialize } from '../../../../../utils/serialize'
import PlayListItem from './components/PlayListItem'

const limit = 5
var timeout

function PlayLists({ onSelectItem }) {
  const [isLoading, setIsLoading] = useState(false)
  const [playlist, setPlayList] = useState([])
  const refLoadMore = useRef()
  const [isLastPage, setIsLastPage] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [page, setPage] = useState(1)

  useEffect(() => {
    handleGetPlaylist(page, limit)
  }, [])

  useEffect(() => {
    if (isLastPage) {
      setLoadingText('- END -')
    }
    if (refLoadMore.current && !isLastPage) {
      refLoadMore.current.addEventListener('scroll', handleScroll, false)
    }
    return () => {
      if (refLoadMore.current) {
        refLoadMore.current.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [playlist])

  const handleGetPlaylist = (page = 1, limit) => {
    setIsLoading(true)
    playlistApi
      .getPlaylist(page, limit)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const { playlists } = res.data
        if (playlists.length < limit) {
          setIsLastPage(true)
        }
        setPlayList(playlists)
      })
      .catch((err) => {
        handleError('getPlaylist', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleScroll = (e) => {
    if (isLastPage) {
      setLoadingText('- END -')
      return
    }

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 300) {
        setLoadingText('Loading ...')
        handeLoadMore()
      }
    }, 300)
  }

  const handeLoadMore = () => {
    const pageNum = page + 1
    setPage(pageNum)

    playlistApi
      .getPlaylist(pageNum, limit)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const { playlists } = res.data
        if (playlists.length < limit) {
          setIsLastPage(true)
        }
        setPlayList([...playlist, ...playlists])
      })
      .catch((err) => {
        if (err) {
          handleError('getPlaylist', err)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Root ref={refLoadMore}>
      <WrapTitle>
        <span>PlayLists</span>
      </WrapTitle>
      {!!playlist.length &&
        playlist.map((elm, index) => {
          return <PlayListItem key={elm?.uuid + index} playlist={elm} onClickItem={onSelectItem} />
        })}
      {loadingText !== '' && (
        <WrapLoadingBottom>
          <WrapLoading>
            {loadingText !== '- END -' && (
              <Loader>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderText></LoaderText>
              </Loader>
            )}
            {loadingText === '- END -' && <WrapEnd>- END -</WrapEnd>}
          </WrapLoading>
        </WrapLoadingBottom>
      )}
      {isLoading && <Loading />}
    </Root>
  )
}

export default PlayLists

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`

const WrapTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  span {
    font-size: 22px;
    color: #999;
    text-transform: uppercase;
  }

  svg {
    height: 40px;
    color: rgb(255, 102, 0);
    cursor: pointer;
    padding: 4px 0 4px 8px;
  }
`
const WrapLoadingBottom = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
`
const WrapLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = styled.div`
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const LoadingAniText = keyframes`
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
`

const LoaderAniDot = keyframes`
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
`

const LoaderDot = styled.div`
  animation-name: ${LoaderAniDot};
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;

  :first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }
  :nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  :nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  :nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  :nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  :nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }
`
const LoaderText = styled.div`
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
  :after {
    content: 'Loading';
    font-weight: bold;
    animation-name: ${LoadingAniText};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`
const WrapEnd = styled.div`
  margin-top: 40px;
  font-weight: 500;
`
