import { useEffect, useState } from 'react'
import styled from 'styled-components'
import userApi from '../../../api/users'

import { faGrip, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltipv2 from 'src/components/ToolTipv2'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { setToken } from '../../../helpers/storage'
import { handleError, handleSuccess } from '../../../utils/handle-error'
import { useDispatch } from 'react-redux'
import GlobalAction from 'src/redux/global/action'

export default function UserEditTab({ selectedItem, onEditSuccess, onDeleteUser }) {
  const dispatch = useDispatch()

  const [isAdmin, setIsAdmin] = useState(false)
  const [isActive, setIsActive] = useState(false)

  const [inputsInfo, setInputsInfo] = useState({
    id: 0,
    name: '',
    email: '',
    phone: '',
    address: '',
    userGroup: '',
    password: '',
    repeatPass: '',
  })

  useEffect(() => {
    const { id, name, email, phone_number, address, role_id, is_active, is_admin } = selectedItem

    setInputsInfo({
      id,
      name,
      email,
      phone: phone_number,
      address,
      userGroup: role_id,
      password: '',
      repeatPass: '',
    })

    const isAdmin = is_admin === 1 ? true : false
    const isActive = is_active === 1 ? true : false

    setIsAdmin(isAdmin)
    setIsActive(isActive)
  }, [selectedItem])

  const handleChangeInfo = (e) => {
    const name = e.target.name
    const value = e.target.value

    setInputsInfo({
      ...inputsInfo,
      [name]: value,
    })
  }

  const handleUpdate = () => {
    if (!inputsInfo.name) {
      return handleError('Name is required!')
    }
    if (!inputsInfo.address) {
      return handleError('Address is required!')
    }
    if (!inputsInfo.email) {
      return handleError('Email is required!')
    }
    if (!inputsInfo.phone) {
      return handleError('Phone number is required!')
    }
    if (!inputsInfo.userGroup) {
      return handleError('User group is required!')
    }

    const { id, name, email, phone, address, userGroup } = inputsInfo

    const data = {
      id,
      address,
      email,
      is_active: isActive,
      is_admin: isAdmin,
      name,
      phone_number: phone,
      role_id: userGroup,
    }

    userApi
      .updateUser(id, data)
      .then((res) => {
        handleSuccess('Create user success!')
        onEditSuccess?.()
      })
      .catch((err) => {
        handleError('updateUser', err)
      })
  }

  const handleChangePass = () => {
    const { id, password, repeatPass } = inputsInfo

    if (password !== repeatPass) {
      return handleError('Repeat pass not match!')
    }

    const data = {
      password,
      id,
    }

    userApi
      .updateUserPassword(id, data)
      .then((res) => {
        handleSuccess('Update password success!')
        setInputsInfo({
          ...inputsInfo,
          password: '',
          repeatPass: '',
        })
      })
      .catch((err) => {
        handleError('updateUserPassword', err)
      })
  }

  const handleAssignUser = () => {
    userApi
      .assignTo({
        userId: inputsInfo.id,
      })
      .then((res) => {
        if (res.data && res.data.token) {
          setToken(res.data.token)
        }
        localStorage.setItem('userAssigned', selectedItem?.name)
        dispatch(GlobalAction.userAssigned(selectedItem?.name))
        handleSuccess(res.msg || 'Success')
      })
      .catch((err) => {
        handleError('assignTo', err.msg || 'Error')
      })
  }

  return (
    <>
      <TitleDetail>
        <span>User Info</span>
        <WrapIconTop>
          <Tooltipv2 overlay="Delete user" placement="top">
            <FontAwesomeIcon onClick={onDeleteUser} icon={faTrashCan} />
          </Tooltipv2>
        </WrapIconTop>
        <WrapIconTop onClick={handleAssignUser}>
          <Tooltipv2 overlay="Assign to" placement="top">
            <FontAwesomeIcon icon={faGrip} />
          </Tooltipv2>
        </WrapIconTop>
      </TitleDetail>
      <WrapCheckbox>
        <CheckBox checked={isAdmin} type="checkbox" onChange={() => setIsAdmin((pre) => !pre)} />
        <TextCheckbox>Admin</TextCheckbox>
      </WrapCheckbox>
      <LabelInput>User name:</LabelInput>
      <WrapInput>
        <Input type="text" name="name" placeholder="Name" value={inputsInfo.name} onChange={handleChangeInfo} />
      </WrapInput>
      <LabelInput>Business address:</LabelInput>
      <WrapInput>
        <Input
          type="text"
          value={inputsInfo.address}
          name="address"
          placeholder="Address"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>User group:</LabelInput>
      <WrapInput>
        <Input
          type="number"
          value={inputsInfo.userGroup}
          name="userGroup"
          placeholder="usergroup"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <LabelInput>Email:</LabelInput>
      <WrapInput>
        <Input type="email" name="email" value={inputsInfo.email} placeholder="Email" onChange={handleChangeInfo} />
      </WrapInput>
      <LabelInput>Phone number:</LabelInput>
      <WrapInput>
        <Input
          type="tel"
          value={inputsInfo.phone}
          name="phone"
          placeholder="Phone Number"
          onChange={handleChangeInfo}
        />
      </WrapInput>
      <WrapCheckbox>
        <CheckBox checked={isActive} type="checkbox" onChange={() => setIsActive((pre) => !pre)} />
        <TextCheckbox>Active (disable account and all devices)</TextCheckbox>
      </WrapCheckbox>
      <WrapInput>
        <ButtonOutLine onClick={handleUpdate} title={'Save Info'} />
      </WrapInput>
      <WrapInput1>
        <Input
          type="password"
          value={inputsInfo.password}
          name="password"
          placeholder="New Password"
          onChange={handleChangeInfo}
        />
      </WrapInput1>
      <WrapInput1>
        <Input
          type="password"
          value={inputsInfo.repeatPass}
          name="repeatPass"
          placeholder="Repeat New Password"
          onChange={handleChangeInfo}
        />
      </WrapInput1>
      <WrapInput>
        <ButtonOutLine onClick={handleChangePass} title={'Save Password'} />
      </WrapInput>
    </>
  )
}

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  /* margin-top: 12px; */

  :last-child {
    margin-top: 12px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const WrapInput1 = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const WrapIconTop = styled.div`
  min-width: 30px;
  height: 30px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  color: rgb(255, 102, 0);
  padding: 0 2px;
  border-radius: 4px;
  background-color: ${({ select }) => (select ? 'rgba(255, 102, 0, 0.3)' : 'none')};
  /* margin: 0 10px; */
  margin-left: 5px;
  cursor: pointer;
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 12px;
`

const LabelInput = styled.div`
  padding: 0 10px;
  font-size: 16px;
  color: #ccc;
  margin-top: 12px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin-left: 5px;
`
