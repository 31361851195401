import { faCirclePlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import TimePicker from 'rc-time-picker'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import youtubeApi from 'src/api/youtube'
import Image from 'src/components/Image'
import ModalPreviewFile from 'src/components/ModalPreviewFile/ModalPreviewFile'
import { WrapTimePicker } from 'src/components/SchedulePower'
import SwiperSlideList from 'src/components/SwiperSlideList/SwiperSlideList'
import UrlAction from 'src/redux/Url/action'
import { changeTimeRenderUI, getNumberFromTime, getTimeFromNumber, returnTimeDate } from 'src/utils/render-time'
import styled from 'styled-components'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../utils/handle-error'

export default function UpdateCanvas({
  selectedCanvaItem,
  onEditSuccess,
  onDeleteSuccess,
  listCanvas,
  onChangeSlide,
  onRemoveItem,
  onPreview,
}) {
  console.log('🚀 ~ file: UpdateCanvas.js ~ line 27 ~ selectedCanvaItem', selectedCanvaItem)
  const [item, setItem] = useState(selectedCanvaItem[0])
  const [canvaUrl, setCanvaUrl] = useState('')
  const [canvaID, setCanvaID] = useState('')
  const [validUrl, setValidUrl] = useState(true)
  const [preview, setPreview] = useState(false)
  const [listData, setListData] = useState(listCanvas)
  const [indexSelectedSlide, setIndexSelectSlide] = useState(-1)
  const [startTime, setStartTime] = useState(null)
  const [duration, setDuration] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    setItem(selectedCanvaItem[0])
    setCanvaID(selectedCanvaItem[0]?.url_link)
    setCanvaUrl(selectedCanvaItem[0]?.url_link.substr(0, 11))
    setValidUrl(true)
    setDuration(selectedCanvaItem[0]?.duration)
    const time = getTimeFromNumber(selectedCanvaItem[0]?.duration)
    setStartTime(time)
  }, [selectedCanvaItem])

  useEffect(() => {
    setListData(listCanvas)
  }, [listCanvas])

  useEffect(() => {
    const idx = listCanvas.findIndex((f) => f?.id === selectedCanvaItem[0]?.id)
    setIndexSelectSlide(idx)
  }, [listCanvas, selectedCanvaItem])

  const handleChangeInfo = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'canvaUrl') {
      const link = value.substr(value.lastIndexOf('design/') + 7, 34)
      const link2 = value.substr(value.lastIndexOf('design/') + 7, 11)
      dispatch(UrlAction.postHaveChange('update-canva', 'Canva link', 'Canva link'))

      if (link.length === 34) {
        setCanvaID(link)
        setValidUrl(true)
        setCanvaUrl(link2)
      } else {
        setCanvaID('')
        setValidUrl(false)
        setCanvaUrl(value)
      }
    } else {
      dispatch(UrlAction.postHaveChange('update-canva', 'Canva title', 'Canva title'))
    }

    setItem({
      ...item,
      [name]: value,
    })
  }

  const handleExport = () => {
    const data = { id: item.id }
    youtubeApi
      .exportToFile(data)
      .then((res) => {
        if (res.success) {
          handleSuccess('Export successfully!')
        }
      })
      .catch((err) => handleError('exportToFile', err))
  }

  const handleSubmit = () => {
    if (!item.title) {
      return handleError('Name is required!')
    }
    if (!item.duration) {
      return handleError('Duration is required!')
    }

    if (!validUrl) {
      return handleError('Canva URL invalid!')
    }
    const data = {
      id: item.id,
      url_link: canvaID,
      type: 'canva',
      thumbnail: `https://www.canva.com/design/${canvaID}/screen`,
      src: `https://www.canva.com/design/${canvaID}/screen`,
      title: item.title,
      duration: duration,
      status: 1,
    }

    youtubeApi
      .updateYoutube(data)
      .then((res) => {
        if (res.success) {
          dispatch(UrlAction.resetHaveChange('update-canva'))
          setTimeout(() => {
            onEditSuccess?.(data)
          }, 200)
          handleSuccess('Update canva success!')
          // window.removeChange('update-canva')
        }
      })
      .catch((err) => handleError('updateCanva', err))
  }

  const handleShowPreview = (preview) => {
    setPreview(preview)
    onPreview(preview)
  }

  const hanldeChangeDuration = (v) => {
    dispatch(UrlAction.postHaveChange('update-canva', 'Canva duration', 'Canva duration'))
    const d = getNumberFromTime(v)
    setDuration(d)
    setStartTime(v)
  }

  const handleDelele = () => {
    handleError('Waiting for update this action')
  }

  return (
    <>
      <WrapImage>
        {!validUrl && <Image src={require('../../../assets/image/canva-default.png')} />}

        {validUrl && indexSelectedSlide > -1 && (
          <>
            <SwiperSlideList
              fromPage="canva_update"
              listDataSource={listData}
              selectedItem={selectedCanvaItem}
              onChangeSlide={onChangeSlide}
              onClickSlideItem={() => handleShowPreview(true)}
              slidesPerView={1}
              isShowIconPreview={true}
              indexSelectedSlide={indexSelectedSlide}
            />
          </>
        )}
      </WrapImage>

      <TitleDetail>
        <span>Update Your Canva</span>
      </TitleDetail>
      <WrapInput>
        <a href={`https://www.canva.com/design/${canvaID}/edit`}>
          <ButtonOutLine title={'Edit Canva'} />
        </a>
      </WrapInput>
      <TitleItem>
        <span>Canva link:</span>
      </TitleItem>
      <WrapInput>
        <Input type="text" name="canvaUrl" placeholder="your URL canva" value={canvaUrl} onChange={handleChangeInfo} />
      </WrapInput>

      <TitleItem>
        <span>Canva title:</span>
      </TitleItem>
      <WrapInput>
        <Input type="text" name="title" placeholder="Title" value={item?.title} onChange={handleChangeInfo} />
      </WrapInput>
      {/* <WrapInput>
        <Input
          type="number"
          name="duration"
          placeholder="Duration"
          value={item?.duration}
          onChange={handleChangeInfo}
        />
      </WrapInput> */}
      <TitleItem>
        <span>Canva duration:</span>
      </TitleItem>

      <WrapTime>
        <WrapTimePicker>
          <TimePicker
            value={startTime ? moment(startTime) : null}
            name="duration"
            placeholder="Select time"
            showSecond={true}
            onChange={(e) => hanldeChangeDuration(e?._d)}
            inputReadOnly={true}
            // format="HH:mm:ss"
          />
        </WrapTimePicker>
      </WrapTime>
      <WrapInput2>
        <ButtonOutLine onClick={handleSubmit} title={'Save Info'} />
      </WrapInput2>
      <WrapLine>
        <Line />
      </WrapLine>
      <WrapInput2>
        <ButtonOutLine onClick={handleExport} title={'Export to FILES'} />
      </WrapInput2>
      <WrapInput2>
        <ButtonOutLine onClick={onRemoveItem} title={'Remove'} />
      </WrapInput2>
      {preview && (
        <ModalPreviewFile
          from="canva-item-preview"
          listDataSource={listCanvas}
          selectedItem={selectedCanvaItem}
          onChangeSlide={onChangeSlide}
          onClose={() => handleShowPreview(false)}
          indexSelectedSlide={indexSelectedSlide}
        />
      )}
    </>
  )
}

const WrapImage = styled.div`
  flex: 1;
  height: 180px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px;
  transform: translateY(-10px);
  background-color: #ccc;
  cursor: pointer;
  position: relative;

  & .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-prev:after {
    color: #ff6600;
    pointer-events: all;
  }

  & .swiper-button-next:after {
    color: #ff6600;
    pointer-events: all;
  }

  /* img {
    height: 100%;
    width: auto;
    max-width: 100%;
  } */
`

const WrapIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(1, 1, 1, 0.6);
  border-radius: 50%;
  bottom: 4px;
  left: 4px;
`

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const TitleItem = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #7c7a7a;
  padding: 8px;
  margin-top: 4px;
`

const WrapTime = styled.div`
  width: 100%;
  padding: 0 8px;
  margin-top: -12px;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  /* margin-top: 12px; */

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const WrapInput2 = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const WrapLine = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  margin-top: 30px;
`
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #ccc;
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 12px;
  margin-left: 12px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin-left: 5px;
`

const ButtonID = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d9af93;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 10px;
  }
  :hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    span {
      color: #fff;
    }
  }
`
