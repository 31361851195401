import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import categoryApi from '../../../../../api/category'
import { convertObjectToArray, getListAllCategory } from '../../../../../utils/convertListCategory'
import { handleError, handleSuccess } from '../../../../../utils/handle-error'
import articleApi from '../../../../../api/article'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SelectBox from 'src/page/Categories/components/SelectBox'

export default function AddArticle({ listCategory, onSuccess }) {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [orientation, setOrientation] = useState({
    value: 0,
    label: 'Portrait',
  })
  const [thumbnail, setThumbnail] = useState('')
  const [articleInfos, setArticleInfos] = useState({
    title: '',
    urlLink: '',
    previewLink: '',
    description: '',
    content: '',
  })

  const handleInputsChange = (e) => {
    const { name, value } = e.target
    setArticleInfos({
      ...articleInfos,
      [name]: value,
    })
  }

  const handleFileChange = (event) => {
    var file = event.target.files[0]
    const fileType = file['type']
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
    if (!validImageTypes.includes(fileType)) {
      return handleError('Error', 'File not match!')
    }
    var formData = new FormData()
    formData.append('file', file)
    formData.append('filename', file.name)
    formData.append('cms', 1)
    formData.append('pid', -1)

    // post img to server
    articleApi
      .uploadThumbnail(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setThumbnail(res.data.path_thumbnail)
      })
      .catch((err) => {
        handleError('uploadThumbnail', err || 'Error')
      })
  }

  const handleSubmit = () => {
    const { title, urlLink, previewLink, description } = articleInfos
    if (!thumbnail) {
      return handleError('Error', 'Please choose file thumbnail!')
    }
    if (!title) {
      return handleError('Error', 'Title is required!')
    } else {
      if (!urlLink) {
        return handleError('Error', 'URL link is required!')
      } else {
        if (!previewLink) {
          return handleError('Error', 'Preview link is required!')
        } else {
          if (!description) {
            return handleError('Error', 'Description is required!')
          }
        }
      }
    }
    const param = {
      title,
      url_link: urlLink,
      thumbnail: thumbnail,
      preview: previewLink,
      description,
      content: '',
      status: 1,
      priority: 2,
      orientation: orientation.value,
    }

    const parentIDs = selectedOptions.reduce((acc, cur, index) => {
      acc[`parent_id_${index + 1}`] = parseInt(cur.value)
      return acc
    }, {})

    const newParam = {
      ...param,
      ...parentIDs,
    }

    articleApi
      .createArticle(newParam)
      .then((res) => {
        if (res.success) {
          setArticleInfos({
            title: '',
            urlLink: '',
            previewLink: '',
            description: '',
            content: '',
          })
          onSuccess?.('add')
          setSelectedOptions([])
          setThumbnail('')
          handleSuccess('Create successfully!')
        }
      })
      .catch((err) => {
        handleError('createArticle', err)
      })
  }

  const options = useMemo(() => {
    return listCategory.map((elm) => {
      return { value: elm.id, label: elm.title }
    })
  }, [listCategory])

  const customStyle = useMemo(() => {
    return {
      control: (base, state) => ({
        ...base,
        border: '1px solid #d9af93',
        minHeight: '42px',
        height: 'auto',
        boxShadow: 'none',
        '&:hover': {
          border: '1px solid #999999',
        },
      }),
    }
  }, [])

  return (
    <Root>
      <Title>CREATE TEMPLATE</Title>
      {thumbnail && (
        <WrapThumbnail>
          <Img src={thumbnail}></Img>
        </WrapThumbnail>
      )}

      <WrapInputFile>
        <InputFile id="file" type="file" onChange={handleFileChange} />
        <LabelInputFile tabindex="0" for="file">
          {thumbnail ? 'Choose new file ...' : 'Select a file...'}
        </LabelInputFile>
        <WrapIconUpload>
          <FontAwesomeIcon color="rgb(255, 102, 0)" icon={faCloudArrowUp} />
        </WrapIconUpload>
      </WrapInputFile>
      <WrapSelect>
        <Select
          closeMenuOnSelect={false}
          value={selectedOptions}
          onChange={(value) => {
            setSelectedOptions(value)
          }}
          isMulti
          options={options}
          styles={customStyle}
          isOptionDisabled={() => selectedOptions.length >= 3}
          hideSelectedOptions={false}
          placeholder="Select categories ..."
          maxMenuHeight={500}
        ></Select>
      </WrapSelect>
      <WrapInputs>
        <Inputs
          name="title"
          value={articleInfos.title}
          onChange={handleInputsChange}
          placeholder="Type article title ..."
        ></Inputs>
      </WrapInputs>
      <WrapInputs>
        <Inputs
          name="urlLink"
          value={articleInfos.urlLink}
          onChange={handleInputsChange}
          placeholder="Type URL link"
        ></Inputs>
      </WrapInputs>
      <WrapInputs>
        <Inputs
          name="previewLink"
          value={articleInfos.previewLink}
          onChange={handleInputsChange}
          placeholder="Type preview link"
        ></Inputs>
      </WrapInputs>
      <WrapSelect>
        <Select
          closeMenuOnSelect={true}
          value={orientation}
          onChange={(value) => {
            setOrientation(value)
          }}
          options={optionsOrientation}
          styles={customStyle}
          defaultValue={optionsOrientation[0]}
        />
      </WrapSelect>
      <WrapInputs>
        <Textarea
          name="description"
          value={articleInfos.description}
          onChange={handleInputsChange}
          placeholder="Type description..."
        ></Textarea>
      </WrapInputs>
      <WrapButton>
        <ButtonSubmit onClick={handleSubmit}>SUBMIT</ButtonSubmit>
      </WrapButton>
    </Root>
  )
}

export const optionsOrientation = [
  {
    value: 0,
    label: 'Portrait',
  },
  {
    value: 1,
    label: 'Landscape',
  },
]

const Root = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
`

const Title = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapInputs = styled.div`
  position: relative;
  width: 100%;
  padding: 0 8px;
`

const WrapInputFile = styled.div`
  position: relative;
  width: 100%;
  padding: 0 8px;
  margin-top: 12px;
`

const InputFile = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  padding: 10px 0;
  cursor: pointer;
`

const WrapIconUpload = styled.div`
  position: absolute;
  bottom: 5px;
  right: 35px;
  cursor: pointer;
  pointer-events: none;
  & .svg-inline--fa {
    width: 24px;
    height: 24px;
  }
`

const LabelInputFile = styled.label`
  display: block;
  padding: 10px 10px;
  background: #fff;
  border: 1px solid #d9af93;
  color: hsl(0, 0%, 20%);
  font-size: 1em;
  transition: all 0.4s;
  border-radius: 4px;
  cursor: pointer;
`
const WrapThumbnail = styled.div`
  width: 100%;
  height: 200px;
  background: #ccc;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const WrapSelect = styled.div`
  position: relative;
  width: 100%;
  padding: 12px 8px 0 8px;
`

const Inputs = styled.input`
  width: 100%;
  padding: 0px 10px;
  margin-top: 12px;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  outline: none;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 10px;
  margin-top: 12px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  outline: none;
`

const WrapButton = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  min-height: 42px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 8px;
`
const ButtonSubmit = styled.div`
  width: 100%;
  height: 100%;
  min-height: 42px;
  font-weight: 500;
  border: 1px solid #d9af93;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    cursor: pointer;
  }
`

// const WrapSelect = styled.div`
//   width: auto;
//   height: auto;
//   margin-left: ${(props) => (props.isMobile ? '0px' : '20px')};
//   color: #000;

//   :first-child {
//     width: ${(props) => (props.isMobile ? '160px' : 'auto')};
//     height: ${(props) => (props.isMobile ? '32px' : 'auto')};
//     margin-right: ${(props) => (props.isMobile ? '10px' : '0px')};
//   }

//   :nth-child(2) {
//     width: ${(props) => (props.isMobile ? '140px' : 'auto')};
//     height: ${(props) => (props.isMobile ? '32px' : 'auto')};
//   }
// `
