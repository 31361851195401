import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NavigationGuard from 'src/components/NavigationGuard'
import ScrollToTop from 'src/components/ScrollToTop'
import styled from 'styled-components'
import * as storage from '../../helpers/storage'
import { dataSizeRunningOut } from '../../utils/dataSizeRunningOut'
import CreateArticle from '../Articles/CreateArticle'
import ListArticleCMS from '../Articles/ListArticle/ListArticleCMS'
import ListArticleTheme from '../Articles/ListArticle/ListArticleTheme'
import Canvas from '../Canvas'
import Categories from '../Categories'
import Detail from '../Device/Detail'
import Main from '../Device/Main'
import Favourite from '../Favourite'
import File from '../File'
import History from '../History'
import Logout from '../Logout'
import NewFavourite from '../NewFavourite'
import NewHistory from '../NewHistory'
import PlaylistDetail from '../Playlists/Detail'
import Playlists from '../Playlists/Main'
import PopupProcessing from '../PopupProcess/PopupProcess'
import User from '../User'
import UserGroup from '../UserGroup'
import Youtube from '../Youtube'
import ContentHomePage from './components/ContentHomePage'
import Header from './components/Header'
import MenuBar from './components/MenuBar'

function HomePage() {
  const navigator = useNavigate()
  const [miniBar, setMiniBar] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [mobileContent, setMobileContent] = useState(false)
  const [userInfoWarning, setUserInfoWarning] = useState([])
  const refTimeout = useRef()

  const activePopup = useSelector((state) => state.fileUpload.active)

  useEffect(() => {
    const widthBrowser = window.innerWidth
    if (widthBrowser <= 1023) {
      setMiniBar(true)
      setIsMobile(true)
    } else {
      setMiniBar(false)
      setIsMobile(false)
    }
    if (widthBrowser < 720) {
      setMobileContent(true)
    } else {
      setMobileContent(false)
    }

    window.addEventListener('resize', () => {
      const widthBrowser = window.innerWidth
      if (refTimeout.current) {
        clearTimeout(refTimeout.current)
      }

      refTimeout.current = setTimeout(() => {
        refTimeout.current = undefined
        if (widthBrowser <= 1023) {
          setIsMobile(true)
          setMiniBar(true)
        } else {
          setIsMobile(false)
          setMiniBar(false)
        }

        if (widthBrowser < 720) {
          setMobileContent(true)
        } else {
          setMobileContent(false)
        }
      }, 200)
    })

    return () => {
      clearTimeout(refTimeout.current)
    }
  }, [])

  useEffect(() => {
    const userInfoTmp = storage.getUserInfo()
    const userInfo = JSON.parse(userInfoTmp || '{}')
    if (!userInfo?.user?.uuid) {
      return navigator('login')
    }
  }, [])

  useEffect(() => {
    dataSizeRunningOut()
  }, [])

  return (
    <Root>
      <Header onMiniBar={() => setMiniBar(!miniBar)} />
      <WrapContent>
        <MenuBar miniBar={miniBar} onCloseMenu={() => setMiniBar(true)} isMobile={isMobile} />
        <Content>
          {activePopup && <PopupProcessing isMobile={isMobile} />}
          <Routes>
            <Route path="/" element={<ContentHomePage mobileContent={mobileContent} />} />

            <Route path="device" element={<Main isMobile={isMobile} />}>
              <Route path=":detailId" element={<Detail isMobile={isMobile} />}>
                <Route path=":pathName" element={<Detail isMobile={isMobile} />} />
              </Route>
            </Route>
            <Route path="file" element={<File isMobile={isMobile} />}>
              <Route path=":pathName" element={<File isMobile={isMobile} />} />
            </Route>

            <Route path="playlist" element={<Playlists isMobile={isMobile} />}>
              <Route path=":playlistId" element={<PlaylistDetail isMobile={isMobile} />}>
                <Route path=":pathName" element={<PlaylistDetail isMobile={isMobile} />} />
              </Route>
            </Route>
            <Route path="cms-category" element={<Categories isMobile={isMobile} />}></Route>
            <Route path="theme-article" element={<ListArticleTheme isMobile={isMobile} />}></Route>
            {/* <Route path="category" element={<Categories isMobile={isMobile} />}></Route> */}
            <Route path="cms-article" element={<ListArticleCMS isMobile={isMobile} />}></Route>
            <Route path="add-article" element={<CreateArticle isMobile={isMobile} />}></Route>
            <Route path="favourite" element={<NewFavourite isMobile={isMobile} />}></Route>

            <Route path="history" element={<NewHistory isMobile={isMobile} />}></Route>
            <Route path="app-canva" element={<Canvas isMobile={isMobile} />}>
              <Route path=":pathName" element={<Canvas isMobile={isMobile} />} />
            </Route>
            <Route path="app-youtube" element={<Youtube isMobile={isMobile} />}>
              <Route path=":pathName" element={<Youtube isMobile={isMobile} />} />
            </Route>
            <Route path="user" element={<User isMobile={isMobile} />}></Route>
            <Route path="user-group" element={<UserGroup isMobile={isMobile} />}></Route>
            <Route path="logout" element={<Logout isMobile={isMobile} />}></Route>
          </Routes>
          <NavigationGuard />
        </Content>
      </WrapContent>
    </Root>
  )
}

export default HomePage

const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const WrapContent = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  position: relative;
`
const Content = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  overflow: hidden;
  position: relative;
`
