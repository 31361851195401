import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomModalConfirm from 'src/components/CustomModalConfirm'
import Tooltipv2 from 'src/components/ToolTipv2'
import styled from 'styled-components'
import articleApi from '../../../../api/article'
import categoryApi from '../../../../api/category'
import { convertObjectToArray, getListAllCategory } from '../../../../utils/convertListCategory'
import { handleError } from '../../../../utils/handle-error'
import SelectBox from '../../../Categories/components/SelectBox'
import { Root } from '../../../Home/components/ContentHomePage'
import MenuRight from './MenuRight'

export default function ListArticleCMS({ isMobile }) {
  const navigate = useNavigate()

  const [listAricle, setListArticle] = useState([])
  const [listCategory, setListCategory] = useState([])
  const [itemSelected, setItemSelected] = useState({})
  const [isNew, setIsNew] = useState(1)
  const [typeMenuRight, setTypeMenuRight] = useState('add-article')
  const [updated, setUpdated] = useState(false)
  const [showMenuRight, setShowMenuRight] = useState(false)
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)

  useEffect(() => {
    setShowMenuRight(false)
  }, [isMobile])

  const [filterValues, setFilterValues] = useState({
    category: '',
    orientation: 0,
    isNew: 1,
  })

  const listOrientation = useMemo(() => {
    return [
      {
        id: -1,
        name: 'default',
        title: '-Default-',
      },
      {
        id: 0,
        name: 'portrait',
        title: 'Portrait',
      },
      {
        id: 1,
        name: 'landscape',
        title: 'Landscape',
      },
    ]
  }, [])

  const columnDefs = useMemo(() => {
    return [
      { headerName: 'Article ID', field: 'id', sortable: true },
      { headerName: 'Article Name', field: 'title', filter: true },
      { headerName: 'Description', field: 'description' },
    ]
  }, [listAricle])

  useEffect(() => {
    categoryApi
      .getListCategory()
      .then((res) => {
        const data = res.data.children
        const listCate = convertObjectToArray(data)
        const resultCategory = getListAllCategory(listCate)
        setListCategory(resultCategory)
      })
      .catch((err) => {
        handleError('getListCategory', err || 'Error')
      })
  }, [])

  useEffect(() => {
    const pid = filterValues.category
    const { orientation, isNew } = filterValues
    articleApi
      .getListAricle(1, 500000, pid, orientation, isNew)
      .then((res) => {
        const articles = res.data
        const resultArticle = articles.map((elm) => {
          return { ...elm, select: false }
        })
        setListArticle(resultArticle)
      })
      .catch((err) => {
        handleError('getListAricle', err || 'Error')
      })
  }, [filterValues, updated])

  // Aggrid table setup
  const gridRef = useRef()
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const onGridReady = useCallback((params) => {
    params.api.sizeColumnsToFit()
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit()
      })
    })
    gridRef.current.api.sizeColumnsToFit()
  }, [])

  const handleFilter = (value) => {
    if (value.name) {
      setFilterValues({
        ...filterValues,
        orientation: value.id,
      })
    } else {
      setFilterValues({
        ...filterValues,
        category: parseInt(value.id) || '',
      })
    }
  }

  const handleCheckedNew = () => {
    setIsNew((pre) => !pre)
    setFilterValues({
      ...filterValues,
      isNew: !isNew ? 1 : 0,
    })
  }

  const selectboxCategoryStyle = useMemo(() => ({ width: '250px', height: '42px' }), [])

  const selectboxOrientationStyle = useMemo(() => ({ width: '160px', height: '42px' }), [])

  const cellClickedListener = useCallback(
    (event) => {
      const newList = [...listAricle]
      const item = event.data
      newList.forEach((elm) => {
        if (elm.id === item.id) {
          if (elm.select) {
            elm.select = false
            setItemSelected({})
            setShowMenuRight(false)
            setTypeMenuRight('add-article')
          } else {
            elm.select = true
            setItemSelected(elm)
            setShowMenuRight(true)
            setTypeMenuRight('update-article')
          }
        } else {
          elm.select = false
        }
      })

      setListArticle(newList)
    },
    [listAricle]
  )

  const hanldeSuccess = (type) => {
    setShowMenuRight(false)
    setUpdated((pre) => !pre)
    setTypeMenuRight('add-article')
    // setItemSelected({})
  }

  const onCloseMenuRight = () => {
    setTypeMenuRight('add-article')
    setShowMenuRight(false)
    setItemSelected({})
  }

  const handleOpenTabAdd = () => {
    setShowMenuRight(true)
    setTypeMenuRight('add-article')
    setItemSelected({})
  }

  const handleRemoveArticle = () => {
    const data = { id: itemSelected.id }
    setIsShowModalDelete(false)

    articleApi
      .deleteItem(data)
      .then((res) => {
        if (res.success) {
          const list = [...listAricle]
          const idx = list.findIndex((v) => v.id === data.id)
          if (idx > -1) {
            list.splice(idx, 1)
          }
          setListArticle(list)
          setItemSelected({})
          setTypeMenuRight('add-article')
          setShowMenuRight(false)
        }
      })
      .catch((err) => {
        handleError('deleteItem', err || 'Error')
      })
  }

  return (
    <>
      <WrapRoot>
        <FullBlockLeft>
          <Root>
            <WrapContent notSelect={Object.keys(itemSelected).length === 0}>
              <WrapHeader isMobile={isMobile}>
                <WrapHeaderLeft>
                  <Title>TEMPLATES</Title>
                  <WrapIcon onClick={handleOpenTabAdd}>
                    <Tooltipv2 overlay="Add template" placement="top">
                      <FontAwesomeIcon icon={faCirclePlus}></FontAwesomeIcon>
                    </Tooltipv2>
                  </WrapIcon>
                </WrapHeaderLeft>
                <WrapHeaderRight>
                  <WrapSelect isMobile={isMobile}>
                    <SelectBox
                      listOption={listCategory}
                      border="#d9af93"
                      style={isMobile ? {} : selectboxCategoryStyle}
                      onSelected={(value) => handleFilter(value)}
                      defaultValue={{ title: '-Select category filter-', id: '-1' }}
                    />
                  </WrapSelect>
                  <WrapSelect isMobile={isMobile}>
                    <SelectBox
                      listOption={listOrientation}
                      border="#d9af93"
                      style={isMobile ? {} : selectboxOrientationStyle}
                      onSelected={(value) => handleFilter(value)}
                    />
                  </WrapSelect>
                  <WrapCheckbox isMobile={isMobile}>
                    <CheckBox checked={isNew} type="checkbox" onChange={handleCheckedNew} />
                    <TextCheckbox isMobile={isMobile}>{isMobile ? 'New' : 'New in 30 days'}</TextCheckbox>
                  </WrapCheckbox>
                </WrapHeaderRight>
              </WrapHeader>
              <div style={containerStyle}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: '100%',
                    overflow: 'none',
                  }}
                >
                  <div style={{ overflow: 'hidden', flexGrow: '1' }}>
                    <div className="ag-theme-alpine" style={gridStyle}>
                      <AgGridReact
                        rowData={listAricle}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        ref={gridRef}
                        onCellClicked={cellClickedListener}
                        onGridSizeChanged={() => {
                          gridRef.current.api.sizeColumnsToFit()
                        }}
                        // rowClassRules={rowClassRules}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </WrapContent>
          </Root>
        </FullBlockLeft>
        <MenuRight
          isMobile={isMobile}
          showMenuRight={showMenuRight}
          typeMenuRight={typeMenuRight}
          itemSelected={itemSelected}
          listCategory={listCategory}
          onSuccess={hanldeSuccess}
          onCloseMenuRight={onCloseMenuRight}
          onRemove={() => setIsShowModalDelete(true)}
        />
      </WrapRoot>
      <CustomModalConfirm
        type="delete"
        title={'Are you sure ?'}
        onClose={() => setIsShowModalDelete(false)}
        onYes={handleRemoveArticle}
        isShow={isShowModalDelete}
      />
    </>
  )
}

const WrapRoot = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
`

const FullBlockLeft = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const WrapContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  & .ag-center-cols-viewport {
    overflow: hidden;
  }

  & .ag-theme-alpine .ag-row {
    cursor: pointer;
  }

  & .ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border-color: transparent;
  }

  & .ag-theme-alpine .ag-row-focus {
    background: ${(props) => (!props.notSelect ? '#d3d3d3' : 'transparent')};
  }
`

const WrapHeader = styled.div`
  margin: 20px 10px 20px 10px;
  color: rgb(255, 102, 0);
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
  justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-start')};
  z-index: 10;
`

const WrapHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`

const WrapHeaderRight = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  width: auto;
  font-size: 22px;
  color: #999999;
  text-transform: uppercase;
  /* margin-bottom: 10px;
  padding: 10px; */
`

const WrapIcon = styled.div`
  margin-left: 20px;
  margin-top: 10px;
  min-width: 30px;
  height: 30px;
  color: rgb(255, 102, 0);
  border-radius: 4px;
  cursor: pointer;
`

const WrapSelect = styled.div`
  width: auto;
  height: auto;
  margin-left: ${(props) => (props.isMobile ? '0px' : '20px')};
  color: #000;

  :first-child {
    width: ${(props) => (props.isMobile ? '160px' : 'auto')};
    height: ${(props) => (props.isMobile ? '32px' : 'auto')};
    margin-right: ${(props) => (props.isMobile ? '10px' : '0px')};
  }

  :nth-child(2) {
    width: ${(props) => (props.isMobile ? '140px' : 'auto')};
    height: ${(props) => (props.isMobile ? '32px' : 'auto')};
  }
`

const WrapCheckbox = styled.div`
  margin-left: ${(props) => (props.isMobile ? '5px' : '30px')};
  display: flex;
  align-items: center;
`

const CheckBox = styled.input`
  min-width: 16px;
  min-height: 16px;
  accent-color: #e74c3c;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  margin-left: ${(props) => (props.isMobile ? '2px' : '15px')};
`
