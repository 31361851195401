import React, { useState } from 'react'
import styled from 'styled-components'
import ButtonOutLine from '../../../../../components/ButtonOutLine'
import InputText from '../../../../../components/InputText'

function Search({ onSearch }) {
  const [typeSearch, setTypeSearch] = useState(0)
  const [keyword, setKeyword] = useState('')
  return (
    <Root>
      <Title>Search</Title>
      <InputText placeholder={'Name'} valueDefault={keyword} onChange={(v) => setKeyword(v)} />
      <WrapBtn>
        <Btn select={typeSearch === 0}>
          <ButtonOutLine title="All" onClick={() => setTypeSearch(0)} />
        </Btn>
        <Btn select={typeSearch === 1}>
          <ButtonOutLine title="Image" onClick={() => setTypeSearch(1)} />
        </Btn>
        <Btn select={typeSearch === 2}>
          <ButtonOutLine title="Video" onClick={() => setTypeSearch(2)} />
        </Btn>
      </WrapBtn>
      <ButtonOutLine title="Search" onClick={() => onSearch?.(keyword, typeSearch)} />
    </Root>
  )
}

export default Search

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .styled-input-custom {
    background: none;
    border: 1px solid #d9af93;
  }
`

const Title = styled.p`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 14px;
`
const WrapBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
`

const Btn = styled.div`
  width: 30%;

  .styled_button {
    background: ${({ select }) => (select ? '#FF6600' : 'none')};
    color: ${({ select }) => (select ? '#fff' : 'black')};
  }
`
