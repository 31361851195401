import React from 'react'
import { useEffect, useMemo, useState } from 'react'
import SelectBox from 'src/page/Categories/components/SelectBox'
import styled from 'styled-components'

export default function ViewDefault({ listCategory, onFilter, onCheckedNew }) {
  // const [filterValues, setFilterValues] = useState({
  //   category: '',
  //   orientation: 0,
  //   isNew: 1,
  // })
  const [isNew, setIsNew] = useState(1)

  const listOrientation = useMemo(() => {
    return [
      {
        id: -1,
        name: 'default',
        title: '-Default-',
      },
      {
        id: 1,
        name: 'portrait',
        title: 'Portrait',
      },
      {
        id: 2,
        name: 'landscape',
        title: 'Landscape',
      },
    ]
  }, [])
  const selectboxCategoryStyle = useMemo(() => ({ width: '250px', height: '42px', fontSize: 15, zIndex: 99 }), [])
  const selectboxOrientationStyle = useMemo(() => ({ width: '250px', height: '42px', fontSize: 15 }), [])

  const handleCheckedNew = () => {
    setIsNew((pre) => !pre)
    onCheckedNew(!isNew)
  }
  return (
    <>
      <WrapInputSearch>
        <WrapTitle>Filter Your History Tempalte</WrapTitle>
        <WrapSelect>
          <SelectBox
            listOption={listCategory}
            border="#d9af93"
            style={selectboxCategoryStyle}
            onSelected={(value) => onFilter(value)}
            defaultValue={{ title: '-Select category filter-', id: '' }}
          />
        </WrapSelect>
        <WrapSelect>
          <SelectBox
            listOption={listOrientation}
            border="#d9af93"
            style={selectboxOrientationStyle}
            onSelected={(value) => onFilter(value)}
          />
        </WrapSelect>
        <WrapCheckbox>
          <CheckBox checked={isNew} type="checkbox" onChange={handleCheckedNew} />
          <TextCheckbox>New in 30 days</TextCheckbox>
        </WrapCheckbox>
      </WrapInputSearch>
    </>
  )
}

const WrapInputSearch = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const WrapTitle = styled.div`
  font-size: 16px;
  width: 100%;
  height: 40px;
`
const WrapSelect = styled.div`
  width: auto;
  height: auto;
  margin-bottom: 20px;
  color: #000;
`

const WrapCheckbox = styled.div`
  /* margin-left: 30px; */
  display: flex;
  align-items: center;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #e74c3c;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;
`
