import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

import { Navigation, Keyboard } from 'swiper'
import Image from 'src/components/Image'

export default function ModalPreviewItem({ onClose, itemSelect, listDataMedia, onChangeSlide }) {
  const [item, setItem] = useState(itemSelect[0])
  const [initialSlide, setInitialSlide] = useState(0)
  const [currentIdx, setCurrentIdx] = useState('')

  const swiper = React.useRef(null)

  const setSwiper = (newSwiper) => {
    swiper.current = newSwiper
  }

  useEffect(() => {
    if (swiper.current && listDataMedia.length > 0 && itemSelect.length > 0) {
      const idx = listDataMedia?.findIndex((elm) => elm.id === itemSelect[0].id)
      swiper.current.slideTo(idx)
      setCurrentIdx(idx)
      setInitialSlide(idx)
    }
  }, [itemSelect])

  useEffect(() => {
    setItem(itemSelect[0])
  }, [itemSelect])

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onClose?.()
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  const handleClosePreview = () => {
    onClose?.()
  }

  return (
    <MyModal>
      <ContentModal>
        <WrapHeader>
          <WrapButton onClick={handleClosePreview}>
            <ButtonItems>ESC</ButtonItems>
            <ButtonItems>
              <FontAwesomeIcon color="red" icon={faXmark} />
            </ButtonItems>
          </WrapButton>
        </WrapHeader>
        <WrapContent>
          <Swiper
            initialSlide={initialSlide}
            onSwiper={setSwiper}
            navigation={true}
            keyboard={{
              enabled: true,
            }}
            modules={[Navigation, Keyboard]}
            className="mySwiper"
            onSlideChange={(swiperCore) => {
              setCurrentIdx(swiperCore.realIndex + 1)
              onChangeSlide(swiperCore.realIndex)
            }}
          >
            {listDataMedia?.map((elm, index) => {
              return (
                <SwiperSlide key={`on_preview_modal${elm.id}`}>
                  {elm.type === 'image' ? (
                    <Image src={elm.path_thumbnail} RenderImg={Img}></Image>
                  ) : (
                    <Video autoPlay={currentIdx === index} controls>
                      <source src={elm.path} type="video/mp4" />
                    </Video>
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </WrapContent>
      </ContentModal>
    </MyModal>
  )
}

const MyModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  z-index: 99999999;
`
const WrapHeader = styled.div`
  background: transparent;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const WrapButton = styled.div`
  width: 80px;
  height: 100%;
  margin-right: 10px;
  border: 1px solid #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const ButtonItems = styled.div`
  margin: 0 5px;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
`

const ContentModal = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
`

const WrapContent = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100% - 100px);
  /* width: auto;  
  max-width: 100%;
  max-height: calc(100% - 100px);
  height: calc(100% - 100px); */
  display: flex;
  justify-content: center;
  align-items: center;

  & .swiper {
    width: 100% !important;
    height: 100% !important;
  }

  & .swiper-slide img,
  video {
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: contain;
  }

  & .swiper-button-prev:after {
    color: #ff6600;
  }

  & .swiper-button-next:after {
    color: #ff6600;
  }
`
const Img = styled.img`
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
`

const Video = styled.video`
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
`
