import { faRectangleList } from '@fortawesome/free-regular-svg-icons'
import { faBars, faGear, faHeartCircleCheck, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../../../assets/image/logo.png'
import Dropdown from '../../../components/Dropdown'
import { getUserInfo } from '../../../helpers/storage'

function Header({ onMiniBar }) {
  const user = JSON.parse(getUserInfo() || '{}')

  const menuConfig = [
    {
      id: 1,
      name: 'FAVOURITES',
      icon: faHeartCircleCheck,
      url: '/favourite',
    },
    {
      id: 2,
      name: 'HISTORIES',
      icon: faRectangleList,
      url: '/history',
    },
    {
      id: 2,
      name: 'SETTING',
      icon: faGear,
      url: '/logout',
    },
  ]

  return (
    <Root>
      <LeftHeader>
        <FontAwesomeIcon icon={faBars} onClick={onMiniBar} />
        <a target="_self" href="/"><Image src={Logo} /></a>
      </LeftHeader>
      <RightHeader>
        <WrapIcon>
          <FontAwesomeIcon icon={faQuestion} />
        </WrapIcon>
        <Dropdown menu={menuConfig} label={user?.user?.name || user?.user?.email} />
      </RightHeader>
    </Root>
  )
}

export default Header

const Root = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4b4b50;
  border-bottom: 1px solid #666;
  padding: 0 10px;
`

const LeftHeader = styled.div`
  width: 50%;
  display: flex;
  align-items: center;

  svg {
    height: 24px;
    color: #fff;
    margin-right: 16px;
    cursor: pointer;
  }
`

const Image = styled.img`
  aspect-ratio: 16/9;
  height: 55px;
  border-radius: 6px;
`

const RightHeader = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`
const WrapIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  color: #ff6600;
  border: 1px solid rgba(255, 102, 0, 0.5);
  margin-right: 16px;
  cursor: pointer;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #000;
    right: -16px;
  }
`

const Button = styled.button`
  min-width: 50px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: none;
  padding: 4px 0 4px 12px;
  cursor: pointer;
`
