import appConstants from '../constants'
import HttpRequest from './HttpRequest'

const apiUrl = `${appConstants.apiUrl}/role`

const userGroupApi = Object.freeze({
  getListUserGroup: (limit = 5, page = 1) =>
    HttpRequest.request({
      method: 'GET',
      url: apiUrl,
      params: {
        limit,
        page,
      },
    }),
  createUserGroup: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: apiUrl,
      data: data,
    }),
  updateUserGroup: (id, data) =>
    HttpRequest.request({
      method: 'PUT',
      url: `${apiUrl}/${id}`,
      data: data,
    }),

  userGroupDelete: (id) =>
    HttpRequest.request({
      method: 'DELETE',
      url: `${apiUrl}/${id}`,
    }),
})

export default userGroupApi
