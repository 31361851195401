import React, { useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import {
  faMagnifyingGlass,
  faArrowUpAZ,
  faArrowUpZA,
  faTable,
  faList,
  faBraille,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FolderListHeader from './component/FolderListHeader'
import ContentFile from './component/ContentFile'
import { mediaApi } from '../../../../../../../api/mediaApi'
import { handleError } from '../../../../../../../utils/handle-error'
import Loading from '../../../../../../../components/Loading'
var timeout

function AddFile({ onSelectFile, playlistDetail }) {
  const refContentFolder = useRef()
  const refTimeOut = useRef()
  const refLoadMore = useRef()
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollLeftListValue, setScrollLeftListValue] = useState([])

  const [statusView, setStatusView] = useState(0)
  const [folderSelect, setFolderSelect] = useState({})
  const [listNameFolder, setListNameFolder] = useState([])
  const [listFolder, setListFolder] = useState([])
  const [listDataMedia, setListDataMedia] = useState({})
  const [pid, setPid] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isEmptyListData, setIsEmptyListData] = useState(false)
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState({
    order_by: 'id',
    sort_za: 1,
  })
  const [loadingText, setLoadingText] = useState('- END -')

  const [selectedFile, setSetlectedFile] = useState([])

  useEffect(() => {
    handleGetListDirectoryByPid(pid, page)
  }, [])

  useEffect(() => {
    handleGetFileMediaOnPid()
  }, [pid, sort])

  const handleScroll = (e) => {
    console.log('scroll run')
    if (isLoading) {
      setLoadingText('Loading ...')
      return
    }

    if (isEmptyListData) {
      setLoadingText('- END -')
      return
    }

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 300) {
        console.log('e.target.scrollTop -->', e.target.scrollTop, e.target.clientHeight, e.target.scrollHeight)
        setIsLoading(true)
        setLoadingText('Loading ...')
        let pageNum = page + 1
        setPage(pageNum)
        handleGetMoreFileMediaOnPid(pageNum)
      }
    }, 300)
  }

  useEffect(() => {
    if (refLoadMore.current) {
      refLoadMore.current.addEventListener('scroll', handleScroll, false)
      if (statusView === 0) {
        setTimeout(() => {
          const a = refLoadMore.current.scrollHeight
          const b = refLoadMore.current.clientHeight

          hanldeNeedLoadMore(a, b)
        }, 500)
      }
    }
    return () => {
      if (refLoadMore.current) {
        refLoadMore.current.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [listDataMedia])

  const hanldeNeedLoadMore = (a, b) => {
    if (!isEmptyListData && listDataMedia[pid].length > 0 && a <= b + 100) {
      let pageNum = page + 1
      setPage(pageNum)
      handleGetMoreFileMediaOnPid(pageNum)
    }
  }

  useEffect(() => {
    refTimeOut.current = setTimeout(() => {
      refContentFolder.current.scroll({ behavior: 'smooth', left: scrollLeft })
    }, 500)
    setScrollLeft(0)

    return () => clearTimeout(refTimeOut.current)
  }, [listNameFolder])

  const handleGetListDirectoryByPid = (id) => {
    setIsLoading(true)
    mediaApi
      .getListDirectoryByPid(id)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const { data } = res
        setListFolder(data)
      })
      .catch((err) => {
        handleError('getListDirectoryByPid', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleGetFileMediaOnPid = () => {
    mediaApi
      .getFileMediaOnPid(pid, page, sort.order_by, sort.sort_za)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const data = res.data.data
        const tmpList = data

        if (res.data.next_page_url === null) {
          setLoadingText('- END -')
          setIsEmptyListData(true)
        } else {
          setLoadingText('Loading ...')
          setIsEmptyListData(false)
        }

        setListDataMedia({ [pid]: data })
      })
      .catch((err) => {
        handleError('handleGetFileMediaOnPid', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleGetMoreFileMediaOnPid = (pageNum) => {
    mediaApi
      .getFileMediaOnPid(pid, pageNum, sort.order_by, sort.sort_za)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const data = res.data.data
        if (res.data.next_page_url === null) {
          setLoadingText('- END -')
          setIsEmptyListData(true)
        } else {
          setLoadingText('Loading ...')
          setIsEmptyListData(false)
        }

        /* 
        let x = {1:["1","2","3"]}
        let y = {1:["4","5","6"]}
        =>> z = {1:["1","2","3","4","5","6"]}
        */

        let result = { [pid]: data }
        let tmpList = { ...listDataMedia }

        let pidState = Object.keys(tmpList)[0]
        let pidRes = Object.keys(result)[0]

        if (pidState && pidState === pidRes) {
          result = { [pidState]: [...tmpList[pidState], ...result[pidState]] }
        }

        if (selectedFile.length > 0) {
          let index = result[pid].findIndex((r) => r.id === selectedFile.id)
          if (index > -1) {
            result[pid][index].select = true
          } else {
            result[pid][index].select = false
          }
        }
        setListDataMedia(result)
        setIsLoading(false)
      })
      .catch((err) => {
        handleError('getFileMediaOnPid', err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleNextFolder = (v) => {
    setListNameFolder([...listNameFolder, v])
    handleGetListDirectoryByPid(v.id)
    setPid(v.id)
    setPage(1)
    setIsEmptyListData(false)
    handleGetScrollFolder()
  }

  const handleGetScrollFolder = () => {
    const contentFolder = refContentFolder.current

    if (contentFolder) {
      const scrollLeft = contentFolder.scrollLeft
      setScrollLeftListValue([...scrollLeftListValue, Math.floor(scrollLeft)])
    }
  }

  const handleBackFolder = () => {
    listNameFolder.pop()
    const folderBack = listNameFolder[listNameFolder.length - 1]
    const fid = folderBack ? folderBack.id : 0

    setListNameFolder([...listNameFolder])

    setScrollLeft(scrollLeftListValue.pop())

    setScrollLeftListValue([...scrollLeftListValue])

    handleGetListDirectoryByPid(fid)
    setPage(1)
    setPid(fid)
    setIsEmptyListData(false)
  }

  const handelSort = (type, value) => {
    setIsLoading(true)
    setPage(1)
    const newSort = {
      ...sort,
    }
    newSort[type] = value
    setSort(newSort)
  }

  const handleSelectedFile = (file) => {
    onSelectFile?.(file)
  }

  const handleSelectFolder = (folder) => {
    setFolderSelect(folder)
    // navigate('upload')
    // setIsOpenSelectFileUpload(false)
  }

  console.log('loading text -->', loadingText)
  return (
    <div ref={refLoadMore} style={{ overflow: 'auto' }}>
      <Content>
        <Title>
          <span> FILES</span>
          <WrapListIcon>
            {listView.map((elm) => {
              return (
                <WrapIcon
                  onClick={() => {
                    setStatusView(elm.id)
                  }}
                  key={elm.id}
                  select={elm.id === statusView}
                >
                  <FontAwesomeIcon icon={elm.icon} />
                </WrapIcon>
              )
            })}
            <Line />
            <WrapIcon onClick={() => handelSort('order_by', sort?.order_by === 'id' ? 'name' : 'id')}>
              <FontAwesomeIcon icon={sort?.order_by === 'id' ? faClock : faBullhorn} />
            </WrapIcon>
          </WrapListIcon>
        </Title>

        {!!listNameFolder.length && (
          <WrapNameListFolder>
            <WrapFolder>
              <span className="root-folder">/</span>
              {listNameFolder.map((elm, index) => {
                return (
                  <div key={elm.id + index}>
                    <span className="next-folder">{`>`}</span>
                    <span className="name-folder">{elm.name}</span>
                  </div>
                )
              })}
            </WrapFolder>
          </WrapNameListFolder>
        )}

        {(listFolder?.list?.length > 0 || listNameFolder.length > 0) && (
          <WrapFolderTop>
            <ContentFolder ref={refContentFolder}>
              <FolderListHeader
                listFolder={listFolder?.list}
                folderSelect={folderSelect}
                onSelectFolder={(v) => {
                  handleNextFolder(v)
                  handleSelectFolder(v)
                  // setFolderSelect(v)
                }}
                listNameFolder={listNameFolder}
                // onNextFolder={handleNextFolder}
                onBackFolder={handleBackFolder}
              />
            </ContentFolder>
          </WrapFolderTop>
        )}
        <ContentFile
          listDataMedia={listDataMedia[pid] || []}
          playlistDetail={playlistDetail}
          statusView={statusView}
          itemSelect={(elmList) => {
            handleSelectedFile(elmList)
          }}
        />
      </Content>
      {loadingText !== '' && (
        <WrapLoadingBottom>
          <WrapLoading>
            {loadingText !== '- END -' && (
              <Loader>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderDot></LoaderDot>
                <LoaderText></LoaderText>
              </Loader>
            )}
            {loadingText === '- END -' && <WrapEnd>- END -</WrapEnd>}
          </WrapLoading>
        </WrapLoadingBottom>
      )}
      {/* {isLoading && <Loading />} */}
    </div>
  )
}

export default AddFile

const listView = [
  {
    id: 0,
    icon: faBraille,
  },
  {
    id: 1,
    icon: faList,
  },
  {
    id: 2,
    icon: faTable,
  },
]

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  /* overflow: auto; */
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  color: #999999;
  margin-top: 12px;
`

const WrapListIcon = styled.div`
  display: flex;
  align-items: center;
`

const WrapIcon = styled.div`
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 102, 0);
  padding: 0 2px;
  border-radius: 4px;
  background-color: ${({ select }) => (select ? 'rgba(255, 102, 0, 0.3)' : 'none')};
  margin-left: 8px;
  cursor: pointer;
`

const Line = styled.div`
  width: 2px;
  height: 20px;
  margin-left: 8px;
  background-color: #999999;
`

const WrapNameListFolder = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
`

const WrapFolder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 8px;
  max-width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;

  .root-folder {
    color: #38afca;
  }

  .next-folder {
    color: #999999;
    margin: 0 4px;
  }

  .name-folder {
    color: #000;
    white-space: nowrap;
  }
`

const WrapFolderTop = styled.div`
  width: 100%;
  min-height: 130px;
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
  position: relative;
  overflow: hidden;
`

const ContentFolder = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: auto;
`

const WrapLoadingBottom = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
`
const WrapLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = styled.div`
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const LoadingAniText = keyframes`
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
`

const LoaderAniDot = keyframes`
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
`

const LoaderDot = styled.div`
  animation-name: ${LoaderAniDot};
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;

  :first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }
  :nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  :nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  :nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  :nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  :nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }
`
const LoaderText = styled.div`
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
  :after {
    content: 'Loading';
    font-weight: bold;
    animation-name: ${LoadingAniText};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`
const WrapEnd = styled.div`
  margin-top: 40px;
  font-weight: 500;
`
