import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export default function SelectBox({
  border,
  listOption,
  onSelected,
  defaultValue,
  style,
  maxHeight = 250,
  isBoldParentItem = false,
}) {
  const [showDropdown, setShowDropDown] = useState(false)
  const [selectedItem, setSelectedItem] = useState(listOption[0])
  const [list, setList] = useState([])
  const [flag, setFlag] = useState(false)

  useEffect(() => {
    const newList = [...listOption]
    if (defaultValue && defaultValue.id === '-1') {
      newList.unshift(defaultValue)
    }
    if (defaultValue && defaultValue.id === '-1' && !flag) {
      setSelectedItem(newList[0])
    }
    if (defaultValue && defaultValue.id !== '-1') {
      setSelectedItem(newList[newList.findIndex((el) => el.id == defaultValue.id)])
    }

    if (!defaultValue) {
      setSelectedItem(listOption[0])
    }

    setList(newList)
  }, [listOption, defaultValue])

  const dropDown = () => {
    setShowDropDown((preState) => !preState)
  }

  const handleSelectedItem = (item) => {
    setFlag(true)
    setSelectedItem(item)
    setShowDropDown(false)
    onSelected(item)
  }

  return (
    <>
      <Container style={style} borderColor={border}>
        <WrapSelectBox>
          <SelectedItem onClick={dropDown}>{selectedItem?.title}</SelectedItem>
          <SelectBoxArrow onClick={dropDown}>
            {showDropdown ? <SelectBoxArrowUp /> : <SelectBoxArrowDown />}
          </SelectBoxArrow>
        </WrapSelectBox>
        <SelectBoxItems borderColor={border} showDropdown={showDropdown} maxHeight={maxHeight}>
          {list.map((item, index) => {
            return (
              <Item
                borderColor={border}
                key={index}
                selectedItem={selectedItem?.title === item?.title}
                onClick={() => handleSelectedItem(item)}
                isParent={item?.isParent && isBoldParentItem}
              >
                {item.title}
              </Item>
            )
          })}
        </SelectBoxItems>
      </Container>
    </>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: ${(props) => (props.borderColor !== null ? `1px solid ${props.borderColor}` : 'none')};
  border-radius: 6px;
`

const WrapSelectBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
`
const SelectBoxArrow = styled.div`
  width: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-right: 10px;
`

const SelectBoxArrowDown = styled.span`
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #e74c3c;
  cursor: pointer;
`

const SelectBoxArrowUp = styled.span`
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #e74c3c;
  cursor: pointer;
`

const SelectedItem = styled.div`
  flex: 1;
  padding: 0 12px;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SelectBoxItems = styled.div`
  width: calc(100% + 4px);
  max-height: ${(props) => `${props.maxHeight}px`};
  overflow-y: scroll;
  display: ${(props) => (props.showDropdown ? 'block' : 'none')};
  border: ${(props) => (props.borderColor !== null ? `1px solid ${props.borderColor}` : 'none')};
  border-radius: 6px;
  background-color: #fff;
  margin: 10px -2px 0 -2px;
  padding: 0 12px;
`

const Item = styled.div`
  width: 100%;
  height: 30px;
  text-transform: ${(props) => (props.isParent ? 'uppercase' : 'none')};
  font-weight: ${(props) => (props.isParent ? '700' : 'inherit')};
  /* font-weight: inherit; */
  margin-top: 10px;
  color: ${(props) => {
    if (props.selectedItem) {
      return '#e74c3c'
    }
  }};
  &:hover {
    color: #e74c3c;
    cursor: pointer;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
