import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Image from 'src/components/Image'
import styled from 'styled-components'

function ViewTable({ listArticle, onSelectedItem }) {
  return (
    <Root>
      <WrapHeader>
        <div className="header-name">
          <p>Name</p>
        </div>
        <div className="header-center">
          <p>Size</p>
        </div>
        <div className="header-center">
          <p>type</p>
        </div>
        <div className="header-time">
          <p>date</p>
        </div>
      </WrapHeader>
      {listArticle.map((elm, index) => {
        return (
          <Item key={elm?.id} onClick={() => onSelectedItem?.(elm)}>
            <WrapContent>
              <WrapName>
                <WrapImgName>
                  <Image src={elm?.thumbnail} RenderImg={Img} />
                  {!!elm.select && (
                    <Select>
                      <FontAwesomeIcon icon={faCheck} />
                    </Select>
                  )}
                  {elm.status === 0 && (
                    <Block>
                      <FontAwesomeIcon icon={faLock} />
                    </Block>
                  )}
                </WrapImgName>
                <span>{elm?.title}</span>
              </WrapName>
              <WrapType>{elm?.title}</WrapType>
              <WrapType>{elm?.title} KB</WrapType>
              <WrapTime>{elm?.title}</WrapTime>
            </WrapContent>
          </Item>
        )
      })}
    </Root>
  )
}

export default ViewTable

const Root = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Img = styled.img`
  height: 100%;
  width: 100%;
  max-width: 100%;
  object-fit: contain;
  border-radius: 15px;
`
const WrapHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 15px 15px 0 0;

  p {
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
  }

  .header-name {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-top-left-radius: 15px;
    padding: 0 10px;
  }

  .header-center {
    width: 15%;
    min-width: 55px;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    p {
      margin-left: 10px;
      text-align: start;
    }
  }

  .header-time {
    width: 20%;
    min-width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #f4f4f4;
    border-top-right-radius: 15px;

    p {
      text-align: start;
    }
  }
`

const Item = styled.div`
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  border: 1px solid #f4f4f4;
  cursor: pointer;
`

const WrapImgName = styled.div`
  min-width: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(220, 220, 220);
  border-radius: 15px;
  position: relative;
`
const Block = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
  }
`
const Select = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background-color: rgba(56, 175, 202, 0.7);
  border-radius: 15px;

  svg {
    font-size: 40px;
    color: #fff;
  }
`

const WrapContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const WrapName = styled.div`
  width: 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(134, 134, 134);
  overflow: hidden;

  span {
    width: calc(100% - 80px);
    padding-left: 10px;
    word-wrap: break-word;
    :last-child {
      padding-right: 0;
    }
  }
`

const WrapType = styled.div`
  width: 15%;
  min-width: 55px;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(134, 134, 134);
`

const WrapTime = styled.div`
  width: 20%;
  min-width: 100px;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(134, 134, 134);
`
