import { useRef, useState } from 'react'
import Image from 'src/components/Image'
import { FreeMode, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/bundle'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'

export default function SwiperPreview({
  listDataSource,
  selectedItem = [],
  onChangeSlide = () => {
    return
  },
  activeKeyboard = false,
  indexSelectedSlide,
}) {
  const swiper = useRef(null)

  const setSwiper = (newSwiper) => {
    swiper.current = newSwiper
  }

  const renderContent = () => {
    const elm = listDataSource[0]
    let src = elm?.path_thumbnail || elm?.thumbnail
    let typeRender = 'image'

    if ((elm.type == 'web' && elm.ext == 'yout') || elm.type == 'youtube') {
      const url = elm.url || elm.url_link
      src = `https://www.youtube.com/embed/${url}?autoplay=1&controls=1&loop=1&enablejsapi=1&html5=1&rel=1`
      typeRender = 'youtube'
    }
    if ((elm.type == 'web' && elm.ext == 'canv') || elm.type == 'canva') {
      const url = elm.url || elm.url_link
      src = `https://www.canva.com/design/${url}/watch?embed&autoplay=1&mute=0`
      typeRender = 'canva'
    }
    if (elm.type === 'video') {
      src = `${elm.path}`
      typeRender = 'video'
    }

    return (
      <>
        {typeRender === 'video' && (
          <Video controls="true" autoPlay>
            <source src={src} type="video/mp4" />
          </Video>
        )}
        {typeRender === 'canva' && <iframe width="100%" height="100%" loading="lazy" src={src} allow="autoplay;" />}
        {typeRender === 'youtube' && (
          <iframe
            width="100%"
            height="100%"
            src={src}
            frameBorder="0"
            allow=" autoplay; encrypted-media;"
            title="Embedded youtube"
          />
        )}
        {typeRender === 'image' && <Image src={src} RenderImg={Img}></Image>}
      </>
    )
  }

  //   console.log('selected --', selectedItem[0], indexSelectedSlide)

  return (
    <Root>
      <Swiper
        direction={'horizontal'}
        slidesPerView={1}
        spaceBetween={10}
        initialSlide={indexSelectedSlide}
        onSwiper={setSwiper}
        navigation={true}
        keyboard={{
          enabled: activeKeyboard,
        }}
        modules={[Keyboard, Pagination, Navigation, Mousewheel, FreeMode]}
        onSlideChange={(swiperCore) => {
          requestAnimationFrame(() => {
            onChangeSlide(swiperCore.activeIndex)
            if (swiperCore.previousIndex >= 0) {
              const preSlide = swiperCore.slides[swiperCore.previousIndex].children[0]
              const preElm = listDataSource[swiperCore.previousIndex]
              let src = preElm?.path_thumbnail || preElm.thumbnail
              if ((preElm.type == 'web' && preElm.ext == 'yout') || preElm.type == 'youtube') {
                src = `https://img.youtube.com/vi/${preElm?.url}/0.jpg`
              }
              if ((preElm.type == 'web' && preElm.ext == 'canv') || preElm.type == 'canva') {
                src = `https://www.canva.com/design/${preElm?.url}/screen`
              }
              preSlide.innerHTML = `<image src='${src}' style=" width: 100%; height: 100%;"</image>`
            }

            const currentSlide = swiperCore.slides[swiperCore.activeIndex].children[0]
            const elm = listDataSource[swiperCore.activeIndex]
            if (elm.type == 'image') return

            if (elm.type == 'video') {
              currentSlide.innerHTML = `
                <video controls="true" autoplay="true" style="width: auto; height: 100%; border: 1px solid #ccc; border-radius: 10px;">
                  <source src='${elm.path}' type="video/mp4" />
                </video>`

              return
            }

            if ((elm.type === 'web' && elm?.ext === 'yout') || elm.type === 'youtube') {
              const url = elm.url || elm.url_link
              currentSlide.innerHTML = `<iframe
                    width="100%"
                    height="100%"
                    src='https://www.youtube.com/embed/${url}?autoplay=1&controls=1&loop=1&enablejsapi=1&html5=1&rel=1'
                    frameBorder="0"
                    allow=" autoplay; encrypted-media;"
                    title="Embedded youtube"
                  />`
            }

            if ((elm.type === 'web' && elm?.ext === 'canv') || elm.type === 'canva') {
              const url = elm.url || elm.url_link
              currentSlide.innerHTML = `<iframe
                    width="100%"
                    height="100%"
                    loading="lazy"
                    src='https://www.canva.com/design/${url}/watch?embed&autoplay=1&mute=0'
                    allow="autoplay;"
                  />`
            }
          })
        }}
        preloadImages={false}
        lazy={true}
        mousewheel={{
          releaseOnEdges: true,
          forceToAxis: true,
        }}
      >
        {listDataSource?.map((elm, index) => {
          return (
            <SwiperSlide>
              <SwiperSlide>
                {index === 0 ? renderContent() : ''}
                {index !== 0 && (
                  <Image
                    alt={elm?.name ?? elm?.title}
                    src={elm?.path_thumbnail || elm?.thumbnail}
                    RenderImg={Img}
                  ></Image>
                )}
              </SwiperSlide>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  height: 100%;

  & .swiper {
    position: relative;
    display: flex;
  }
`

const SwiperScrollbar = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;

  .swiper-scrollbar-drag {
    height: 100%;
    transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) !important;
    background: rgba(0, 0, 0, 0.4);
  }
`

const WrapIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(1, 1, 1, 0.6);
  border-radius: 50%;
  bottom: 4px;
  left: 4px;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
`
const WrapYoutube = styled.div`
  width: auto;
  max-width: 500px;
  height: 100%;
`
const YoutubeImg = styled.img`
  width: 238px;
  max-width: 238px;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  pointer-events: none;
`
const CanvaImg = styled.img`
  width: auto;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  pointer-events: none;
`

const ImgMainPlaylist = styled.img`
  width: auto;
  max-width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
  pointer-events: none;
`

const Video = styled.video`
  width: auto;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* pointer-events: none; */
`
const Block = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
    width: 24px;
    height: 24px;
  }
`
