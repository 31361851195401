import appConstants from '../constants'
import HttpRequest from './HttpRequest'

const apiUrl = `${appConstants.apiUrl}/user`
const apiAssign = `${appConstants.apiUrl}/assign-to`
const userApi = Object.freeze({
  getListUser: (limit = 5, page = 1) =>
    HttpRequest.request({
      method: 'GET',
      url: apiUrl,
      params: {
        limit,
        page,
      },
    }),
  createUser: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: apiUrl,
      data: data,
    }),
  updateUser: (id, data) =>
    HttpRequest.request({
      method: 'PUT',
      url: `${apiUrl}/${id}`,
      data: data,
    }),
  updateUserPassword: (id, data) =>
    HttpRequest.request({
      method: 'PUT',
      url: `${apiUrl}/${id}`,
      data: data,
    }),
  searchUser: (keyword, page = 1, limit = 20) =>
    HttpRequest.request({
      method: 'GET',
      url: `${apiUrl}`,
      params: {
        limit,
        page,
        keyword,
      },
    }),
  assignTo: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: `${apiAssign}`,
      data: data,
    }),
  userDelete: (id) =>
    HttpRequest.request({
      method: 'DELETE',
      url: `${apiUrl}/${id}`,
    }),
})

export default userApi
