import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

const UncontrolInputPassword = React.forwardRef(({ placeholder, ...rest }, ref) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <Wrap>
      <Input ref={ref} placeholder={placeholder} type={isShow ? "text" : "password"} {...rest} />
      <FontAwesomeIcon icon={isShow ? faEye : faEyeSlash} onClick={() => setIsShow(!isShow)} />
    </Wrap>
  );
});

export default UncontrolInputPassword;

const Wrap = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

const Input = styled.input`
  width: 100%;
  min-height: 48px;
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 0 30px 0 8px;
  border-radius: 10px;

  :focus {
    outline: none;
  }
`;
