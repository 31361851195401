import React from 'react'
import styled from 'styled-components'
import { faTrashCan, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputText from '../../../../components/InputText'
import Image from 'src/components/Image'

function SettingLogo({ data, onChangeLogo, onDeleteLogo, onChangePosition }) {
  const logo_position = data?.logo_position || {}

  return (
    <Root>
      {data?.logo && (
        <WrapImg>
          <Image src={data?.logo} />
        </WrapImg>
      )}
      <WrapIcon>
        <FontAwesomeIcon icon={faPen} onClick={onChangeLogo} />
        <FontAwesomeIcon icon={faTrashCan} onClick={onDeleteLogo} />
      </WrapIcon>

      {listPosition.map((elm) => {
        return (
          <WrapRow key={elm.id}>
            <span>{elm.title}</span>
            <WrapInput>
              <InputText
                placeholder={elm.title}
                type="number"
                value={logo_position[elm.title] || ''}
                onChange={(v) => onChangePosition?.(elm.title, v)}
              />
            </WrapInput>
          </WrapRow>
        )
      })}
    </Root>
  )
}

export default SettingLogo

const listPosition = [
  { id: 0, title: 'top' },
  { id: 1, title: 'left' },
  { id: 2, title: 'bottom' },
  { id: 3, title: 'right' },
  { id: 4, title: 'width' },
  { id: 5, title: 'height' },
]

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const WrapImg = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;

  img {
    width: fit-content;
    max-width: 100%;
    height: 100%;
  }
`

const WrapIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 4px 0;

  svg {
    height: 20px;
    color: #ff6600;
    margin: 0 6px;
    cursor: pointer;
  }
`

const WrapRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  span {
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }
`

const WrapInput = styled.div`
  width: 180px;
  height: 100%;

  .styled-input-custom {
    background: none;
    border: 1px solid;
    border-color: #d9af93;

    :focus {
      border-color: #ff6600;
    }
  }
`
