import { faClock } from '@fortawesome/free-regular-svg-icons'
import {
  faArrowUpAZ,
  faArrowUpZA,
  faBraille,
  faBullhorn,
  faCircleCheck,
  faList,
  faArrowAltCircleLeft,
  faMagnifyingGlass,
  faTable,
  faPen,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'rc-tooltip/assets/bootstrap.css'
import { useEffect, useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Image from 'src/components/Image'
import Loading from 'src/components/Loading'
import ScrollToTop from 'src/components/ScrollToTop'
import Tooltipv2 from 'src/components/ToolTipv2'
import FileUploadAction from 'src/redux/FileUpload/action'
import styled, { css, keyframes } from 'styled-components'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import { v4 as uuidv4 } from 'uuid'
import { ref } from 'yup'
import { mediaApi } from '../../api/mediaApi'
import ButtonOutLine from '../../components/ButtonOutLine'
import CustomModalConfirm from '../../components/CustomModalConfirm'
import { checkIsAdmin } from '../../utils/checkIsAdmin'
import { dataSizeRunningOut } from '../../utils/dataSizeRunningOut'
import { handleError, handleSuccess } from '../../utils/handle-error'
import { Root } from '../Home/components/ContentHomePage'
import ContentFile from './components/ContentFile'
import FolderListHeader from './components/FolderListHeader'
import MenuLeft from './components/MenuLeft'
import CircularProgress from './components/MenuLeft/components/CircularProgress'

var arrFilesProcessing = []
var timeout
function File({ isMobile }) {
  const Param = useParams()
  const pathName = Param?.pathName || 'upload'
  const navigate = useNavigate()

  const refContentFolder = useRef()
  const refTimeOut = useRef()
  const refContentFile = useRef(null)
  const refLoadMore = useRef()
  const refMenuLeft = useRef(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [multiSelect, setMultiSelect] = useState(false)
  const [statusView, setStatusView] = useState(0)
  const [typeViewMenuLeft, setTypeMenuLeft] = useState('upload')
  const [itemSelect, setItemSelect] = useState([])
  const [folderSelect, setFolderSelect] = useState({})
  const [listNameFolder, setListNameFolder] = useState([])
  const [listFolder, setListFolder] = useState([])
  const [listDataMedia, setListDataMedia] = useState({})
  const [pid, setPid] = useState(0)
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)
  const [isShowModalBlock, setIsShowModalBlock] = useState(false)
  const [isShowModalUnBlock, setIsShowModalUnBlock] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [isEmptyListData, setIsEmptyListData] = useState(false)
  const [sort, setSort] = useState({
    order_by: 'id',
    sort_za: 1,
  })
  const [keySearch, setKeySearch] = useState({})
  const [scrollLeftListValue, setScrollLeftListValue] = useState([])
  const [page, setPage] = useState(1)
  const [loadingText, setLoadingText] = useState('')
  const [showModalDeleteFoler, setShowModalDeleteFoler] = useState(false)
  const [filesProcessing, setFileProcessing] = useState([])
  const [filesUpload, setFilesUpload] = useState([])
  const [showProcesssBar, setShowProcesssBar] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)

  const [listFileTotal, setListFileTotal] = useState([])
  const [isOpenSelectFileUpload, setIsOpenSelectFileUpload] = useState(false)
  const [isUploadDone, setIsUploadDone] = useState(false)

  const [needLoadMore, setNeedLoadMore] = useState(false)
  const [isLoadingDone, setIsLoadingDone] = useState(false)
  const [flag, setFlag] = useState(false)
  const [typeDelete, setTypeDelete] = useState('normal')

  // redux
  const dispatch = useDispatch()
  const fileUploadDoneFromRedux = useSelector((state) => state.fileUpload.fileUploadDone)
  const pidFromRedux = useSelector((state) => state.fileUpload.pid)

  useEffect(() => {
    if (fileUploadDoneFromRedux.length > 0 && pidFromRedux === pid) {
      setListFileTotal([...fileUploadDoneFromRedux, ...listFileTotal])
    }
  }, [pidFromRedux, fileUploadDoneFromRedux])

  useEffect(() => {
    setShowMenuDetail(false)
  }, [isMobile])

  useEffect(() => {
    handleGetListDirectoryByPid(pid)
  }, [])

  useEffect(() => {
    setLoadingText('')
    setListDataMedia({})
    setIsEmptyListData(false)
    setIsLoadingDone(false)
    setNeedLoadMore(false)
    handleGetFileMediaOnPid('1')
  }, [pid, keySearch, sort])

  useEffect(() => {
    if (flag) {
      handleGetFileMediaOnPid('2')
    }
  }, [needLoadMore, flag])

  useEffect(() => {
    if (refLoadMore) {
      refLoadMore.current.addEventListener('scroll', handleScroll, false)

      if (isLoadingDone && statusView === 0) {
        setTimeout(() => {
          const a = refLoadMore.current.scrollHeight
          const b = refLoadMore.current.clientHeight

          hanldeNeedLoadMore(a, b)
        }, 500)
      }
    }
    return () => {
      if (refLoadMore.current) {
        refLoadMore.current.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [isLoadingDone, listFileTotal])

  const handleLoadingImgDone = (index) => {
    if (index === listFileTotal.filter((v) => v?.isFileUpload !== true).length - 1) {
      setTimeout(() => {
        setIsLoadingDone(true)
      }, 200)
    }
  }

  const hanldeNeedLoadMore = (a, b) => {
    if (!isEmptyListData && listFileTotal.length > 0 && a <= b) {
      setPage((pre) => pre + 1)
      setNeedLoadMore((pre) => !pre)
      setFlag(true)
    } else {
      setFlag(false)
    }
  }

  useEffect(() => {
    refTimeOut.current = setTimeout(() => {
      refContentFolder.current.scroll({ behavior: 'smooth', left: scrollLeft })
    }, 500)
    setScrollLeft(0)

    return () => clearTimeout(refTimeOut.current)
  }, [listNameFolder])

  // hanlde render swiper
  const widthRef = useRef()
  const [widthOfProcessBar, setWidthOfProcessBar] = useState()
  useEffect(() => {
    if (widthRef.current) {
      setWidthOfProcessBar(widthRef.current.clientWidth)
    }

    function handleResize() {
      const newWidth = widthRef.current.clientWidth
      setWidthOfProcessBar(newWidth)
    }

    window.addEventListener('resize', handleResize)

    return (_) => {
      window.removeEventListener('resize', handleResize)
    }
  }, [widthRef.current])
  // end hanlde render swiper

  const handleScroll = (e) => {
    if (isLoading) {
      setLoadingText('Loading ...')
      return
    }

    if (isEmptyListData) {
      setLoadingText('- END -')
      return
    }

    clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 300) {
        setLoadingText('Loading ...')
        setIsLoading(true)
        let pageNum = page + 1
        setPage(pageNum)
        handleGetMoreFileMediaOnPid(pageNum)
      }
    }, 300)
  }

  const handleGetListDirectoryByPid = (id) => {
    setIsLoading(true)
    mediaApi
      .getListDirectoryByPid(id)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const { data } = res
        setListFolder(data)
      })
      .catch((err) => {
        if (err) {
          handleError('getListDirectoryByPid', err)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // api
  const handleGetFileMediaOnPid = (v) => {
    setListFileTotal([])
    mediaApi
      .getFileMediaOnPid(pid, page, sort.order_by, sort.sort_za, keySearch.keyword, keySearch.type)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const data = res.data.data

        if (res.data.next_page_url === null) {
          setLoadingText('- END -')
          setIsEmptyListData(true)
        }

        if (v === '1') {
          setListFileTotal([...data])
        } else {
          setListFileTotal([...listFileTotal, ...data])
        }

        //  setListDataMedia({ [pid]: [listDataMedia] })
      })
      .catch((err) => {
        if (err) {
          handleError('getFileMediaOnPid', err)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleGetMoreFileMediaOnPid = (pageNum) => {
    setIsLoading(true)
    mediaApi
      .getFileMediaOnPid(pid, pageNum, sort.order_by, sort.sort_za, keySearch.keyword, keySearch.type)
      .then((res) => {
        if (!res?.success) {
          throw res
        }
        const data = res.data.data
        if (res.data.next_page_url === null) {
          setIsEmptyListData(true)
        } else {
          setIsEmptyListData(false)
        }

        /* 
        let x = {1:["1","2","3"]}
        let y = {1:["4","5","6"]}
        =>> z = {1:["1","2","3","4","5","6"]}
        */

        let result = { [pid]: data }
        // let tmpList = { ...listDataMedia }

        // let pidState = Object.keys(tmpList)[0]
        // let pidRes = Object.keys(result)[0]

        // if (pidState && pidState === pidRes) {
        //   result = { [pidState]: [...tmpList[pidState], ...result[pidState]] }
        // }
        // setListDataMedia(result)
        //  setListFileTotal(result)
        setListFileTotal([...listFileTotal, ...data])
        setIsLoading(false)
      })
      .catch((err) => {
        if (err) {
          handleError('getFileMediaOnPid - ', err || 'error')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleReturnItemSelect = (listItem) => {
    setListDataMedia({ [pid]: listItem })
    return setItemSelect(listItem.filter((elm) => elm.select === true))
  }

  const handleNextFolder = (v) => {
    setShowProcesssBar(false)
    setFileProcessing([])
    setFilesUpload([])
    navigate('upload')
    setListNameFolder([...listNameFolder, v])
    handleGetListDirectoryByPid(v.id)
    setPid(v.id)
    setPage(1)
    setListFileTotal([])
    setIsEmptyListData(false)
    handleGetScrollFolder()
    setIsOpenSelectFileUpload(false)
    setIsLoadingDone(false)
    dispatch(FileUploadAction.resetFileUploadDone())
  }

  const createFolderSuccess = (folder) => {
    setListFolder((prevState) => ({
      ...prevState,
      list: [],
    }))
    dispatch(FileUploadAction.resetFileUploadDone())
    // dispatch(UrlAction.resetHaveChange('add-folder'))
    // window.removeChange("add-folder")

    setListNameFolder([...listNameFolder, ...[folder]])
    setPid(folder.id)
    setPage(1)
    navigate('upload')
  }

  const handleGetScrollFolder = () => {
    const contentFolder = refContentFolder.current

    if (contentFolder) {
      const scrollLeft = contentFolder.scrollLeft
      setScrollLeftListValue([...scrollLeftListValue, Math.floor(scrollLeft)])
    }
  }

  const handleBackFolder = () => {
    listNameFolder.pop()
    const folderBack = listNameFolder[listNameFolder.length - 1]
    const fid = folderBack ? folderBack.id : 0
    setFileProcessing([])
    setFilesUpload([])
    setListNameFolder([...listNameFolder])
    setShowProcesssBar(false)
    setScrollLeft(scrollLeftListValue.pop())
    setListFileTotal([])
    setScrollLeftListValue([...scrollLeftListValue])
    setIsOpenSelectFileUpload(false)
    handleGetListDirectoryByPid(fid)
    setPage(1)
    setPid(fid)
    setIsEmptyListData(false)
    setIsLoadingDone(false)
    dispatch(FileUploadAction.resetFileUploadDone())
  }

  const handelSort = (type, value) => {
    setIsLoading(true)
    setPage(1)
    setIsLoadingDone(false)
    const newSort = {
      ...sort,
    }
    newSort[type] = value
    setSort(newSort)
  }

  const handleSearch = (keyword, type) => {
    setIsLoading(true)
    setIsLoadingDone(false)
    setPage(1)
    setKeySearch({ keyword, type })
  }

  const handleDeleteFile = () => {
    // console.log('type -->', typeDelete)

    setIsShowModalDelete(false)
    if (!itemSelect.length) return
    const listIdItem = itemSelect.map((elm) => {
      return elm.id
    })

    const param = {
      list: listIdItem,
      status: 2,
    }

    // admindeletefile
    if (typeDelete === 'admin') {
      param.canDeleteOnlyFile = 1
    }

    mediaApi
      .updateMediaFile(param, 'DELETE')
      .then((res) => {
        handleSuccess('Success!')
        //Remove state

        let tmp = [...listFileTotal]
        let index
        for (let i = 0; i <= listIdItem.length; i++) {
          index = tmp.findIndex((t) => t.id === listIdItem[i])
          if (index > -1) {
            tmp.splice(index, 1)
          }
        }
        setListFileTotal(tmp)
        setShowMenuDetail(false)
        dataSizeRunningOut()
        navigate('upload')
        setIsOpenSelectFileUpload(false)
        setMultiSelect(false)
      })
      .catch((err) => {
        //Remove state
        // let tmp = [...listDataMedia[pid]]
        // let index
        // for (let i = 0; i <= listIdItem.length; i++) {
        //   index = tmp.findIndex((t) => t.id === listIdItem[i])
        //   if (index > -1) {
        //     tmp.splice(index, 1)
        //   }
        // }
        // setListDataMedia({ [pid]: tmp })
        if (err) {
          handleError('updateMediaFile', err)
        }
      })
  }

  const handleUnBlockFile = () => {
    setIsShowModalUnBlock(false)
    const newListItemUnBlock = itemSelect.filter((elm) => elm.status === 0).map((elm) => elm.id)
    const data = { list: newListItemUnBlock, status: 1 }

    mediaApi
      .updateMediaFile(data, 'PUT')
      .then((res) => {
        if (!res.success) {
          throw res
        }
        let tmp = [...listFileTotal]
        let index
        for (let i = 0; i <= newListItemUnBlock.length; i++) {
          index = tmp.findIndex((t) => t.id === newListItemUnBlock[i])
          if (index > -1) {
            tmp[index].status = 1
          }
        }
        const list = tmp.map((elm) => {
          return { ...elm, select: false }
        })
        setItemSelect([])
        setListFileTotal(list)
        handleSuccess('Success!')
        // setListFileTotal(tmp)
        setShowMenuDetail(false)
        navigate('upload')
        setIsOpenSelectFileUpload(false)
        setMultiSelect(false)
      })
      .catch((err) => {
        handleError('updateMediaFile', err)
      })
  }

  const handleBlockFile = () => {
    setIsShowModalBlock(false)
    const newListItemBlock = itemSelect.filter((elm) => elm.status === 1).map((elm) => elm.id)
    const data = { list: newListItemBlock, status: 0 }

    mediaApi
      .updateMediaFile(data, 'PUT')
      .then((res) => {
        if (!res.success) {
          throw res
        }
        let tmp = [...listFileTotal]
        let index
        for (let i = 0; i <= newListItemBlock.length; i++) {
          index = tmp.findIndex((t) => t.id === newListItemBlock[i])
          if (index > -1) {
            tmp[index].status = 0
          }
        }
        const list = tmp.map((elm) => {
          return { ...elm, select: false }
        })
        setItemSelect([])
        setListFileTotal(list)
        handleSuccess('Success!')
        // setListFileTotal(tmp)
        setShowMenuDetail(false)
        navigate('upload')
        setIsOpenSelectFileUpload(false)
        setMultiSelect(false)
      })
      .catch((err) => {
        handleError('updateMediaFile', err)
      })
  }

  const handleSaveListFile = () => {}

  const onChangeSlide = (currentIdx) => {
    const tmpList = [...listFileTotal]
    const list = tmpList.map((elm, index) => {
      if (index === currentIdx) {
        return {
          ...elm,
          select: true,
        }
      }
      return {
        ...elm,
        select: false,
      }
    })
    setItemSelect(list.filter((v) => v.select === true))
    setListFileTotal(list)
  }

  const updateNameSuccess = (item) => {
    const tmpList = [...listFolder.list]
    let index = tmpList.findIndex((t) => t.id === item.id)
    if (index > -1) {
      tmpList[index].name = item.name
    }

    const tmpListNameFolder = [...listNameFolder]
    let index2 = tmpListNameFolder.findIndex((t) => t.id === item.id)
    if (index2 > -1) {
      tmpListNameFolder[index2].name = item.name
    }
    setListNameFolder(tmpListNameFolder)
    setListFolder((prevState) => ({
      ...prevState,
      list: tmpList,
    }))
  }

  const updateFileNameSuccess = (item) => {
    const tmpList = [...listFileTotal]
    let index = tmpList.findIndex((t) => t.id === item.id)
    if (index > -1) {
      tmpList[index].name = item.name
    }
    setListFileTotal(tmpList)
    // handleOnCloseMenuRightMobile()
  }

  const handleProcessUpload = (item, arr) => {
    const newList = [...arrFilesProcessing]
    const idxItem = newList.findIndex((elm) => elm.id === item.id)

    if (idxItem !== -1) {
      newList[idxItem].per = item.per
    } else {
      newList.push(item)
    }
    arrFilesProcessing = [...newList]
    setFileProcessing([...newList])
  }

  const handleUploadDone = () => {
    if (isMobile) {
      setShowMenuDetail(false)
    }
    setIsOpenSelectFileUpload(false)
    dataSizeRunningOut()
  }

  const handleSendFileUpload = (data) => {
    setShowProcesssBar(true)
    setIsUploadDone(false)
    setFilesUpload(data)
  }

  const handleSelectFileItem = (item) => {
    if (isMobile && !multiSelect) {
      setShowMenuDetail(true)
    }
    const tmpList = [...listFileTotal]
    const newList = tmpList.map((elm) => {
      if (item.id === elm.id) {
        return { ...elm, select: elm?.select ? false : true }
      }
      if (multiSelect) return elm
      return { ...elm, select: false }
    })
    setItemSelect(newList.filter((elm) => elm.select === true))
    navigate('itemDetail')
    setIsOpenSelectFileUpload(false)
    setListFileTotal(newList)
  }

  const handleChangeTypeSelect = () => {
    const tmpList = [...listFileTotal]
    const list = tmpList.map((elm) => {
      return { ...elm, select: false }
    })
    setItemSelect([])
    setMultiSelect((pre) => !pre)
    setListFileTotal(list)
  }

  const handleOnCloseMenuRightMobile = () => {
    const tmpList = [...listFileTotal]
    const list = tmpList.map((elm) => {
      return { ...elm, select: false }
    })

    if (!multiSelect) {
      setItemSelect([])
      setListFileTotal(list)
    }
    setShowMenuDetail(false)
    navigate('itemDetail')
  }

  const handleSelectFolder = (folder) => {
    setFolderSelect(folder)
    navigate('upload')
    setIsOpenSelectFileUpload(false)
  }

  const handleEditFolderCurrent = () => {
    navigate('folderDetail')
    setIsOpenSelectFileUpload(false)
    setShowMenuDetail(true)
  }

  const handleDeleteFolder = () => {
    const folderId = folderSelect?.id

    mediaApi
      .deteleFolder(folderId)
      .then((res) => {
        if (res.data == 'success') {
          handleSuccess('Delete folder success !')
          setPid(0)
          setPage(1)
          navigate('upload')
          setShowMenuDetail(false)
          setShowModalDeleteFoler(false)
          handleGetListDirectoryByPid(0)
          setListNameFolder([])
        }
      })
      .catch((err) => {
        setShowModalDeleteFoler(false)
        handleError('deteleFolder', err)
      })
  }

  const handleAddFileToPlaylistDone = () => {
    const tmpList = [...listFileTotal]
    const list = tmpList.map((elm) => {
      return { ...elm, select: false }
    })

    setItemSelect([])
    setListFileTotal(list)
    setMultiSelect(false)
    setShowMenuDetail(false)
    navigate('upload')
    // console.log('handleAddFileToPlaylistDone')
  }

  const renderScrollToTOp = useCallback(() => {
    let flag = true
    if (isMobile && showMenuDetail) {
      flag = false
    }

    return flag ? <ScrollToTop refScroll={refLoadMore} /> : ''
  }, [showMenuDetail, isMobile])

  console.log('listFolder -->', listFolder)
  console.log('listNameFolder -->', listNameFolder)

  return (
    <>
      <WrapContent mobile={isMobile}>
        <WrapRoot ref={widthRef}>
          <Root>
            <Content ref={refLoadMore}>
              {isMobile ? (
                <HeaderFileMobile>
                  <WrapTopMobile>
                    <Title mobile={isMobile}> FILES</Title>
                    {pid !== 0 && (
                      <WrapPenMobile>
                        <WrapIcon onClick={handleEditFolderCurrent}>
                          <FontAwesomeIcon icon={faPen} />
                        </WrapIcon>
                      </WrapPenMobile>
                    )}

                    <WrapListIcon>
                      {listView.map((elm) => {
                        return (
                          <WrapIcon
                            mobile={isMobile}
                            onClick={() => {
                              // setListFileTotal([])
                              // setPage(1)
                              setStatusView(elm.id)
                            }}
                            key={elm.id}
                            select={elm.id === statusView}
                          >
                            <Tooltipv2 overlay={elm.tooltip} placement="top">
                              <FontAwesomeIcon icon={elm.icon} />
                            </Tooltipv2>
                          </WrapIcon>
                        )
                      })}
                      <Line />
                      <WrapIcon onClick={() => handelSort('order_by', sort?.order_by === 'id' ? 'name' : 'id')}>
                        <Tooltipv2 overlay="Sort time" placement="top">
                          <FontAwesomeIcon icon={sort?.order_by === 'id' ? faClock : faBullhorn} />
                        </Tooltipv2>
                      </WrapIcon>
                      <WrapIcon onClick={() => handelSort('sort_za', sort?.sort_za === 1 ? 0 : 1)}>
                        <Tooltipv2 overlay="desc" placement="top">
                          <FontAwesomeIcon icon={sort?.sort_za === 1 ? faArrowUpAZ : faArrowUpZA} />
                        </Tooltipv2>
                      </WrapIcon>
                      <WrapIcon
                        onClick={() => {
                          setShowMenuDetail(true)
                          navigate('search')
                          setIsOpenSelectFileUpload(false)
                        }}
                      >
                        <Tooltipv2 overlay="Search" placement="top">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Tooltipv2>
                      </WrapIcon>
                    </WrapListIcon>
                  </WrapTopMobile>
                  <WrapDoubleButton>
                    <WrapButton>
                      <ButtonOutLine
                        title=" + New Folder"
                        onClick={() => {
                          setShowMenuDetail(true)
                          navigate('newFolder')
                          setIsOpenSelectFileUpload(false)
                        }}
                      />
                    </WrapButton>
                    <WrapButton>
                      <ButtonOutLine
                        title="Upload"
                        onClick={() => {
                          setIsOpenSelectFileUpload(true)
                          navigate('upload')
                          setShowMenuDetail(true)
                        }}
                      />
                    </WrapButton>
                  </WrapDoubleButton>
                  {!multiSelect && (
                    <WrapButtonBottom>
                      <WrapButtonSelect>
                        <ButtonOutLine title={'Select'} onClick={handleChangeTypeSelect} />
                      </WrapButtonSelect>
                    </WrapButtonBottom>
                  )}

                  {multiSelect && (
                    <WrapDoubleButton>
                      <WrapButton>
                        <ButtonOutLine title="Deselect" onClick={handleChangeTypeSelect} />
                      </WrapButton>
                      <WrapButton>
                        <ButtonOutLine
                          title="Action"
                          onClick={() => {
                            navigate('itemDetail')
                            setShowMenuDetail(true)
                          }}
                        />
                      </WrapButton>
                    </WrapDoubleButton>
                  )}
                </HeaderFileMobile>
              ) : (
                <HeaderFile>
                  <HeaderLeft>
                    <Title> FILES</Title>
                    {pid !== 0 && (
                      <WrapPenDesktop>
                        <WrapIcon onClick={handleEditFolderCurrent}>
                          <Tooltipv2 overlay="Rename" placement="top">
                            <FontAwesomeIcon icon={faPen} />
                          </Tooltipv2>
                        </WrapIcon>
                      </WrapPenDesktop>
                    )}

                    <WrapButton isNewFolder={true}>
                      <ButtonOutLine
                        title=" + New Folder"
                        onClick={() => {
                          navigate('newFolder')
                          setShowMenuDetail(true)
                          setIsOpenSelectFileUpload(false)
                        }}
                      />
                    </WrapButton>
                  </HeaderLeft>
                  <HeaderRight>
                    <WrapButtonTop>
                      <WrapButton>
                        <ButtonOutLine
                          title="Upload"
                          onClick={() => {
                            setIsOpenSelectFileUpload(true)
                            navigate('upload')
                            setShowMenuDetail(true)
                          }}
                        />
                      </WrapButton>
                      <WrapButton select={multiSelect}>
                        <ButtonOutLine title={multiSelect ? 'DeSelect' : 'Select'} onClick={handleChangeTypeSelect} />
                      </WrapButton>
                      {isMobile && (
                        <WrapButton>
                          <ButtonOutLine title="Action" onClick={() => setShowMenuDetail(true)} />
                        </WrapButton>
                      )}
                    </WrapButtonTop>
                    <WrapListIcon>
                      {listView.map((elm) => {
                        return (
                          <WrapIcon
                            onClick={() => {
                              // setListFileTotal([])
                              // setPage(1)
                              setStatusView(elm.id)
                            }}
                            key={elm.id}
                            select={elm.id === statusView}
                          >
                            <Tooltipv2 overlay={elm.tooltip} placement="top">
                              <FontAwesomeIcon icon={elm.icon} />
                            </Tooltipv2>
                          </WrapIcon>
                        )
                      })}
                      <Line />
                      <WrapIcon onClick={() => handelSort('order_by', sort?.order_by === 'id' ? 'name' : 'id')}>
                        <Tooltipv2 overlay="Sort time" placement="top">
                          <FontAwesomeIcon icon={sort?.order_by === 'id' ? faClock : faBullhorn} />
                        </Tooltipv2>
                      </WrapIcon>
                      <WrapIcon onClick={() => handelSort('sort_za', sort?.sort_za === 1 ? 0 : 1)}>
                        <Tooltipv2 overlay="desc" placement="top">
                          <FontAwesomeIcon icon={sort?.sort_za === 1 ? faArrowUpAZ : faArrowUpZA} />
                        </Tooltipv2>
                      </WrapIcon>
                      <WrapIcon
                        onClick={() => {
                          setShowMenuDetail(true)
                          navigate('search')
                          setIsOpenSelectFileUpload(false)
                        }}
                      >
                        <Tooltipv2 overlay="Search" placement="top">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </Tooltipv2>
                      </WrapIcon>
                    </WrapListIcon>
                  </HeaderRight>
                </HeaderFile>
              )}

              <TotalSizeUsed>{(listFolder?.used?.file / 1024).toFixed(2)} MB used of n/a</TotalSizeUsed>

              {!!listNameFolder.length && (
                <WrapNameListFolder>
                  <WrapNameAndBack>
                    {pid !== 0 && (
                      <>
                        <ItemBack onClick={handleBackFolder}>
                          <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                          <span>Back</span>
                        </ItemBack>
                        <LinePortrain />
                        <CurrentFolderName>{listNameFolder[listNameFolder.length - 1]?.name}</CurrentFolderName>
                      </>
                    )}

                    <WrapFolder>
                      {listNameFolder.length > 1 && <span className="root-folder">/</span>}
                      {listNameFolder.map((elm, index) => {
                        if (index !== listNameFolder.length - 1) {
                          return (
                            <div key={uuidv4()}>
                              <span className="next-folder">{`>`}</span>
                              <span className="name-folder">{elm.name}</span>
                            </div>
                          )
                        }
                      })}
                    </WrapFolder>
                  </WrapNameAndBack>
                </WrapNameListFolder>
              )}
              {(listFolder?.list?.length > 0 || listNameFolder.length > 1) && (
                <WrapFolderTop isMobile={isMobile}>
                  <ContentFolder ref={refContentFolder} className="root-folder">
                    <FolderListHeader
                      currentPid={pid}
                      listFolder={listFolder?.list}
                      folderSelect={folderSelect}
                      onSelectFolder={(v) => {
                        handleNextFolder(v)
                        handleSelectFolder(v)
                      }}
                      listNameFolder={listNameFolder}
                      // onNextFolder={handleNextFolder}
                      // onBackFolder={handleBackFolder}
                    />
                  </ContentFolder>
                </WrapFolderTop>
              )}
              {/* <WrapTitleUpload show={showProcesssBar}>Files Uploaded</WrapTitleUpload> */}
              {/* <WrapUploadFiles show={showProcesssBar}>
                <Swiper slidesPerView="auto" spaceBetween={10} modules={[Pagination]}>
                  {renderFileUpload}
                </Swiper>
              </WrapUploadFiles> */}
              <WrapnContentFile ref={refContentFile} isMobile={isMobile}>
                {listFileTotal.length > 0 && (
                  <WrapContentTop>
                    <ContentFile
                      isMobile={isMobile}
                      listDataMedia={listDataMedia[pid] || []}
                      onSelectFileItem={(item) => handleSelectFileItem(item)}
                      onLoading={handleLoadingImgDone}
                      multiSelect={multiSelect}
                      statusView={statusView}
                      listFileTotal={listFileTotal}
                      onShowModal={() => setShowMenuDetail(true)}
                    />
                  </WrapContentTop>
                )}
                {loadingText !== '' && (
                  <WrapLoadingBottom>
                    <WrapLoading>
                      {loadingText !== '- END -' && (
                        <Loader>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderDot></LoaderDot>
                          <LoaderText></LoaderText>
                        </Loader>
                      )}
                      {loadingText === '- END -' && <WrapEnd>- END -</WrapEnd>}
                    </WrapLoading>
                  </WrapLoadingBottom>
                )}
              </WrapnContentFile>
            </Content>
            {/* {showProcesssBar && <ProcessBarPopup>{renderFileUploadProcessing}</ProcessBarPopup>} */}
            {!showPreviewModal ? renderScrollToTOp() : ' '}
            {/* {renderScrollToTOp()} */}
          </Root>
        </WrapRoot>
        <MenuLeft
          ref={refMenuLeft}
          // forwardedRef={refMenuLeft}
          listDataMedia={listDataMedia[pid]}
          onChangeSlide={onChangeSlide}
          onSearch={handleSearch}
          isMobile={isMobile}
          pid={pid}
          showMenuDetail={showMenuDetail}
          onClose={handleOnCloseMenuRightMobile}
          type={pathName}
          itemSelect={itemSelect}
          listFileTotal={listFileTotal}
          multiSelect={multiSelect}
          folderSelect={folderSelect}
          createFolderSuccess={createFolderSuccess}
          onUpload={() => setIsLoading(true)}
          uploadDone={handleUploadDone}
          // cancelFileUpload={handleCancelUploadFiles}
          showPreviewModal={(preview) => {
            // if(filesUpload.length > 0) {
            //   setShowProcesssBar(!preview)
            // }

            setShowPreviewModal(preview)
          }}
          onProcess={(item) => {
            handleProcessUpload(item, filesProcessing)
          }}
          onSendFiles={handleSendFileUpload}
          onDeleteFile={(val) => {
            setTypeDelete(val)
            setIsShowModalDelete(true)
          }}
          onBlockFile={() => {
            setIsShowModalBlock(true)
          }}
          onUnBlockFile={() => {
            setIsShowModalUnBlock(true)
          }}
          updateNameSuccess={updateNameSuccess}
          updateFileNameSuccess={updateFileNameSuccess}
          isOpenSelectFileUpload={isOpenSelectFileUpload}
          onDeleteFolder={() => {
            setShowModalDeleteFoler(true)
          }}
          onAddFileToPlaylistDone={handleAddFileToPlaylistDone}
        />
      </WrapContent>

      {isLoading && <Loading />}

      <CustomModalConfirm
        type="delete"
        title={'Delete this file also affect Playlists & Devices. Are you sure to execute ?'}
        onClose={() => setIsShowModalDelete(false)}
        onYes={handleDeleteFile}
        isShow={isShowModalDelete}
      />

      <CustomModalConfirm
        type="delete"
        title={'Do you want delete this folder ?'}
        onClose={() => setShowModalDeleteFoler(false)}
        onYes={handleDeleteFolder}
        isShow={showModalDeleteFoler}
      />

      <CustomModalConfirm
        title={'Unlock this file also affect Playlists & Devices. Are you sure to execute ?'}
        onClose={() => setIsShowModalUnBlock(false)}
        onYes={handleUnBlockFile}
        isShow={isShowModalUnBlock}
      />
      <CustomModalConfirm
        title={'Lock this file also affect Playlists & Devices. Are you sure to execute ?'}
        onClose={() => setIsShowModalBlock(false)}
        onYes={handleBlockFile}
        isShow={isShowModalBlock}
      />
    </>
  )
}

export default File

const listView = [
  {
    id: 0,
    icon: faBraille,
    tooltip: 'View as Grid',
  },
  {
    id: 1,
    icon: faList,
    tooltip: 'View as List',
  },
  {
    id: 2,
    icon: faTable,
    tooltip: 'View as Table',
  },
]

const ProcessBarPopup = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 370px;
  height: 400px;
  background: #fff;
  z-index: 999;
  border-radius: 0 4px 0 0;
  overflow: hidden;
  border-right: 1px solid #ff6600;
`

const PopupProcessing = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const TitlePopup = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ff6600;
  padding: 0 10px;
`
const TextTitle = styled.div`
  font-size: 16px;
  color: #ccc;
`
const ClosePopup = styled.div`
  width: 40px;
  height: 40px;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  cursor: pointer;
`
const ContentPopup = styled.div`
  width: 100%;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`

const FileItem = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid #ccc;
`

const ImageFileItem = styled.div`
  width: 50px;
  height: 100%;
  background: #ccc;
  border-radius: 5px;
  /* border: 1px solid red; */
`

const ImgPopup = styled.img`
  width: 100%;
  height: 100%;
  /* max-width: 100%; */
  object-fit: contain;
`

const NameFileItem = styled.div`
  flex: 1;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const ProcessingIcon = styled.div`
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WrapContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;

  ${({ mobile }) =>
    mobile &&
    css`
      ${WrapButton} {
        width: 170px;
      }
    `}
`
const WrapRoot = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
`

const HeaderFile = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 12px; */
  margin-top: 10px;
`

const TotalSizeUsed = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 10px;
  color: #999999;
`

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`
const Title = styled.span`
  /* margin-right:  */
  font-size: 22px;
  color: #999999;
`
const HeaderRight = styled.div`
  display: flex;
`

const WrapListIcon = styled.div`
  display: flex;
  align-items: center;
`

const WrapPenMobile = styled.div`
  margin-right: 20px;
`
const WrapPenDesktop = styled.div`
  margin-right: 10px;
`

const WrapIcon = styled.div`
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 102, 0);
  padding: 0 2px;
  border-radius: 4px;
  background-color: ${({ select }) => (select ? 'rgba(255, 102, 0, 0.3)' : 'none')};
  margin-left: ${(props) => (props.mobile ? '12px' : '8px')};
  cursor: pointer;
`

const Line = styled.div`
  width: 2px;
  height: 20px;
  margin-left: 8px;
  background-color: #999999;
`

const WrapButtonTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const WrapButton = styled.div`
  width: ${({ isNewFolder }) => (isNewFolder ? '120px' : '100px')};
  margin: 0 4px;
  .styled_button {
    background: ${({ select }) => (select ? '#FF6600' : 'none')};
    color: ${({ select }) => (select ? '#fff' : '#000')};

    :hover {
      background-color: rgb(255, 102, 0);
      color: #fff;
    }
  }
`

const WrapNameListFolder = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
`

const WrapNameAndBack = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
`

const WrapFolder = styled.div`
  /* position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 8px; */
  max-width: 100%;
  display: flex;
  align-items: center;

  .root-folder {
    color: #38afca;
  }

  .next-folder {
    color: #999999;
    margin: 0 4px;
  }

  .name-folder {
    color: #000;
    white-space: nowrap;
  }
`

const WrapFolderTop = styled.div`
  width: 100%;
  min-height: ${({ isMobile }) => (isMobile ? '115px' : '130px')};
  position: relative;
  overflow: hidden;
`
const WrapTitleUpload = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  font-size: 26px;
  color: #83110f;
  margin-bottom: 8px;
`
const WrapUploadFiles = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 180px;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  align-items: center;
  background: #fff;
  cursor: pointer;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  z-index: 99;
  /* margin-left: -10px;
  margin-right: -10px; */

  & .swiper {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  & .swiper-slide {
    width: 180px !important;
    height: 162px !important;
  }
`

const WrapContentImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  border: 1px solid #ddd;
  border-radius: 15px;
`

const WrapChecked = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;

  svg {
    color: #06befa;
    width: 100%;
    height: 100%;
  }
`

const WrapPercent = styled.div`
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50px;
  height: 50px;
  /* color: red;
  font-size: 20px; */
`

const WrapImage = styled.div`
  width: 100%;
  height: 130px;
  background-color: rgb(220, 220, 220);
  border-radius: 15px 15px 0px 0px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WrapVideo = styled.video`
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WrapName = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    height: 100%;
    color: rgb(134, 134, 134);
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 4px;
  }
`

const ContentFolder = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: auto;
  transition: 0.3s;
`

const HeaderFileMobile = styled.div`
  display: flex;
  flex-direction: column;
`

const WrapTopMobile = styled.div`
  width: 100%;
  display: flex;
`

const WrapDoubleButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0 0px 0;
`
const WrapButtonBottom = styled.div`
  width: 100%;
  /* padding: 0 4px; */
  display: flex;
  justify-content: center;
  margin: 15px 0 10px 0;
  .styled_button {
    background: ${({ select }) => (select ? '#FF6600' : 'none')};
    color: ${({ select }) => (select ? '#fff' : '#000')};

    :hover {
      background-color: rgb(255, 102, 0);
      color: #fff;
    }
  }
`
const WrapButtonSelect = styled.div`
  width: 350px;
`

const WrapnContentFile = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  margin-top: ${({ isMobile }) => (isMobile ? '0px' : '10px')};
`

const WrapContentTop = styled.div`
  width: 100%;
`
const WrapLoadingBottom = styled.div`
  position: relative;
  width: 100%;
  min-height: 80px;
`
const WrapLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = styled.div`
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const LoadingAniText = keyframes`
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
`

const LoaderAniDot = keyframes`
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
`

const LoaderDot = styled.div`
  animation-name: ${LoaderAniDot};
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;

  :first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }
  :nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }
  :nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }
  :nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }
  :nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }
  :nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }
`
const LoaderText = styled.div`
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 4rem;
  margin: auto;
  :after {
    content: 'Loading';
    font-weight: bold;
    animation-name: ${LoadingAniText};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
`
const WrapEnd = styled.div`
  margin-top: 40px;
  font-weight: 500;
`

const Img = styled.img`
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
  padding: 4px;
  border-radius: 4px;
`
const Block = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
  }
`

const ItemBack = styled.div`
  flex: 0 0 80px;
  width: 80px;
  display: flex;
  align-items: center;

  padding: 8px 0;
  cursor: pointer;

  svg {
    height: 24px;
    color: #999999;
  }

  span {
    font-size: 18px;
    font-weight: 500;
    margin-left: 4px;
  }
`

const CurrentFolderName = styled.div`
  flex: 0 0 auto;
  margin-right: 8px;
`

const LinePortrain = styled.div`
  flex: 0 0 2px;
  width: 1px;
  height: 18px;
  border-left: 1.5px solid #807979;
  margin-left: -8px;
  margin-right: 10px;
  margin-bottom: 2px;
`
