import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'src/components/Image'
import SwiperSlideList from 'src/components/SwiperSlideList/SwiperSlideList'

function PlayListItem({ playlist, onClickItem }) {
  
  const [items, setItems] = useState(playlist?.files)
  useEffect(() => {
    const newData = playlist?.files?.map((elm, index) => {
      let src = ''
      if (elm?.type === 'web') {
        if (elm?.ext === 'canv') {
          src = `https://www.canva.com/design/${elm?.url}/screen`
        } else {
          src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
        }
      } else {
        src = elm?.path_thumbnail
      }
      return {
        ...elm,
        path_thumbnail: src,
        // status: elm.status === 0 ? elm.status : playlist.statuses[index],
      }
    })

    setItems(newData)
  }, [playlist])
  return (
    <Root>
      <Header>
        <Title>{playlist?.name || 'n/a'}</Title>
      </Header>
      <WrapContentList>
        {playlist?.files?.length > 0 ? (
          <SwiperSlideList
            activeKeyboard={false}
            fromPage="from_device_add_playlist"
            listDataSource={items || []}
            isShowIconBlock={true}
            onClickSlideItem={() => onClickItem(playlist)}
          />
        ) : (
          <ContentEmpty>
            <span>Empty</span>
          </ContentEmpty>
        )}
      </WrapContentList>
    </Root>
  )
}

export default PlayListItem

const Root = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
`

const WrapContentList = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  & .swiper {
    margin-left: 0;
    margin-right: 0;
  }

  & .swiper-slide {
    width: 140px !important;
    height: 140px !important;
  }

  & .swiper-button-prev {
    left: -6px;
  }

  & .swiper-button-next {
    right: -6px;
  }

  & .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 24px;
    font-weight: 800;
    pointer-events: all;
    color: #ff6600;
  }
`

const WrapContentImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Img = styled.img`
  width: auto;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
`

const ContentEmpty = styled.div`
  flex: 1;
  display: flex;
  font-size: 24px;
  font-weight: 500;
  span {
    margin: auto;
  }
`

const Block = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 10;
  svg {
    color: #ff6600;
  }
`
