import React from "react";
import styled from "styled-components";

const UnControlInputText = React.forwardRef(({ placeholder, ...rest }, ref) => {
  return (
    <Wrap>
      <Input ref={ref} className="styled-input-custom" placeholder={placeholder} {...rest} />
    </Wrap>
  );
});

export default UnControlInputText;

const Wrap = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  min-height: 48px;
  font-size: 14px;
  border: 1px solid #ddd;
  padding: 0 8px;
  border-radius: 10px;

  :focus {
    outline: none;
  }
`;
