import styled from 'styled-components'

export const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
`

export const WrapContent = styled.div`
  width: 360px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 25px 30px -13px rgb(40 40 40 / 40%);
  border: 1px solid #38afca;
`

export const Logo = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    /* width: 100%; */
    aspect-ratio: 16/9;
    /* object-fit: contain; */
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
`
export const WrapInput = styled.div`
  width: 100%;
  display: flex;
  margin: 6px 0;
  flex-direction: column;
`

export const CheckBox = styled.input`
  width: 15px;
  height: 15px;
  cursor: pointer;
`

export const Label = styled.p`
  flex: 1;
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
`

export const Button = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 25px;
  border: 1px solid #d9af93;
  font-size: 18px;
  font-weight: 500;
  color: #d26d2a;
  background: none;
  margin-top: 8px;
  cursor: pointer;
  :hover {
    background-color: #ff6600;
    color: #fff;
  }
`

export const Title = styled.p`
  width: 100%;
  text-align: center;
  margin-bottom: 4px;
  color: rgb(64, 144, 120);
  cursor: pointer;
`

export const TextError = styled.p`
  margin: 6px 0;
  color: #bf1650;
  ::before {
    display: inline;
    content: '⚠ ';
  }
`
