import React, { useLayoutEffect, useState } from 'react'

export default function useCheckIsMobile() {
  const [isMobie, setIsMobile] = useState(false)
  useLayoutEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth <= 1023)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return isMobie
}
