import { faCheck, faCircleMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import handleError from 'src/api/handle-error'
import Schedule from 'src/page/Device/Detail/components/SettingPlaylist/components/Schedule'
import UrlAction from 'src/redux/Url/action'
import { returnDataDate, returnTimeDate } from 'src/utils/render-time'
import ButtonOutLine from '../ButtonOutLine'
import { map } from '../TimeSchedulev2/components/utils'
import { BtnAllTime, Content, TitleSchedule, WrapButton, WrapIconPlus } from './styled'

function ScheduleList({ allTime, onClickBtnAllTime, listSchedule, onSaveSchedule }) {
  const [drafListSchedule, setDrafListSchedule] = useState(listSchedule)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!allTime) {
      if (listSchedule.length === 0) {
        setDrafListSchedule([
          {
            id: new Date().getTime(),
            days: [0, 1, 2, 3, 4, 5, 6],
            end_date: 0,
            end_time: null,
            start_date: 0,
            start_time: null,
          },
        ])
      } else {
        setDrafListSchedule(listSchedule)
      }
    }
  }, [allTime, listSchedule])

  const _addDataToStateSchedule = (currentList, indexRemove) => {
    const wifiList = []

    // const { isAllDate, wifi_list } = this.state
    for (let i = 0; i < currentList.length; i++) {
      if (i === indexRemove) {
        continue
      }

      // doc gia tri hien tai
      const refCheckDay = currentList[i].days

      const days = refCheckDay

      // doc gia tri hien tai
      let start_date = 0,
        end_date = 0

      // if (this.props.isDate !== false) {
      if (allTime) {
        start_date = -1
        end_date = -1
      } else {
        const refStartDate = currentList[i].start_date

        start_date = refStartDate

        // doc gia tri hien tai
        const refEndDate = currentList[i].end_date

        end_date = refEndDate
      }
      // }
      // doc gia tri hien tai
      const refStartTime = currentList[i].start_time

      const start_time = refStartTime

      const rows = {
        days,
        start_time,
      }

      if (days.length > 0 && !start_time) {
        // this.props.dispatch(openNotiBox('Please choose start time'))
        return 'Please choose start time'
      }
      // if (this.props.isDate !== false) {
      rows.start_date = start_date
      rows.end_date = end_date
      // }
      // if (this.props.isEndTime === false) {
      // wifiList.push({ ...rows })
      // continue
      // }
      const refEndTime = currentList[i].end_time

      const end_time = refEndTime

      if (days.length > 0 && end_time === false) {
        // this.props.dispatch(openNotiBox('Please choose start time'))
        return 'Please choose end time'
      }

      wifiList.push({
        ...rows,
        end_time,
      })
    }

    return wifiList
  }

  const handleAddItemSchedule = () => {
    const list = _addDataToStateSchedule(drafListSchedule)

    if (!Array.isArray(list)) {
      toast.error(list)
      return
    }
    const newItemSchedule = {
      id: new Date().getTime(),
      days: [0, 1, 2, 3, 4, 5, 6],
      end_date: 0,
      end_time: null,
      start_date: 0,
      start_time: null,
    }
    dispatch(UrlAction.postHaveChange('device-setting-menuright', 'add-schedule', 'add schedule'))
    setDrafListSchedule([...list, newItemSchedule])
  }

  const handelDeleteItemSchedule = (index) => {
    const list = _addDataToStateSchedule(drafListSchedule, index)
    if (!Array.isArray(list)) {
      toast.error(list)
      return
    }

    dispatch(UrlAction.postHaveChange('device-setting-menuright', 'delete-schedule', 'delete schedule'))

    setDrafListSchedule([...list])
  }

  const handleUpdateItemSchedule = (index, type, value) => {
    if (type === 'all-date' && !value) {
      drafListSchedule[index].start_date = null
      drafListSchedule[index].end_date = 0
    }
    if (type === 'all-date' && value) {
      drafListSchedule[index].start_date = 0
      drafListSchedule[index].end_date = 0
    }

    if (type === 'list_date') {
      let newDay = drafListSchedule[index].days
      if (newDay.includes(value)) {
        drafListSchedule[index].days = newDay.filter((elm) => elm !== value)
      } else {
        drafListSchedule[index].days.push(value)
      }
    } else {
      drafListSchedule[index][type] = value
    }

    dispatch(UrlAction.postHaveChange('device-setting-menuright', 'update-schedule', 'update schedule'))
    return setDrafListSchedule([...drafListSchedule])
  }

  const _saveSchedule = (times) => {
    let data
    if (times && times.length > 0) {
      // convert time
      const timeConvert = []
      let flag = true
      //kiem tra cac gia tri thoi gian
      map(times, (aDay, aIndex) => {
        let start_time = aDay.start_time,
          end_time = aDay.end_time

        if (moment.isDate(aDay.start_time)) {
          const tmps = moment(aDay.start_time)
          start_time = tmps.hours() * 60 + tmps.minutes()
        }

        if (moment.isDate(aDay.end_time)) {
          const tmps = moment(aDay.end_time)
          end_time = tmps.hours() * 60 + tmps.minutes()
        }

        const start_date = aDay.start_date
        const end_date = aDay.end_date

        if (start_date > end_date || (start_date === 0 && end_date > 0)) {
          // bao loi
          toast.error('Schedule list date: row ' + (aIndex + 1))
          flag = false
          return
        }

        if (start_time > end_time || (start_time === 0 && end_time > 0)) {
          // bao loi
          toast.error('Schedule list time: row ' + (aIndex + 1))
          flag = false
          return
        }
        timeConvert.push({
          start_date,
          end_date,
          days: aDay.days,
          start_time,
          end_time,
        })
      })
      if (!flag) return flag
      // kiem tra co bi xung dot, neu co thi bo qua luon
      let isExistsTimepattern = false
      for (let aIndex = 0; aIndex < timeConvert.length; aIndex++) {
        const aDay = timeConvert[aIndex]
        for (let bIndex = aIndex + 1; bIndex < timeConvert.length; bIndex++) {
          try {
            const bDay = timeConvert[bIndex]
            let isExist = false
            // kiem tra danh sach cac ngay trung
            if (aDay.start_date !== 0 && bDay.start_date !== 0) {
              if (aDay.end_date < bDay.start_date || aDay.start_date > bDay.end_date) {
                continue
              }
              // lay day ngay trung nhau

              // if (
              //   aDay.end_date > bDay.start_date &&
              //   aDay.start_date < bDay.start_date
              // ) {

              // }
              let maxStartDate = Math.max(aDay.start_date, bDay.start_date)
              let minEndDate = Math.max(aDay.end_date, bDay.end_date)

              let rangeDate = minEndDate - maxStartDate

              // parse date, month, year
              let tmps = maxStartDate.toString().match(/.{1,2}/g)
              let start_date = '20' + tmps[0] + '/' + tmps[1] + '/' + tmps[2]
              if (moment.isDate(aDay.start_date) || moment.isDate(bDay.start_date)) {
                tmps = maxStartDate
                start_date = tmps
                rangeDate = moment(minEndDate).diff(moment(maxStartDate), 'days')
              }
              const selectedDay = new Date(start_date)
              // day.setDate();
              // day.setDate();
              // day.setDate();
              // day vao ngay dau tien
              const arrDaySelectFromDateTmp = [selectedDay.getDay()]

              for (let i = 0; i < rangeDate; i++) {
                selectedDay.setDate(selectedDay.getDate() + 1)

                const day = selectedDay.getDay()
                if (arrDaySelectFromDateTmp.indexOf(day) > -1) {
                  continue
                }

                arrDaySelectFromDateTmp.push(day)
              }

              // so voi thu dang chon
              const arrDaySelectFromDate = []
              map(arrDaySelectFromDateTmp, (day) => {
                if (aDay.days.indexOf(day) < 0 || bDay.days.indexOf(day) < 0) {
                  return
                }
                // them voa mang co trung lap voi thu
                arrDaySelectFromDate.push(day)
              })

              // neu day ngay khong trung nhau, thi bo qua
              if (arrDaySelectFromDate.length < 1) {
                continue
              }
            }

            map(aDay.days, (day) => {
              if (bDay.days.indexOf(day) > -1) {
                isExist = true
                return false
              }
            })
            if (!isExist) {
              continue
            }

            if (aDay.end_time < bDay.start_time || aDay.start_time > bDay.end_time) {
              continue
            }
            toast.error('Schedule list duplicate time: row ' + (aIndex + 1) + ', ' + (bIndex + 1))

            isExistsTimepattern = true
            break
          } catch (error) {
            handleError('saveSchedule', error || 'Error')
          }
        }
        if (isExistsTimepattern) {
          break
        }
      }
      if (isExistsTimepattern) {
        return
      }
      data = {
        times,
      }
    } else {
      data = {
        times: -1,
      }
    }

    return { ...data }
  }

  const handleSaveSchedule = () => {
    if (!drafListSchedule.length) {
      const data = { times: -1 }
      onSaveSchedule?.(data)
    } else {
      if (!handleValidateSchedule()) {
        return
      }
      const formatData = handleFormatData()
      const data = { times: formatData }
      onSaveSchedule?.(data)
    }
  }

  const handleValidateSchedule = () => {
    // const len = listSchedule.length
    const result = _saveSchedule(drafListSchedule)
    return result
  }

  const handleFormatData = () => {
    const newData = drafListSchedule.map((elm) => {
      return {
        ...elm,
        start_time: returnTimeDate(elm.start_time) + ':0',
        start_date: returnDataDate(elm.start_date),
        end_date: returnDataDate(elm.end_date),
        end_time: returnTimeDate(elm.end_time) + ':0',
      }
    })
    return newData
  }

  return (
    <Content>
      {allTime && (
        <WrapButton>
          <TitleSchedule>Schedule</TitleSchedule>
          <BtnAllTime onClick={onClickBtnAllTime}>
            <FontAwesomeIcon icon={faCheck} />
            All Date and Time
          </BtnAllTime>
        </WrapButton>
      )}
      {!allTime && (
        <WrapButton>
          <BtnAllTime onClick={onClickBtnAllTime}>
            <FontAwesomeIcon icon={faCircleMinus} />
            All Date and Time
          </BtnAllTime>
          <TitleSchedule>
            Schedule
            <WrapIconPlus onClick={handleAddItemSchedule}>
              <FontAwesomeIcon icon={faPlus} />
            </WrapIconPlus>
          </TitleSchedule>
          {!!drafListSchedule.length &&
            drafListSchedule.map((elm, index) => {
              return (
                <Schedule
                  valueSchedule={elm}
                  key={elm.id}
                  onDeleteItemSchedule={() => handelDeleteItemSchedule(index)}
                  onUpdateValue={(type, value) => handleUpdateItemSchedule(index, type, value)}
                />
              )
            })}
        </WrapButton>
      )}

      <WrapButton>
        <ButtonOutLine title="Save Schedule" className="button-save-schedule" onClick={handleSaveSchedule} />
      </WrapButton>
    </Content>
  )
}

export default React.memo(ScheduleList)
