import React, { forwardRef } from 'react'
import styled from 'styled-components'

const ButtonOutLine = forwardRef(({ title, onClick, ...rest }, ref) => {
  return (
    <Button
      className="styled_button"
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
      }}
      {...rest}
      ref={ref}
    >
      {title}
    </Button>
  )
})

export default ButtonOutLine

const Button = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 25px;
  color: #333 !important;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #d9af93;
  background: none;
  cursor: pointer;

  :hover {
    background-color: rgb(255, 102, 0);
    color: #fff !important;
  }
`
