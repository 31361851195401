import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import styled from 'styled-components'
import InputText from '../../../../components/InputText'

function SettingWifiItem({ onDelete, wifi, onChangeName, onChangePass }) {
  // const [isShowWifi, setIsShowWifi] = useState(false)

  return (
    <Root>
      <RowTop>
        <span>WIFI</span>
        <FontAwesomeIcon icon={faTrashCan} onClick={() => onDelete?.()} />
        {/* <WrapIcon>
          <FontAwesomeIcon
            icon={!isShowWifi ? faChevronDown : faChevronUp}
            onClick={() => setIsShowWifi(!isShowWifi)}
          />
        </WrapIcon> */}
      </RowTop>
      <div className={`wifi-content active`}>
        <WrapInput>
          <InputText placeholder={'Wifi name'} valueDefault={wifi?.name} onChange={(v) => onChangeName?.(v)} />
        </WrapInput>
        <WrapInput>
          <InputText placeholder={'Password'} valueDefault={wifi?.password} onChange={(v) => onChangePass?.(v)} />
        </WrapInput>
      </div>
    </Root>
  )
}

export default SettingWifiItem

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  /* .wifi-content {
    display: none;
  }

  .active {
    display: block;
  } */
`

const RowTop = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 18px;
    margin-left: 12px;
    cursor: pointer;
  }
`

const WrapInput = styled.div`
  width: 100%;
  margin-top: 8px;

  .styled-input-custom {
    background: none;
    border: 1px solid;
    border-color: #d9af93;

    :focus {
      border-color: #ff6600;
    }
  }
`

const WrapIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(255, 102, 0);
  border-radius: 50%;
  margin-left: 12px;

  svg {
    height: 18px;
    margin: 0;
    cursor: pointer;
  }
`
