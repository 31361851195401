import React, { useState } from 'react'
import styled from 'styled-components'
import { faFolder, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons'

function FolderListHeader({ listFolder, onSelectFolder, folderSelect, onNextFolder, onBackFolder, listNameFolder }) {
  const handleSelectFolder = (folder) => {
    // if (folderSelect.id === folder.id) {
    //   return onNextFolder?.(folder)
    // }
    onSelectFolder?.(folder)
  }

  return (
    <>
      {!!listNameFolder.length && (
        <ItemBack onClick={onBackFolder}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} />
          <span>Back</span>
        </ItemBack>
      )}
      {!!listFolder?.length &&
        listFolder.map((elm) => {
          return (
            <ItemFolder key={elm.id} onClick={() => handleSelectFolder(elm)}>
              <IconFolder icon={faFolder} />
              <Name>{elm.name}</Name>
              {elm.id === folderSelect.id && (
                <SelectFontDrop>
                  <FontAwesomeIcon icon={faCheck} />
                </SelectFontDrop>
              )}
            </ItemFolder>
          )
        })}
    </>
  )
}

export default FolderListHeader

const ItemBack = styled.div`
  width: 80px;
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 40px;
  padding: 8px 0;
  cursor: pointer;

  svg {
    height: 24px;
    color: #999999;
  }

  span {
    font-size: 18px;
    font-weight: 500;
    margin-left: 4px;
  }
`

const ItemFolder = styled.div`
  min-width: 80px;
  max-width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
`

const SelectFontDrop = styled.div`
  position: absolute;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(56, 175, 202, 0.7);
  border-radius: 8px;
  z-index: 2;

  svg {
    height: 28px;
    color: #fff;
  }
`

const IconFolder = styled(FontAwesomeIcon)`
  width: 100%;
  height: 80px;
  color: #50b6c5;
`

const Name = styled.p`
  max-width: 80px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-top: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
