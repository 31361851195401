import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { map, range } from './components/utils'
import DndContainer from '../DndContainer'
import DndDragItem from './components/DndDragItem'
import update from 'immutability-helper'
import Time from './components/Time'
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { GridItem } from './components/GridItems'
import { SortableList } from './components/SortableList'
import { v4 as uuidv4 } from 'uuid'

const TimeSchedulev2 = (props) => {
  const {
    isMobile,
    defaultDate,
    clickSchedule,
    typeDevice,
    data,
    click,
    clickConflict,
    clickMenu,
    clickPriorityFirst,
    onChangePosition,
  } = props

  const [dataSource, setdataSource] = useState(data)
  const [timesHeader, setTimesHeader] = useState([])
  const [selectedRow, setselectedRow] = useState({})
  const [selectedDate, setselectedDate] = useState(() => defaultDate || new Date())

  const _priority = useRef(null)

  useEffect(() => {
    if (Array.isArray(data)) {
      setdataSource(data)
    }
  }, [data])

  useEffect(() => {
    if (defaultDate) {
      setselectedDate(defaultDate)
    }
  }, [defaultDate])

  useEffect(() => {
    const timesHeaderArr = []
    for (var i = 1; i <= 24; i++) {
      if (i % 2 !== 0) {
        continue
      }
      timesHeaderArr.push(i)
    }
    setTimesHeader(timesHeaderArr)
  }, [])

  useEffect(() => {
    // const playlists = data;
    _priority.current = []
    _handleProps(props, true)
  }, [])

  useEffect(() => {
    _handleProps(props, 3)
  }, [selectedDate, props])

  const handleClick = useCallback(
    (col) => {
      click?.(col)
      setselectedRow(col.id)
    },
    [click]
  )

  // const clickPriorityFirst = useCallback((col) => {
  //   _priority.current = [col.id, ..._priority.current]
  //   sethighPriority(col)
  // }, [])

  const handleClickMenu = useCallback(
    (col) => {
      if (typeof clickMenu === 'function') {
        clickMenu(col)
      }
      setselectedRow(col.id)
    },
    [clickMenu]
  )
  const handleClickConflict = useCallback(
    (col) => {
      if (typeof clickConflict === 'function') {
        clickConflict(col)
      }
      setselectedRow(col.id)
    },
    [clickConflict]
  )

  const handleClickSchedule = useCallback(
    (col) => {
      if (typeof clickSchedule === 'function') {
        clickSchedule(col)
      }
      setselectedRow(col.id)
    },
    [clickSchedule]
  )

  const handlePriorityFirst = useCallback(
    (col) => {
      // const playlists = data
      // const filterPriority = playlists.filter((val) => val.id !== col.id)
      // _priority.current = [col.id, ...filterPriority]
      // _handleProps(props)
      clickPriorityFirst?.(col)
    },
    [clickPriorityFirst]
  )

  const _handleProps = useCallback(
    (props, initState = false) => {
      const playlists = props.data
      if (!Array.isArray(playlists)) {
        return
      }

      // tinh lai priority de dam bao playlist vua them vao luon co index nam duoi

      // _priority.current = [];
      if (initState === 3) {
        const sortableData = _priority.current
        const rowsNoSort = []
        const rowsSort = {}
        map(playlists, (v, i) => {
          // if (v.priority > 0) {
          //   rowsSort[v.id] = v.id
          //   return
          // }

          if (Array.isArray(sortableData) && sortableData.indexOf(v.id) > -1) {
            rowsSort[v.id] = v.id
            return
          }
          rowsNoSort.push(v.id)
        })

        // du lieu dua len can co, fileId, duration, order
        // const totalFile = playlists.length

        const lastRows = []
        map(sortableData, (id) => {
          const v = rowsSort[id]
          if (v !== id) {
            return
          }
          lastRows.push(id)
        })

        map(rowsNoSort, (v, i) => {
          lastRows.push(v)
        })

        _priority.current = [...lastRows]
      }

      const checkTimeConflict = (playlist, timesConvert, index, timeStart, timeEnd) => {
        const timesConflict = []

        const compareCheck = (iObj) => {
          const { playlistCheck, dateStart, dateEnd, timeStartCheck, timeEndCheck } = iObj

          let day = selectedDate?.getDate()
          let month = selectedDate?.getMonth() + 1
          let year = selectedDate?.getFullYear()

          if (day < 10) {
            day = '0' + day
          }

          if (month < 10) {
            month = '0' + month
          }

          const currentDate = year.toString().substr(-2) + month + day
          // const currentDate = selectedDate.getFullYear() + selectedDate.getDate() + selectedDate.getDate();
          if (dateStart !== 0 && dateEnd !== 0 && (dateStart > currentDate || dateEnd < currentDate)) {
            return
          }

          if (timeStartCheck > timesConvert.timeEnd) {
            return
          }

          if (timeEndCheck < timesConvert.timeStart) {
            return
          }

          const timeCheckStart = Math.max(timeStart, timeStartCheck)
          const timeCheckEnd = Math.min(timeEnd, timeEndCheck)

          // vi tri cang nho, cang nam tren thi cang ko bi anh huong
          let posCurrent = 0,
            posCompare = -1
          map(_priority.current, (v, i) => {
            if (v === playlist.id) {
              posCurrent = i
            } else if (v === playlistCheck.id) {
              posCompare = i
            }
          })

          if (playlist.priority === 1) {
            posCurrent = posCompare - 1
          }
          if (playlistCheck.priority === 1) {
            posCurrent = posCompare + 1
          }

          // if (priority_first.includes(.id)) {
          //   return
          // }
          if (posCurrent > posCompare) {
            timesConflict.push({
              id: playlist.id,
              name: playlist.name,
              conflictId: playlistCheck.id,
              conflictName: playlistCheck.name,
              timeEnd: timeCheckEnd,
              timeStart: timeCheckStart,
              priority: playlist?.priority,
            })
          }
        }
        playlists.forEach((playlistCheck, indexCheck) => {
          if (playlistCheck.status !== 1) {
            return
          }

          if (index === indexCheck) {
            return
          }

          if (!Array.isArray(playlistCheck.time_pattern) || playlistCheck.time_pattern.length < 1) {
            const dateStart = Math.ceil(new Date(-8640000000000000).getTime() / 1000) * 1
            const dateEnd = Math.ceil(new Date(8640000000000000).getTime() / 1000) * 1

            let timeStartCheck = 0
            let timeEndCheck = 1439
            compareCheck({
              playlistCheck,
              timeStartCheck,
              timeEndCheck,
              dateStart,
              dateEnd,
            })
          } else {
            playlistCheck.time_pattern.forEach((timesCheck) => {
              // kiem tra time start
              const dateStart = timesCheck.start_date * 1
              const dateEnd = timesCheck.end_date * 1

              let timeStartCheck = 0
              let timeEndCheck = 1439

              if (timesCheck.days.indexOf(currentDay) < 0) {
                return
              }

              timeStartCheck = timesCheck.start_time
              timeEndCheck = timesCheck.end_time

              compareCheck({
                playlistCheck,
                timeStartCheck,
                timeEndCheck,
                dateStart,
                dateEnd,
              })
            })
          }
        })

        return timesConflict
      }

      const currentDay = selectedDate?.getDay()

      const rows = []

      playlists.forEach((playlist, index) => {
        // const row = ;
        const playlistTimes = []
        let timesConflict

        if (!Array.isArray(playlist.time_pattern) || playlist.time_pattern.length < 1) {
          // kiem tra time start
          const dateStart = Math.ceil(new Date(-8640000000000000).getTime() / 1000) * 1
          const dateEnd = Math.ceil(new Date(8640000000000000).getTime() / 1000) * 1

          const timeStart = 0
          const timeEnd = 1439

          const timesConvert = {
            dateStart,
            dateEnd,
            timeStart,
            timeEnd,
            days: range(7),
          }
          playlistTimes.push({
            ...timesConvert,
            id: playlist.id,
            name: playlist.name,
          })

          if (playlist.status !== 1) {
            timesConflict = []

            timesConflict.push({
              id: playlist.id,
              name: playlist.name,
              conflictId: 1,
              conflictName: 'none',
              timeEnd: 1440,
              timeStart: 0,
            })
          } else {
            timesConflict = checkTimeConflict(playlist, timesConvert, index, timeStart, timeEnd)
          }
        } else {
          if (playlist.status !== 1) {
            timesConflict = []
          }

          playlist.time_pattern.forEach((times) => {
            if (times.days.indexOf(currentDay) < 0) {
              return
            }

            // kiem tra time start
            const dateStart = times.start_date * 1
            const dateEnd = times.end_date * 1

            let day = selectedDate?.getDate()
            let month = selectedDate?.getMonth() + 1
            let year = selectedDate?.getFullYear()

            if (day < 10) {
              day = '0' + day
            }

            if (month < 10) {
              month = '0' + month
            }

            const currentDate = year.toString().substr(-2) + month + day
            // const currentDate = selectedDate.getFullYear() + selectedDate.getDate() + selectedDate.getDate();
            if (dateStart !== 0 && dateEnd !== 0 && (dateStart > currentDate || dateEnd < currentDate)) {
              return
            }

            let timeStart = times.start_time
            let timeEnd = times.end_time

            const timesConvert = {
              dateStart,
              dateEnd,
              timeStart,
              timeEnd,
              days: times.days,
            }
            playlistTimes.push({
              ...timesConvert,
              id: playlist.id,
              name: playlist.name,
            })

            if (playlist.status !== 1) {
              timesConflict.push({
                id: playlist.id,
                name: playlist.name,
                conflictId: 1,
                conflictName: 'none',
                timeEnd: timeEnd,
                timeStart: timeStart,
              })
            }
            // else if (playlist.priority == 1) {
            //   timesConflict = [];
            // }
            else {
              timesConflict =
                Array.isArray(timesConflict) && timesConflict.length > 0
                  ? [...timesConflict, ...checkTimeConflict(playlist, timesConvert, index, timeStart, timeEnd)]
                  : checkTimeConflict(playlist, timesConvert, index, timeStart, timeEnd)
            }
          })
        }

        rows.push({
          id: playlist.id,
          name: playlist.name,
          status: playlist.status,
          times: playlistTimes,
          timesConflict,
          time_pattern: playlist.time_pattern,
          priority: playlist?.priority,
        })
      })

      // if (initState === true) {
      //   this.state.data = [...rows];
      //   return;
      // }

      const sortableData = _priority.current

      const rowsNoSort = []
      const rowsSort = {}
      map(rows, (v, i) => {
        if (Array.isArray(sortableData) && sortableData.indexOf(v.id) > -1) {
          rowsSort[v.id] = { ...v }
          return
        }
        rowsNoSort.push({ ...v })
      })

      // du lieu dua len can co, fileId, duration, order
      // const totalFile = rows.length

      const lastRows = []
      map(sortableData, (id) => {
        const v = rowsSort[id]
        if (typeof v !== 'object' || v === null || v.id !== id) {
          return
        }
        lastRows.push({ ...v })
      })

      map(rowsNoSort, (v, i) => {
        lastRows.push({ ...v })
      })

      if (initState === true) {
        setdataSource([...lastRows])
        return
      }

      if (initState === 2) {
        setdataSource([...lastRows])
        return
      }

      setdataSource([...lastRows])
    },
    [selectedDate]
  )

  const findCard = useCallback(
    (id) => {
      const card = dataSource.filter((c, index) => c.id === id)[0]
      return {
        card,
        index: dataSource.indexOf(card),
      }
    },
    [dataSource]
  )

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const { card, index } = findCard(dragIndex)

      const newDataSource = update(dataSource, {
        $splice: [
          [index, 1],
          [hoverIndex, 0, card],
        ],
      })

      _priority.current = newDataSource.map((item) => item.id)
      onChangePosition(newDataSource)
    },
    [dataSource, findCard, onChangePosition]
  )

  const renderItem = useMemo(() => {
    if (Array.isArray(dataSource) && dataSource.length > 0) {
      return dataSource.map((elm, index) => (
        <SortableList
          key={uuidv4()}
          timesHeader={timesHeader}
          playlistItem={elm}
          handleClick={handleClick}
          handleClickMenu={handleClickMenu}
          handleClickConflict={handleClickConflict}
          handleClickSchedule={handleClickSchedule}
          handlePriorityFirst={handlePriorityFirst}
          moveRow={moveRow}
          typeDevice={typeDevice}
          selectedRow={selectedRow}
          isMobile={isMobile}
          index={index}
        />
      ))
    }
  }, [
    dataSource,
    handleClick,
    handleClickMenu,
    isMobile,
    moveRow,
    selectedRow,
    timesHeader,
    typeDevice,
    handleClickConflict,
    handleClickSchedule,
    handlePriorityFirst,
  ])

  function handleDragEnd(event) {
    const { active, over } = event
    if (active.id !== over.id) {
      setdataSource((dataSource) => {
        const oldIndex = dataSource.findIndex((val) => val.id === active.id)
        const newIndex = dataSource.findIndex((val) => val.id === over.id)
        const tmpArr = arrayMove(dataSource, oldIndex, newIndex)
        _priority.current = tmpArr.map((item) => item.id)
        onChangePosition(tmpArr)
        // handleUpdateDataSource(tmpArr)
        return tmpArr
      })
    }
  }

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          overflowX: 'auto',
          backgroundColor: '#FFF',
          // minHeight: '400px',
        }}
      >
        <div
          style={{
            minWidth: 700,
            maxWidth: '100%',
            border: '2px solid #f3f3f3',
            boxSizing: 'border-box',
            backgroundClip: 'padding-box',
            borderRadius: '6px',
          }}
        >
          <Time timesHeader={timesHeader} typeDevice={typeDevice} />
          <div
            style={{
              position: 'relative',
            }}
          >
            {/* <DndContainer isMobile={isMobile}>{renderItem}</DndContainer> */}

            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={dataSource.map((elm) => elm.id)} strategy={rectSortingStrategy}>
                <GridItem>{renderItem}</GridItem>
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeSchedulev2
