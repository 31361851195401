import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import TimePicker from 'rc-time-picker'
import moment from 'moment'

function SchedulePower({ onDelete, onSelectTile, valueSchedule, onChangeTime }) {
  const [startDate, setStartDate] = useState(null)
  const [listDate, setListDate] = useState(listDateData)

  useEffect(() => {
    const { days, start_time } = valueSchedule

    setStartDate(start_time)

    setListDate(days || [])
  }, [JSON.stringify(valueSchedule)])

  return (
    <Root>
      <RowTop>
        <span>Days</span>
        <FontAwesomeIcon icon={faTrashCan} onClick={() => onDelete?.()} />
      </RowTop>
      <RowBottom>
        {listDateData.map((elm, index) => {
          return (
            <Item key={elm.id} select={listDate.includes(elm.id)} onClick={() => onSelectTile?.(index, elm.id)}>
              {elm.title}
            </Item>
          )
        })}
      </RowBottom>
      <WrapTimePicker>
        <TimePicker
          value={startDate ? moment(startDate) : null}
          placeholder="Select time"
          showSecond={false}
          onChange={(e) => {
            setStartDate(e?._d)
            onChangeTime?.(e?._d)
          }}
        />
      </WrapTimePicker>
    </Root>
  )
}

export default SchedulePower

const listDateData = [
  {
    id: '0',
    title: 'Su',
  },
  {
    id: '1',
    title: 'Mo',
  },
  {
    id: '2',
    title: 'Tu',
  },
  {
    id: '3',
    title: 'We',
  },
  {
    id: '4',
    title: 'Th',
  },
  {
    id: '5',
    title: 'Fr',
  },
  {
    id: '6',
    title: 'Sa',
  },
]
const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`

const RowTop = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;

  svg {
    margin-left: 8px;
    cursor: pointer;
  }
`

const RowBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`

const Item = styled.div`
  width: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 1px solid #ff6600;
  border-radius: 15px;
  color: ${({ select }) => (select ? '#fff' : '#000')};
  background-color: ${({ select }) => (select ? '#ff6600' : 'none')};
  cursor: pointer;
`

export const WrapTimePicker = styled.div`
  width: 100%;
  margin-top: 12px;

  .rc-time-picker {
    width: 100%;
    height: 42px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #d9af93;
    background: none;
    cursor: pointer;

    .rc-time-picker-input {
      background: none;
      border: none;
      height: 100%;
      text-align: center;
      font-size: 16px;

      :focus {
        outline: none;
      }
    }
    .rc-time-picker-clear {
      top: 8px;
      right: 10px;

      .rc-time-picker-clear-icon {
        ::after {
          font-size: 18px;
        }
      }
    }
    :hover {
      background-color: rgb(255, 102, 0);
      color: #fff;

      .rc-time-picker-input {
        color: #fff;
      }
    }
  }
`
