import React, { useEffect, useState } from 'react'
import useCheckIsMobile from 'src/hooks/useCheckIsMobile'
import { getHHMMSSfromDurationNumber } from 'src/utils/render-time'
import styled from 'styled-components'
import defaultImage from '../../assets/image/defaultImage.png'
import { faVideoCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import YoutubeLogo from '../../assets/image/youtube.svg'
import CanvaLogo from '../../assets/image/canva.svg'

export default function ImgaeV2({
  handleOnClick,
  item,
  isMultiImg = false,
  showCount = false,
  style,
  onLoading,
  showName = true,
  RenderImg,
  fromPage = '',
}) {
  const [data, setData] = useState(item)
  const [src, setSrc] = useState(() => {
    return isMultiImg ? item.path_thumbnail : item.src
  })
  const isMobile = useCheckIsMobile()

  useEffect(() => {
    setData(item)

    setSrc(isMultiImg ? item.path_thumbnail : item.src)
  }, [item])

  const handleError = (e) => {
    setSrc(defaultImage)
  }

  const propsTypeImg = {
    alt: data?.name || data?.title,
    src,
    onError: handleError,
    onLoad: onLoading,
    style,
  }

  return (
    <Root fromPage={fromPage}>
      <WrapImage isMobile={isMobile} showName={showName} fromPage={fromPage}>
        {data.type === 'video' && (
          <>
            <TimeOfVideo showCount={showCount}>{getHHMMSSfromDurationNumber(data?.duration)}</TimeOfVideo>
            <IconVideo showCount={showCount}>
              <FontAwesomeIcon icon={faVideoCamera} />
            </IconVideo>
          </>
        )}
        {data.ext === 'canv' && (
          <>
            <TimeOfVideo showCount={showCount}>{getHHMMSSfromDurationNumber(data?.duration)}</TimeOfVideo>
            <Icon showName={showName}>
              <img src={CanvaLogo} />
            </Icon>
          </>
        )}
        {data.ext === 'yout' && (
          <>
            <TimeOfVideo showCount={showCount}>{getHHMMSSfromDurationNumber(data?.duration)}</TimeOfVideo>
            <Icon showName={showName}>
              <img src={YoutubeLogo} />
            </Icon>
          </>
        )}
        {RenderImg ? <RenderImg {...propsTypeImg} /> : <Img {...propsTypeImg} />}
      </WrapImage>
      {showName && (
        <WrapName onClick={handleOnClick}>
          <span>{data?.name || data?.title}</span>
        </WrapName>
      )}
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.fromPage == 'from_playlist_main_item' ? '' : ' 1px solid #ddd')};
  border-radius: 15px;
`

const WrapImage = styled.div`
  width: 100%;
  height: ${(props) => (props.isMobile ? '132px' : !props.showName ? '178px' : '162px')};
  background-color: rgb(220, 220, 220);
  border-radius: ${(props) => (props.fromPage == 'from_playlist_main_item' ? '15px' : '15px 15px 0px 0px')};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Img = styled.img`
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
`

const WrapName = styled.p`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    height: 100%;
    color: rgb(134, 134, 134);
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px;
  }
`
const IconVideo = styled.div`
  position: absolute;
  top: ${(props) => (props.showCount ? '26px' : '15px')};
  right: 15px;
  width: 28px;
  display: flex;
  border-radius: 50%;
  height: 28px;
  background-color: #ccc;
  z-index: 10;
  pointer-events: none;
  svg {
    margin: auto;
    color: #fff;
  }
`

const Icon = styled.div`
  position: absolute;
  bottom: ${(props) => (props.showName ? '36px' : '6px')};
  left: 15px;
  width: auto;
  /* background: #ccc; */
  display: flex;
  height: 16px;
  z-index: 10;
  pointer-events: none;
  border-radius: 4px;
  svg {
    margin: auto;
    color: #fff;
  }
`

const TimeOfVideo = styled.div`
  position: absolute;
  top: ${(props) => (props.showCount ? '32px' : '15px')};
  left: 15px;
  width: ${(props) => (props.showCount ? '50px' : '60px')};
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #626262;
  border-radius: 4px;
  z-index: 10;
  font-size: ${(props) => (props.showCount ? '12px' : '14px')};
  color: #fff;
  pointer-events: none;
`
