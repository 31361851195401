import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

function OrientationList({ orientation, onSelectOrientation }) {
  const [select, setSelect] = useState(0)

  useEffect(() => {
    setSelect(orientation)
  }, [orientation])
  return (
    <Root>
      {listOrientation.map((elm) => {
        return (
          <Item
            key={elm.id}
            select={select === elm.id}
            onClick={() => {
              setSelect?.(elm.id)
              onSelectOrientation(elm.id)
            }}
          >
            {elm.title}
          </Item>
        )
      })}
    </Root>
  )
}

export default OrientationList
const listOrientation = [
  { id: 0, title: '0' },
  { id: 1, title: '90' },
  { id: 2, title: '180' },
  { id: 3, title: '270' },
]

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 4px 0;
`

const Item = styled.div`
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 1px solid #ff6600;
  border-radius: 15px;
  padding: 0 4px;
  margin: 0 2px;
  color: ${({ select }) => (select ? '#fff' : '#000')};
  background-color: ${({ select }) => (select ? '#ff6600' : 'none')};
  cursor: pointer;
`
