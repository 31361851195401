import React, { useCallback, useEffect, useState } from 'react'
import DndKitGrid from 'src/components/DndKitGrid'
import styled from 'styled-components'
import ButtonOutLine from '../../../../../../components/ButtonOutLine'
import StackGridList from '../../../../../../components/StackGridList'
import SelectedImage from './SelectdImage'

function ViewGrid({ data, onSelectFileItem, handleDragDrop, multiSelect, isMobile }) {
  const [items, setItems] = useState(data)
  const [dragDrop, setDragDrop] = useState(true)

  useEffect(() => {
    const newData = data.map((elm, index) => {
      let src = ''
      if (elm?.type === 'web') {
        if (elm?.ext === 'canv') {
          src = `https://www.canva.com/design/${elm?.url}/screen`
        } else {
          src = `https://img.youtube.com/vi/${elm?.url}/0.jpg`
        }
      } else {
        src = elm?.path_thumbnail
      }
      return {
        ...elm,
        src: src,
        width: elm.width,
        height: elm.height,
      }
    })

    setItems(newData)
  }, [data])

  const onSortEnd = useCallback(
    (array) => {
      handleDragDrop(array)
    },
    [handleDragDrop]
  )

  return (
    <Root>
      <DndKitGrid dataSource={items} onSelectedItem={onSelectFileItem} handleUpdateDataSource={onSortEnd} />
      {/* <StackGridList
        isMobile={isMobile}
        dragDrop={dragDrop}
        handleDragDrop={onSortEnd}
        dataSource={items}
        onSelectedItem={onSelect}
        CustomRender={SelectedImage}
        dndLayout
      /> */}
    </Root>
  )
}

export default ViewGrid
const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const WrapButton = styled.div`
  width: 120px;
  height: 46px;
  margin-bottom: 12px;

  .styled_button {
    background: ${({ drag }) => (drag ? '#FF6600' : ' none')};
    color: ${({ drag }) => (drag ? '#fff' : ' #000')};
  }
`
