import { faCircleMinus, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import timezones from 'timezones.json'
import ButtonOutLine from '../../../../components/ButtonOutLine'
import { changeUuid } from '../../../../utils/change-uuid'
import { serialize } from '../../../../utils/serialize'
import { devicesApi } from '../../../../api/devicesApi'
import { handleError, handleSuccess } from '../../../../utils/handle-error'

function DuplicateDevice({ info, onClose }) {
  const [driverId, setDriverId] = useState('')
  const [nameDevice, setNameDevice] = useState('')
  const [valueZone, setValueZone] = useState(null)

  useEffect(() => {
    setNameDevice(info?.name || '')
  }, [info])

  const options = []

  timezones.forEach((v) => {
    const value = v.value
    if (value === info?.extra_time_zone && !valueZone) {
      setValueZone({ value, extraData: v.value, label: v.text })
    }
    options.push({
      value: v.offset + '|' + v.value,
      extraData: v.value,
      label: v.text,
    })
  })

  const handleSaveDuplicate = () => {
    if (!driverId) {
      return handleError('Your device ID is empty!')
    }
    if (!nameDevice) {
      return handleError('Your name of device is empty!')
    }
    const uuid = driverId
    const timezone = 0
    const extraTimezone = valueZone.extraData
    const name = nameDevice
    const isDuplicate = 1
    const fromId = info?.id

    const data = { uuid, timezone, extraTimezone, name, isDuplicate, fromId }
    devicesApi
      .createDevice(data)
      .then((res) => {
        // if (!res.success) {
        //   throw res
        // }

        handleSuccess('Save success')
        onClose?.()
      })
      .catch((err) => {
        if (err) {
          handleError('createDevice', err)
        }
      })
  }

  return (
    <Root>
      <WrapTitleDuplicate>
        <FontAwesomeIcon icon={faCircleMinus} onClick={onClose} />
        <span>Duplicate from ID: {changeUuid(info?.uuid)}</span>
      </WrapTitleDuplicate>
      <WrapInput>
        <Input placeholder="your device ID" value={driverId} onChange={(e) => setDriverId(e.target?.value)} />
      </WrapInput>
      <WrapInput>
        <Input placeholder="your name of device" value={nameDevice} onChange={(e) => setNameDevice(e.target?.value)} />
      </WrapInput>
      <WrapInput>
        <span>Time zone:</span>
      </WrapInput>
      <WrapInput>
        <ReactSelect value={valueZone} options={options} styles={customStyles} onChange={(e) => setValueZone(e)} />
      </WrapInput>
      <WrapInput>
        <ButtonOutLine title={'Save Info'} onClick={handleSaveDuplicate} />
      </WrapInput>
    </Root>
  )
}

export default DuplicateDevice

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 42,
    borderColor: '#d9af93',
    borderRadius: 6,
    background: 'none',
    boxShadow: 'none',
  }),
}

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const TitleDetail = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
  border-bottom: 1px solid rgb(204, 204, 204);

  svg {
    height: 22px;
    margin-left: 12px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }
`

const WrapTitleDuplicate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid rgb(255, 102, 0);
  border-radius: 25px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
  }

  svg {
    height: 24px;
    color: rgb(255, 102, 0);
    cursor: pointer;
  }

  :hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    svg {
      color: #fff;
    }
  }
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`
