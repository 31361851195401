import { useDrag, useDrop } from 'react-dnd'
import React, { forwardRef } from 'react'
import Icon from './Icon'
import styles from './stylesGeneral.css'
import {
  WrapIconCopy,
  WrapIconList,
  WrapperCenter,
  WrapperDnd,
  WrapperIcon,
  WrapperScheduleRow,
  WrapperTitle,
} from '../styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsUpDownLeftRight, faList, faStar } from '@fortawesome/free-solid-svg-icons'
import { faClone } from '@fortawesome/free-regular-svg-icons'
import { is_touch_enabled } from './utils'
import { Row } from 'src/components/Grid/styled'
import StarRegular from './StarRegular'
import useCheckIsMobile from 'src/hooks/useCheckIsMobile'
import styled from 'styled-components'
const DND_ITEM_TYPE = 'row'

export const PlaylistItemDrag = forwardRef(
  (
    {
      style,

      col,
      isDragging,
      index,
      moveRow,
      onSelect,
      highPriority,
      typeDevice,
      selectedRow,
      handleClickMenu,
      handleClick,
      handleClickSchedule,
      handleClickConflict,
      timesHeader,
      handlePriorityFirst,
      ...props
    },
    ref
  ) => {
    const isMobile = useCheckIsMobile()
    return (
      <WrapperDnd ref={ref} style={style}>
        <div
          style={{
            paddingLeft: (isMobile ? 150 : 200) + 'px',
            position: 'relative',
            height: 80,
            borderBottom: '2px solid #f3f3f3',
          }}
          dataid={col?.id}
        >
          {col?.status === 0 && (
            <div
              style={{
                position: 'absolute',
                bottom: 10 + 'px',
                left: (isMobile ? 160 : 210) + 'px',
                zIndex: 10,
                padding: '0px 5px',
              }}
            >
              <Icon tooltip="Block" name="fi-locked" style={[styles.font28, styles.txtLight, styles.txtMainActive]} />
            </div>
          )}
          <WrapperScheduleRow
            style={{
              backgroundColor: selectedRow === col?.id ? 'rgba(178,209,324,0.8)' : 'unset',
              width: (isMobile ? 150 : 200) + 'px',
            }}
            onClick={(e) => {
              if (e.target.tagName === 'I') {
                return
              }
              handleClick(col)
            }}
          >
            <WrapRowTop>
              <IconTop
                name="fi-menu"
                onClick={(e) => {
                  e.stopPropagation()
                  handleClickMenu(col)
                }}
              >
                <FontAwesomeIcon icon={faList} />
              </IconTop>

              <WrapText>{col?.name}</WrapText>
            </WrapRowTop>
            <WrapRowBottom>
              {(typeDevice || is_touch_enabled()) && (
                <IconCopy
                  name="fi-copy"
                  className={'my-drag-handle'}
                  style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                  {...props}
                >
                  <FontAwesomeIcon icon={faClone} />
                </IconCopy>
              )}
              <IconCopy
                name="fa-star"
                onClick={(e) => {
                  e.stopPropagation()
                  handlePriorityFirst?.(col)
                }}
              >
                {col.priority === 1 ? <FontAwesomeIcon icon={faStar} /> : <StarRegular />}
              </IconCopy>
            </WrapRowBottom>
          </WrapperScheduleRow>

          <div
            style={{
              position: 'relative',
            }}
          >
            <div
              style={{
                width: '100%',
              }}
            >
              {Array.isArray(timesHeader) &&
                timesHeader.map((v, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: '8.333333333%',
                        float: 'left',
                        height: 80,
                        borderLeft: v === 2 ? 'none' : '2px solid #f3f3f3',
                        marginLeft: v === 2 ? '0px' : '-2px',
                      }}
                    />
                  )
                })}
            </div>

            <div
              style={{
                position: 'absolute',
                width: '100%',
                overflow: 'hidden',
                height: 80,
              }}
            >
              {Array.isArray(col?.times) &&
                col?.times.map((vCol, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        position: 'absolute',
                        width: 100 * ((vCol.timeEnd - vCol.timeStart) / 1440) + '%',
                        left: 100 * (vCol.timeStart / 1440) + '%',
                        height: 80,
                        backgroundColor: '#13b086',
                        background: 'repeating-linear-gradient(0deg, #FFF, #FFF 1px, #13b086 10px, #13b086 10px)',
                        minWidth: 3,
                        zIndex: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleClickSchedule && handleClickSchedule(vCol)
                      }}
                    />
                  )
                })}

              {Array.isArray(col?.timesConflict) &&
                col?.timesConflict.map((vCol, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        position: 'absolute',
                        width: 100 * ((vCol.timeEnd - vCol.timeStart) / 1440) + '%',
                        left: 100 * (vCol.timeStart / 1440) + '%',
                        height: 80,
                        backgroundColor: '#fc037b',
                        background: 'repeating-linear-gradient(0deg, #FFF, #FFF 1px, #fc037b 10px, #fc037b 10px)',
                        minWidth: 3,
                        zIndex: 2,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleClickConflict && handleClickConflict(vCol)
                      }}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      </WrapperDnd>
    )
  }
)

const WrapRowTop = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 54px;
`

const IconTop = styled.div`
  width: 24px !important;
  height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff6600;
  border-radius: 50%;
  z-index: 9;
  cursor: pointer;
  margin: 5px;
  svg {
    height: 12px;
    color: #fff;
  }
`

const WrapText = styled.div`
  flex: 1;
  width: 100%;
  padding-top: 5px;
  font-size: 14px;
  line-height: 16px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
`

const WrapRowBottom = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const IconCopy = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 22px;
    color: #ff6600;
  }
`

// export default React.memo(PlaylistItemDrag)
