import React, { useEffect, useState } from 'react'
import { editorStateFromRaw, editorStateToJSON, MegadraftEditor } from 'megadraft'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'megadraft/dist/css/megadraft.css'
import Select from 'react-select'
import styled from 'styled-components'
import articleApi from '../../../../../api/article'
import { handleError, handleSuccess } from '../../../../../utils/handle-error'
import pluginImage from '../../../CreateArticle/ImagePlugin/plugin'
import { convertToRaw, convertFromRaw } from 'draft-js'
import { optionsOrientation } from './AddArticle'

const UpdateArticle = ({ itemSelected, listCategory, onSuccess, onRemove }) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [articleDetail, setArticleDetail] = useState(itemSelected)
  const [options, setOptions] = useState([])
  const [orientation, setOrientation] = useState({})
  useEffect(() => {
    let defaultList = listCategory.map((l) => {
      if (
        l.id === itemSelected?.theme_category_id_1.toString() ||
        l.id === itemSelected?.theme_category_id_2.toString() ||
        l.id === itemSelected?.theme_category_id_3.toString()
      ) {
        return {
          value: l.id,
          label: l.title,
        }
      }
    })
    defaultList = defaultList.filter(function (element) {
      return element !== undefined
    })

    setOrientation(optionsOrientation[itemSelected.orientation])
    setArticleDetail(itemSelected)
    setSelectedOptions(defaultList)
  }, [itemSelected])

  useEffect(() => {
    const option = listCategory.map((elm) => {
      return { value: elm.id, label: elm.title }
    })
    setOptions(option)
  }, [])

  const handleInputsChange = (e) => {
    const { name, value } = e.target
    setArticleDetail((pre) => ({
      ...pre,
      [name]: value,
    }))
  }

  const handleFileChange = (event) => {
    var file = event.target.files[0]
    const fileType = file['type']
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg']
    if (!validImageTypes.includes(fileType)) {
      return handleError('File not match!')
    }
    var formData = new FormData()
    formData.append('file', file)
    formData.append('filename', file.name)
    formData.append('cms', 1)
    formData.append('pid', -1)

    // post img to server
    articleApi
      .uploadThumbnail(formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setArticleDetail({
          ...articleDetail,
          thumbnail: res.data.path_thumbnail,
        })
      })
      .catch((err) => {
        handleError('uploadThumbnail', err)
      })
  }

  const handleSubmit = () => {
    const { id, title, url_link, preview, description, thumbnail } = articleDetail

    // get thumnail Url

    if (!title) {
      return handleError('Title is required!')
    } else {
      if (!url_link) {
        return handleError('URL link is required!')
      } else {
        if (!preview) {
          return handleError('Preview link is required!')
        } else {
          if (!description) {
            return handleError('Description is required!')
          }
        }
      }
    }

    const param = {
      id,
      title,
      url_link,
      thumbnail,
      preview,
      description,
      content: '',
      status: 1,
      priority: 2,
      orientation: orientation.value,
    }

    const parentIDs = selectedOptions.reduce((acc, cur, index) => {
      acc[`parent_id_${index + 1}`] = parseInt(cur.value)
      return acc
    }, {})

    const newParam = {
      ...param,
      ...parentIDs,
    }

    articleApi
      .updateArticle(newParam)
      .then((res) => {
        if (res.success) {
          setSelectedOptions([])
          setArticleDetail({})
          onSuccess?.('update')
          handleSuccess('Update successfully!')
        }
      })
      .catch((err) => {
        handleError('updateArticle', err)
      })
  }

  const handleDelete = () => {
    const data = { id: itemSelected.id }
    articleApi
      .deleteItem(data)
      .then((res) => {
        console.log('res --> ', res)
      })
      .catch((err) => {
        console.log('err --> ', err)
      })

    console.log('delete -->')
  }

  const customStyle = {
    control: (base, state) => ({
      ...base,
      border: '1px solid #d9af93',
      height: 'auto',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #999999',
      },
    }),
  }

  return (
    <Root>
      <WrapContentTop>
        <Title>UPDATE TEMPLATE</Title>
        {articleDetail.thumbnail && (
          <WrapThumbnail>
            <Img src={articleDetail.thumbnail}></Img>
          </WrapThumbnail>
        )}
        <WrapInputFile>
          <InputFile id="file" type="file" onChange={handleFileChange} />
          <LabelInputFile tabindex="0" for="file">
            {articleDetail.thumbnail ? 'Choose new file ...' : 'Select a file...'}
          </LabelInputFile>
          <WrapIconUpload>
            <FontAwesomeIcon color="rgb(255, 102, 0)" icon={faCloudArrowUp} />
          </WrapIconUpload>
        </WrapInputFile>
        <WrapSelect>
          <Select
            closeMenuOnSelect={false}
            value={selectedOptions}
            onChange={(value) => {
              setSelectedOptions(value)
            }}
            maxMenuHeight={500}
            isMulti
            options={options}
            styles={customStyle}
            isOptionDisabled={() => selectedOptions.length >= 3}
          ></Select>
        </WrapSelect>
        <WrapInputs>
          <Inputs
            name="title"
            value={articleDetail.title}
            onChange={handleInputsChange}
            placeholder="Type ..."
          ></Inputs>
        </WrapInputs>
        <WrapInputs>
          <Inputs
            name="url_link"
            value={articleDetail.url_link}
            onChange={handleInputsChange}
            placeholder="http://url_link"
          ></Inputs>
        </WrapInputs>
        <WrapInputs>
          <Inputs
            name="preview"
            value={articleDetail.preview}
            onChange={handleInputsChange}
            placeholder="http://preview"
          ></Inputs>
        </WrapInputs>
        <WrapSelect>
          <Select
            closeMenuOnSelect={true}
            value={orientation}
            onChange={(value) => {
              setOrientation(value)
            }}
            options={optionsOrientation}
            styles={customStyle}
            defaultValue={optionsOrientation[0]}
          />
        </WrapSelect>
        <WrapInputs>
          <Textarea
            name="description"
            value={articleDetail.description}
            onChange={handleInputsChange}
            placeholder="Type ..."
          ></Textarea>
        </WrapInputs>
      </WrapContentTop>

      <WrapButton submit={true}>
        <ButtonSubmit onClick={handleSubmit}>SAVE CHANGES</ButtonSubmit>
      </WrapButton>

      <WrapButton>
        <ButtonSubmit onClick={onRemove}>DELETE TEMPLATE</ButtonSubmit>
      </WrapButton>
    </Root>
  )
}

export default UpdateArticle

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const WrapContentTop = styled.div`
  width: 100%;
  height: calc(100% - 62px);
  overflow-y: auto;
`

const Title = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const WrapInputFile = styled.div`
  position: relative;
  width: 100%;
  padding: 0 8px;
  margin-top: 12px;
`

const InputFile = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  padding: 10px 0;
  cursor: pointer;
`

const WrapIconUpload = styled.div`
  position: absolute;
  bottom: 5px;
  right: 35px;
  cursor: pointer;
  pointer-events: none;
  & .svg-inline--fa {
    width: 24px;
    height: 24px;
  }
`

const LabelInputFile = styled.label`
  display: block;
  padding: 10px 10px;
  background: #fff;
  border: 1px solid #d9af93;
  color: hsl(0, 0%, 20%);
  font-size: 1em;
  transition: all 0.4s;
  border-radius: 4px;
  cursor: pointer;
`

const WrapThumbnail = styled.div`
  width: 100%;
  height: 200px;
  background: #ccc;
  margin-bottom: 10px;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const WrapInputs = styled.div`
  position: relative;
  width: 100%;
  padding: 0 8px;
`

const WrapSelect = styled.div`
  position: relative;
  width: 100%;
  padding: 12px 8px 0 8px;
`

const Inputs = styled.input`
  width: 100%;
  padding: 0px 10px;
  margin-top: 12px;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  outline: none;
`

const Textarea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 10px;
  margin: 10px 0 20px 0;
  border: 1px solid #d9af93;
  border-radius: 6px;
  outline: none;
`

const WrapButton = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${(props) => (props.submit ? '70px' : '15px')};
  min-height: 42px;
  display: flex;
  justify-content: center;
  padding: 8px;
`
const ButtonSubmit = styled.div`
  width: 100%;
  height: 100%;
  min-height: 42px;
  font-weight: 500;
  border: 1px solid #d9af93;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    cursor: pointer;
  }
`
