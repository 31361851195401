import appConstants from '../constants'
import HttpRequest from './HttpRequest'

const apiUrl = `${appConstants.apiUrl}/embedApp/item`
const canvasApi = Object.freeze({
  getListCanva: (page = 1, limit = 10) =>
    HttpRequest.request({
      method: 'GET',
      url: `${appConstants.apiUrl}/embedApp/list?limit=${limit}&type=canva&page=${page}`,
    }),
  createCanva: (data) =>
    HttpRequest.request({
      method: 'POST',
      url: apiUrl,
      data: data,
    }),
})

export default canvasApi
