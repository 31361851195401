import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import userApi from '../../../api/users'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../utils/handle-error'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPen, faSearch, faXmark, faCircleRight, faCircleLeft } from '@fortawesome/free-solid-svg-icons'
import userGroupApi from '../../../api/userGroup'
import youtubeApi from '../../../api/youtube'
import Image from 'src/components/Image'
import SwiperSlideList from 'src/components/SwiperSlideList/SwiperSlideList'
import ModalPreviewFile from 'src/components/ModalPreviewFile/ModalPreviewFile'
import { WrapTimePicker } from 'src/components/SchedulePower'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import { getNumberFromTime, getTimeFromNumber } from 'src/utils/render-time'
import YouTube from 'react-youtube'
import { useDispatch } from 'react-redux'
import UrlAction from 'src/redux/Url/action'

export default function UpdateYoutube({
  selectedItem,
  onEditSuccess,
  onDeleteSuccess,
  listCanvas,
  onChangeSlide,
  onPreview,
  onRemoveItem,
}) {
  const [item, setItem] = useState(selectedItem[0])
  const [youtubeID, setYoutubeID] = useState('')
  const [youtubeUrl, setYoutubeUrl] = useState('')
  const [validUrl, setValidUrl] = useState(true)
  const [inputsInfo, setInputsInfo] = useState({
    title: selectedItem.title,
    // duration: selectedItem.duration,
  })
  const [preview, setPreview] = useState(false)
  const [indexSelectedSlide, setIndexSelectSlide] = useState(-1)
  const [startTime, setStartTime] = useState(null)
  const [duration, setDuration] = useState(0)
  const [flag, setFlag] = useState(0)

  const dispatch = useDispatch()

  useEffect(() => {
    setItem(selectedItem[0])
    setYoutubeID(selectedItem[0]?.url_link)
    setYoutubeUrl(selectedItem[0]?.url_link)
    setStartTime(getTimeFromNumber(selectedItem[0]?.duration))
    setDuration(selectedItem[0]?.duration)
    setValidUrl(true)
    setFlag(0)
  }, [selectedItem])

  useEffect(() => {
    const idx = listCanvas.findIndex((f) => f?.id === selectedItem[0]?.id)
    setIndexSelectSlide(idx)
  }, [listCanvas, selectedItem])

  const matchYoutubeUrl = (url) => {
    var p =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    if (url.match(p)) {
      return url.match(p)[1]
    }
    return false
  }

  const handleExport = () => {
    const data = { id: item.id }
    youtubeApi
      .exportToFile(data)
      .then((res) => {
        if (res.success) {
          handleSuccess('Export successfully!')
        }
      })
      .catch((err) => handleError('exportToFile', err))
  }

  const handleSubmit = () => {
    if (!item.title) {
      return handleError('Name is required!')
    }
    if (!item.duration) {
      return handleError('Duration is required!')
    }
    if (!youtubeID) {
      return handleError('youtubeID is invalid!')
    }

    const data = {
      id: item.id,
      url_link: youtubeID,
      type: 'youtube',
      thumbnail: `https://img.youtube.com/vi/${youtubeID}/0.jpg`,
      src: `https://img.youtube.com/vi/${youtubeID}/0.jpg`,
      title: item.title,
      duration: duration,
      status: 1,
    }

    youtubeApi
      .updateYoutube(data)
      .then((res) => {
        if (res.success) {
          dispatch(UrlAction.resetHaveChange('update-youtube'))
          setTimeout(() => {
            onEditSuccess?.(data)
          }, 200)
          setYoutubeID('')
          setFlag(0)
          handleSuccess('Update youtube success!')
        }
      })
      .catch((err) => handleError('updateYoutube', err))
  }

  const handldeChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'youtubeUrl') {
      dispatch(UrlAction.postHaveChange('update-youtube', 'Youtube link', 'Youtube link'))

      const match = matchYoutubeUrl(value)
      if (!match) {
        setValidUrl(false)
        setYoutubeID('')
        setYoutubeUrl(value)
      } else {
        setFlag(1)
        setValidUrl(true)
        setYoutubeID(match)
        setYoutubeUrl(match)
      }
    } else {
      dispatch(UrlAction.postHaveChange('update-youtube', 'Youtube title', 'Youtube title'))
    }
    setItem({
      ...item,
      [name]: value,
    })
  }

  const handleShowPreview = (preview) => {
    setPreview(preview)
    onPreview(preview)
  }

  const hanldeChangeDuration = (v) => {
    dispatch(UrlAction.postHaveChange('update-youtube', 'Youtube duration', 'Youtube duration'))

    const d = getNumberFromTime(v)
    setDuration(d)
    setStartTime(v)
  }

  const onReadyYoutubeVideo = (e) => {
    if (flag && youtubeID) {
      const num = e.target.getDuration()
      setDuration(num)
      setStartTime(getTimeFromNumber(num))
    }
  }

  const renderYoutue = useMemo(() => {
    return <YouTube videoId={youtubeID} onReady={onReadyYoutubeVideo} />
  }, [youtubeID])

  return (
    <>
      <WrapYoutube>{renderYoutue}</WrapYoutube>
      <WrapImage>
        {!validUrl && <Image src={require('../../../assets/image/youtube-default.png')} />}
        {validUrl && (
          <>
            <SwiperSlideList
              fromPage="youtube_update"
              listDataSource={listCanvas}
              selectedItem={selectedItem}
              onChangeSlide={onChangeSlide}
              onClickSlideItem={() => handleShowPreview(true)}
              slidesPerView={1}
              isShowIconPreview={true}
              indexSelectedSlide={indexSelectedSlide}
            />
          </>
        )}
        {/* <iframe
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${youtubeID}?playsinline=1&autoplay=1&controls=0&mute=1&loop=1&rel=0&enablejsapi=1&widgetid=1
          `}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="Embedded youtube"
        /> */}
      </WrapImage>
      <TitleDetail>
        <span>Update Your Youtube</span>
      </TitleDetail>
      <TitleItem>
        <span>Youtube link:</span>
      </TitleItem>
      <WrapInput>
        <Input
          type="text"
          name="youtubeUrl"
          placeholder="your URL youtube"
          value={youtubeUrl}
          onChange={handldeChange}
        />
      </WrapInput>
      <TitleItem>
        <span>Youtube title:</span>
      </TitleItem>
      <WrapInput>
        <Input type="text" name="title" placeholder="Title" value={item?.title} onChange={handldeChange} />
      </WrapInput>
      {/* <WrapInput>
        <Input type="number" name="duration" placeholder="Duration" value={item?.duration} onChange={handldeChange} />
      </WrapInput> */}
      <TitleItem>
        <span>Youtube duration:</span>
      </TitleItem>
      <WrapTime>
        <WrapTimePicker>
          <TimePicker
            value={startTime ? moment(startTime) : null}
            name="duration"
            placeholder="Select time"
            showSecond={true}
            onChange={(e) => hanldeChangeDuration(e?._d)}
            inputReadOnly={true}
            // format="HH:mm:ss"
          />
        </WrapTimePicker>
      </WrapTime>
      <WrapInput2>
        <ButtonOutLine onClick={handleSubmit} title={'Save Info'} />
      </WrapInput2>
      <WrapLine>
        <Line />
      </WrapLine>

      <WrapInput2>
        <ButtonOutLine onClick={handleExport} title={'Export to FILES'} />
      </WrapInput2>
      <WrapInput2>
        <ButtonOutLine onClick={onRemoveItem} title={'Remove'} />
      </WrapInput2>
      {preview && (
        <ModalPreviewFile
          from="canva-item-preview"
          listDataSource={listCanvas}
          selectedItem={selectedItem}
          onChangeSlide={onChangeSlide}
          onClose={() => handleShowPreview(false)}
          indexSelectedSlide={indexSelectedSlide}
        />
      )}
    </>
  )
}

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const WrapYoutube = styled.div`
  display: none;
`

const WrapImage = styled.div`
  flex: 1;
  height: 180px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px;
  transform: translateY(-10px);
  background-color: #ccc;
  cursor: pointer;
  position: relative;

  & .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  & .swiper-button-next {
    right: 0;
  }

  & .swiper-button-prev {
    left: 0;
  }

  & .swiper-button-prev:after {
    color: #ff6600;
    pointer-events: all;
  }

  & .swiper-button-next:after {
    color: #ff6600;
    pointer-events: all;
  }

  img {
    height: 100%;
    width: auto;
    max-width: 100%;
  }
`

const WrapIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(1, 1, 1, 0.6);
  border-radius: 50%;
  bottom: 4px;
  left: 4px;
`

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
`

const TitleItem = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #7c7a7a;
  padding: 8px;
  margin-top: 4px;
`
const WrapTime = styled.div`
  width: 100%;
  padding: 0 8px;
  margin-top: -12px;
`
const WrapLine = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  margin-top: 30px;
`
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #ccc;
`
const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  /* margin-top: 12px; */

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const WrapInput2 = styled.div`
  width: 100%;
  padding: 0 10px;
  margin-top: 12px;

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 12px;
  margin-left: 12px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin-left: 5px;
`

const ButtonID = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d9af93;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 10px;
  }
  :hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    span {
      color: #fff;
    }
  }
`
