import React, { Component } from 'react'
import { MegadraftIcons, MegadraftPlugin } from 'megadraft'
const { BlockContent, BlockData, BlockInput, CommonBlock } = MegadraftPlugin

export default class ImageBlock extends Component {
  render() {
    const blockActions = [
      {
        key: 'delete',
        icon: MegadraftIcons.DeleteIcon,
        action: this.props.container.remove,
      },
    ]

    return (
      <CommonBlock actions={blockActions} {...this.props}>
        <BlockContent>
          <img src={this.props.data.src} />
        </BlockContent>

        <BlockData>
          <BlockInput placeholder="Enter an image caption" />
        </BlockData>
      </CommonBlock>
    )
  }
}
