import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import ButtonOutLine from 'src/components/ButtonOutLine'
import useCheckIsMobile from 'src/hooks/useCheckIsMobile'
import styled from 'styled-components'

function Time({ typeDevice, timesHeader }) {
  const isMobile = useCheckIsMobile()
  const [startDate, setStartDate] = useState('')

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => {
    // console.log('value -->', value)
    const text = value.length > 0 ? value : 'select date'
    return (
      <ButtonCustom onClick={onClick} isMobile={isMobile}>
        {text}
      </ButtonCustom>
    )
  })

  return (
    <div
      style={{
        position: 'relative',
        height: 60,
        borderBottom: '2px solid #f3f3f3',
        paddingLeft: isMobile ? 150 : 200,
      }}
    >
      <WrapButtonSchedule>
        <WrapBtnTime isMobile={isMobile}>
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date)
            }}
            customInput={<ExampleCustomInput />}
          />
        </WrapBtnTime>
      </WrapButtonSchedule>

      <div
        style={{
          position: 'relative',
        }}
      >
        <div
          style={{
            width: '100%',
          }}
        >
          {Array.isArray(timesHeader) &&
            timesHeader.map((v, i) => {
              return (
                <div
                  key={i}
                  style={{
                    width: '8.333333333%',
                    float: 'left',
                    position: 'relative',
                    height: 60,
                    borderLeft: v === 2 ? 'none' : '2px solid #f3f3f3',
                    marginLeft: v === 2 ? '0px' : '-2px',
                  }}
                >
                  <span
                    style={{
                      margin: 0,
                      padding: 0,
                      border: 0,
                      outline: 0,
                      verticalAlign: 'baseline',
                      boxSizing: 'border-box',
                      color: '#828C91',
                      fontSize: 16,
                      fontWeight: 600,
                      fontStyle: 'normal',
                      position: 'absolute',
                      left: '100%',
                      top: '50%',
                      transform: 'translate3d(-50%,-50%,0)',
                      zIndex: 20,
                      backgroundColor: '#fff',
                      height: 40,
                      lineHeight: '40px',
                    }}
                  >
                    {v === 24 ? '' : v + ':00'}
                  </span>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default Time

const WrapButtonSchedule = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`

const WrapBtnTime = styled.div`
  width: ${({ isMobile }) => (isMobile ? '150px' : '200px')};
  display: flex;
  margin-right: 12px;
  border-right: 2px solid rgb(243, 243, 243);

  .react-datepicker-popper {
    z-index: 999;
  }
`
const ButtonCustom = styled.button`
  width: ${({ isMobile }) => (isMobile ? '150px' : '200px')};
  height: 60px;
  outline: none;
  border: none;
  background: transparent;
  font-size: ${({ isMobile }) => (isMobile ? '14px' : '18px')};
  color: #000;
`
