import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import ButtonOutLine from '../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../utils/handle-error'
import { Root } from '../Home/components/ContentHomePage'
import { paramCase } from 'change-case'
import { toNonAccentVietnamese } from '../../utils/remove-alias'

import categoryApi from '../../api/category'
import { serialize } from '../../utils/serialize'
import UserGroupAddTab from './TabMenuRight/UserGroupAddTab'
import UserGroupEditTab from './TabMenuRight/UserGroupEditTab'

export default function UserGroupMenuRight({
  isMobile,
  showMenuDetail,
  selectedItem,
  typeMenuRight,
  onSuccess,
  onDeleteUserGroup,
  onCloseMenuRight,
}) {
  return (
    <>
      <MenuDetail mobile={isMobile} show={showMenuDetail}>
        <Root>
          <Content>
            {typeMenuRight === 'add-user-group' && <UserGroupAddTab onAddSuccess={onSuccess} />}
            {typeMenuRight === 'edit-user-group' && (
              <UserGroupEditTab
                selectedItem={selectedItem || []}
                onEditSuccess={onSuccess}
                onDeleteUserGroup={onDeleteUserGroup}
              />
            )}
          </Content>
        </Root>
      </MenuDetail>
      {isMobile && showMenuDetail && <BackDrop onClick={onCloseMenuRight} />}
    </>
  )
}

const MenuDetail = styled.div`
  position: ${({ mobile }) => (mobile ? 'absolute' : 'relative')};
  top: 0;
  right: 0;
  width: 320px;
  height: 100%;
  display: flex;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) -2px 2px 4px;
  transform: ${({ show, mobile }) => (!mobile ? '' : show ? 'translateX(0px)' : 'translateX(360px)')};
  transition: transform 0.3s;
  overflow: hidden;
  z-index: 92;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`
