import { faClock } from '@fortawesome/free-regular-svg-icons'
import {
  faArrowUpAZ,
  faArrowUpZA,
  faBraille,
  faBullhorn,
  faList,
  faTable,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useMemo, useState, useRef } from 'react'
import GridFileList from 'src/components/GridFileList/GridFileList'
import Tooltipv2 from 'src/components/ToolTipv2'
import styled from 'styled-components'
import articleApi from '../../../../api/article'
import categoryApi from '../../../../api/category'
import { convertObjectToArray, getListAllCategory } from '../../../../utils/convertListCategory'
import { handleError, handleSuccess } from '../../../../utils/handle-error'
import SelectBox from '../../../Categories/components/SelectBox'
import { Root } from '../../../Home/components/ContentHomePage'
import ContentArticle from './ContentArticle'
import MenuRight from './MenuRight'
import ModalPreviewImage from './ModalPreviewImage'
import { v4 as uuidv4 } from 'uuid'
import { object } from 'underscore'
import favouriteApi from 'src/api/favourite'

const limit = 50

function ListArticleTheme({ isMobile }) {
  const [statusView, setStatusView] = useState(0)
  const [listArticle, setListArticle] = useState([])
  const [itemsSelected, setItemsSelected] = useState([])
  const [listCategory, setListCategory] = useState([])
  const [isNew, setIsNew] = useState(1)
  const [typeMenuRight, setTypeMenuRight] = useState('search')
  const [showMenuDetail, setShowMenuDetail] = useState(false)
  const [isLastPage, setIsLastPage] = useState(false)
  const [page, setPage] = useState(1)
  const refLoadMore = useRef()

  const [filterValues, setFilterValues] = useState({
    category: '',
    orientation: 0,
    isNew: 1,
  })

  useEffect(() => {
    categoryApi
      .getListCategory()
      .then((res) => {
        const data = res.data.children
        const listCate = convertObjectToArray(data)
        const resultCategory = getListAllCategory(listCate)

        setListCategory(resultCategory)
      })
      .catch((err) => {
        handleError('getListCategory', err)
      })
  }, [])

  useEffect(() => {
    setShowMenuDetail(false)
  }, [isMobile])

  useEffect(() => {
    const pid = filterValues.category
    const { orientation, isNew } = filterValues
    const resetPage = 1
    setPage(resetPage)
    articleApi
      .getListAricle(resetPage, limit, pid, orientation, isNew)
      .then((res) => {
        const articles = res.data
        if (articles.length < limit) {
          setIsLastPage(true)
        } else {
          setIsLastPage(false)
        }

        const resultArticle = articles.map((elm, idx) => {
          return { ...elm, select: false }
        })
        setListArticle(resultArticle)
      })
      .catch((err) => {
        handleError('getListAricle', err)
      })
  }, [filterValues])

  const selectboxCategoryStyle = useMemo(() => ({ width: '250px', height: '42px', fontSize: 15 }), [])

  const selectboxOrientationStyle = useMemo(() => ({ width: '160px', height: '42px', fontSize: 15 }), [])

  const handleFilterChange = (value) => {
    if (value.name) {
      setFilterValues({
        ...filterValues,
        orientation: value.id,
      })
    } else {
      setFilterValues({
        ...filterValues,
        category: parseInt(value.id) || '',
      })
    }
  }

  const handleCheckedNew = (isNew) => {
    setFilterValues({
      ...filterValues,
      isNew,
    })
  }

  const handleItemsSelected = (item) => {
    const newList = listArticle.map((elm) => {
      if (elm.id === item.id) {
        return {
          ...elm,
          select: elm.select ? false : true,
        }
      }
      return {
        ...elm,
        select: false,
      }
    })

    const itemSelected = newList.filter((elm) => elm.select === true)
    if (itemSelected.length < 1) {
      setTypeMenuRight('search')
    } else {
      setTypeMenuRight('detail')
      setShowMenuDetail(true)
    }

    setListArticle(newList)
    setItemsSelected(itemSelected)
  }

  const hanldeCloseModalPreview = () => {
    const listTmp = [...listArticle]
    const list = listTmp.map((elm) => {
      return {
        ...elm,
        select: false,
      }
    })
    setListArticle(list)
    setTypeMenuRight('search')
    setItemsSelected([])
  }

  const handleChangeSlide = (currentIdx) => {
    const tmpList = [...listArticle]
    const list = tmpList.map((elm, index) => {
      if (index === currentIdx) {
        return {
          ...elm,
          select: true,
        }
      }
      return {
        ...elm,
        select: false,
      }
    })
    setItemsSelected(list.filter((v) => v.select === true))
    setListArticle(list)
  }

  const handeLoadMore = () => {
    const pageNum = page + 1
    setPage(pageNum)
    const pid = filterValues.category
    const { orientation, isNew } = filterValues
    articleApi
      .getListAricle(pageNum, limit, pid, orientation, isNew)
      .then((res) => {
        const data = res.data

        if (data.length < limit) {
          setIsLastPage(true)
        }

        setListArticle([...listArticle, ...data])
      })
      .catch((err) => {
        if (err) {
          handleError('getListAricle', err)
        }
      })
  }

  const hanldeCloseMemuRight = () => {
    const newListData = [...listArticle]
    const newList = newListData.map((elm) => {
      return {
        ...elm,
        select: false,
      }
    })
    setListArticle(newList)
    setTypeMenuRight('search')
    setShowMenuDetail(false)
    setItemsSelected([])
  }

  const handleCheckFavourite = (item) => {
    if (!item) return
    const tmpList = [...listArticle]
    const newList = tmpList.map((elm) => {
      if (elm.id === item.id) {
        const current = item?.favourite

        if (current) {
          favouriteApi
            .deleteItem({
              tid: item.id,
            })
            .then((res) => handleSuccess('Delete Success'))
            .catch((err) => handleError('deleteItem', err))
        } else {
          favouriteApi
            .addFavouriteItem({
              id: item.id,
            })
            .then((res) => handleSuccess('Add to favourite Success'))
            .catch((err) => console.log(err))
        }
        return {
          ...elm,
          favourite: !current,
        }
      }
      return elm
    })

    setListArticle(newList)
  }

  const handleOpenTabSearch = () => {
    setShowMenuDetail(true)
    setTypeMenuRight('search')
    setItemsSelected([])
  }

  // const onSearchSuccess = (listUser) => {
  // }

  return (
    <>
      <FullScreen>
        <FullBlockLeft>
          <Root>
            <WrapBlockLeft ref={refLoadMore}>
              <HeaderBlockLeft>
                <span> TEMPLATES </span>

                <WrapListIcon>
                  {viewTypes.map((elm) => {
                    return (
                      <WrapIcon onClick={() => setStatusView(elm.id)} key={elm.id} select={elm.id === statusView}>
                        <Tooltipv2 overlay={elm.title} placement="top">
                          <FontAwesomeIcon icon={elm.icon} />
                        </Tooltipv2>
                      </WrapIcon>
                    )
                  })}
                  {/* <Line />
                  <WrapIcon
                  >
                    <Tooltipv2 overlay="Sort time" placement="top">
                      <FontAwesomeIcon icon={true ? faClock : faBullhorn} />
                    </Tooltipv2>
                  </WrapIcon>
                  <WrapIcon>
                    <Tooltipv2 overlay="desc" placement="top">
                      <FontAwesomeIcon icon={true ? faArrowUpAZ : faArrowUpZA} />
                    </Tooltipv2>
                  </WrapIcon> */}
                  <WrapIcon onClick={handleOpenTabSearch}>
                    <Tooltipv2 overlay="Search" placement="top">
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Tooltipv2>
                  </WrapIcon>
                </WrapListIcon>
              </HeaderBlockLeft>

              <GridFileList
                dataSource={listArticle || []}
                onLoadMore={handeLoadMore}
                refLoadMore={refLoadMore}
                isLastPage={isLastPage}
                statusView={statusView}
                onSelectItem={(item) => handleItemsSelected(item)}
                selectedItem={itemsSelected}
                fromPage={'template'}
                onCheckFavourite={handleCheckFavourite}
              />

              {/* <ContentArticle
                isMobile={isMobile}
                listArticle={listArticle}
                onSelectedItem={(item) => handleItemsSelected(item)}
                statusView={statusView}
              /> */}
            </WrapBlockLeft>
          </Root>
        </FullBlockLeft>

        <MenuRight
          isMobile={isMobile}
          typeMenuRight={typeMenuRight}
          showMenuDetail={showMenuDetail}
          listArticle={listArticle}
          selectedItem={itemsSelected}
          onChangeSlide={handleChangeSlide}
          listCategory={listCategory}
          onCheckedNew={handleCheckedNew}
          onFilter={handleFilterChange}
          onCloseMenuRight={hanldeCloseMemuRight}
          // onSearchSuccess={onSearchSuccess}
        />

        {/* {Object.keys(itemsSelected).length > 0 && (
          <ModalPreviewImage
            onNextItem={onNextItem}
            onPreItem={onPreItem}
            itemsSelected={itemsSelected}
            onClose={hanldeCloseModalPreview}
          />
        )} */}
      </FullScreen>

      {/* {isLoading && <Loading />} */}

      {/* <CustomModalConfirm
        title={
          "Delete this file also affect Playlists & Devices. Are you sure to execute ?"
        }
        onClose={() => setIsShowModalDelete(false)}
        onYes={handleDeleteFile}
        isShow={isShowModalDelete}
      />
      <CustomModalConfirm
        title={
          "Unlock this file also affect Playlists & Devices. Are you sure to execute ?"
        }
        onClose={() => setIsShowModalUnBlock(false)}
        onYes={handleUnBlockFile}
        isShow={isShowModalUnBlock}
      />
      <CustomModalConfirm
        title={
          "Lock this file also affect Playlists & Devices. Are you sure to execute ?"
        }
        onClose={() => setIsShowModalBlock(false)}
        onYes={handleBlockFile}
        isShow={isShowModalBlock}
      /> */}
    </>
  )
}

export default ListArticleTheme

const viewTypes = [
  {
    id: 0,
    title: 'View as Grid',
    icon: faBraille,
  },
  {
    id: 1,
    title: 'View as List',
    icon: faList,
  },
  {
    id: 2,
    title: 'View as Table',
    icon: faTable,
  },
]

const FullScreen = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
`
const FullBlockLeft = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`

const WrapBlockLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
`

const HeaderBlockLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  color: #999999;
  margin-bottom: 20px;
`

const WrapListIcon = styled.div`
  display: flex;
  align-items: center;
`

const WrapIcon = styled.div`
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 102, 0);
  padding: 0 2px;
  border-radius: 4px;
  background-color: ${({ select }) => (select ? 'rgba(255, 102, 0, 0.3)' : 'none')};
  margin-left: 8px;
  cursor: pointer;
`

const Line = styled.div`
  width: 2px;
  height: 20px;
  margin-left: 8px;
  background-color: #999999;
`

const WrapInputSearch = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
`
const WrapSelect = styled.div`
  width: auto;
  height: auto;
  margin-left: 20px;
  color: #000;
`

const WrapCheckbox = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #e74c3c;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;
`
const WrapInputSearchMobile = styled.div`
  width: 100%;
  display: flex;
  z-index: 10;
`

const WrapSelectMobile = styled.div`
  width: 140px;
  height: 32px;
  color: #000;

  :first-child {
    width: 160px;
    margin-right: 10px;
  }
`
