import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core'
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { useEffect, useState } from 'react'
import { checkTouchScreen } from 'src/utils/checkTouchScreen'

import { GridPhoto } from './GridPhoto'
import { SortablePhoto } from './SortablePhoto'

const DndKitGrid = ({ dataSource, onSelectedItem, handleUpdateDataSource }) => {
  const [items, setItems] = useState(dataSource)
  const [dragged, setDragged] = useState(false)
  const isEnableTouch = checkTouchScreen()

  const sensorsNotTouch = useSensors(
    useSensor(MouseSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  )
  const sensorsTouch = useSensors(useSensor(MouseSensor), useSensor(TouchSensor))

  useEffect(() => {
    setItems(dataSource)
  }, [dataSource])

  function handleDragEnd(event) {
    const { active, over } = event

    if (active && over && active?.id !== over?.id) {
      setDragged(true)
      setItems((items) => {
        const oldIndex = items.findIndex((val) => val.idv4 === active.id)
        const newIndex = items.findIndex((val) => val.idv4 === over.id)
        const tmpArr = arrayMove(items, oldIndex, newIndex)

        handleUpdateDataSource(tmpArr)
        return tmpArr
      })
    }
  }

  function handleDragStart(e) {
    setDragged(false)
    // console.log('start ---->', e)
  }

  return (
    <DndContext
      sensors={isEnableTouch ? sensorsTouch : sensorsNotTouch}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext items={items.map((elm) => elm.idv4)} strategy={rectSortingStrategy}>
        <GridPhoto dataSource={dataSource}>
          {items.map((elm, index) => (
            <SortablePhoto key={elm.idv4} photo={elm} onSelectedItem={onSelectedItem} dragged={dragged} />
          ))}
        </GridPhoto>
      </SortableContext>
    </DndContext>
  )
}

export default DndKitGrid
