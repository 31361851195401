import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import userGroupApi from '../../../api/userGroup'
import userApi from '../../../api/users'
import ButtonOutLine from '../../../components/ButtonOutLine'
import { handleError, handleSuccess } from '../../../utils/handle-error'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPen, faSearch, faXmark, faCircleRight, faCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { get } from 'sortablejs'
import canvasApi from '../../../api/canvas'
import youtubeApi from '../../../api/youtube'
import Image from 'src/components/Image'
import { WrapTimePicker } from 'src/components/SchedulePower'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import { getNumberFromTime, getTimeFromNumber } from 'src/utils/render-time'
import YouTube from 'react-youtube'
import { useDispatch } from 'react-redux'
import UrlAction from 'src/redux/Url/action'

export default function AddYoutube({ onCreateSuccess }) {
  const [preview, setPreview] = useState(false)
  const [canvaUrl, setCanvaUrl] = useState('')
  const [youtubeID, setYoutubeID] = useState('')
  const [validUrl, setValidUrl] = useState(false)
  const [inputsInfo, setInputsInfo] = useState({
    title: '',
  })
  const [startTime, setStartTime] = useState(null)
  const [duration, setDuration] = useState(0)

  const dispatch = useDispatch()

  const handleChangeCanvaUrl = (e) => {
    const url = e.target.value
    const isValidYtbUrl = matchYoutubeUrl(url)
    dispatch(UrlAction.postHaveChange('add-youtube', 'Youtube link', 'Youtube link'))

    if (isValidYtbUrl) {
      setValidUrl(true)
      setYoutubeID(isValidYtbUrl)
      setCanvaUrl(isValidYtbUrl)
    } else {
      setValidUrl(false)
      setYoutubeID('')
      setCanvaUrl(url)
    }
  }

  const matchYoutubeUrl = (url) => {
    var p =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    if (url.match(p)) {
      return url.match(p)[1]
    }
    return false
  }

  const handleChangeInfo = (e) => {
    const name = e.target.name
    const value = e.target.value

    dispatch(UrlAction.postHaveChange('add-youtube', 'Youtube title', 'Youtube title'))

    setInputsInfo({
      ...inputsInfo,
      [name]: value,
    })
  }

  const hanldeChangeDuration = (v) => {
    dispatch(UrlAction.postHaveChange('add-youtube', 'Youtube duration', 'Youtube duration'))
    const d = getNumberFromTime(v)
    setDuration(d)
    setStartTime(v)
  }

  const handleSubmit = () => {
    const { title } = inputsInfo
    if (!title) {
      return handleError('Name is required!')
    }
    if (!duration) {
      return handleError('Duration is required!')
    }
    if (!youtubeID) {
      return handleError('Youtube URL is invalid!')
    }

    const data = {
      url_link: youtubeID,
      type: 'youtube',
      thumbnail: `https://img.youtube.com/vi/${youtubeID}/0.jpg`,
      title,
      duration: duration,
      status: 1,
    }

    youtubeApi
      .createYoutube(data)
      .then((res) => {
        if (res.success) {
          setCanvaUrl('')
          setInputsInfo({
            title: '',
          })
          setYoutubeID('')
          setValidUrl(false)
          // window.removeChange('add-youtube')
          dispatch(UrlAction.resetHaveChange('add-youtube'))
          handleSuccess('Create youtube success!')

          // handle create success
          if (res.data.id) {
            data.id = res.data.id
          }
          onCreateSuccess?.(data)
        }
      })
      .catch((err) => handleError('createYoutube', err))
  }

  const handleExport = () => {}

  const onReadyYoutubeVideo = (e) => {
    if (youtubeID) {
      const num = e.target.getDuration()
      setDuration(num)
      setStartTime(getTimeFromNumber(num))
    }
  }

  const renderYoutue = useMemo(() => {
    return <YouTube videoId={youtubeID} onReady={onReadyYoutubeVideo} />
  }, [youtubeID])

  return (
    <>
      <WrapYoutube>{renderYoutue}</WrapYoutube>

      <WrapImage>
        {!validUrl && <Image src={require('../../../assets/image/youtube-default.png')} />}
        {validUrl && (
          <>
            <Image src={`https://img.youtube.com/vi/${youtubeID}/0.jpg`} />
          </>
        )}
      </WrapImage>
      <TitleDetail>
        <span>Add Your Youtube</span>
      </TitleDetail>
      <TitleItem>
        <span>Youtube link:</span>
      </TitleItem>
      <WrapInput>
        <Input
          type="text"
          name="canvaUrl"
          placeholder="your URL youtube"
          value={canvaUrl}
          onChange={handleChangeCanvaUrl}
        />
      </WrapInput>
      <TitleItem>
        <span>Youtube title:</span>
      </TitleItem>
      <WrapInput>
        <Input type="text" name="title" placeholder="Title" value={inputsInfo.title} onChange={handleChangeInfo} />
      </WrapInput>
      {/* <WrapInput>
        <Input
          type="number"
          name="duration"
          placeholder="Duration"
          value={inputsInfo.duration}
          onChange={handleChangeInfo}
        />
      </WrapInput> */}
      <TitleItem>
        <span>Youtube duration:</span>
      </TitleItem>

      <WrapTime>
        <WrapTimePicker>
          <TimePicker
            value={startTime ? moment(startTime) : null}
            name="duration"
            placeholder="Select time"
            showSecond={true}
            onChange={(e) => hanldeChangeDuration(e?._d)}
            inputReadOnly={true}
            defaultOpenValue={moment().startOf('day')}
            // format="HH:mm:ss"
          />
        </WrapTimePicker>
      </WrapTime>

      {/* <WrapInput>
        <ButtonOutLine onClick={handleExport} title={'Export to File'} />
      </WrapInput> */}

      <WrapInput>
        <ButtonOutLine onClick={handleSubmit} title={'Save Info'} />
      </WrapInput>
    </>
  )
}

const WrapYoutube = styled.div`
  display: none;
`

const WrapImage = styled.div`
  flex: 1;
  height: 180px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 -10px;
  transform: translateY(-10px);
  background-color: #ccc;
  cursor: pointer;
  position: relative;

  img {
    height: 100%;
    width: auto;
    max-width: 100%;
  }
`

const WrapIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: rgba(1, 1, 1, 0.6);
  border-radius: 50%;
  bottom: 4px;
  left: 4px;
`

const TitleDetail = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 8px;
  /* margin-bottom: 16px; */
`

const TitleItem = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #7c7a7a;
  padding: 8px;
  margin-top: 4px;
`

const WrapTime = styled.div`
  width: 100%;
  padding: 0 8px;
  margin-top: -12px;
`

const WrapInput = styled.div`
  width: 100%;
  padding: 0 10px;
  /* margin-top: 12px; */

  :last-child {
    margin-top: 20px;
  }

  span {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }
`

const Input = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #d9af93;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 12px;

  :focus {
    outline: none;
  }
`

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 1000%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 91;
`

const WrapCheckbox = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 12px;
  margin-left: 12px;
`

const CheckBox = styled.input`
  width: 16px;
  height: 16px;
  accent-color: #000;
  cursor: pointer;
`

const TextCheckbox = styled.div`
  font-style: normal;
  font-size: 14px;
  font-weight: 500;
  /* cursor: pointer; */
  margin-left: 5px;
`

const ButtonID = styled.button`
  width: 100%;
  height: 42px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #d9af93;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 10px;
  }
  :hover {
    background-color: rgb(255, 102, 0);
    color: #fff;
    span {
      color: #fff;
    }
  }
`
